import { Stack } from "@fluentui/react";
import moment from "moment";
import React, { Component } from "react";
import { localize } from "src/l10n";
import { Label, UserHovercard } from "src/ui";
import { Style } from "src/ui/helpers";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./SocialSystemStatus.scss";
import { Link } from "react-router-dom";

interface IProps {
    post: Spintr.ISocialSystemStatus;
}

interface IState {}

class SocialSystemStatus extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    public render() {
        return (
            <div className="SocialSystemStatus">
                <div>
                    <Visage2Icon size="small" color="primaryContent" icon="clock" />
                    <Label size="body-2" color="primaryContent">
                        {moment(this.props.post.startDate).calendar({ sameElse: "lll" })}
                        {this.props.post.endDate && this.props.post.endDate !== this.props.post.startDate && (
                            <>
                                &nbsp;{localize("Till_small")} {moment(this.props.post.endDate).calendar({ sameElse: "lll" })}
                            </>
                        )}
                    </Label>
                </div>
                <div>
                    <Visage2Icon size="small" color="primaryContent" icon="user" />
                    <Label size="body-2" color="primaryContent">
                        {localize("SkapadAv") + ": " + this.props.post.UserName}
                    </Label>
                </div>
                {this.props.post.responsibleUser && (
                    <div>
                        <Visage2Icon size="small" color="primaryContent" icon="user" />
                        <Label size="body-2" color="primaryContent">
                            {localize("RESPONSIBLE_PERSON") + ": " + this.props.post.responsibleUser.name}
                        </Label>
                    </div>
                )}
            </div>
        );
    }
}

export default SocialSystemStatus;
