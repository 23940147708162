import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Loader, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./SystemStatusForm.scss";
import { Checkbox, DefaultButton, Dropdown, Image, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { FormControl, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import { IActiveUserProfile } from "src/profile/reducer";
import { FileSelector } from "src/spintr/components";
import { Style } from "src/ui/helpers";
import { useHistory } from "react-router-dom";
import { SpintrTypes } from "src/typings";
import { setCurrentResource } from "./actions";
import OpenTimesEditor from "src/ui/components/Forms/OpenTimesEditor";

interface IProps {
    currentUser?: IActiveUserProfile;
    currentResource?: Spintr.ISystemStatusResource;
    enableCompany?: boolean;
    id?: number;
    onDone: () => void;
    onCancel: () => void;
}

const SystemStatusForm: FunctionComponent<IProps> = ({ currentUser, currentResource, enableCompany, id, onDone, onCancel }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [newResource, setNewResource] = useState<Spintr.ISystemStatusResource>();
    const [showProfileImageSelector, setShowProfileImageSelector] = useState(false);
    const [isLoadingItem, setIsLoadingItem] = useState(false);
    const [categories, setCategories] = useState([]);

    const fetchCategories = useCallback(() => {
        api.get("/api/v1/systemstatuses/categories").then((response) => {
            setCategories(response.data);
        });
    }, []);

    useEffect(() => {
        fetchCategories();

        if (id) {
            fetchResource(id);
        } else {
            // @ts-ignore
            setNewResource({
                targets: [],
                owners: [
                    {
                        id: currentUser.id,
                        key: currentUser.id,
                        name: currentUser.name,
                        imageUrl: currentUser.images.feedComposer,
                        subText: currentUser.department.name,
                    },
                ],
                faq: [],
                categoryId: -1
            });
        }
    }, []);

    const fetchResource = (id: number) => {
        setIsLoadingItem(true);
        api.get("/api/v1/systemstatuses/resources/" + id).then((response) => {
            const resource = response.data;

            resource.owners = resource.owners.map((owner) => ({
                ...owner,
                imageUrl: owner.image,
            }));

            resource.targets = resource.targets || [];
            resource.openTimes = (resource.openTimes || []).map(x => {
                return {
                    ...x,
                    startTime: new Date(x.startTime),
                    endTime: new Date(x.endTime)
                }
            });
            resource.enableOpenTimes = resource.openTimes.length > 0;

            setNewResource(resource);
            setIsLoadingItem(false);
        });
    };

    const toggleShowProfileImageSelector = () => {
        setShowProfileImageSelector(!showProfileImageSelector);
    };

    const saveResource = async () => {
        setIsLoadingItem(true);

        if (newResource.id) {
            const resauce = await api.put<Spintr.ISystemStatusResource>(
                "/api/v1/systemstatuses/resources/" + newResource.id,
                {
                    ...newResource,
                }
            );

            if (currentResource?.id === newResource.id) {
                dispatch(setCurrentResource(resauce.data));
            }
        } else {
            const resauce = await api.post<Spintr.ISystemStatusResource>("/api/v1/systemstatuses/resources", {
                ...newResource,
            });

            history.push({pathname: "/operations/" + resauce.data.id});
        }

        onDone();
    };

    const cancel = () => {
        onCancel();
    }

    const onChangeText = (ev, val) => {
        const name = ev.target.name;

        setNewResource({
            ...newResource,
            [name]: val,
        });
    };

    const onChangeOwners = (owners) => {
        setNewResource({
            ...newResource,
            owners: owners.map((owner) => ({
                id: owner.key,
                ...owner,
            })),
        });
    };

    const onChangeTargets = (targets) => {
        setNewResource({
            ...newResource,
            targets: targets.map((target) => ({
                id: target.key,
                ...target,
            })),
        });
    };

    const onAddFAQ = () => {
        setNewResource({
            ...newResource,
            faq: [...newResource.faq, { question: "", answer: "" }],
        });
    };

    const onRemoveFAQ = (removeIdx: number) => {
        setNewResource({
            ...newResource,
            faq: newResource.faq.filter((f, idx) => idx !== removeIdx),
        });
    };

    if (isLoadingItem) {
        return <Loader />;
    }

    return (
        <form>
            <FormControl>
                <TextField
                    label={localize("Namn")}
                    value={newResource?.title}
                    name="title"
                    onChange={onChangeText}
                />
            </FormControl>
            <FormControl>
                <TextField
                    label={localize("Beskrivning")}
                    value={newResource?.description}
                    name="description"
                    onChange={onChangeText}
                />
            </FormControl>
            <FormControl>
                <Dropdown
                    label={localize("Kategori")}
                    selectedKey={newResource?.categoryId}
                    onChange={(_e, v) => {
                        setNewResource({
                            ...newResource,
                            categoryId: Number(v.key),
                            categoryName: ""
                        });
                    }}
                    defaultValue={-1}
                    options={[
                        {
                            key: -1,
                            text: localize("Ingen")
                        },
                        ...categories.map((c) => {
                            return {
                                key: c.id,
                                text: c.name
                            }
                        }),
                        {
                            key: 0,
                            text: localize("SkapaNyKategori")
                        }
                    ]}
                    styles={{ dropdown: { width: 250 } }}
                />
            </FormControl>
            {newResource?.categoryId === 0 && (
                <FormControl>
                    <TextField
                        label={localize("Kategorinamn")}
                        value={newResource?.categoryName}
                        name="categoryname"
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                            setNewResource({
                                ...newResource,
                                categoryName: newValue
                            });
                        }}
                    />
                </FormControl>
            )}
            <FormControl>
                <FormTokenizedObjectInput
                    items={newResource?.owners}
                    types={[SpintrTypes.UberType.User]}
                    label={localize("SYSTEM_OWNERS")}
                    onChange={onChangeOwners}
                />
            </FormControl>
            <FormControl>
                <FormTokenizedObjectInput
                    items={newResource?.targets}
                    types={[
                        SpintrTypes.UberType.TargetGroup,
                        SpintrTypes.UberType.Role,
                        SpintrTypes.UberType.Department,
                        SpintrTypes.UberType.Office,
                        ...(enableCompany ? [SpintrTypes.UberType.Company] : []),
                    ]}
                    label={localize("Malgrupp")}
                    onChange={onChangeTargets}
                    showAllWhenEmpty
                />
            </FormControl>
            <FormControl label={localize("OPEN_TIMES")}>
                <Checkbox
                    label={localize("ENABLE_OPEN_TIMES")}
                    checked={newResource?.enableOpenTimes}
                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                        setNewResource({
                            ...newResource,
                            enableOpenTimes: checked
                        });
                    }}
                />
                {newResource?.enableOpenTimes && (
                    <OpenTimesEditor
                        openTimes={newResource?.openTimes || []}
                        onUpdate={(openTimes: any[]) => {
                            setNewResource({
                                ...newResource,
                                openTimes
                            });
                        }}
                    />
                )}
            </FormControl>
            <FormSection>
                <FormControl label={localize("Bild")}>
                    <PrimaryButton onClick={toggleShowProfileImageSelector}>{localize("ValjBild")}</PrimaryButton>
                </FormControl>
                {newResource?.imageUrl && (
                    <>
                        <Image src={newResource?.imageUrl} className="profile-image" width={100} height={100} />
                        <FormControl>
                            <PrimaryButton
                                style={{ marginTop: Style.getSpacingStr(3) }}
                                onClick={() => {
                                    setNewResource({
                                        ...newResource,
                                        imageTicket: {
                                            remove: true,
                                        },
                                        imageUrl: "",
                                    });
                                }}
                            >
                                {localize("TaBortNuvarande")}
                            </PrimaryButton>
                        </FormControl>
                    </>
                )}
                {showProfileImageSelector && (
                    <FileSelector
                        renderSizeString={() => {
                            return `${localize("OptimalBildStorlek")}: 100x100px <br />`;
                        }}
                        cropImage
                        cropShape="round"
                        cropAspect={1}
                        onClose={toggleShowProfileImageSelector}
                        onSelect={(data) => {
                            toggleShowProfileImageSelector();
                            setNewResource({
                                ...newResource,
                                imageUrl: data[0].thumbnailUrl,
                                imageTicket: {
                                    ticket: data[0].ticket,
                                },
                            });
                        }}
                        allowMultipleFiles={false}
                        fileTypesString={"image/png, image/jpeg"}
                        fileTypes={["png", "jpeg", "jpg"]}
                        sourcesToDisplay={[0]}
                        fileUploadType={1}
                    />
                )}
            </FormSection>
            <FormSection title={localize("FragorOchSvar")}>
                {newResource?.faq.map((faq, idx) => (
                    <FormSection key={idx} className="faq-item">
                        <FormControl className="question-control">
                            <TextField
                                label={localize("Fraga")}
                                value={faq.question}
                                onChange={(ev, val) => {
                                    const faqs = [...newResource.faq];
                                    faqs[idx].question = val;
                                    setNewResource({
                                        ...newResource,
                                        faq: faqs,
                                    });
                                }}
                            />
                            <UnstyledButton
                                className="remove-button"
                                onClick={() => {
                                    onRemoveFAQ(idx);
                                }}
                            >
                                <Visage2Icon icon="trash" />
                            </UnstyledButton>
                        </FormControl>
                        <FormControl>
                            <TextField
                                label={localize("Svar")}
                                value={faq.answer}
                                onChange={(ev, val) => {
                                    const faqs = [...newResource.faq];
                                    faqs[idx].answer = val;
                                    setNewResource({
                                        ...newResource,
                                        faq: faqs,
                                    });
                                }}
                            />
                        </FormControl>
                    </FormSection>
                ))}
                <UnstyledButton className="create-button primaryBGColor visage-box-shadow" onClick={onAddFAQ}>
                    <Visage2Icon icon="add" color="white" />
                </UnstyledButton>
            </FormSection>
            <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                <DefaultButton onClick={cancel} text={localize("Avbryt")} />
                <PrimaryButton onClick={saveResource} text={localize("Spara")} />
            </Stack>
        </form>
    );
};

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    currentUser: state.profile.active,
    currentResource: state.systemStatus.currentResource,
    enableCompany: state.instance.get("enableCompanyLevel"),
});

export default connect(mapStateToProps)(SystemStatusForm);
