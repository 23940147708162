import React, { CSSProperties, ReactElement, useCallback, useMemo } from "react";
import { TimelineGroupProps } from "./TimelineGroup.types";
import { TimelineRow } from "../TimelineRow";
import { UnstyledButton } from "src/ui";
import { CaptionBody, SmallBody } from "src/components/Text";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Conditional } from "src/components/Conditional";
import classNames from "classnames";
import { localize } from "src/l10n";

function TimelineGroup({
    axisWidth,
    displayGroupHeader,
    group,
    hasWriteAccess,
    cumulativeIndex,
    offset,
    onBarClick,
    onBarGroupClick,
    onCategoryUpdate,
    onGroupClick,
    onItemDurationChange,
    onDisplayedItemsChange,
    onRailClick,
    timelineDuration,
    timelineMode,
    timelineSize,
    todayTime
}: TimelineGroupProps): ReactElement {
    const categories = group.categories || [];

    const onAxisClicked = useCallback(
        () => onGroupClick?.(group),
        [group, onGroupClick],
    );

    const onViewMoreClicked = useCallback(
        () => onDisplayedItemsChange?.(
            group,
            group.itemsDisplayed >= (group.categories || []).length
                ? Math.min((group.categories || []).length, 3)
                : (group.categories || []).length),
        [group, onDisplayedItemsChange],
    );

    const categoryStyle = useMemo<CSSProperties>(() => ({
        top: `${offset}px`,
    }), [axisWidth, offset]);

    const labelStyle = useMemo<CSSProperties>(() => ({
        height: `52px`,
        overflow: axisWidth > 0 ? undefined : "hidden",
        padding: axisWidth > 0 ? undefined : 0,
        top: `${offset}px`,
        width: `${axisWidth}px`,
    }), [axisWidth, offset]);

    const viewMoreStyle = useMemo<CSSProperties>(() => ({
        overflow: axisWidth > 0 ? undefined : "hidden",
        height: "40px",
        top: categories
            .slice(0, group.itemsDisplayed)
            .reduce((acc, category) => category.items.some((barGroup) => barGroup.expanded)
                ? acc + 50 + 50 * Math.max(...category.items.filter((barGroup) => barGroup.expanded).map(barGroup => barGroup.layers))
                : acc + 50, (displayGroupHeader ? 52 : 0)) + "px",
    }), [axisWidth, categories, group.itemsDisplayed]);

    return (
        <div
            className={classNames("TimelineGroup", {
                "expanded": group.expanded,
                "showingAll": categories.length <= group.itemsDisplayed,
            })}
            style={categoryStyle}
        >
            <Conditional condition={displayGroupHeader}>
                <div className="TimelineGroup-axisLabel" style={labelStyle}>
                    <UnstyledButton className="TimelineGroup-axisButton" onClick={onAxisClicked}>
                        <SmallBody color="contentDark" weight="medium">
                            {group.name}
                        </SmallBody>
                        <Visage2Icon className="TimelineGroup-axisIcon" color="spintrGrey" icon="arrow-up-2" size="medium" />
                    </UnstyledButton>
                </div>
            </Conditional>
            <Conditional condition={group.expanded}>   
                <div className="TimelineGroup-items">
                    {categories.slice(0, group.itemsDisplayed).map((category, index) => {
                        const rowStyle: CSSProperties = {
                            backgroundColor: (cumulativeIndex + 1 + index) % 2 === 0
                                ? undefined
                                : "rgba(0, 0, 0, 0.04)",
                            height: category.items.some((barGroup) => barGroup.expanded)
                                ? category.items.reduce((acc, barGroup) => acc + (barGroup.expanded ? 50 + 50 * (barGroup.layers ?? 1) : 0), 0) + "px"
                                : "50px",
                            top: categories
                                .slice(0, index)
                                .reduce((acc, prevCat) => prevCat.items.some((barGroup) => barGroup.expanded)
                                    ? acc + 50 + 50 * Math.max(...prevCat.items.filter((barGroup) => barGroup.expanded).map(barGroup => barGroup.layers))
                                    : acc + 50, (displayGroupHeader ? 52 : 0)) + "px",
                        };

                        return (
                            <TimelineRow
                                axisWidth={axisWidth}
                                category={category} 
                                hasWriteAccess={hasWriteAccess}
                                key={index}
                                onBarClick={onBarClick}
                                onBarGroupClick={onBarGroupClick}
                                onCategoryUpdate={onCategoryUpdate}
                                onItemDurationChange={onItemDurationChange}
                                onRailClick={onRailClick}
                                rowIndex={index}
                                style={rowStyle}
                                timelineDuration={timelineDuration}
                                timelineMode={timelineMode}
                                timelineWidth={timelineSize}
                                todayTime={todayTime} />
                            );
                    })}
                </div>
                <Conditional condition={categories.length > 3 && displayGroupHeader}>
                    <div
                        className="TimelineGroup-viewMore"
                        style={viewMoreStyle}
                    >
                        <UnstyledButton
                            className="TimelineGroup-viewMore-button"
                            onClick={onViewMoreClicked}
                            style={{
                                width: `${axisWidth}px`
                            }}
                        >
                            <CaptionBody color="blue" weight="medium">
                                {localize(categories.length <= group.itemsDisplayed
                                    ? "VisaMindre"
                                    : "VisaFler")}
                            </CaptionBody>

                            <Visage2Icon
                                className="TimelineGroup-viewMore-icon"
                                color="visageMidBlue"
                                icon="arrow-up-2"
                                size="small" />
                        </UnstyledButton>
                    </div>
                </Conditional>
            </Conditional>
        </div>
    );
}

export default TimelineGroup;
