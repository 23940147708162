import React, { FunctionComponent, useMemo } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import "./ResponsiveFooter.scss";
import SpintrHeaderMenu from "../SpintrHeaderMenu";

interface IDispatchProps {}

interface IOwnProps {}

interface IStateProps {
    useColorHeader?: boolean;
    headerColor?: string;
}

type Props = IStateProps & IOwnProps & IDispatchProps;

const ResponsiveFooter: FunctionComponent<Props> = (props) => {
    const headerColor = useMemo(() => {
        return props.useColorHeader ? (props.headerColor ?? "#FFFFFF") : "#FFFFFF";
    }, []);

    return (
        <div className="ResponsiveFooter">
            <div className="spacer" />
            <div className="menu" style={{
                backgroundColor: headerColor
            }}>
                <SpintrHeaderMenu responsive />
            </div>
        </div>
    );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state) => ({
        useColorHeader: state.instance.get("useColorHeader"),
        headerColor: state.instance.get("headerColor"),
    });

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = {};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveFooter);