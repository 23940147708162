import { ActionButton, DefaultButton, IIconProps, PrimaryButton, TooltipHost } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import ThemeContext from "src/style/ThemeContext";
import { SpintrTypes } from "src/typings";
import { Label, setFormFooterBarTop, setFormFooterBarVisible } from "src/ui";
import { Style } from "src/ui/helpers";
import { ActionMenu } from "..";
import ConfirmDialog from "./ConfirmDialog";
import "./FormFooterBar.scss";

interface IProps {
    className?: string;
    onSaveDraftClick?: any;
    onCancelClick?: any;
    onSaveClick?: any;
    buttons?: any[];
    expanded?: any;
    saveText?: string;
    draftText?: string;
    hideCancelButton?: any;
    hideSaveButton?: boolean;
    isSmallViewMode: boolean;
    autosaveText?: string;
    dispatch: any;
    confirmSave?: boolean | string;
    disableSaveButton?: boolean;
    disableSaveButtonReason?: string;
    disableDraftButton?: boolean;
    disableDraftButtonReason?: string;
    onPreviewClick?: any;
    top?: boolean;
}

const cancelIcon: IIconProps = { iconName: "Cancel" };

interface IState {
    showConfirmDialog: boolean;
}

class FormFooterBar extends Component<IProps, IState> {
    public static contextType = ThemeContext;

    state = {
        showConfirmDialog: false,
    };

    componentDidMount = () => {
        this.props.dispatch(setFormFooterBarVisible(true));
    };

    componentWillUnmount = () => {
        this.props.dispatch(setFormFooterBarVisible(false));
    };

    showConfirmDialog = () => {
        var form = document.getElementById("form");
        if (form) {
            //@ts-ignore
            let formValid = form.reportValidity();
            if (formValid) {
                this.setState({ showConfirmDialog: true });
            }
        } else {
            this.setState({ showConfirmDialog: true });
        }
    };

    public render() {
        const classNames = ["FormFooterBar"];

        if (this.props.className) {
            classNames.push(this.props.className);
        }

        const items = [];
        const overflowItems = [];

        const overflowArray = this.props.isSmallViewMode ? overflowItems : items;

        if (!this.props.hideCancelButton) {
            items.push({
                onRender: () => {
                    return (
                        <ActionButton
                            key="cancel"
                            iconProps={cancelIcon}
                            onClick={this.props.onCancelClick}
                        >
                            {localize("Avbryt")}
                        </ActionButton>
                    );
                },
            });
        }

        if (this.props.buttons) {
            // @ts-ignore
            for (const [idx, button] of this.props.buttons.entries()) {
                overflowArray.push({
                    text: button.text,
                    onClick: button.onClick,
                    onRender: () => {
                        return (
                            <div
                                key={idx}
                            >
                                <DefaultButton text={button.text} onClick={button.onClick} />
                            </div>
                        );
                    },
                });
            }
        }

        if (this.props.onPreviewClick) {
            overflowArray.push({
                text: localize("Forhandsgranska"),
                onClick: this.props.onPreviewClick,
                onRender: () => {
                    return (
                        <div
                            key="preview"
                        >
                            <DefaultButton text={localize("Forhandsgranska")} onClick={this.props.onPreviewClick} />
                        </div>
                    );
                },
            });
        }

        if (this.props.onSaveDraftClick) {
            overflowArray.push({
                text: this.props.draftText || localize("SparaSomUtkast"),
                onClick: this.props.onSaveDraftClick,
                disabled: this.props.disableDraftButton,
                onRender: () => {
                    return (
                        <div
                            key="savedraft"
                        >
                            <TooltipHost className="marketplace-tooltip" content={this.props.disableDraftButtonReason}>
                                <DefaultButton
                                    text={this.props.draftText || localize("SparaSomUtkast")}
                                    onClick={this.props.onSaveDraftClick}
                                    disabled={this.props.disableDraftButton}
                                />
                            </TooltipHost>
                        </div>
                    );
                },
            });
        }

        if (!this.props.hideSaveButton) {
            items.push({
                onRender: () => {
                    return (
                        <div
                            key="save"
                        >
                            <TooltipHost className="marketplace-tooltip" content={this.props.disableSaveButtonReason}>
                                <PrimaryButton
                                    color="black"
                                    data-cy="formfooterbar-save"
                                    type={!this.props.onSaveClick ? "submit" : undefined}
                                    text={this.props.saveText || localize("Spara")}
                                    theme={this.context}
                                    disabled={this.props.disableSaveButton}
                                    onClick={() => {
                                        if (this.props.confirmSave) {
                                            return this.showConfirmDialog();
                                        } else if (this.props.onSaveClick) {
                                            return this.props.onSaveClick();
                                        }
                                    }}
                                />
                            </TooltipHost>
                        </div>
                    );
                },
            });
        }

        return (
            <div
                className={classNames.join(" ")}
                style={{
                    backgroundColor: Style.getHexFromSpintrColor("light-grey"),
                    // borderTopColor: Style.getHexFromSpintrColor("dusk-grey"),
                    // borderTopStyle: "solid",
                    // borderTopWidth: 1,
                    zIndex: 42, 
                    // transition: "width 0.3s ease-in-out",
                }}
            >
                <ConfirmDialog
                    title={localize("SkapaInnehall")}
                    message={(typeof this.props.confirmSave !== "boolean") && this.props.confirmSave}
                    show={this.state.showConfirmDialog}
                    onDismiss={() => {
                        this.setState({ showConfirmDialog: false });
                    }}
                    onConfirm={() => {
                        this.setState({ showConfirmDialog: false }, () => {
                            this.props?.onSaveClick();
                        });
                    }}
                />
                <div role="menu" className="menu">
                    <div
                        className="FormFooterBar-left"
                        style={{
                            flex: "1 1 auto",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        {this.props.autosaveText && (
                            <Label
                                className="autosave-text"
                                color="dark-grey"
                                size="body-2"
                            >
                                {this.props.autosaveText}
                            </Label>
                        )}
                    </div>
                    {items.map((item) => item.onRender())}
                    {overflowItems.length > 0 && (
                        <div className="action-menu-wrapper">
                            <ActionMenu
                                categories={[
                                    {
                                        items: overflowItems.map((overflowItem) => ({
                                            text: overflowItem.text,
                                            onClick: overflowItem.onClick,
                                            disabled: overflowItem.disabled,
                                        })),
                                    },
                                ]}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect((appState: IApplicationState) => ({
    expanded: appState.ui.sidebarExpanded,
    isSmallViewMode: appState.ui.viewMode <= SpintrTypes.ViewMode.PhoneLandscape,
}))(FormFooterBar);
