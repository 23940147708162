import React, { FunctionComponent, useEffect, useState } from "react";
import api from "src/spintr/SpintrApi";
import { ActionMenu, Label, Loader, UnstyledButton } from ".";
import moment from "moment";
import "./TaxiFiles.scss";
import { Stack } from "@fluentui/react";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { localize } from "src/l10n";

interface IExpandableCategoryProps {
    title: string;
    className?: any;
}

const ExpandableCategory: FunctionComponent<IExpandableCategoryProps> = ({ title, children, className }) => {
    const [isExpanded, setExpanded] = useState<boolean>(false);

    return (
        <div className={className}>
            <UnstyledButton className="group" onClick={() => setExpanded(!isExpanded)}>
                <Stack horizontal horizontalAlign="space-between">
                    <Label weight="bold">{title}</Label>
                    <Visage2Icon icon={isExpanded ? "arrow-up-2" : "arrow-down-1"} />
                </Stack>
            </UnstyledButton>
            {isExpanded && children}
        </div>
    );
};

const TaxiFiles: FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        api.get<any[]>("/api/v1/integrations/taxifiles")
            .then((response) => {
                const groupedFiles = response.data.reduce((acc, file) => {
                    const { year, month } = file;

                    if (!acc[year]) {
                        acc[year] = {};
                    }

                    if (!acc[year][month]) {
                        acc[year][month] = [];
                    }

                    if (!acc[year][month][file.category]) {
                        acc[year][month][file.category] = [];
                    }

                    acc[year][month][file.category].push(file);

                    return acc;
                }, {});

                setFiles(groupedFiles);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    const isViewable = (name: string) => {
        const viewableExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".pdf", ".png"];
        return viewableExtensions.some((extension) => name.toLowerCase().endsWith(extension));
    }

    return (
        <div className="TaxiFiles">
            {Object.entries(files)
                .reverse()
                .map(([year, months]) => {
                    return (
                        <>
                            <ExpandableCategory key={year} title={year}>
                                {Object.entries(months)
                                    .reverse()
                                    .map(([month, monthCategories]) => (
                                        <ExpandableCategory
                                            key={month}
                                            className="month"
                                            title={moment()
                                                .month(parseInt(month, 10) - 1)
                                                .format("MMMM")}
                                        >
                                            {Object.entries(monthCategories).map(([category, monthFiles]) => (
                                                <ExpandableCategory key={category} title={category}>
                                                    <Stack horizontal grow className="header" tokens={{ childrenGap: 4 }}>
                                                        <Label size="h6" color="mid-grey" weight="medium">
                                                            {localize("Titel")}
                                                        </Label>
                                                    </Stack>
                                                    {(monthFiles as any[]).map((file) => (
                                                        <Stack horizontal className="file" verticalAlign="center">
                                                            <Stack
                                                                horizontal
                                                                grow
                                                                verticalAlign="center"
                                                                tokens={{ childrenGap: 4 }}
                                                            >
                                                                <a
                                                                    href={`/api/v1/servefile/${file.id}?isTaxiFile=true${
                                                                        isViewable(file.aliasName) ? "&show=true" : ""
                                                                    }`}
                                                                    target="_blank"
                                                                >
                                                                    <Label size="body-2">{file.aliasName}</Label>
                                                                </a>
                                                            </Stack>
                                                            <ActionMenu
                                                                categories={[
                                                                    {
                                                                        items: [
                                                                            ...(isViewable(file.aliasName) ? [{
                                                                                text: localize("Visa"),
                                                                                onClick: () => {
                                                                                    window.location.href = `/api/v1/servefile/${file.id}?isTaxiFile=true&show=true`;
                                                                                },
                                                                            }] : []),
                                                                            {
                                                                                text: localize("LaddaNed"),
                                                                                onClick: () => {
                                                                                    window.location.href = `/api/v1/servefile/${file.id}?isTaxiFile=true`;
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                ]}
                                                            />
                                                        </Stack>
                                                    ))}
                                                </ExpandableCategory>
                                            ))}
                                        </ExpandableCategory>
                                    ))}
                            </ExpandableCategory>
                        </>
                    );
                })}
        </div>
    );
};

export default TaxiFiles;
