import React, { CSSProperties, ReactElement, useMemo } from "react";
import { TimelineCellOverlayProps } from "./TimelineCellOverlay.types";
import { calculateBarPosition } from "../utils";

function TimelineCellOverlay({
    columnDuration,
    index,
    intervalCount,
    isToday,
    timelineDuration,
    timelineMode,
    timelineWidth,
}: TimelineCellOverlayProps): ReactElement {
    const style = useMemo<CSSProperties>(() => {
        const { left, right } = calculateBarPosition(
            timelineWidth,
            timelineDuration,
            columnDuration.startMilliseconds,
            columnDuration.endMilliseconds,
        );

        return {
            backgroundColor: isToday ? "rgba(0, 0, 0, 0.04)" : undefined,
            left: `${left}px`,
            right: `${right}px`,
        };
    }, [index, intervalCount, timelineMode, timelineWidth, isToday])

    return (
        <div className="TimelineCellOverlay" style={style} />
    );
}

export default TimelineCellOverlay;
