import React, {  } from 'react';
import "./PinnedMessage.scss";
import { ActionMenu, Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { localize } from 'src/l10n';
import api from 'src/spintr/SpintrApi';
import { unpinMessageRealtime } from 'src/chat/redux';
import { useDispatch } from 'react-redux';

interface IProps {
    message: Spintr.IChatMessage;
    onClick: () => void;
}

const PinnedMessage = (props: IProps) => {
    const dispatch = useDispatch();

    return (
        <UnstyledButton className="PinnedMessage" onClick={props.onClick}>
            <Visage2Icon icon="pin" color="visageGray2" type="custom" size="small" />
            <Label className="left-text" weight="medium">{localize("PINNED_ITEM") + ":"}</Label>
            <div className="line" />
            <Label className="preview-text">{props.message.memoTitle || props.message.text}</Label>
            <div className="spacer" />
            <ActionMenu
                categories={[{
                    items: [{
                        text: localize("TaBort"),
                        onClick: () => {
                            api.delete("/api/v1/messages/" + props.message.id + "/pin").then(() => {}).catch(() => {});
                            dispatch(unpinMessageRealtime(props.message));
                        },
                    }],
                }]}
            />
        </UnstyledButton>
    )
}

export default PinnedMessage;