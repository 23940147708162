import React, {  } from "react";
import "./SystemStatusSocialPostHeader.scss";
import { Label, SpintrUser } from "src/ui";
import { connect } from "react-redux";
import { Style } from "src/ui/helpers";
import { localize } from "src/l10n";
import moment from "moment";
import SystemStatusTypePill from "./SystemStatusTypePill";

interface IProps {
    compact?: boolean;
    post: any;
}

const SystemStatusSocialPostHeader = (props: IProps) => {
    return (
        <div className={[
            "SystemStatusSocialPostHeader",
            ...(props.compact ? ["compact"] : [])
        ].join(" ")}>
            {props.post.content.length > 0 &&
                <SystemStatusTypePill statusType={props.post.content[0].systemStatusType} big />}
            <Label title={moment(props.post.Date).format("lll")} color="grey">
                {moment(props.post.Date).calendar({ sameElse: "lll" })}
            </Label>
        </div>
    )
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    currentUser: state.profile.active,
});

export default connect(mapStateToProps)(SystemStatusSocialPostHeader);

