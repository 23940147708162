import React, { useCallback } from 'react';
import "./MessageTodoList.scss";
import { localize } from 'src/l10n';
import { TextField } from '@fluentui/react';
import { Label, SpintrUser, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import moment from 'moment';
import api from 'src/spintr/SpintrApi';
import { memoTodoUpdated } from 'src/chat/redux';
import { useDispatch } from 'react-redux';

interface IProps {
    data: any;
}

const MessageTodoList = (props: IProps) => {
    const dispatch = useDispatch();

    const toggleCompleted = useCallback((id) => {
        const todo = [...props.data.todos].find(x => x.id === id);

        todo.isCompleted = !todo.isCompleted;

        api.put("/api/grouptodos/" + id + "/savecompleted", todo);

        dispatch(memoTodoUpdated(todo));
    }, [props.data]);

    return (
        <div className="MessageTodoList">
            {props.data.todos.map((todo: any) => {
                return (
                    <div className="todo" key={todo.id}>
                        <div className="top-row">
                            <UnstyledButton onClick={() => toggleCompleted(todo.id)}>
                                <Visage2Icon
                                    icon={todo.isCompleted ? "tick-circle" : "add-circle"}
                                    type={todo.isCompleted ? "bold" : "outline"}
                                    size="small"
                                />
                            </UnstyledButton>
                            <Label>{todo.title}</Label>
                            {todo.assignees.map((user) => {
                                return (
                                    <div className="user-wrapper" key={user.id}>
                                        <SpintrUser
                                            id={user.id}
                                            personalName
                                            name={user.name}
                                            imageUrl={user.image}
                                            hideText
                                            size={16}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        {/* {todo.dueDate && (
                            <Label size="body-3">{moment(todo.dueDate).format("YYYY-MM-DD")}</Label>
                        )} */}
                    </div>
                )
            })}
        </div>
    )
}

export default MessageTodoList;
