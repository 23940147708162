import classnames from "classnames";
import { ContextualMenu, ContextualMenuItemType, Icon } from "@fluentui/react";
import React from "react";
import { Label } from "src/ui";
import { localize } from "../../../l10n";
import "./ActionMenu.scss";
import { Style } from "src/ui/helpers";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { setConfirmPopup, setInfoPopup } from "src/popups/actions";
import api from "src/spintr/SpintrApi";
import { connect } from "react-redux";
import { Action } from "redux";

interface IProps {
    categories: Spintr.IActionMenuCategory[];
    className?: string | undefined;
    icon?: string;
    label?: string;
    isOpen?: boolean;
    onMenuOpened?: () => void;
    onMenuDismissed?: () => void;
    iconColor?: string;
    gapSpace?: number;
    disableHoverEffect?: boolean;
    renderButton?: any;
    renderLeftButton?: any;
    labelColor?: spintrColors;
    menuClassName?: string;
    canReport?: boolean;
    hasReportIcon?: boolean;
    objectId?: number;
    dispatch?: (action: Action) => void;
    reportType?: number;
}

interface State {
    visible: boolean;
    hasBeenVisible: boolean;
}

const convertCategoriesToMenuItems = (categories, canReport, objectId, hasReportIcon, reportType, dispatch) => {
    let menuItems = [];

    if (canReport && objectId) {
        menuItems.push({
            key: "report",
            text: localize("REPORT_CONTENT"),
            ...(hasReportIcon ? { iconProps: { iconName: "Flag" } } : {}),
            onClick: async () => {
                dispatch(
                    setConfirmPopup({
                        isOpen: true,
                        message: localize("REPORT_CONTENT_CONFIRMATION"),
                        onConfirm: () => {
                            api.post(`/api/v1/objects/reports/${objectId}?reportType=${reportType || 1}`);

                            dispatch(
                                setInfoPopup({
                                    isOpen: true,
                                    message: localize("REPORT_CONTENT_THANKS"),
                                })
                            );
                        },
                    })
                );
            },
        });
    }

    for (let c of categories) {
        if (c.title) {
            menuItems.push({
                key: "k" + categories.indexOf(c),
                text: c.title,
                itemType: ContextualMenuItemType.Header,
            });
        }

        for (let ci of c.items) {
            menuItems.push({
                key: ci.key || ci.text,
                onClick: ci.onClick,
                href: ci.href,
                text: ci.text,
                ...(ci.icon ? { iconProps: { iconName: ci.icon } } : null),
                onRenderIcon: ci.onRenderIcon,
                canCheck: ci.canCheck,
                isChecked: ci.isChecked,
                subMenuProps: ci.subMenuProps,
                className: ci.className,
                disabled: ci.disabled,
            });
        }
    }

    return menuItems;
};

class ActionMenu extends React.Component<IProps, State> {
    private rootClassname = "actionmenu-component";
    private linkRef = React.createRef<any>();

    state = {
        visible: false,
        hasBeenVisible: false
    };

    onShowContextualMenu = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ visible: !this.state.visible, hasBeenVisible: true });
    };

    onHideContextualMenu = () => {
        this.setState({
            visible: false
        }, () => {
            if (this.props.onMenuDismissed) {
                this.props.onMenuDismissed();
            }
        });
    };

    onKeyDown = (e) => {
        if ([13, 32].some((k) => e.keyCode === k)) {
            this.onShowContextualMenu(e);
        }
    };

    render() {
        return (
            <a
                title={localize("VisaAlternativ")}
                onKeyDown={this.onKeyDown}
                tabIndex={0}
                ref={this.linkRef}
                onClick={this.onShowContextualMenu}
                className={classnames(`${this.rootClassname}`, this.props.className, "print-ignore", { ["open"]: this.state.visible, ["hover-effect"]: !this.props.disableHoverEffect })}
            >
                {
                    this.props.renderLeftButton && this.props.renderLeftButton()
                }
                {
                    !this.props.renderButton && this.props.label && (
                        <Label as="span" size="body-3" color={this.props.labelColor ? this.props.labelColor : "dark-grey"}>
                            {this.props.label}
                        </Label>
                    )
                }
                {
                    this.props.renderButton && this.props.renderButton()
                }
                {
                    !this.props.renderButton && !!this.props.icon && (
                        <Visage2Icon icon={this.props.icon} hexColor={this.props.iconColor} />
                    )
                }
                {
                    !this.props.renderButton && !this.props.icon && (
                        <Visage2Icon
                            className="hide-circle"
                            icon="more-square"
                            hexColor={this.props.iconColor ? this.props.iconColor : Style.getHexFromSpintrColor("mid-grey")}
                        />
                    )
                }
                {
                    !!this.state.hasBeenVisible && (
                        <ContextualMenu
                            items={convertCategoriesToMenuItems(
                                this.props.categories,
                                this.props.canReport,
                                this.props.objectId,
                                this.props.hasReportIcon,
                                this.props.reportType,
                                this.props.dispatch
                            )}
                            hidden={!this.state.visible}
                            target={this.linkRef}
                            onMenuOpened={this.props.onMenuOpened}
                            onMenuDismissed={this.props.onMenuDismissed}
                            onItemClick={this.onHideContextualMenu}
                            gapSpace={this.props.gapSpace}
                            onDismiss={this.onHideContextualMenu}
                            className={this.props.menuClassName}
                        />
                    )
                }
            </a>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(ActionMenu);
