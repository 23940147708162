import { Dropdown, Stack } from "@fluentui/react";
import classNames from "classnames";
import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import { DragDropContext, DragStart, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Axios, { AxiosError } from "axios";
import { localize } from "src/l10n";

import {
    setSidebarMode,
    VisageSidebarMode,
} from "src/sidebar"
import {
    IStartPageBuilderContext,
    StartPageBuilderContextProvider,
    StartPageBuilderFooter,
    StartPageBuilderGrid,
    StartPageBuilderMenu
} from "src/spintr/components";
import { StartPageView } from "src/spintr/components/StartPageView";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import SpintrLoader from "src/ui/components/Loader";
import { FormFooterBar } from "src/ui/components/Forms";
import { generateUuid, MixPanelEvents, mixpanelTrack, NoOp, roundToNearest } from "src/utils";
import { setStartPageBuilderMode } from "src/ui";
import { updateInstanceProperty } from "src/instance/actions";

const stepSize = 5;

interface IRouteParams {
    id: string;
}

interface IState {
    isLoading: boolean;
    mode: SpintrTypes.StartPageBuilderMode;

    name?: string;
    contentStatus?: SpintrTypes.ContentStatus;
}

const getDefaultRows = (): Spintr.IStartPageRow[] => {
    return [{
        columns: [{
            id: generateUuid(),
            items: [],
            width: 100,
        }],
        id: generateUuid(),
    }]
}

const StartPageEditView: FunctionComponent = () => {
    const dispatch = useDispatch();

    const dynamoEnabled = useSelector<Spintr.AppState, boolean>(
        (appState) => appState.instance.get("dynamoEnabled") as boolean,
    );

    const userRoles = useSelector<Spintr.AppState, string[]>(
        (appState) => appState.profile.active.roles,
    );

    const rightColumns = useSelector<Spintr.AppState, any[]>(
        (appState) => appState.rightColumn.items || [],
    );

    const activeLayoutId = useSelector<Spintr.AppState, string>(
        (state) => state.instance.get("defaultStartPageLayout")?.id,
    );

    const defaultLayout = useMemo<Spintr.IStartPageRow[]>(
        () => {
            const rightColumn = rightColumns.some((col) => (col.contexts || []).indexOf("start") > -1)
                ? rightColumns.find((col) => (col.contexts || []).indexOf("start") > -1)
                : rightColumns[0] || { formattedData: [] };

            const mapName = (name : string): string => {
                switch (name) {
                    case "birthdays":
                        return "Birthdays";

                    case "dashboard":
                        return "Dashboard";

                    case "operatinginfo":
                        return "OperatingInfo";

                    case "todo":
                        return "Todo";

                    default:
                        return "";
                }
            };

            const rightColumnComponents = (rightColumn.formattedData || [])
                .map((section: any) => (section.widgets || [])
                    .map((widget: any) => {
                        return {
                            componentKey: mapName(widget.name),
                            id: generateUuid(),
                        } as Spintr.IStartPageComponent;
                    })
                )
                .flat()
                .filter((obj: Spintr.IStartPageComponent) => !!obj.componentKey);


            return [{
                columns: [{
                    id: generateUuid(),
                    items: [{
                        componentKey: "SocialFeed",
                        id: generateUuid(),
                    }],
                    name: localize("SocialtFlode"),
                    width: 35
                }, {
                    id: generateUuid(),
                    items: [{
                        componentKey: "SpotlightNews",
                        id: generateUuid(),
                    },{
                        componentKey: "DataBoxes",
                        id: generateUuid(),
                    },{
                        componentKey: "TeaserBoxes",
                        id: generateUuid(),
                    },{
                        componentKey: "BlogBoxes",
                        id: generateUuid(),
                    },{
                        componentKey: "NewsFeed",
                        id: generateUuid(),
                    }],
                    name: localize("Nyheter"),
                    width: rightColumnComponents.length > 0 ? 50 : 65,
                }, {
                    id: generateUuid(),
                    items: rightColumnComponents,
                    name: localize("Hogerspalter"),
                    width: 15
                }].filter((c) => c.items.length > 0),
                id: generateUuid(),
            }];
        },
        [rightColumns],
    );

    const isAdministrator = useMemo(
        () => userRoles.indexOf("administrators") !== -1,
        [userRoles],
    );

    const [rows, setRows] = useState<Spintr.IStartPageRow[]>(getDefaultRows());
    const [state, setState] = useState<IState>({
        isLoading: true,
        mode: SpintrTypes.StartPageBuilderMode.Edit
    });
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { isLoading, mode } = state;
    
    const [context, setContext] = useState<IStartPageBuilderContext>({
        stepSize,
        widthStep: 0,

        addCell: NoOp,
        removeComponent: NoOp,
        resizeComponent: NoOp,
        setDrawerOpen: setDrawerOpen,
        setColumnName: NoOp,
        setSelected: NoOp,
        updateWidth: NoOp, // Need to set this after setContext has been produced
        updateComponent: NoOp,
    });
    
    const history = useHistory();
    const { id: layoutId } = useParams<IRouteParams>();
    useEffect(
        () => {
            if (!isAdministrator || !dynamoEnabled) {
                return;
            }

            const cancelTokenSource = Axios.CancelToken.source();

            if (!layoutId) {
                // This is only here temporarily
                api.get<Spintr.ILayoutTemplateQueryResponseEnvelope>(
                    "/api/v1/layouttemplates", {
                        cancelToken: cancelTokenSource.token,
                        params: { statuses: 3 },
                    })
                    .then((response) => {
                        const { items } = response.data
                        if (items && items.length > 0) {
                            const layout = items[0];
                            
                            history.push(`/edit-startpage/${layout.id}`);
                            return;
                        }

                        const model: Spintr.ILayoutTemplateRequestModel = {
                            name: localize("Sidlayout"),
                            jsonData: JSON.stringify(defaultLayout),
                            status: SpintrTypes.ContentStatus.Draft,
                            type: SpintrTypes.LayoutType.StartPage,
                        }

                        api.post<Spintr.ILayoutTemplate>(
                            `/api/v1/layouttemplates`,
                            model
                        ).then((createdResponse) => {
                            history.push(`/edit-startpage/${createdResponse.data.id}`);
                        }).catch((err: AxiosError<Spintr.IErrorResponse | undefined>) => {
                            console.error(err);
        
                            history.push("/");
                        });
                    })
                    .catch((err: AxiosError<Spintr.IErrorResponse | undefined>) => {
                        console.error(err);
    
                        history.push("/edit-startpage");
                    });
                return;
            }

            api.get<Spintr.ILayoutTemplate>(
                `/api/v1/layouttemplates/${layoutId}`, {
                    cancelToken: cancelTokenSource.token
                })
                .then((response) => {
                    const layout = response.data;
                    if (layout.type !== SpintrTypes.LayoutType.StartPage) {
                        history.push("/edit-startpage");
                        return;
                    }

                    setState((s) => ({
                        ...s,
                        contentStatus: layout.status,
                        isLoading: false,
                        name: layout.name,
                    }));
                    setRows(JSON.parse(layout.jsonData));
                })
                .catch((err: AxiosError<Spintr.IErrorResponse | undefined>) => {
                    console.error(err);

                    history.push("/edit-startpage");
                });

            return () => cancelTokenSource.cancel();
        },
        [layoutId, setState, setRows, history],
    );

    const addComponent = useCallback(
        (id: string, addBefore: boolean) => setRows((rs) => {
            if (rs.length === 0) {
                return [{
                    columns: [{
                        id: generateUuid(),
                        items: [],
                        width: 100,
                    }],
                    id: generateUuid(),
                }];
            }

            const rowIndex = rs.findIndex((row) => row.id === id);
            if (rowIndex !== -1) {
                const items = rs.slice();
                items.splice(addBefore ? rowIndex : rowIndex + 1, 0, {
                    columns: [{
                        id: generateUuid(),
                        items: [],
                        width: 100,
                    }],
                    id: generateUuid(),
                });

                return items;
            }

            return rs.map((row) => {
                if (row.columns.every((col) => col.id !== id && col.items.every((item) => item.id !== id))) {
                    return row;
                }

                if (row.columns.length === 3) {
                    return row;
                }

                const colIndex = row.columns.findIndex((col) => col.id === id);
                if (colIndex === -1) {
                    return row;
                }
                
                const newColumn = {
                    id: generateUuid(),
                    items: [],
                    width: (() => {
                        switch (row.columns.length) {
                            case 0:
                                return 100;
                            case 1:
                                return 50;
                            default:
                                return roundToNearest(100 / (row.columns.length + 1), 5);
                        }
                    })(),
                };

                const items = row.columns.slice().map((col) => ({
                    ...col,
                    width: roundToNearest((100 - newColumn.width) / row.columns.length, 5),
                }));

                items.splice(addBefore ? colIndex : colIndex + 1, 0, newColumn);
                const totalWidth = items.reduce((acc, col) => acc + col.width, 0);
                if (totalWidth > 100) {
                    newColumn.width -= totalWidth - 100;
                }

                return {
                    ...row,
                    columns: items,
                };
            });
        }),
        [setRows],
    );

    useEffect(
        () => setContext((s) => ({
            ...s,

            addCell: addComponent,

            removeComponent: (id) => setRows((rs) => rs
                .filter((row) => row.id !== id)
                .map((row) => {
                    if (row.columns.every((col) => col.id !== id && col.items.every((item) => item.id !== id))) {
                        return row;
                    }

                    if (row.columns.every((col) => col.id !== id)) {
                        // ID refers to a cell
                        return {
                            ...row,
                            columns: row.columns
                                .map((col) => col.items.every((item) => item.id !== id)
                                    ? col
                                    : {
                                        ...col,
                                        items: col.items.filter((item) => item.id !== id),
                                    },
                                )
                        }
                    }

                    const colIndex = row.columns.findIndex((col) => col.id === id);
                    if (colIndex === -1) {
                        return row;
                    }

                    if (row.columns.length === 1) {
                        return {
                            ...row,
                            columns: [],
                        };
                    }

                    const columns = row.columns.slice();
                    const ejected = columns.splice(colIndex, 1)[0];
                    const widthPerCol = roundToNearest(ejected.width / columns.length, 5);
                    const totalSize = (widthPerCol * columns.length) + columns.reduce(
                        (acc, col) => acc + col.width,
                        0
                    );

                    // likely never more than 5
                    const sizeToSubtract = Math.max(0, totalSize - 100);
                    
                    return {
                        ...row,
                        columns: columns.map((col, index) => ({
                            ...col,
                            width: col.width + widthPerCol - (index === columns.length - 1 ? sizeToSubtract : 0),
                        })),
                    };
                })
                .filter((row) => row.columns.length > 0)),

            resizeComponent: (id, size) => setRows((rs) => ([
                ...rs.map((row) => ({
                    ...row,
                    columns: row.columns.map((col) => col.id !== id ? col : ({
                        ...col,
                        width: size,
                    })),
                })),
            ])),

            setColumnName: (id, name) => setRows(rows => rows.map(
                (row) => row.columns.every((col) => col.id !== id)
                    ? row
                    : {
                        ...row,
                        columns: row.columns.map(
                            (col) => col.id !== id
                                ? col
                                : {
                                    ...col,
                                    name,
                                }
                        )
                    }
            )),

            setSelected: (id) => setContext((c) => ({
                ...c,
                selectedId: id,
            })),

            updateComponent: (id, component) => setRows(rows => rows.map(
                (row) => row.columns.every((col) => col.items.every((c) => c.id !== id))
                    ? row
                    : {
                        ...row,
                        columns: row.columns.map(
                            (col) => col.items.every((c) => c.id !== id)
                                ? col
                                : {
                                    ...col,
                                    items: col.items.map((cell) => cell.id !== id
                                        ? cell
                                        : {
                                            ...cell,
                                            ...component,
                                        })
                                }
                        )
                    }
            )),

            updateWidth: (width) => setContext((c) => ({
                ...c,
                widthStep: Math.floor(width / (100 / stepSize)),
            })),
        })),
        [setContext, setRows, addComponent],
    );

    useEffect(
        () => {
            dispatch(setSidebarMode(VisageSidebarMode.noMenu));

            return () => {
                dispatch(setSidebarMode(VisageSidebarMode.menu));
            };
        },
        [dispatch],
    );

    const onDragStarted = useCallback(
        (initial: DragStart, provided: ResponderProvided): void => {
            const isUuid = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gmi.test(initial.draggableId);
            if (isUuid) {
                return;
            }

            setDrawerOpen(false);
        },
        [setDrawerOpen],
    );

    const onModeChanged = useCallback(
        (mode: SpintrTypes.StartPageBuilderMode) => {
            setState((s) => ({ ...s, mode }));
            switch (mode) {
                case SpintrTypes.StartPageBuilderMode.Edit:
                    mixpanelTrack(MixPanelEvents.Dynamo_EnterEditMode);
                    break;
                    
                case SpintrTypes.StartPageBuilderMode.DesktopPreview:
                    mixpanelTrack(MixPanelEvents.Dynamo_PreviewDesktop);
                    break;

                case SpintrTypes.StartPageBuilderMode.ResponsivePreview:
                    mixpanelTrack(MixPanelEvents.Dynamo_PreviewMobile);
                    break;
            }
        },
        [setState],
    );

    const onDragEnd = useCallback((result: DropResult, provided: ResponderProvided) => {
        if (result.reason === "CANCEL") {
            return;
        }

        const destinationId = result.destination?.droppableId;
        if (!destinationId) {
            return;
        }

        const isUuid = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gmi.test(result.draggableId);
        if (!isUuid) {
            mixpanelTrack(MixPanelEvents.Dynamo_DropNewComponent, {key: result.draggableId});
            
            let componentProps: { [key: string]: any } | undefined;
            if (result.draggableId === "NewsFeed") {
                componentProps = {
                    template: 2,
                };
            }
            
            // New component
            setRows((rowState) => rowState.map((row) => {
                const isTargetRow = (
                    row.id === destinationId ||
                    row.columns.some((column) => (
                        column.id === destinationId ||
                        column.items.some((item) => item.id === destinationId))
                    )
                );

                if (!isTargetRow) {
                    return row;
                }

                if (row.id === destinationId) {
                    return {
                        ...row,
                        columns: [{
                            id: generateUuid(),
                            items: [{
                                componentKey: result.draggableId,
                                id: generateUuid(),
                                props: componentProps,
                            }],
                            name: result.draggableId,
                            width: 100,
                        }]
                    };
                }

                return {
                    ...row,
                    columns: row.columns.map((column) => {
                        const isTargetColumn = (
                            column.id === destinationId ||
                            column.items.some((item) => item.id === destinationId)
                        );

                        if (!isTargetColumn) {
                            return column;
                        }

                        if (column.id === destinationId) {
                            return {
                                ...column,
                                name: column.name || result.draggableId,
                                items: [
                                    ...column.items.slice(0, result.destination.index),
                                    {
                                        componentKey: result.draggableId,
                                        id: generateUuid(),
                                        props: componentProps,
                                    },
                                    ...column.items.slice(result.destination.index)
                                ]
                            }
                        }

                        // idfk
                        return {
                            ...column,
                        }
                    })
                };
            }));
            return;
        }

        const sourceId = result.source.droppableId;

        mixpanelTrack(MixPanelEvents.Dynamo_MoveComponent);
        setRows((currentRows) => {
            let component: Spintr.IStartPageComponent | undefined;
            let nextRows = currentRows.map(
                (row) => row.id !== sourceId && row.columns.every((col) => col.id !== sourceId)
                    ? row
                    : {
                        ...row,
                        columns: row.columns.map((col) => {
                            if (col.id !== sourceId) {
                                return col;
                            }

                            const items = col.items.slice();
                            component = items.splice(result.source.index, 1)[0];
                            
                            return !component ? col : {
                                ...col,
                                items,
                            };
                        }),
                    },
            );

            if (!component) {
                return nextRows;
            }

            return nextRows.map((row) => row.id !== destinationId && row.columns.every((col) => col.id !== destinationId)
                ? row
                : {
                    ...row,
                    columns: row.columns.map((col) => {
                        if (col.id !== destinationId) {
                            return col;
                        }

                        const items = col.items.slice();
                        items.splice(result.destination.index, 0, component);

                        return {
                            ...col,
                            items,
                        };
                    }),
            });
        });
    }, [setRows]);

    const save = useCallback(
        (status: SpintrTypes.ContentStatus) => {
            if (!layoutId) {
                return;
            }

            setState((s) => ({ ...s, isLoading: true }));

            const jsonData = JSON.stringify(rows);
            const model: Spintr.ILayoutTemplateRequestModel = {
                name: state.name,
                jsonData,
                status: status,
                type: SpintrTypes.LayoutType.StartPage,
            }

            const promise = api.put<Spintr.ILayoutTemplate>(
                `/api/v1/layouttemplates/${layoutId}`,
                model
            );

            promise
                .then((response) => {
                    setState((s) => ({ ...s, isLoading: false }));

                    if (layoutId === activeLayoutId) {
                        dispatch(updateInstanceProperty("defaultStartPageLayout", {
                            id: layoutId,
                            name: state.name,
                            jsonData,
                        }));
                    }
                })
                .catch((err: AxiosError<Spintr.IErrorResponse | undefined>) => {
                    console.error(err);
                });
        },
        [layoutId, rows, setState, state, activeLayoutId],
    )

    const onSaveClick = useCallback(
        () => save(state.contentStatus),
        [save, state.contentStatus]
    );
    const [departments, setDepartments] = useState<any>([]);
    const [themes, setThemes] = useState<any>([]);

    useEffect(() => {
        dispatch(setStartPageBuilderMode(state.mode));
    }, [state.mode, dispatch]);

    /*
    useEffect(() => {
        api.get("/api/v1/units/simple").then((response) => {
            setDepartments(
                response.data.map((dep) => ({
                    key: dep.id,
                    text: dep.name,
                }))
            );
        });

        api.get("/api/v1/themes", {
            params: {
                includeDeleted: false,
                take: 9999,
            },
        }).then((response) => {
            setThemes(
                response.data.map((theme) => ({
                    key: theme.id,
                    text: theme.name,
                }))
            );
        });
    }, []);

    const onRenderLeft = useCallback(() => {
        return (
            <Stack horizontal>
                <Dropdown
                    options={[
                        {
                            key: 0,
                            text: localize("MinAvdelning"),
                        },
                        ...departments,
                    ]}
                    label={localize("VIEW_AS")}
                />

                <Dropdown
                    options={[
                        {
                            key: 0,
                            text: "Temanamn",
                        },
                        ...themes,
                    ]}
                    label={localize("VIEW_WITH_THEME")}
                />
            </Stack>
        );
    }, [departments, themes]);
    */

    const onBackgroundClick = useCallback(
        (event: React.MouseEvent) => {
            const { target } = event;
            if (!target || !(target instanceof HTMLElement)) {
                return;
            }

            if (target.id !== "StartPageEditView" && !target.classList.contains("grid-wrapper")) {
                return;
            }

            setContext((ctx) => ({
                ...ctx,
                selectedId: undefined,
            }));
            setDrawerOpen(false);
        },
        [setContext],
    );

    if (!isAdministrator || !dynamoEnabled) {
        return <Redirect to="/" />;
    }

    return (
        <div
            id="StartPageEditView"
            className={classNames({
                "drawer-open": drawerOpen,
                "editing": state.mode === SpintrTypes.StartPageBuilderMode.Edit,
            })}
            onClick={onBackgroundClick}
        >
            {isLoading && <SpintrLoader />}
            {!isLoading && (
                <StartPageBuilderContextProvider value={context}>
                    <DragDropContext
                        onDragStart={onDragStarted}
                        onDragEnd={onDragEnd}
                    >
                        <StartPageBuilderMenu
                            drawerIsOpen={drawerOpen}
                            onDrawerToggle={setDrawerOpen}
                            onModeChange={onModeChanged}
                            onSaveClick={onSaveClick}
                        />
                        <div
                            className="grid-wrapper"
                        >
                            {mode === SpintrTypes.StartPageBuilderMode.Edit && (
                                <StartPageBuilderGrid
                                    rows={rows}
                                />
                            )}
                            {mode !== SpintrTypes.StartPageBuilderMode.Edit && (
                                <StartPageView
                                    rows={rows}
                                    responsive={mode === SpintrTypes.StartPageBuilderMode.ResponsivePreview}
                                />
                            )}
                        </div>
                    </DragDropContext>
                    <StartPageBuilderFooter
                        onSaveClick={save}
                    />
                    {/* Transition to this later
                    <FormFooterBar
                        onRenderLeft={onRenderLeft}
                    />
                    */}
                </StartPageBuilderContextProvider>
            )}
        </div>
    );
};

export default StartPageEditView;
