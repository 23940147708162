import React from "react";
import { IAcademyCourse } from "src/academy/types";
import "./AcademyCourseSidebarMeta.scss";
import { Label } from "src/ui";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import languages from "src/utils/languages";
import moment from "moment";

interface IProps {
    course: IAcademyCourse;
}

interface IAcademyCourseSidebarMetaRowProps {
    icon: string;
    name: string;
    value?: string;
}

const AcademyCourseSidebarMetaRow = (props: IAcademyCourseSidebarMetaRowProps) => {
    return (
        <div className="AcademyCourseSidebarMetaRow">
            <Visage2Icon icon={props.icon} size="small" color="grey" />
            <Label size="body-2">{props.name}</Label>
            <div className="AcademyCourseSidebarMetaRow-value">
                {props.value && (
                    <Label size="body-2" color="grey">{props.value}</Label>
                )}
            </div>
        </div>
    )
}

const AcademyCourseSidebarMeta = (props: IProps) => {
    return (
        <div className="AcademyCourseSidebarMeta">
            {!!props.course.category && (
                <AcademyCourseSidebarMetaRow
                    icon="Category"
                    name={localize("Kategori")}
                    value={props.course.category.name} />
            )}
            <AcademyCourseSidebarMetaRow
                icon="Clock"
                name={localize("SenastUppdaterad")}
                value={moment(props.course.createdDate).format("LL")} />
            {props.course.publishEndDate &&
                <AcademyCourseSidebarMetaRow
                icon="timer-1"
                name={localize("AVAILABLE_UNTIL")}
                value={moment(props.course.publishEndDate).format("LLL")} />}
            <AcademyCourseSidebarMetaRow
                icon="Global"
                name={localize("Sprak")}
                value={languages.find(x => x.key === props.course.language.toString()).text} />
            <AcademyCourseSidebarMetaRow
                icon="book-1"
                name={localize("PAGE_COUNT")}
                value={props.course.statistics.lessonCount.toString()} />
            <AcademyCourseSidebarMetaRow
                icon="profile-2user"
                name={localize("Deltagare_stor")}
                value={props.course.statistics.usersTotal.toString()} />
            {props.course.enableFinalExam && (
                <AcademyCourseSidebarMetaRow
                    icon="Crown-1"
                    name={localize("FINAL_EXAM")} />
            )}
        </div>
    )
}

export default AcademyCourseSidebarMeta;
