import { PrimaryButton, Stack } from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import AcademyTestQuestion from "src/academy/course/AcademyTestQuestion/AcademyTestQuestion";
import { IAcademyQuestion, IAcademyTest } from "src/academy/types";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label, PageHeader } from "src/ui";
import { FormControl, FormSection } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import "./AcademyTestView.scss";
import { saveTestAnswer } from "src/academy/utils";
import moment from "moment";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import AcademyCourseSidebarProgressCircle from "../sidebar/AcademyCourseSidebarProgressCircle/AcademyCourseSidebarProgressCircle";
import { connect, useDispatch } from "react-redux";
import { cleanForms } from "src/utils/isAnythingDirty";
import { setConfirmPopup } from "src/popups/actions";

interface IProps {
    onDone: (test: IAcademyTest) => void;
    goToNextStep: () => void;
    id: number;
    isFinalExam: boolean;
    isLastStep: boolean;
}

interface IState {
    isLoading: boolean;
    item?: IAcademyTest;
    canSubmit?: boolean;
    isSubmitting: boolean;
    isLocked: boolean;
}

const AcademyTestView = (props: IProps) => {
    const dispatch = useDispatch();
    const [state, setState] = useState<IState>({
        isLoading: true,
        isSubmitting: false,
        isLocked: false
    });

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        if (!state.item) {
            return;
        }

        const canSubmit = state.item.questions.every((q: IAcademyQuestion) => {
            return !!q.answer && (!!q.answer.text || q.answer.alternatives.length === 1);
        });

        if (canSubmit !== state.canSubmit) {
            setState((s: IState) => {
                return {
                    ...s,
                    canSubmit
                }
            });
        }
    }, [state.item]);

    const fetch = useCallback(() => {
        api.get("/api/v1/academy/tests/" + props.id).then((response: AxiosResponse) => {
            setState((s: IState) => {
                return {
                    ...s,
                    isLoading: false,
                    item: response.data,
                    isLocked: props.isFinalExam &&
                        !response.data.userProgress.isCompleted &&
                        !!response.data.userProgress?.submittedDate &&
                        new Date(response.data.userProgress?.submittedDate) > moment().add(response.data.finalExamLockoutMinutes * -1, "m").toDate()
                }
            });
        }).catch(() => { });
    }, []);

    const onSubmit = useCallback(() => {
        setState((s: IState) => {
            return {
                ...s,
                isSubmitting: true
            }
        });

        const envelope = {
            ...state.item,
            questions: state.item.questions.filter(x => !x.answer.validationResult)
        }

        saveTestAnswer(envelope).then((test: IAcademyTest) => {
            cleanForms();

            setState((s: IState) => {
                return {
                    ...s,
                    item: test,
                    isSubmitting: false
                }
            });

            props.onDone(test);

            if (props.isFinalExam && !test.userProgress.isCompleted) {
                setState((s: IState) => {
                    return {
                        ...s,
                        isLocked: true
                    }
                });

                dispatch(setConfirmPopup({
                    isOpen: true,
                    message: localize("ACADEMY_EXAM_FAILED").replace("{0}",
                        moment(test.userProgress?.submittedDate).add(state.item.finalExamLockoutMinutes, "m").format("LLL")),
                    onConfirm: () => {}
                }));
            }
        }).catch(() => {
            setState((s: IState) => {
                return {
                    ...s,
                    isSubmitting: false
                }
            });
        });
            
        return;
    }, [state.item, state.canSubmit, state.isSubmitting]);

    if (state.isLoading) {
        return (
            <SpintrLoader />
        )
    }

    if (state.isLocked) {
        return (
            <div className="AcademyTestView locked">
                <AcademyCourseSidebarProgressCircle isEligable={false} />
                <div>
                    <Label weight="medium">{localize("ACADEMY_EXAM_LOCKED")}</Label>
                    <Label size="body-2" color="grey">
                        {
                            localize("ACADEMY_EXAM_LOCKED_INFO").replace("{0}",
                                moment(state.item.userProgress?.submittedDate).add(state.item.finalExamLockoutMinutes, "m").format("LLL"))
                        }
                        </Label>
                </div>
            </div>
        )
    }

    return (
        <div className="AcademyTestView">
            <PageHeader title={state.item.name} />
            <form onSubmit={onSubmit}>
                <FormSection>
                    <FormControl>
                        <div className="questions">
                            {state.item.questions.map((question: IAcademyQuestion, index: number) => {
                                return (
                                    <AcademyTestQuestion
                                        key={question.id}
                                        question={question}
                                        index={index}
                                        isSubmitting={state.isSubmitting}
                                        disabled={state.item.userProgress.isCompleted || state.isSubmitting || question.answer?.validationResult}
                                        onUpdate={(updatedQuestion: IAcademyQuestion) => {
                                            setState((s: IState) => {
                                                return {
                                                    ...s,
                                                    item: {
                                                        ...s.item,
                                                        questions: s.item.questions.map((q: IAcademyQuestion) => {
                                                            if (q.id === updatedQuestion.id) {
                                                                return updatedQuestion;
                                                            }

                                                            return q;
                                                        })
                                                    }
                                                }
                                            });
                                        }} />
                                )
                            })}
                        </div>
                    </FormControl>
                </FormSection>
                <div className="test-footer">
                    {!state.isSubmitting && (
                        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 12 }}>
                            
                            {!state.item.userProgress.isCompleted && (
                                <PrimaryButton disabled={!state.canSubmit} text={localize("Skicka")} onClick={onSubmit} />
                            )}
                            {!props.isLastStep && state.item.userProgress.isCompleted && (
                                <PrimaryButton text={localize("GaVidare")} onClick={props.goToNextStep} />
                            )}
                        </Stack>
                    )}
                    {state.isSubmitting && (
                        <div>
                            <SpintrLoader />
                        </div>
                    )}
                </div>
            </form>
        </div>
    )
}

export default AcademyTestView;
