import React, { CSSProperties, ReactElement, useEffect, useMemo, useState } from "react";
import "./SocialPostText.scss";
import { InteractiveTextFormatOptions, decodeHtmlEntities, formatInteractiveText } from "src/utils";
import { Label } from "src/ui";
import TranslateButton from "src/ui/components/TranslateButton";
import { SpintrTypes } from "src/typings";
import { useSelector } from "react-redux";

export type SocialPostTextProps = {
    post:                       Spintr.ISocialPostBase;
    interactiveTextOptions?:    InteractiveTextFormatOptions | undefined;
}

type SocialPostTextInnerProps = SocialPostTextProps & { text: string };

const getText = (post: Spintr.ISocialPostBase) => {
    return post.Text ?? post.postText ?? post.question ?? "";
}

const defaultFonts: string[] = [
    "\"Eloquia\", sans-serif",
    "\"Merriweather\", serif",
    "\"Indie Flower\", cursive",
    "\"Pacifico\", cursive",
];

function SocialPostTextInner(props: SocialPostTextInnerProps): ReactElement {
    const { post, text, interactiveTextOptions } = props;

    if (post.background) {
        const style: CSSProperties = {
            backgroundImage: `linear-gradient(${post.background.start.color} 0%, ${post.background.stop.color} 100%)`
        };

        const fontStyle: CSSProperties = {
            fontFamily: defaultFonts[post.font - 1],
            lineHeight: "35px"
        };

        return (
            <div className="status-frame ignore-custom-font">
                <div className="stage-wrapper">
                    <div className="stage" style={style}>
                        <Label 
                            as="div"
                            color="white"
                            weight="medium"
                            size="h3"
                            centerText
                            style={fontStyle}
                            className="general-row-break interactive-text feed-post-text">
                                {formatInteractiveText(
                                    decodeHtmlEntities(text),
                                    interactiveTextOptions,
                                )}
                        </Label>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Label 
            as="div"
            color="dark-grey"
            size="body-1"
            className="general-row-break interactive-text feed-post-text">
                {formatInteractiveText(
                    decodeHtmlEntities(text),
                    interactiveTextOptions,
                )}
        </Label>
    )
}

function SocialPostText(props: SocialPostTextProps): ReactElement {
    const { post } = props;
    const [text, setText] = useState(getText(post));

    useEffect(() => setText(getText(post)), [post.Text]);

    const interactiveTextOptions = useMemo<InteractiveTextFormatOptions>(
        () => !props.interactiveTextOptions ? undefined : ({
            ...props.interactiveTextOptions,
            products: {
                ...props.interactiveTextOptions.products,
                linkedProducts: (post.content || [])
                    .filter((item) => item.Type === SpintrTypes.UberType.Product)
                    .map((item) => item as Spintr.ISocialProduct),
            },
        }),
        [props.interactiveTextOptions],
    );

    if (!text) {
        return null;
    }

    return (
        <>
            <SocialPostTextInner post={post} text={text} interactiveTextOptions={interactiveTextOptions} />
            <TranslateButton 
                authorLanguage={post.UserLanguage} 
                uberId={post.Id} 
                text={post.Text}
                onTranslateFn={(translatedtext: string) => {
                    setText(translatedtext);
                }} />
        </>
    )
}

export default SocialPostText;