import { Dropdown, Modal, PrimaryButton } from "@fluentui/react";
import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { Link, withRouter } from "react-router-dom";
import { ISetCalendarPopupParams } from "src/calendar/action-types";
import { localize } from "src/l10n";
import { canUserCreateAndEditNewsArticles, canUserCreatePages } from "src/privileges/utils";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { capitalizeFirstLetter, mixpanelTrack, uniqueId } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { setCalendarPopup } from "../../calendar/actions";
import UnstyledButton from "./Buttons/UnstyledButton/UnstyledButton";
import "./CreateWizard.scss";
import { FormControl, FormSection } from "./Forms";
import { Label } from "./Label/Label";
import SpintrLoader from "./Loader";

interface IPageRouteParams {
    path: string;
    category: string;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    onDismiss?: any;
    disableGroupCreation?: boolean;
    setCalendarPopup?: (params: ISetCalendarPopupParams) => void;
    instance?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    canUserCreateAndEditNewsArticles?: boolean;
    canUserCreatePages?: boolean;
    modalLess?: boolean;
    isUserManager?: boolean;
    restrictWikis?: boolean;
    restrictBlogs?: boolean;
    apps?: any;
}

interface IState {
    showModal: boolean;
    action: string;
    blogOptions: any[];
    wikiOptions: any[];
    isLoading: boolean;
    selectedBlog: any;
}

const createDocuments = [
    {
        name: "Word",
        icon: "WordDocument",
        iconColor: "#19539c",
        link: "https://www.office.com/launch/word",
        imageUrl: "/Style/Images/word.svg",
    },
    {
        name: "Excel",
        icon: "ExcelDocument",
        iconColor: "#007540",
        link: "https://www.office.com/launch/excel",
        imageUrl: "/Style/Images/excel.svg",
    },
    {
        name: "Powerpoint",
        icon: "PowerPointDocument",
        iconColor: "#dc3a1e",
        link: "https://www.office.com/launch/powerpoint",
        imageUrl: "/Style/Images/powerpoint.svg",
    },
];

class CreateWizard extends Component<IProps, IState> {
    protected imageIconAnimationRefList?: any[];

    state = {
        showModal: true,
        action: undefined,
        blogOptions: [],
        wikiOptions: [],
        isLoading: false,
        selectedBlog: undefined,
    };

    isAppEnabled(id) {
        return !!this.props.apps.items.find((a) => a.id === id && a.enabled);
    }

    getContent = () => {
        let content = [];

        if (this.isAppEnabled(SpintrTypes.SpintrApp.Calendars)) {
            content.push({
                name: capitalizeFirstLetter(localize("Kalenderpost")),
                // animatedIcon: calendar,
                visageIcon: "calendar-1",
                action: "calendar",
            });
        }

        if (this.props.canUserCreatePages) {
            content.push({ name: localize("Textsida"), visageIcon: "document", link: "/pages/create" });
        }

        if (!this.props.disableGroupCreation) {
            content.push({ name: localize("Grupp"), visageIcon: "people", link: "/groups/create" });
        }

        if (this.isAppEnabled(SpintrTypes.SpintrApp.News) && this.props.canUserCreateAndEditNewsArticles) {
            content.push({ name: localize("Nyhetsartikel"), visageIcon: "firstline", link: "/news/create" });
        }

        if (this.isAppEnabled(SpintrTypes.SpintrApp.Wikis) && (this.props.isAdmin || this.props.isEditor || !this.props.restrictWikis)) {
            content.push({
                name: capitalizeFirstLetter(localize("Wikiartikel")),
                visageIcon: "book",
                action: "wikipage",
            });
        }

        if (this.props.isAdmin || this.props.isUserManager) {
            content.push({ name: localize("user"), visageIcon: "profile-circle", link: "/organisation/invite" });
        }

        if (this.isAppEnabled(SpintrTypes.SpintrApp.Notes)) {
            content.push({ name: localize("Anteckning"), visageIcon: "note-1", link: "/notes/create" });
        }

        if (this.isAppEnabled(SpintrTypes.SpintrApp.Blogs) && (!this.props.restrictBlogs || this.state.blogOptions.length > 0)) {
            content.push({ name: localize("Bloggpost"), visageIcon: "messages-2", action: "blogpost" });
        }

        if (this.props.isAdmin || this.props.isEditor) {
            content.push({ name: localize("Textpuff"), visageIcon: "refresh-square-2", link: "/admin/teaserbox/create" });
        }

        if ((this.props.isAdmin || this.props.isEditor) && this.isAppEnabled(SpintrTypes.SpintrApp.OperatingInfo)) {
            // content.push({ name: localize("Driftinformation"), animatedIcon: warning, link: "/operatinginfo/create" });
            content.push({ name: localize("Driftinformation"), visageIcon: "info-circle", link: "/operatinginfo/create" });
        }

        if ((this.props.isAdmin || this.props.isEditor) && this.isAppEnabled(SpintrTypes.SpintrApp.EmergencyAlerts)) {
            content.push({
                name: localize("AkutMeddelande"),
                visageIcon: "warning-2",
                //animatedIcon: warningCircle,
                link: "/admin/emergency/create"
            });
        }

        content.sort((a, b) => (a.name > b.name ? 1 : -1));

        return content;
    };

    componentDidMount = () => {
        this.imageIconAnimationRefList = [];

        if (this.props.restrictBlogs) {
            this.setState({
                isLoading: true,
            });
        }

        api.get("/api/v1/blogs/userblogs").then((response) => {
            let blogOptions = response.data.map((r) => {
                return { key: r.id, text: r.name, url: r.url };
            });

            if (this.props.isAdmin || this.props.isEditor || !this.props.restrictBlogs) {
                blogOptions.unshift({
                    key: "skapaNyBlogg",
                    text: localize("SkapaEgenBlogg"),
                    url: `/blogs/create`,
                });
            }

            this.setState({
                blogOptions,
                isLoading: false,
            });
        });

        if (this.isAppEnabled(SpintrTypes.SpintrApp.Wikis) && (this.props.isAdmin || this.props.isEditor || !this.props.restrictWikis)) {
            api.get("/api/v1/wikis/availablewikis").then((response) => {
                const wikiOptions = response.data.map((r) => ({ key: r.id, text: r.name }));

                wikiOptions.unshift({
                    key: "skapaNyWiki",
                    text: localize("SkapaNyWiki"),
                    url: `/wikis/create`,
                });

                this.setState({
                    wikiOptions,
                });
            });
        }

        if (this.props.location.pathname === "/") {
            mixpanelTrack("Opened Create Wizard");
        }
    };

    addContent = () => {
        return (
            <>
                {!this.props.modalLess && (
                    <Label as="h1" size="h3">
                        {localize("SkapaInnehall")}
                    </Label>
                )}
                <div className="CreateContent">
                    {this.getContent().map((c: any, index: number) => {
                        return c.link ? (
                            <Link
                                key={index}
                                className={classnames("link" + (c.iconClass ? (" " + c.iconClass) : ""), { "icon-animation animation-pulse": !c.animatedIcon })}
                                to={c.link}
                                onClick={this.props.onDismiss}
                                onMouseEnter={() => {
                                    if (!c.animatedIcon) {
                                        return null;
                                    }

                                    if (!!this.imageIconAnimationRefList[c.name]) {
                                        this.imageIconAnimationRefList[c.name]?.play();
                                    }
                                }}>
                                <div className="createWizardShortcut">
                                    <span>
                                        <Visage2Icon
                                            className="linkIcon"
                                            icon={c.visageIcon}
                                            color="mid-grey" />
                                    </span>
                                    <span className="text">{c.name}</span>
                                </div>
                            </Link>
                        ) : (
                            <Link
                                key={index}
                                className="link icon-animation animation-pulse"
                                to="#"
                                onClick={() => {
                                    this.setState({ action: c.action });
                                }}
                            >
                                <div className="createWizardShortcut">
                                    <span>
                                        <Visage2Icon
                                            className="linkIcon"
                                            icon={c.visageIcon}
                                            color="mid-grey" />
                                    </span>
                                    <span className="text">{c.name}</span>
                                </div>
                            </Link>
                        );
                    })}
                </div>
                {!this.props.instance.get("hideOffice365Shortcuts") && !this.props.modalLess && (
                    <div>
                        <div className="CreateContent office-shortcuts">
                            {createDocuments.map((c) => {
                                return (
                                    <a
                                        key={uniqueId()}
                                        className={`link documentLink`}
                                        href={c.link}
                                        onClick={this.props.onDismiss}
                                        target="_blank"
                                    >
                                        <div className="createWizardShortcut image-and-text primaryFGColorHover">
                                            <div className="image">
                                                <img alt={c.name} src={c.imageUrl} />
                                                <div className="image-plus">
                                                    <Visage2Icon icon="add-circle" hexColor="#475267" />
                                                </div>
                                            </div>
                                            <span className="text">{c.name}</span>
                                        </div>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                )}
            </>
        );
    };

    selectBlog = () => {
        if (this.state.selectedBlog) {
            this.props.history.push({ pathname: this.state.selectedBlog });
        }

        if (this && this.props && this.props.onDismiss) {
            this.props.onDismiss();
        }
    };

    addBlogPost = () => {
        return (
            <>
                {
                    !this.props.modalLess && (
                        <Label as="h1" size="h1">
                            {localize("NyBloggpost")}
                        </Label>
                    )
                }
                <FormSection>
                    <FormControl>
                        <Dropdown
                            label={localize("ValjBlog")}
                            options={this.state.blogOptions}
                            styles={{ dropdown: { width: 300 } }}
                            onChange={(_e, v) => {
                                //@ts-ignore
                                let url = v.url.split("/")[2];
                                if (url !== "create") {
                                    url = "/blogs/" + url + "/create";
                                    this.setState({ selectedBlog: url });
                                } else {
                                    //@ts-ignore
                                    this.setState({ selectedBlog: v.url });
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <PrimaryButton onClick={this.selectBlog} disabled={!this.state.selectedBlog}>
                            {localize("Valj")}
                        </PrimaryButton>
                    </FormControl>
                </FormSection>
            </>
        );
    };

    addWikiPage = () => {
        return (
            <>
                {
                    !this.props.modalLess && (
                        <Label as="h1" size="h3">
                            {localize("SkapaEnWikiartikel")}
                        </Label>
                    )
                }
                <FormSection>
                    <FormControl>
                        <Dropdown
                            label={localize("ValjWiki")}
                            options={this.state.wikiOptions}
                            styles={{ dropdown: { width: 300 } }}
                            onChange={(_e, v) => {
                                // @ts-ignore
                                const pathname = v.url || `/wikis/create-article/${v.key}`;
                                this.props.history.push({
                                    pathname,
                                });

                                if (this && this.props && this.props.onDismiss) {
                                    this.props.onDismiss();
                                }
                            }}
                        />
                    </FormControl>
                </FormSection>
            </>
        );
    };

    addCalendar = () => {
        this.props.setCalendarPopup({ isOpen: true });

        if (this && this.props && this.props.onDismiss) {
            this.props.onDismiss();
        }

        return null;
    };

    renderContent() {
        const { action } = this.state;

        return (
            <div className="CreateWizardContentWrapper">
                {this.state.isLoading && <SpintrLoader />}
                {!this.state.isLoading &&
                    (action === "wikipage"
                        ? this.addWikiPage()
                        : action === "blogpost"
                            ? this.addBlogPost()
                            : action === "calendar"
                                ? this.addCalendar()
                                : this.addContent())}
            </div>
        );
    }

    render() {
        if (this.props.modalLess) {
            return this.renderContent();
        }

        return (
            <Modal
                isOpen={this.state.showModal}
                onDismiss={this.props.onDismiss}
                isBlocking={false}
                containerClassName={"CreateWizardModal"}
            >
                <UnstyledButton
                    className="close-button standard-position"
                    title={localize("Stang")}
                    onClick={this.props.onDismiss}
                >
                    <Visage2Icon icon="close-circle" />
                </UnstyledButton>
                {this.renderContent()}
            </Modal>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    return {
        ...props,
        disableGroupCreation: state.instance.get("disableGroupCreation") || !state.profile.active.rights.hasAccessToGroups || (state.instance.get("restrictGroups") && !state.profile.active.isAdmin && !state.profile.active.isEditor && !state.profile.active.settings.canCreateGroups),
        instance: state.instance,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        restrictWikis: state.instance.get("restrictWikis"),
        restrictBlogs: state.instance.get("restrictBlogs"),
        canUserCreateAndEditNewsArticles: canUserCreateAndEditNewsArticles(
            state.privileges.data,
            state.profile.active,
            state.instance
        ),
        canUserCreatePages: canUserCreatePages(state.privileges.data, state.profile.active, state.instance),
        isUserManager: state.profile.active.roles.includes("usermanager"),
        apps: state.app
    };
};

const dispatchActions = {
    setCalendarPopup,
};

// @ts-ignore
export default withRouter(connect(mapStateToProps, dispatchActions)(CreateWizard));
