import React, { ReactElement } from "react";
import { SidebarContentListBoxItem, SidebarContentListBoxProps } from "./SidebarContentListBox.types";
import { SidebarContentBox } from "../SidebarContentBox";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { CaptionBody, CaptionBolder } from "../Text";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { localize } from "src/l10n";
import StandardActionMenu from "src/ui/components/ActionMenu/StandardActionMenu";

function renderItem(item: SidebarContentListBoxItem): ReactElement {
    const icon = !item.iconName ? null : (
        <Visage2Icon className="SidebarContentListBox-icon"
                     icon={item.iconName}
                     color="spintrGrey"
                     size="small"
                     style={item.iconStyle} />
    );

    const text = (
        <CaptionBolder
            className="SidebarContentListBox-text"
            color="contentDark"
            letterSpacing="normal"
        >
            {item.text}
        </CaptionBolder>
    );

    const actionMenu = (
        <StandardActionMenu
            canAddToFavourites
            isFavourite
            pinInsteadOfFavourite
            objectId={item.wikiId}
        />
    );

    const ConditionalLink = ({ children }) => (
        !item.url ? children : (
            <Link to={item.url}>
                {children}
            </Link>
        )
    );

    return (
        <li key={item.key}>
            <ConditionalLink>
                <div className="SidebarContentListBox-item">
                    {icon}
                    {text}
                    <div className="SidebarContentListBox-actionMenu">
                    {!!item.wikiId && actionMenu}
                    </div>
                </div>
            </ConditionalLink>
        </li>
    );
}

function SidebarContentListBox({ className, items, title }: SidebarContentListBoxProps): ReactElement {
    const content = items.length > 0
        ? (
            <ol className="SidebarContentListBox-list">
                {items.map(renderItem)}
            </ol>
        )
        : (
            <CaptionBody 
                className="SidebarContentListBox-empty"
                color="contentLight"
                letterSpacing="normal"
            >
                {localize("IngaPoster")}
            </CaptionBody>
        )

    return (
        <SidebarContentBox
            className={classNames("SidebarContentListBox", className)}
            title={title}
        >
            {content}
        </SidebarContentBox>
    );
}

export default SidebarContentListBox;
