import React, { Component, ReactNode } from "react";
import { Link } from "react-router-dom";
import { getTypeName, localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Breadcrumbs, Label, Loader, PageHeader } from "src/ui";
import "./SitemapView.scss";

interface IProps {
    list: any;
}
interface IState {
    list: any;
    isLoading: boolean;
}
class SitemapView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            isLoading: true
        };
    }

    componentDidMount() {
        this.getLetterItems();
    }

    async getLetterItems() {
        await api.get("/api/sitemap").then((res) => {
            const items = res.data.map((obj) => obj);
            this.setState({
                list: items,
                isLoading: false
            });
        });
    }

    public render(): ReactNode {
        const homeText = localize("Hem");
        const sitemapText = localize("Intranatskarta");

        return (
            <div id="sitemap">
                <Breadcrumbs
                    items={[
                        {
                            text: homeText,
                            key: 0,
                            link: "/",
                        },
                        {
                            text: sitemapText,
                            key: 1,
                        },
                    ]}
                />
                <div id="sitemapInner">
                    <PageHeader title={sitemapText} />
                    {
                        this.state.isLoading && (
                            <Loader />
                        )
                    }
                    {
                        !this.state.isLoading && (
                            <div id="sitemapBody">
                                <div className="index-container columns-enabled col4">
                                    {this.state.list.map(({ letter, items }, i) => (
                                        <div id="indexContent" key={i} className="indexList">
                                            <div className="index-list">
                                                <div className="indexHeader">
                                                    <Label as="h2">
                                                        {letter}
                                                        </Label></div>
                                                <ul>
                                                    {items.map((item, j) => (
                                                        <li key={j}>
                                                            <Link to={item.url}>
                                                                <span className="itemName">{item.name} </span>
                                                                <span className="itemType">
                                                                    {(() => {
                                                                        let type = parseInt(item.type);

                                                                        if (!isNaN(type)) {
                                                                            {
                                                                                item.type =
                                                                                    "- " +
                                                                                    getTypeName(item.type);
                                                                            }
                                                                        }
                                                                    })()}
                                                                    {item.type}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default SitemapView;
