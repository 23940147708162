import React, { ReactElement } from "react";
import { TimelineGroupsProps } from "./TimelineGroups.types";
import { TimelineGroup } from "../TimelineGroup";

function TimelineGroups({
    axisWidth,
    groups,
    hasWriteAccess,
    onBarClick,
    onBarGroupClick,
    onCategoryUpdate,
    onGroupClick,
    onItemDurationChange,
    onDisplayedItemsChange,
    onRailClick,
    timelineDuration,
    timelineMode,
    timelineSize,
    todayTime,
}: TimelineGroupsProps): ReactElement {
    const groupCount = groups.length;

    return (
        <section className="TimelineGroups" role="rowgroup">
            {groups.map((group, index) => {
                const offset = index === 0 ? 0 : groups
                    .slice(0, index)
                    .reduce((acc, group) => {
                        if (!group.expanded) {
                            return acc + 52;
                        }

                        const groupOffset = acc + 52 + group.categories
                            .slice(0, group.itemsDisplayed)
                            .reduce((acc, category) => {
                                const categoryHeight = category.items.every((barGroup) => !barGroup.expanded)
                                    ? 50
                                    : Math.max(...category.items.map((barGroup) => 
                                        barGroup.expanded ? 50 + 50 * (barGroup.layers ?? 1) : 50
                                    ));
                                return acc + categoryHeight;
                            }, 0) + (group.categories.length > 3 ? 40 : 0);

                        return groupOffset;
                    }, 0);

                const cumulativeIndex = groups
                    .slice(0, index)
                    .reduce((acc, group) => acc + 1 + group.itemsDisplayed, 0);

                return (
                    <TimelineGroup
                        axisWidth={axisWidth}
                        displayGroupHeader={groupCount > 1}
                        group={group}
                        hasWriteAccess={hasWriteAccess}
                        cumulativeIndex={cumulativeIndex}
                        key={index}
                        offset={offset}
                        onBarClick={onBarClick}
                        onBarGroupClick={onBarGroupClick}
                        onCategoryUpdate={onCategoryUpdate}
                        onGroupClick={onGroupClick}
                        onDisplayedItemsChange={onDisplayedItemsChange}
                        onItemDurationChange={onItemDurationChange}
                        onRailClick={onRailClick}
                        timelineDuration={timelineDuration}
                        timelineMode={timelineMode}
                        timelineSize={timelineSize}
                        todayTime={todayTime} />
                );
            })}
        </section>
    );
}

export default TimelineGroups;
