import React from "react";
import { Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./SmallCreateButton.scss";

interface IProps {
    text: string;
    onClick?: () => void;
}

const SmallCreateButton = (props: IProps) => {
    return (
        <UnstyledButton className="SmallCreateButton" onClick={props.onClick}>
            <Visage2Icon icon="add-circle" color="light-blue" size="small" />
            <div className="text-wrapper">
                <Label size="body-2" color={"light-blue"} className="item-text">
                    {props.text}
                </Label>
            </div>
        </UnstyledButton>
    )
}

export default SmallCreateButton;
