import { MutableRefObject, useEffect, useRef, useState } from "react";

export function useHover<TElement extends HTMLElement = HTMLElement>(): [MutableRefObject<TElement>, boolean] {
    const ref = useRef<TElement>(null);
    const [isHovering, setHovering] = useState<boolean>(false);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const handleMouseEnter = () => setHovering(true);
        const handleMouseLeave = () => setHovering(false);

        ref.current.addEventListener("mouseenter", handleMouseEnter);
        ref.current.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            ref.current?.removeEventListener("mouseenter", handleMouseEnter);
            ref.current?.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, [ref.current]);

    return [ref, isHovering];
}
