import { AxiosError, AxiosResponse } from "axios";
import { Action } from "redux";

import {
    postMessage,
    postReactionToMessage,
    queryMessagesApi,
    removeReactionFromMessage,
    updateMessageApi,
} from "../api";
import { IQueryMessagesParams, IQueryPinnedMessagesParams } from "../message-types";
import { MessageActionTypes } from "./message-action-types";
import api from "src/spintr/SpintrApi";

export interface IQueryMessagesAction extends Action {
    meta: IQueryMessagesParams;
    payload: Promise<AxiosResponse<Spintr.IChatMessage[]>>;
    type: MessageActionTypes.QueryMessages;
}

export interface IQueryMessagesFulFilledAction extends Action {
    meta: IQueryMessagesParams;
    payload: AxiosResponse<Spintr.IChatMessage[]>;
    type: MessageActionTypes.QueryMessagesFulFilled;
}

export interface IQueryMessagesPendingAction extends Action {
    meta: IQueryMessagesParams;
    payload: Promise<AxiosResponse<Spintr.IChatMessage[]>>;
    type: MessageActionTypes.QueryMessagesPending;
}

export interface IQueryMessagesRejectedAction extends Action {
    meta: IQueryMessagesParams;
    payload: AxiosError;
    type: MessageActionTypes.QueryMessagesRejected;
}

export type QueryMessagesAction = 
      IQueryMessagesAction
    | IQueryMessagesFulFilledAction
    | IQueryMessagesPendingAction
    | IQueryMessagesRejectedAction;

export type BoundQueryMessagesHandler =
    (params: IQueryMessagesParams) => Spintr.ThunkPromise<
        AxiosResponse<Spintr.IChatMessage[]> | AxiosError,
        IQueryMessagesFulFilledAction | IQueryMessagesRejectedAction
    >;
export type QueryMessagesHandler = (params: IQueryMessagesParams) => IQueryMessagesAction;
export const queryMessages: QueryMessagesHandler = (params) => ({
    meta: params,
    payload: queryMessagesApi(params),
    type: MessageActionTypes.QueryMessages,
});

export interface IQueryPinnedMessagesAction extends Action {
    meta: IQueryPinnedMessagesParams;
    payload: Promise<AxiosResponse<Spintr.IChatMessage[]>>;
    type: MessageActionTypes.QueryPinnedMessages;
}

export interface IQueryPinnedMessagesFulfilledAction extends Action {
    meta: IQueryPinnedMessagesParams;
    payload: AxiosResponse<Spintr.IChatMessage[]>;
    type: MessageActionTypes.QueryPinnedMessagesFulfilled;
}

export interface IQueryPinnedMessagesPendingAction extends Action {
    meta: IQueryPinnedMessagesParams;
    payload: Promise<AxiosResponse<Spintr.IChatMessage[]>>;
    type: MessageActionTypes.QueryPinnedMessagesPending;
}

export interface IQueryPinnedMessagesRejectedAction extends Action {
    meta: IQueryPinnedMessagesParams;
    payload: AxiosError;
    type: MessageActionTypes.QueryPinnedMessagesRejected;
}

export type QueryPinnedMessagesAction = 
      IQueryPinnedMessagesAction
    | IQueryPinnedMessagesFulfilledAction
    | IQueryPinnedMessagesPendingAction
    | IQueryPinnedMessagesRejectedAction;

export type BoundQueryPinnedMessagesHandler =
    (params: IQueryPinnedMessagesParams) => Spintr.ThunkPromise<
        AxiosResponse<Spintr.IChatMessage[]> | AxiosError,
        IQueryPinnedMessagesFulfilledAction | IQueryPinnedMessagesRejectedAction
    >;
export type QueryPinnedMessagesHandler = (params: IQueryPinnedMessagesParams) => IQueryPinnedMessagesAction;
export const queryPinnedMessages: QueryPinnedMessagesHandler = (params: IQueryPinnedMessagesParams) => ({
    meta: params,
    payload: api.get<Spintr.IChatMessage[]>("/api/v1/conversations/" + params.conversationId + "/pins"),
    type: MessageActionTypes.QueryPinnedMessages,
});

export interface IMessageReceivedMeta {
    message: Spintr.IChatMessage;
    ownId: number;
}

export interface IMessageReceivedAction extends Action {
    meta: IMessageReceivedMeta;
    type: MessageActionTypes.AddMessageFulfilled,
}

export type MessageReceivedHandler =
    (message: Spintr.IChatMessage, ownId: number) => IMessageReceivedAction;
export const addMessageRealtime: MessageReceivedHandler = (message, ownId) => ({
    meta: { message, ownId },
    type: MessageActionTypes.AddMessageFulfilled,
});

export const updateMessageRealtime = (message) => ({
    meta: { message },
    type: MessageActionTypes.UpdateMessageFulfilled
});

export interface IMessageIdMeta {
    messageId: number;
}

export interface IMemoTodoUpdatedAction extends Action {
    meta: any;
    type: MessageActionTypes.MemoTodoUpdated;
}

export type MemoTodoUpdatedHandler = (messageId: number) => IMemoTodoUpdatedAction;
export const memoTodoUpdated: MemoTodoUpdatedHandler = (todo) => ({
    meta: todo,
    type: MessageActionTypes.MemoTodoUpdated,
});

export interface IMessageDeletedAction extends Action {
    meta: IMessageIdMeta;
    type: MessageActionTypes.RemoveMessageFulfilled;
}

export type MessageDeletedHandler = (messageId: number) => IMessageDeletedAction;
export const removeMessageRealtime: MessageDeletedHandler = (messageId) => ({
    meta: { messageId },
    type: MessageActionTypes.RemoveMessageFulfilled,
});

export interface IMessagePinnedAction extends Action {
    meta: {
        message: Spintr.IChatMessage
    };
    type: MessageActionTypes.PinMessageFulfilled;
}

export type MessagePinnedHandler = (message: Spintr.IChatMessage) => IMessagePinnedAction;
export const pinMessageRealtime: MessagePinnedHandler = (message) => ({
    meta: { message },
    type: MessageActionTypes.PinMessageFulfilled,
});

export interface IMessageUnpinnedAction extends Action {
    meta: {
        message: Spintr.IChatMessage
    };
    type: MessageActionTypes.UnpinMessageFulfilled;
}

export type MessageUnpinnedHandler = (message: Spintr.IChatMessage) => IMessageUnpinnedAction;
export const unpinMessageRealtime: MessageUnpinnedHandler = (message) => ({
    meta: { message },
    type: MessageActionTypes.UnpinMessageFulfilled,
});

export interface IAddReactionMeta {
    messageId: number;
    likeType: number;
    userId: number;
    userName: string;
}

export interface IAddReactionAction extends Action {
    meta: IAddReactionMeta;
    payload?: Promise<AxiosResponse<never>>;
    type: MessageActionTypes.AddReaction;
}

export interface IAddReactionFulfilledAction extends Action {
    meta: IAddReactionMeta;
    payload: AxiosResponse<never>;
    type: MessageActionTypes.AddReactionFulfilled;
}

export interface IAddReactionPendingAction extends Action {
    meta: IAddReactionMeta;
    payload: Promise<AxiosResponse<never>>;
    type: MessageActionTypes.AddReactionPending;
}

export type AddReactionAction = 
    IAddReactionAction
    | IAddReactionFulfilledAction
    | IAddReactionPendingAction;

export type BoundAddReactionHandler =
    (
        messageId: number,
        likeType: number,
        userId: number,
        userName: string,
    ) => Spintr.ThunkPromise<
        AxiosResponse<never> | AxiosError,
        IAddReactionFulfilledAction
    >;
export type AddReactionHandler = (
    messageId: number,
    likeType: number,
    userId: number,
    userName: string,
) => IAddReactionAction;

export const addReaction: AddReactionHandler =
    (messageId, likeType, userId, userName) => ({
        meta: {
            messageId,
            likeType,
            userId,
            userName
        },
        payload: postReactionToMessage(messageId, likeType),
        type: MessageActionTypes.AddReaction,
    });

export const addReactionRealtime: AddReactionHandler =
    (messageId, likeType, userId, userName) => ({
        meta: {
            messageId,
            likeType,
            userId,
            userName
        },
        type: MessageActionTypes.AddReaction,
    });

export interface IRemoveReactionMeta {
    messageId: number;
    likeType: number;
    userId: number;
}

export interface IRemoveReactionAction extends Action {
    meta: IRemoveReactionMeta;
    payload?: Promise<AxiosResponse<never>>;
    type: MessageActionTypes.RemoveReaction;
}

export interface IRemoveReactionFulfilledAction extends Action {
    meta: IRemoveReactionMeta;
    payload: AxiosResponse<never>;
    type: MessageActionTypes.RemoveReactionFulfilled;
}

export interface IRemoveReactionPendingAction extends Action {
    meta: IRemoveReactionMeta;
    payload: Promise<AxiosResponse<never>>;
    type: MessageActionTypes.RemoveReactionPending;
}

export type RemoveReactionAction =
    IRemoveReactionAction
    | IRemoveReactionFulfilledAction
    | IRemoveReactionPendingAction;


export type BoundRemoveReactionHandler =
    (
        messageId: number,
        likeType: number,
        userId: number,
    ) => Spintr.ThunkPromise<
        AxiosResponse<never> | AxiosError,
        IQueryMessagesFulFilledAction | IQueryMessagesRejectedAction
    >;
export type RemoveReactionHandler = (
    messageId: number,
    likeType: number,
    userId: number,
) => IRemoveReactionAction;

export const removeReaction: RemoveReactionHandler =
    (messageId, likeType, userId) => ({
        meta: {
            messageId,
            likeType,
            userId
        },
        payload: removeReactionFromMessage(messageId, likeType),
        type: MessageActionTypes.RemoveReaction,
    });

export const removeReactionRealtime: RemoveReactionHandler =
    (messageId, likeType, userId) => ({
        meta: {
            messageId,
            likeType,
            userId
        },
        type: MessageActionTypes.RemoveReaction,
    });

export interface ISaveMessageMeta {
    message: Spintr.IChatMessage;
    ownId: number;
}

export interface ISaveMessageAction extends Action {
    meta: ISaveMessageMeta;
    payload: Promise<AxiosResponse<Spintr.IChatMessage>>;
    type: MessageActionTypes.SaveMessage;
}

export interface ISaveMessageFulfilledAction extends Action {
    meta: ISaveMessageMeta;
    payload: AxiosResponse<Spintr.IChatMessage>;
    type: MessageActionTypes.SaveMessageFulfilled;
}

export interface ISaveMessagePendingAction extends Action {
    meta: ISaveMessageMeta;
    payload: Promise<AxiosResponse<Spintr.IChatMessage>>;
    type: MessageActionTypes.SaveMessagePending;
}

export interface ISaveMessageRejectedAction extends Action {
    meta: ISaveMessageMeta;
    payload: AxiosError;
    type: MessageActionTypes.SaveMessageRejected;
}

export type SaveMessageHandler = (message: Spintr.IChatMessage, ownId: number) => ISaveMessageAction;
export const saveMessage: SaveMessageHandler = (message, ownId) => ({
    meta: { message, ownId },
    payload: postMessage(message),
    type: MessageActionTypes.SaveMessage,
})

export interface IUpdateMessageMeta {
    message: Spintr.IChatMessage;
}

export interface IUpdateMessageAction extends Action {
    meta: IUpdateMessageMeta;
    payload: Promise<AxiosResponse<Spintr.IChatMessage>>;
    type: MessageActionTypes.UpdateMessage;
}

export interface IUpdateMessageFulfilledAction extends Action {
    meta: IUpdateMessageMeta;
    payload: AxiosResponse<Spintr.IChatMessage>;
    type: MessageActionTypes.UpdateMessageFulfilled;
}

export interface IUpdateMessagePendingAction extends Action {
    meta: IUpdateMessageMeta;
    payload: Promise<AxiosResponse<Spintr.IChatMessage>>;
    type: MessageActionTypes.UpdateMessagePending;
}

export interface IUpdateMessageRejectedAction extends Action {
    meta: IUpdateMessageMeta;
    payload: AxiosError;
    type: MessageActionTypes.UpdateMessageRejected;
}

export type UpdateMessageHandler = (message: Spintr.IChatMessage) => IUpdateMessageAction;
export const updateMessage: UpdateMessageHandler = (message) => ({
    meta: { message },
    payload: updateMessageApi(message),
    type: MessageActionTypes.UpdateMessage,
})

export type SaveMessageAction =
    ISaveMessageAction
    | ISaveMessageFulfilledAction
    | ISaveMessagePendingAction
    | ISaveMessageRejectedAction;

export type MessageAction =
    QueryMessagesAction
    | QueryPinnedMessagesAction
    | IMessageReceivedAction
    | IMessageDeletedAction
    | IMessagePinnedAction
    | IMessageUnpinnedAction
    | AddReactionAction
    | RemoveReactionAction
    | SaveMessageAction
    | IMemoTodoUpdatedAction
    | IUpdateMessageAction
    | IUpdateMessageFulfilledAction;