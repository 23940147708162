import React, {
    PropsWithChildren,
    createContext,
    useCallback,
    useEffect,
    useState
} from "react";
import {
    IRealtimeContext,
    SubscribeToRealtimeEventHandler,
    TriggerRealtimeEventHandler,
    UnsubscribeFromRealtimeEventHandler,
} from "./RealtimeContext.types";
import { NoOp } from "src/utils";

export const DefaultRealtimeContext: IRealtimeContext = {
    subscribe: NoOp,
    trigger: NoOp,
    unsubscribe: NoOp,
};

const RealtimeContext = createContext<IRealtimeContext>(DefaultRealtimeContext);

export function RealtimeContextProvider({ children }: PropsWithChildren<{}>) {
    const [eventListeners, setEventListeners] = useState<Record<string, Function[]>>({});
    const [context, setContext] = useState<IRealtimeContext>(DefaultRealtimeContext);

    const subscribe = useCallback<SubscribeToRealtimeEventHandler>(
        (eventName, callback) => setEventListeners((prevState) => ({
            ...prevState,
            [eventName]: (prevState[eventName] || []).concat(callback),
        })),
        [setEventListeners],
    );

    const unsubscribe = useCallback<UnsubscribeFromRealtimeEventHandler>(
        (eventName, callback) => setEventListeners((prevState) => ({
            ...prevState,
            [eventName]: (prevState[eventName] || []).filter((cb) => cb !== callback),
        })),
        [setEventListeners],
    );

    const trigger = useCallback<TriggerRealtimeEventHandler>(
        (event, ...args) => {
            const listeners = eventListeners[event] || [];

            listeners.forEach((listener) => listener(...args));
        },
        [eventListeners],
    );

    useEffect(
        () => setContext({ subscribe, trigger, unsubscribe }),
        [subscribe, trigger, unsubscribe],
    );

    return <RealtimeContext.Provider value={context}>{children}</RealtimeContext.Provider>;
}

export default RealtimeContext;
