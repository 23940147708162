import React, { ReactElement, useMemo } from "react";
import { DrawerHeaderTitleProps } from "./DrawerHeaderTitle.types";
import { LargeBody, TextProps } from "src/components/Text";
import classNames from "classnames";

const defaultTextProps: TextProps = {
    as: "h2",
    color: "contentDark",
    size: "bodyMedium",
};

function DrawerHeaderTitle(props: DrawerHeaderTitleProps): ReactElement {
    const action = props.action ? (
        <div className="DrawerHeaderTitle-action">
            {props.action}
        </div>
    ) : null;

    const className = useMemo(() => classNames("DrawerHeaderTitle", props.className), [props.className]);
    const textProps = useMemo(() => ({
        ...defaultTextProps,
        ...(props.textProps || {}),
        className: classNames("DrawerHeaderTitle-text", props.textProps?.className),
    }), [props.textProps]);

    return (
        <div className={className}>
            <LargeBody {...textProps}>
                {props.children}
            </LargeBody>
            {action}
        </div>
    );
}

export default DrawerHeaderTitle;
