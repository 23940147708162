import React from 'react';
import "./MemoHeader.scss";
import { Label } from 'src/ui';
import { formatInteractiveText } from 'src/utils';
import MessageAttachments from '../MessageAttachments/MessageAttachments';
import MessageObjects from '../MessageObjects/MessageObjects';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { localize } from 'src/l10n';

interface IProps {
    memoTitle?: string;
}

const MemoHeader = (props: IProps) => {
    return (
        <div className="MemoHeader">
            <div className="top-row">
                <Visage2Icon icon="task-square" />
                <Label size="h4" weight="medium">{localize("MEMO")}</Label>
            </div>
            {props.memoTitle && (
                <div>
                    <Label weight="medium">{props.memoTitle}</Label>
                </div>
            )}
        </div>
    )
}

export default MemoHeader;
