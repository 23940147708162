import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import "./Tabs.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { ActionMenu, Label, UnstyledButton } from "src/ui";
import { NavLink } from "react-router-dom";
import { debounce } from "src/utils";

export interface ITabItem {
    icon?: string;
    text?: string;
    type: "link" | "action" | "view";
    onClick?: () => void;
    onRender?: () => ReactNode;
    href?: string;
}

interface IProps {
    activeIndex?: number;
    items: ITabItem[];
}

interface IState {
    activeIndex: number;
    wrapperWidth: number;
    tabWidths: number[];
    displayLimit: number;
    hasInitialized: boolean;
}

const TabContent = (item: ITabItem) => {
    return (
        <>
            {item.icon && <Visage2Icon icon={item.icon} color="grey" size="small" type="bold" />}
            {item.text && <Label color="grey" weight="medium" size="body-2">{item.text}</Label>}
        </>
    )
}

const Tabs = (props: IProps) => {
    const wrapperRef = useRef<HTMLDivElement>(undefined);
    const [state, setState] = useState<IState>({
        activeIndex: props.activeIndex || 0,
        wrapperWidth: 0,
        tabWidths: [],
        displayLimit: 1337,
        hasInitialized: false
    });

    const calc = useCallback(() => {
        const wrapperWidth = wrapperRef.current.clientWidth;
        const tabWidths = state.hasInitialized ?
            state.tabWidths :
            Array.from(wrapperRef.current.children).map(x => x.clientWidth);

        let displayLimit = 0;
        let combinedContentWidth = 50; // some extra space for action menu 
        let hasOverflow = false;

        for (const tabWidth of tabWidths) {
            if (hasOverflow) { continue; }

            if ((combinedContentWidth + tabWidth) < wrapperWidth) {
                displayLimit++;
                combinedContentWidth += tabWidth;
            } else {
                hasOverflow = true;
            }
        }

        if (state.hasInitialized && displayLimit === state.displayLimit) {
            return;
        }

        setState((s) => ({
            ...s,
            wrapperWidth,
            tabWidths,
            displayLimit,
            hasInitialized: true
        }));
    }, [wrapperRef.current, state.hasInitialized, state.tabWidths, state.displayLimit]);

    const debouncedCalc = debounce(() => calc(), 100);
    
    useEffect(() => {
        calc();
    }, []);

    useEffect(() => {
        //@ts-ignore
        const ro = new ResizeObserver(() => { debouncedCalc() });
        ro.observe(wrapperRef.current);
        return () => ro.disconnect();
    }, [wrapperRef.current, state.hasInitialized, state.tabWidths, state.displayLimit]);

    return (
        <div className="Tabs">
            <div
                className={[
                    "Tabs-bar",
                    state.hasInitialized ? "" : "calculating"
                ].filter(x => x).join(" ")}
                ref={wrapperRef}>
                {props.items.map((item, index) => {
                    if (state.hasInitialized && index >= state.displayLimit) { return null; }

                    return (
                        <div
                            className="tab-wrapper"
                            key={index}>
                            {(item.type === "action" || item.type === "view") && (
                                <UnstyledButton
                                    className={[
                                        "tab",
                                        index === state.activeIndex ? "active" : ""
                                    ].filter(x => x).join(" ")}
                                    onClick={() => {
                                        setState((s) => ({
                                            ...s,
                                            activeIndex: index
                                        }));

                                        item.onClick();
                                    }}>
                                    <TabContent {...item} />
                                </UnstyledButton>
                            )}
                            {item.type === "link" && (
                                <NavLink
                                    className={"tab"}
                                    to={item.href}
                                    isActive={() => {
                                        if (!props.items.some(x => window.location.pathname.includes(x.href))) {
                                            return index === 0;
                                        }

                                        return window.location.pathname.includes(item.href);
                                    }}
                                    onClick={() => {
                                        setState((s) => ({
                                            ...s,
                                            activeIndex: index
                                        }));
                                    }}
                                    activeClassName="active">
                                    <TabContent {...item} />
                                </NavLink>
                            )}
                        </div>
                    )
                })}
                {state.displayLimit < props.items.length && (
                    <ActionMenu
                        categories={[{
                            items: props.items.map((e,i) => i >= state.displayLimit ? e : undefined).filter(x => x).map((item) => {
                                return {
                                    text: item.text,
                                    onClick: () => {},
                                }
                            })
                        }]}
                    />
                )}
            </div>
            {props.items[state.activeIndex].type === "view" && (
                <div className="Tabs-view">
                    {props.items[state.activeIndex].onRender()}
                </div>
            )}
        </div>
    )
}

export default Tabs;