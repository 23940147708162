import React, { useEffect, useState } from "react";
import { Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./AcademyCourseSidebarChapter.scss";
import { IAcademyChapter, IAcademyCourse, IAcademyLesson, IAcademyTest } from "src/academy/types";
import AcademyCourseSidebarLesson from "../AcademyCourseSidebarLesson/AcademyCourseSidebarLesson";
import AcademyCourseSidebarTest from "../AcademyCourseSidebarTest/AcademyCourseSidebarTest";
import { localize } from "src/l10n";
import AcademyCourseSidebarProgressCircle from "../AcademyCourseSidebarProgressCircle/AcademyCourseSidebarProgressCircle";

interface IProps {
    chapter: IAcademyChapter;
    activeStepId?: number;
    goToLesson: (lesson: IAcademyLesson) => void;
    goToTest: (test: IAcademyTest) => void;
}

interface IState {
    isExpanded: boolean;
}

const AcademyCourseSidebarChapter = (props: IProps) => {
    const [state, setState] = useState<IState>({
        isExpanded: false
    });

    useEffect(() => {
        if (props.chapter.lessons.find(x => x.id === props.activeStepId) || props.chapter.test?.id === props.activeStepId) {
            setState((s: IState) => {
                return {
                    ...s,
                    isExpanded: true
                }
            });
        }
    }, [props.activeStepId]);

    return (
        <div className="AcademyCourseSidebarChapter">
            <UnstyledButton className="top-row" onClick={() => {
                setState((s: IState) => {
                    return {
                        ...s,
                        isExpanded: !s.isExpanded
                    }
                });
            }}>
                <AcademyCourseSidebarProgressCircle
                    isDone={props.chapter.userProgress.isCompleted}
                    isEligable={props.chapter.userProgress.isEligable}
                    progressPercent={props.chapter.userProgress.completionPercent}
                />
                <div className="info">
                    <div className="name">
                        <Label weight="medium">{props.chapter.name}</Label>
                    </div>
                    <div className="meta">
                        <div>
                            <Visage2Icon icon="book-1" size="small" color="grey" />
                            <Label size="body-2" color="grey">{props.chapter.lessons.length + " " + localize("PAGES_SMALL")}</Label>
                        </div>
                        {props.chapter.enableTest && (
                            <div>
                                <Visage2Icon icon="star" size="small" color="grey" />
                                <Label size="body-2">{1 + " " + localize("EXAM_SMALL")}</Label>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <Visage2Icon icon={state.isExpanded ? "arrow-up-2" : "arrow-down-1"} size="small" />
                </div>
            </UnstyledButton>
            {state.isExpanded && (
                <div className="chapter-content">
                    {props.chapter.lessons.map((l: IAcademyLesson) => {
                        return (
                            <AcademyCourseSidebarLesson
                                key={l.id}
                                lesson={l}
                                activeStepId={props.activeStepId}
                                goToLesson={props.goToLesson} />
                        )
                    })}
                    {props.chapter.enableTest && (
                        <AcademyCourseSidebarTest
                            test={props.chapter.test}
                            activeStepId={props.activeStepId}
                            goToTest={props.goToTest} />
                    )}
                </div>
            )}
        </div>
    )
}

export default AcademyCourseSidebarChapter;
