import React, { useCallback, useMemo } from 'react';
import "./ComposerAttachments.scss";
import { Label } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { SpintrTypes } from 'src/typings';
import ComposerAttachmentImage from '../ComposerAttachmentImage/ComposerAttachmentImage';
import ComposerAttachmentAudio from '../ComposerAttachmentAudio/ComposerAttachmentAudio';
import ComposerAttachmentFile from '../ComposerAttachmentFile/ComposerAttachmentFile';
import ComposerAttachmentGeneral from '../ComposerAttachmentGeneral/ComposerAttachmentGeneral';
import { ExternalFile } from 'src/chat';
import ComposerAttachmentExternalFile from '../ComposerAttachmentExternalFile/ComposerAttachmentExternalFile';

interface IProps {
    attachments: Spintr.IChatMessageAttachment[];
    objects: any[];
    onUpdate: (attachments: Spintr.IChatMessageAttachment[], objects: any[]) => void;
}

const ComposerAttachments = (props: IProps) => {
    const onRemove = useCallback((tmpId: number) => {
        props.onUpdate(
            [...props.attachments].filter(x => x.tmpId !== tmpId),
            [...props.objects].filter(x => x.tmpId !== tmpId)
        );
    }, [props.attachments, props.objects]);

    const onAttachmentUpdate = useCallback((updatedItem: Spintr.IChatMessageAttachment) => {
        props.onUpdate(
            props.attachments.map((item) => {
                if (item.tmpId === updatedItem.tmpId) {
                    return updatedItem;
                }

                return item;
            }),
            props.objects
        );
    }, [props.attachments, props.objects]);

    const onObjectUpdate = useCallback((updatedItem: any) => {
        props.onUpdate(
            props.attachments,
            props.objects.map((item) => {
                if (item.tmpId === updatedItem.tmpId) {
                    return updatedItem;
                }

                return item;
            })
        );
    }, [props.attachments, props.objects]);

    const getGroupedFileType = useCallback((typeId: SpintrTypes.FileType) => {
        const types = [
            {
                groupedTypeId: SpintrTypes.FileType.Png,
                typeIds: [SpintrTypes.FileType.Png, SpintrTypes.FileType.Jpg, SpintrTypes.FileType.Gif]
            }, 
            {
                groupedTypeId: SpintrTypes.FileType.Aac,
                typeIds: [SpintrTypes.FileType.Aac, SpintrTypes.FileType.Mp3]
            }
        ];

        const foundType = types.find(x => x.typeIds.includes(typeId));

        if (foundType) {
            return foundType.groupedTypeId;
        }

        return typeId;
    }, []);

    if (props.objects.filter(x => x.type !== SpintrTypes.UberType.Milestone).length === 0 &&
        props.attachments.length === 0) {
        return null;
    }

    return (
        <div className="attachments">
            {props.attachments.map((attachment: Spintr.IChatMessageAttachment) => {
                const typeId = getGroupedFileType(attachment.typeId);

                switch (typeId) {
                    case SpintrTypes.FileType.Png:
                        return <ComposerAttachmentImage
                            key={attachment.tmpId}
                            attachment={attachment}
                            onRemove={() => onRemove(attachment.tmpId)} />
                    case SpintrTypes.FileType.Aac:
                        return <ComposerAttachmentAudio
                            key={attachment.tmpId}
                            attachment={attachment}
                            onRemove={() => onRemove(attachment.tmpId)}
                            onUpdate={onAttachmentUpdate} />
                    default:
                        return <ComposerAttachmentFile
                            key={attachment.tmpId}
                            attachment={attachment}
                            onRemove={() => onRemove(attachment.tmpId)} />
                }
            })}
            {props.objects.filter(x => x.type !== SpintrTypes.UberType.Milestone).map((object: any) => {
                switch (object.type) {
                    case SpintrTypes.UberType.File:
                        return <ComposerAttachmentFile
                            key={object.tmpId}
                            attachment={object}
                            onRemove={() => onRemove(object.tmpId)} />
                    case SpintrTypes.UberType.ExternalFile:
                        return <ComposerAttachmentExternalFile
                            key={object.tmpId}
                            object={object}
                            onRemove={() => onRemove(object.tmpId)} />
                    default:
                        return (
                            <ComposerAttachmentGeneral
                                key={object.tmpId}
                                name={object.name}
                                onRemove={() => onRemove(object.tmpId)} />
                        )
                }
            })}
        </div>
    )
}

export default ComposerAttachments;
