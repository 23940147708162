import { ITheme } from '@fluentui/react';
import React, { Component, ReactNode, useMemo } from "react";
import { defaults } from 'react-chartjs-2';
import { connect } from "react-redux";
import Color from 'src/style/colors/Color';
import ThemeContext, { produceTheme } from 'src/style/ThemeContext';
import { getSpintrTheme } from 'src/style/utilities';
import { Style } from 'src/ui/helpers';
import getLightOrDarkColorBasedOnColor from 'src/utils/getLightOrDarkColorBasedOnColor';

interface ITenantStyle {
    css: string;
    theme: ITheme;
}

interface IProps {
    instance: any;
    appMode: boolean;
    isInTeamsApp: boolean;
    children?: any;
}

interface IState {
    style?: ITenantStyle;
}

class SpintrCustomStyleProvider extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            style: {
                css: "",
                theme: undefined
            }
        };
    }

    componentDidMount(): void {
        this.setState({
            style: this.getStyleString(this.props),
        });
    }

    public getStyleString(props: IProps): ITenantStyle {
        const spintrTheme = getSpintrTheme(this.props.instance);
        let { primaryColor, accentColor, headerColor, headerSecondaryColor } = spintrTheme;
        let lightPrimaryColor = Color.fromHex(primaryColor).customLighten().toString("hex");
        let desaturatePrimaryColor = Color.fromHex(primaryColor).desaturate(40).toString("hex");

        if (!accentColor || accentColor.length === 0) {
            accentColor = "#FFFFFF";
        }

        if (!headerColor || headerColor.length === 0) {
            headerColor = "#FFFFFF";
        }

        const css = `
            ${this.getColorStyle(primaryColor, "primary")}
            ${this.getColorStyle(headerSecondaryColor, "secondary")}
            ${this.getColorStyle(accentColor, "accent")}
            ${this.getColorStyle(lightPrimaryColor, "lightPrimary")}
            ${this.getColorStyle(desaturatePrimaryColor, "desaturatePrimary")}
            ${this.getCustomSidebarCss(spintrTheme)}
            ${this.getCustomFontHeadlineCss()}
            ${this.getUppercaseHeadlineCss()}
            ${this.getAppModeCss()}
            ${this.getPivotFilterStyleStyle(primaryColor)}
            ${this.getHeaderColorsCss(spintrTheme)}
        `.trim();

        const theme = produceTheme(primaryColor);

        return { css, theme };
    }

    getHeaderColorsCss(theme: Spintr.ISpintrTheme) {
        let headerBackgroundColor = theme.useColorHeader
            ? theme.headerColor
            : "#FFFFFF";

        if (!headerBackgroundColor) {
            headerBackgroundColor = "#FFFFFF";
        }

        let textColor = getLightOrDarkColorBasedOnColor(headerBackgroundColor,
            Style.getHexFromSpintrColor("white"),
            Style.getHexFromSpintrColor("dark-grey"));

        let result = `
            .header-background {
                background-color: ${headerBackgroundColor};
            }

            .ResponsiveHeader, .notch-wrapper {
                background-color: ${headerBackgroundColor} !important;
            }

            .SpintrHeaderMenu .search-field input { color: ${textColor} !important; }
            .SpintrHeaderMenu .search-field input::-webkit-input-placeholder { color: ${textColor} !important; }
            .SpintrHeaderMenu .search-field input:-ms-input-placeholder  { color: ${textColor} !important; }
            .SpintrHeaderMenu .search-field input::placeholder { color: ${textColor} !important; }
            .SpintrHeaderMenu .search-field input::placeholder { color: ${textColor} !important; }
            .SpintrHeaderMenu .clear-button i { color: ${textColor} !important; }
        `

        if (headerBackgroundColor === "#FFFFFF") {
            result += `
                .SpintrStaticLinks .header-gradient-border > .gradient { display: none; }
                .SpintrUserMenu > .gradient { display: none; }
            `
        } else {
            const isDarkHeaderColor = getLightOrDarkColorBasedOnColor(headerBackgroundColor, "a", "b") === "a";

            if (isDarkHeaderColor) {
                const highlightColor = Color.fromHex(headerBackgroundColor).lighten(15);

                result += `
                    .header-gradient-border {
                            background: linear-gradient(${highlightColor}, ${highlightColor}) padding-box,
                                linear-gradient(135deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1)) border-box;
                    }
                    .menu-item::before {
                            background: linear-gradient(${highlightColor}, ${highlightColor}) padding-box,
                                linear-gradient(135deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1)) border-box;
                    }
                    .SpintrUserMenu .ms-Persona-primaryText .ms-TooltipHost {
                        color: ${Style.getHexFromSpintrColor("light-grey")} !important;
                    }
                `
            } else {
                const highlightColor = Color.fromHex(headerBackgroundColor).darken(5).desaturate(5);
                result += `
                    .header-gradient-border {
                            background: linear-gradient(${highlightColor}, ${highlightColor}) padding-box,
                                linear-gradient(135deg, rgba(34, 35, 75, 0.2), rgba(34, 35, 75, 0.2)) border-box;
                    }
                    .menu-item::before {
                            background: linear-gradient(${highlightColor}, ${highlightColor}) padding-box,
                                linear-gradient(135deg, rgba(34, 35, 75, 0.2), rgba(34, 35, 75, 0.2)) border-box;
                    }
                `
            }
        }

        return result;
    }

    getPivotFilterStyleStyle(primaryColor) {
        return `
            .pivot-filter-style .ms-Pivot .ms-Pivot-link.is-selected { background-color: ${primaryColor}; }
        `;
    }

    getAppModeCss() {
        if (!this.props.appMode) {
            return "";
        }

        return `
            .SpintrHeader { display: none !important; }
            .ResponsiveHeader { display: none !important; }
            .mainWrap { width: 100% !important; }
            .appWrap { padding-top: 0px !important; }
            .contentWrap { min-height: 100vh !important; }
        `;
    }

    getColorStyle(color: string, name: string): string {
        const textColor = getLightOrDarkColorBasedOnColor(color, "#FFF", "#000");

        const css = `
            .${name}BGColor { background-color: ${color} !important; }
            .${name}BGColorHover:hover { background-color: ${color} !important; }
            .${name}FGColor { color: ${color} !important; }
            .${name}FGColorHover:hover { color: ${color} !important; }
            .${name}IconColor svg path { fill: ${color} !important; }
            .${name}SubmenuIconColor ul li  i { color: ${color} !important; }
            .${name}IconColorHover:hover {color: ${color} !important }
            .${name}IconColorHover:hover div i {color: ${color} !important }
            .${name}IconColorHover:hover svg path { fill: ${color} !important }
            .${name}BorderColor { border-color: ${color} !important; }
            .${name}BorderColorHover:hover { border-color: ${color} !important; }
            .${name}FGColorHover:hover { color: ${color} !important; }
            .${name}BorderBottomColor { border-bottom-color: ${color} !important; }
            .${name}BorderTopColor { border-top-color: ${color} !important; }
            .${name}BorderBottomColorHover:hover { border-bottom-color: ${color} !important; }
            .${name}BorderLeft { border-left: 2px solid ${color} !important; }
            .${name}BorderLeftColor { border-left-color: ${color} !important; }
            .${name}BGColorInitials .ms-Persona-initials { background-color: ${color} !important; }
            .${name}VisageIconColorFill svg * { fill: ${color} !important; }
            .${name}VisageIconColorStroke svg * { fill: ${color} !important; }
            .${name}TextContrast { color: ${textColor} !important; }
        `;

        return css;
    }

    getCustomFontHeadlineCss() {
        const fontUrl = this.props.instance.get("fontUrl");

        if (fontUrl) {
            return `
                @font-face {
                    font-family: SpintrCustomHeadlineFont;
                    src: url('${fontUrl}');
                }

                h1:not(.ignore-custom-font):not(.ignore-custom-font *), 
                h2:not(.ignore-custom-font):not(.ignore-custom-font *), 
                h3:not(.ignore-custom-font):not(.ignore-custom-font *), 
                h4:not(.ignore-custom-font):not(.ignore-custom-font *), 
                h5:not(.ignore-custom-font):not(.ignore-custom-font *), 
                h6:not(.ignore-custom-font):not(.ignore-custom-font *), 
                .fs-h1:not(.ignore-custom-font):not(.ignore-custom-font *), 
                .fs-h2:not(.ignore-custom-font):not(.ignore-custom-font *), 
                .fs-h3:not(.ignore-custom-font):not(.ignore-custom-font *),
                .fs-h4:not(.ignore-custom-font):not(.ignore-custom-font *),
                .fs-h5:not(.ignore-custom-font):not(.ignore-custom-font *), 
                .fs-h6:not(.ignore-custom-font):not(.ignore-custom-font *) { font-family: SpintrCustomHeadlineFont, Eloquia, Helvetica, Arial, sans-serif !important; }
            `;
        }

        return "";
    }

    getUppercaseHeadlineCss() {
        if (!this.props.instance.get('useUppercaseMainMenu')) {
            return '';
        }

        return `
            h1, h2 { text-transform: uppercase; }
            .SpintrHeader ul li a { text-transform: uppercase; }
            .InformationFeedEntry .entry-title h4 { text-transform: uppercase; }
        `;
    }

    getCustomSidebarCss(spintrTheme: Spintr.ISpintrTheme) {
        if (!spintrTheme?.useColorHeader) {
            return '';
        }

        const accentColor = spintrTheme.accentColor as string;

        return `
          .SpintrSidebarContainer.minimized .tabs ul .primaryFGColor { color: ${accentColor} !important; }
          .SpintrSidebarContainer.minimized .tabs ul .primaryBorderBottomColor { border-bottom-color: ${accentColor} !important; }
          .SpintrSidebarContainer.minimized .tabs ul .primaryFGColorHover:hover { color: ${accentColor} !important; }
          .SpintrSidebarContainer.minimized .tabs ul .primaryBorderBottomColorHover:hover { border-bottom-color: ${accentColor} !important; }
          .SpintrSidebarContainer.minimized .tabs ul .primaryIconColorHover:hover i { color: ${accentColor} !important; }
        `;
    }

    public componentDidUpdate(prevProps: Readonly<IProps>) {
        let updateStyle: boolean = false;

        if (
            (!!this.props.instance.get("instanceId") &&
                this.props.instance.get("instanceId") > 0 &&
                this.props.instance.get("instanceId") !== prevProps.instance.get("instanceId")) ||
            this.props.appMode !== prevProps.appMode
        ) {
            updateStyle = true;
        }

        if (!updateStyle && (
            prevProps.instance.get("theme") !== this.props.instance.get("theme") ||
            prevProps.instance.get("color") !== this.props.instance.get("color") ||
            prevProps.instance.get("logoUrl") !== this.props.instance.get("logoUrl") ||
            prevProps.instance.get("fontUrl") !== this.props.instance.get("fontUrl") ||
            prevProps.instance.get("bodyFontUrl") !== this.props.instance.get("bodyFontUrl") ||
            prevProps.instance.get("menuFontUrl") !== this.props.instance.get("menuFontUrl") ||
            prevProps.instance.get("useUppercaseMainMenu") !== this.props.instance.get("useUppercaseMainMenu") ||
            prevProps.instance.get("useColorHeader") !== this.props.instance.get("useColorHeader") ||
            prevProps.instance.get("searchFieldBackgroundColor") !== this.props.instance.get("searchFieldBackgroundColor") ||
            prevProps.instance.get("headerColor") !== this.props.instance.get("headerColor") ||
            prevProps.instance.get("accentColor") !== this.props.instance.get("accentColor") ||
            prevProps.instance.get("backgroundColor") !== this.props.instance.get("backgroundColor") ||
            prevProps.instance.get("backgroundType") !== this.props.instance.get("backgroundType") ||
            prevProps.instance.get("useSecondaryColorHeader") !== this.props.instance.get("useSecondaryColorHeader") ||
            prevProps.instance.get("headerSecondaryColor") !== this.props.instance.get("headerSecondaryColor"))) {
            updateStyle = true;
        }

        if (updateStyle) {
            if (!!defaults.font) {
                if (this.props.instance.get("bodyFontUrl")) {
                    defaults.font.family = "SpintrCustomBodyFont";
                } else {
                    defaults.font.family = "Eloquia";
                }
            }

            this.setState({
                style: this.getStyleString(this.props),
            });
        }
    }

    public render(): ReactNode {
        return (
            <ThemeContext.Provider value={this.state.style.theme}>
                <style>{this.state.style.css}</style>
                {
                    this.props.children
                }
            </ThemeContext.Provider>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    instance: state.instance,
    appMode: state.ui.appMode,
    isInTeamsApp: state.ui.isInTeamsApp,
});

export default connect(mapStateToProps)(SpintrCustomStyleProvider);
