import axios from "axios";
import moment from "moment";
import qs from "qs";
import React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IActiveUserProfile } from "src/profile/reducer";
import { IVisageSidebarMenuItem, VisageSidebarMode } from "src/sidebar";
import { expandToActiveItem, setSidebarItems, setSidebarMode } from "src/sidebar/actions";
import api from "src/spintr/SpintrApi";
import { IApplicationState } from "src/spintr/reducer";
import { ActionMenu, Breadcrumbs, Label, PageHeader, SpintrUser, UnstyledButton } from "src/ui";
import InfoDialog from "src/ui/components/Dialogs/InfoDialog";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { capitalizeFirstLetter, getQueryStringMap } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { disableUser, enableUser, fetchPeople, followUser, lockUser, unfollowUser, unlockUser } from "../actions";
import RoleListModal from "./RoleListModal";
import UserCard from "./UserCard";
import "./UserCatalogueView.scss";
import { getSubMenu } from "src/sidebar/utils";
import { SpintrTypes } from "src/typings";
import { Icon as FabricIcon, Selection, SelectionMode, Stack } from "@fluentui/react";
import { IContentHeaderButton } from "src/ui/components/PageHeader";

export interface IDetailsListPeopleState {
    hideActiveUsers: boolean;
    hideLockedUsers: boolean;
    hideDeactivatedUsers: boolean;
    searchText: string;
    orderByColumn: string;
    isAscending: boolean;
    infoDialog: IInfoDialogState;
    roleListModal: IRoleListModal;
    units: any[];
    roles: any[];
    selectedItems: any[];
    renderAsCards: boolean;
    isLoading: boolean;
}

interface IInfoDialogState {
    isOpen: boolean;
    message: string;
}

interface IRoleListModal {
    isOpen: boolean;
    roles: string[];
    name: string;
}

export interface IDocument {
    id: number;
    key: string;
    name: string;
    iconName: string;
    departmentName: string;
    number: string;
    fileType: string;
    employment: any;
    isFollowing: boolean;
    email: string;
    rights: any;
    image: string;
    roles: any[];
    state?: number;
}

interface IProps {
    people?: any;
    isLoading?: boolean;
    totalCount?: number;
    dispatch?: (any) => void;
    currentUser?: IActiveUserProfile;
    history?: any;
    filterOnRole?: number;
    filterOnDepartment?: number;
    isAdmin?: boolean;
    isEditor?: boolean;
    isUserManager?: boolean;
    enableUserResources?: boolean;
    hideUserFollowFunction?: boolean;
    hideUserEmailAddresses?: boolean;
    organisationEnabled?: boolean;
    isSmallViewMode?: boolean;
    instance?: any;
    enableCompany?: boolean;
    instanceName?: string;
    instanceColor?: string;
    coworkersEnabled: boolean;
}

type Props = IProps & RouteComponentProps;

export class UserCatalogueView extends React.Component<Props, IDetailsListPeopleState> {
    private listRef = React.createRef<SpintrList>();
    private initialSearchText = getQueryStringMap(this.props.location)?.q;
    private _isMounted: boolean = false;
    private selection: Selection;

    constructor(props: Props) {
        super(props);
        this.state = {
            hideActiveUsers: false,
            hideLockedUsers: false,
            hideDeactivatedUsers: true,
            renderAsCards: true,
            searchText: "",
            orderByColumn: "name",
            isAscending: true,
            isLoading: false,
            infoDialog: {
                isOpen: false,
                message: "",
            },
            roleListModal: {
                isOpen: false,
                name: "",
                roles: [],
            },
            units: [],
            roles: [],
            selectedItems: [],
        };

        if (!props.coworkersEnabled && !this.props.location.pathname.startsWith("/admin")) {
            window.location.href = "/"
        }

        this.selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectedItems: this.selection.getSelection(),
                });
            },
        });
    }

    private formatDate(employment) {
        if (!employment) {
            return;
        }

        const startDate = new Date(employment.startDate);
        let dateString = startDate.toLocaleDateString();

        if (employment.endDate) {
            const endDate = new Date(employment.endDate);
            dateString = `${dateString} - ${endDate.toLocaleDateString()}`;
        }

        return dateString;
    }

    private toggleCardRender = () => {
        this.setState((prevState) => ({
            renderAsCards: !prevState.renderAsCards,
        }));
    };

    private renderActionMenu = (item) => {
        let hasMatchingDepartment =
            this.props.currentUser.departments.indexOf(item.departmentId) > -1;

        if (item.departments && !hasMatchingDepartment) {
            for (const department of item.departments) {
                if (this.props.currentUser.departments.indexOf(department.id) > -1) {
                    hasMatchingDepartment = true;
                }
            }
        }

        const currentUserCanEditEmployment =
            this.props.enableUserResources &&
            this.props.currentUser.settings.canEditEmployment &&
            (this.props.currentUser.settings.canEditAllEmployments ||
                (!item.isPrioritized && hasMatchingDepartment));

        return (
            <ActionMenu
                menuClassName="hasSpintrIcons"
                categories={[
                    {
                        items: [
                            ...(item.id !== this.props.currentUser.id &&
                                !this.props.hideUserFollowFunction
                                ? [
                                    item.isFollowing
                                        ? {
                                            text: localize("SlutaFoljDennaPerson"),
                                            onClick: () => this.unfollowUser(item.id),
                                            icon: "flag",
                                            onRenderIcon: () => {
                                                return (
                                                    <Visage2Icon
                                                        icon="flag"
                                                        size="small"
                                                        color="mid-grey" />
                                                )
                                            }
                                        }
                                        : {
                                            text: localize("FoljDennaPerson"),
                                            onClick: () => this.followUser(item.id),
                                            icon: "flag",
                                            onRenderIcon: () => {
                                                return (
                                                    <Visage2Icon
                                                        icon="flag"
                                                        //iconStyle={iconStyle}
                                                        size="small"
                                                        color="mid-grey" />
                                                )
                                            }
                                        },
                                ]
                                : []),
                            ...(item.number
                                ? [
                                    {
                                        text: item.number,
                                        href: `tel:${item.number}`,
                                        icon: "phone",
                                        onRenderIcon: () => {
                                            return (
                                                <Visage2Icon
                                                    icon="phone"
                                                    //iconStyle={iconStyle}
                                                    size="small"
                                                    color="mid-grey" />
                                            )
                                        }
                                    },
                                ]
                                : []),
                            ...(item.email && !this.props.hideUserEmailAddresses
                                ? [
                                    {
                                        text: item.email,
                                        href: `mailto:${item.email}`,
                                        icon: "mail",
                                        onRenderIcon: () => {
                                            return (
                                                <Visage2Icon
                                                    icon="sms"
                                                    //iconStyle={iconStyle}
                                                    size="small"
                                                    color="mid-grey" />
                                            )
                                        }
                                    },
                                ]
                                : []),
                            ...(currentUserCanEditEmployment
                                ? [
                                    {
                                        text: localize("Anstallning"),
                                        onClick: () => {
                                            /* TODO: Go to resources.userForm */
                                        },
                                    },
                                    {
                                        text: localize("TilldeladeResurser"),
                                        onClick: () => {
                                            /* TODO: Go to resources.user */
                                        },
                                    },
                                ]
                                : []),
                        ],
                    },
                    ...(this.props.isAdmin ||
                        (this.props.isUserManager &&
                            (item.departmentId === this.props.currentUser.department.id ||
                                this.props.currentUser.departments.some(
                                    (d) => d.id === item.departmentId
                                )))
                        ? [
                            {
                                title: "Admin",
                                items: [
                                    {
                                        text: localize("Redigera"),
                                        onClick: () => {
                                            const isAdminView =
                                                window.location.href.indexOf("/admin/") > -1;

                                            if (isAdminView) {
                                                this.props.history.push(
                                                    "/admin/users/" + item.id + "/edit"
                                                );
                                            } else {
                                                this.props.history.push(
                                                    "/people/" + item.id + "/edit"
                                                );
                                            }
                                        },
                                    },
                                    {
                                        text: localize("SkickaEttNyGenereratLosenord"),
                                        onClick: () =>
                                            this.sendNewPassword(item.id, item.email),
                                    },
                                    ...(item.isEligibleForNewInvite ? [{
                                        text: localize("SkickaInbjudan"),
                                        onClick: () => this.sendInvite(item.id),
                                    }] : []),
                                    item.rights.locked
                                        ? {
                                            text: localize("LasUppAnvandare"),
                                            onClick: () => this.unlockUser(item.id),
                                        }
                                        : {
                                            text: localize("LasAnvandare"),
                                            onClick: () => this.lockUser(item.id),
                                        },
                                    item.rights.deleted
                                        ? {
                                            text: localize("AktiveraAnvandare"),
                                            onClick: () => this.enableUser(item.id),
                                        }
                                        : {
                                            text: localize("InaktiveraAnvandare"),
                                            onClick: () => this.disableUser(item.id),
                                        },
                                    {
                                        text: localize("AnonymiseraGDPR"),
                                        onClick: () => this.anonymizeUser(item.id),
                                    },
                                ],
                            },
                        ]
                        : []),
                ]}
            />
        );
    }

    public render() {
        const isAdminView = window.location.href.indexOf("/admin/") > -1;
        const headerText = isAdminView ? localize("Anvandare") : localize("EMPLOYEES_AT").replace("{0}", this.props.instanceName);
        const queryStringMap = getQueryStringMap(this.props.location);

        return (
            <div className="UserCatalogueView ModularPage" data-cy="coworkers">
                <div className="ModularPageHeader visage-box-shadow">
                    <Breadcrumbs
                        displayInstance
                        items={[
                            ...(isAdminView ? [{
                                text: localize("Administration"),
                                key: "breadcrumbs-administration",
                                link: "/admin",
                            }] : []),
                            {
                                text: localize("Medarbetare"),
                                key: "breadcrumbs-coworkers",
                                link: isAdminView ? "/admin/users" : "/people",
                            },
                        ]}
                    />
                    <PageHeader
                        title={headerText}
                        hits={this.props.totalCount}
                        buttons={[
                            {
                                text: localize("Filter"),
                                icon: "sort",
                                subMenuProps: {
                                    items: [
                                        ...(this.props.isAdmin || this.props.isUserManager ? [
                                            {
                                                key: "hideActive",
                                                text: this.state.hideActiveUsers
                                                    ? localize("VisaAktivaAnvandare")
                                                    : localize("DoljAktivaAnvandare"),
                                                onClick: this._toggleShowActiveUsers,
                                            },
                                            {
                                                key: "hideLocked",
                                                text: this.state.hideLockedUsers
                                                    ? localize("VisaLastaAnvandare")
                                                    : localize("DoljLastaAnvandare"),
                                                onClick: this._toggleShowLockedUsers,
                                            },
                                            {
                                                key: "hideDeactivated",
                                                text: this.state.hideDeactivatedUsers
                                                    ? localize("VisaInaktiveradeAnvandare")
                                                    : localize("DoljInaktiveradeAnvandare"),
                                                onClick: this._toggleShowDeactivatedUsers,
                                            },
                                            {
                                                key: "export",
                                                text: localize("ExporteraUtsokningTillCSV"),
                                                onClick: this.exportCSV,
                                            },
                                            {
                                                key: "listType",
                                                text: this.state.renderAsCards
                                                    ? localize("VIEW_AS_LIST")
                                                    : localize("VIEW_AS_CARDS"),
                                                onClick: this.toggleCardRender,
                                            },
                                        ] : []),
                                        ...(!!this.state.roles && this.state.roles.length > 0 ? [
                                            {
                                                key: "roles",
                                                text: localize("Roller"),
                                                onClick: () => {},
                                                subMenuProps: {
                                                    items: [
                                                        {
                                                            key: "role-all",
                                                            text: localize("Alla"),
                                                            onClick: () => {
                                                                const query = {
                                                                    ...getQueryStringMap(this.props.location),
                                                                    r: 0
                                                                };
            
                                                                this.props.history.push({ search: qs.stringify(query) });
                                                                this.listRef.current.reFetch();
                                                            },
                                                            className: queryStringMap.r === "0" ? "SocialFeedFilter-active-item" : "",
                                                        },
                                                        ...(this.state.roles.map(x => {
                                                            return {
                                                                key: "role-" + x.id,
                                                                text: x.name,
                                                                onClick: () => {
                                                                    const query = {
                                                                        ...getQueryStringMap(this.props.location),
                                                                        r: x.id
                                                                    };
                
                                                                    this.props.history.push({ search: qs.stringify(query) });
                                                                    this.listRef.current.reFetch();
                                                                },
                                                                className: queryStringMap === x.id.toString() ? "SocialFeedFilter-active-item" : "",
                                                            }
                                                        }))
                                                    ]
                                                }
                                            }
                                        ] : []),
                                        ...(!!this.state.units && this.state.units.length > 0 ? [
                                            {
                                                key: "units",
                                                text: localize("Avdelningar"),
                                                onClick: () => {},
                                                subMenuProps: {
                                                    items: [
                                                        {
                                                            key: "unit-all",
                                                            text: localize("Alla"),
                                                            onClick: () => {
                                                                const query = {
                                                                    ...getQueryStringMap(this.props.location),
                                                                    d: 0
                                                                };
            
                                                                this.props.history.push({ search: qs.stringify(query) });
                                                                this.listRef.current.reFetch();
                                                            },
                                                            className: queryStringMap.d === "0" ? "SocialFeedFilter-active-item" : "",
                                                        },
                                                        ...(this.state.units.map(x => {
                                                            return {
                                                                key: "unit-" + x.id,
                                                                text: x.name,
                                                                onClick: () => {
                                                                    const query = {
                                                                        ...getQueryStringMap(this.props.location),
                                                                        d: x.id
                                                                    };
                
                                                                    this.props.history.push({ search: qs.stringify(query) });
                                                                    this.listRef.current.reFetch();
                                                                },
                                                                className: queryStringMap.d === x.id.toString() ? "SocialFeedFilter-active-item" : "",
                                                                subMenuProps: {
                                                                    items: [
                                                                        {
                                                                            key: "unit-" + x.id,
                                                                            text: x.name,
                                                                            onClick: () => {
                                                                                const query = {
                                                                                    ...getQueryStringMap(this.props.location),
                                                                                    d: x.id
                                                                                };
            
                                                                                this.props.history.push({ search: qs.stringify(query) });
                                                                                this.listRef.current.reFetch();
                                                                            },
                                                                            className: queryStringMap.d === x.id.toString() ? "SocialFeedFilter-active-item" : "",
                                                                        },
                                                                        ...x.departments.map(d => {
                                                                            return {
                                                                                key: "unit" + d.id,
                                                                                text: d.name,
                                                                                onClick: () => {
                                                                                    const query = {
                                                                                        ...getQueryStringMap(this.props.location),
                                                                                        d: d.id
                                                                                    };
            
                                                                                    this.props.history.push({ search: qs.stringify(query) });
                                                                                    this.listRef.current.reFetch();
                                                                                },
                                                                                className: queryStringMap.d === d.id.toString() ? "SocialFeedFilter-active-item" : "",
                                                                            }
                                                                        })
                                                                    ]
                                                                }
                                                            }
                                                        }))
                                                    ]
                                                }
                                            }
                                        ] : [])
                                    ]
                                }
                            },
                            ...(this.state.selectedItems.length > 0 ? [{
                                key: "anonymize",
                                text: localize("AnonymiseraGDPR"),
                                onClick: this.anonymizeUsers,
                            }] : []),
                            ...(this.state.selectedItems.some((u) => u.rights.locked) ? [{
                                key: "unlock",
                                text: localize("LasUppAnvandare"),
                                onClick: this.unlockUsers,
                            }] : []),
                            ...(this.state.selectedItems.some((u) => !u.rights.locked) ? [{
                                key: "lock",
                                text: localize("LasAnvandare"),
                                onClick: this.lockUsers
                            }] : []),
                            ...(this.state.selectedItems.some((u) => u.rights.deleted) ? [{
                                key: "enable",
                                text: localize("AktiveraAnvandare"),
                                onClick: this.enableUsers,
                            }] : []),
                            ...(this.state.selectedItems.some((u) => !u.rights.deleted) ? [{
                                key: "disable",
                                text: localize("InaktiveraAnvandare"),
                                onClick: this.disableUsers
                            }] : []),
                            ...(this.props.isAdmin || this.props.isUserManager
                                ? [
                                    {
                                        key: "add",
                                        text: localize("BjudIn"),
                                        onClick: () => {
                                            this.props.history.push("/organisation/invite");
                                        },
                                        iconProps: { iconName: "Add" },
                                        theme: "primary",
                                        className: "commandBarAddButton",
                                    },
                                    {
                                        key: "create",
                                        text: localize("SkapaAnvandare"),
                                        onClick: () => {
                                            const isAdminView = window.location.href.indexOf("/admin/") > -1;
    
                                            if (isAdminView) {
                                                this.props.history.push("/admin/users/create");
                                            } else {
                                                this.props.history.push("/people/create");
                                            }
                                        },
                                        iconProps: { iconName: "Add" },
                                        theme: "primary",
                                        className: "commandBarAddButton",
                                    },
                                ]
                                : []),
                        ] as IContentHeaderButton[]}
                        displaySearch
                        onSearchQueryChange={(value: string) => {
                            this.listRef.current.setSearchText(value);
                        }}
                    />
                </div>
                <SpintrList
                    renderCommandBarAsHeader={window.location.href.indexOf("/admin/") > -1}
                    ref={this.listRef}
                    fetch={(skip, take, orderByColumn, isAscending, searchText, cancelToken, fetchMore) => {
                        const { hideActiveUsers, hideDeactivatedUsers, hideLockedUsers } = this.state;

                        this.setState({ orderByColumn, searchText, isAscending });

                        const query : any = {
                            ...getQueryStringMap(this.props.location),
                            q: searchText?.length > 0 ? searchText : undefined,
                        };

                        this.props.history.push({ search: qs.stringify(query) });

                        if (fetchMore) {
                            this.props.dispatch(
                                fetchPeople(
                                    orderByColumn,
                                    isAscending,
                                    this.props.people.length,
                                    take,
                                    searchText,
                                    hideActiveUsers,
                                    hideDeactivatedUsers,
                                    hideLockedUsers,
                                    query.r,
                                    query.d,
                                    cancelToken,
                                    fetchMore
                                )
                            );
                        } else {
                            this.props.dispatch(
                                fetchPeople(
                                    orderByColumn,
                                    isAscending,
                                    skip,
                                    take,
                                    searchText,
                                    hideActiveUsers,
                                    hideDeactivatedUsers,
                                    hideLockedUsers,
                                    query.r,
                                    query.d,
                                    cancelToken,
                                    fetchMore
                                )
                            );
                        }

                    }}
                    disablePagination={this.state.renderAsCards}
                    data={{ data: this.props.people, totalCount: this.props.totalCount }}
                    infiniteScroll={this.state.renderAsCards}
                    initialSearchText={this.initialSearchText}
                    isLoading={this.props.isLoading || this.state.isLoading}
                    listType={this.state.renderAsCards ? "cards" : "list"}
                    renderCard={(item: any, index: number) => {
                        return (
                            <UserCard
                                item={item}
                                key={item.id}
                                history={this.props.history}
                                instanceColor={this.props.instanceColor}
                                renderActionMenu={() => {
                                    return this.renderActionMenu(item);
                                }}
                            />
                        )
                    }}
                    renderShimmerCard={(index: number) => {
                        return (
                            <UserCard isShimmer={true} index={index} key={index} history={this.props.history} />
                        )
                    }}
                    columns={[
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                            minWidth: 200,
                            isMultiline: true,
                            onRender: (item) => {
                                return (
                                    <Stack className="UserCatalogueView-nameColumn" horizontal verticalAlign="center">
                                        {item.rights.deleted ? (
                                            <span>
                                                <SpintrUser name={item.name} subText={item.roles
                                                    .map((role: any) => role.name)
                                                    .join(", ")} imageUrl={item.image}
                                                    personalName={true} />
                                            </span>
                                        ) : (
                                            <>
                                                <Link
                                                    to={`/profile/${item.id}`}
                                                    className="userLink"
                                                    aria-label={item.name}
                                                >
                                                    <SpintrUser name={item.name} subText={item.roles
                                                        .map((role: any) => role.name)
                                                        .join(", ")} imageUrl={item.image} state={item.state}
                                                        personalName={true} />
                                                </Link>{" "}
                                            </>
                                        )}
                                        {item.rights.locked && <FabricIcon iconName="LockSolid" title={localize("Last")} />}
                                        {item.rights.deleted && <FabricIcon iconName="BlockedSolid" title={localize("Inaktiverad")} />}
                                        {item.absence && (
                                            <Label color="red" size="small-1" as="span">
                                                {!item.absenceLanguageTag
                                                    ? item.absence
                                                    : localize(item.absenceLanguageTag)}
                                            </Label>
                                        )}
                                    </Stack>
                                );
                            },
                        },
                        ...(!this.props.isSmallViewMode
                            ? [
                                ...(this.props.organisationEnabled
                                    ? [
                                        {
                                            name: localize("Avdelning"),
                                            fieldName: "department",
                                            minWidth: 125,
                                            onRender: (item: IDocument) => {
                                                return (
                                                    <>
                                                        <span>{item.departmentName}</span>
                                                    </>
                                                );
                                            },
                                        },
                                    ]
                                    : []),
                                {
                                    name: localize("Telefonnummer"),
                                    fieldName: "number",
                                    minWidth: 125,
                                    onRender: (item) => {
                                        return (
                                            <>
                                                {item.phone && (
                                                    <span title={localize("Telefonnummer")}>{item.phone}</span>
                                                )}
                                                {!item.phone && item.cellphone && (
                                                    <div>
                                                        <span title={localize("Mobilnummer")}>{item.cellphone}</span>
                                                    </div>
                                                )}
                                            </>
                                        );
                                    },
                                },
                                ...(this.props.enableUserResources
                                    ? [
                                        {
                                            name: localize("AnstalldSedan"),
                                            fieldName: "employment",
                                            minWidth: 125,
                                            onRender: (item: IDocument) => {
                                                return (
                                                    <>
                                                        {item.employment?.hasEnded && (
                                                            <div>
                                                                <span className="primaryFGColor">
                                                                    {localize("AnstallningAvslutad")}
                                                                </span>
                                                            </div>
                                                        )}

                                                        <span>
                                                            {item.employment && this.formatDate(item.employment)}
                                                        </span>
                                                    </>
                                                );
                                            },
                                        },
                                    ]
                                    : []),
                            ]
                            : []),
                        {
                            name: localize("SenastSedd"),
                            fieldName: "lastSeen",
                            minWidth: 125,
                            onRender: (item) => {
                                return (
                                    <>
                                        <div>{item.lastSeen && moment(item.lastSeen).fromNow()}</div>
                                    </>
                                );
                            },
                        },
                        {
                            name: "",
                            minWidth: 40,
                            maxWidth: 60,
                            onRender: (item: any) => {
                                return this.renderActionMenu(item);
                            },
                        },
                    ]}
                    orderByColumn="name"
                    selectionMode={this.props.isAdmin ? SelectionMode.multiple : SelectionMode.none}
                    selection={this.selection}
                    take={24}
                    disableSearch
                    disableCommandBar
                />
                {
                    !!this.state.infoDialog.isOpen && (
                        <InfoDialog
                            show={this.state.infoDialog.isOpen}
                            onDismiss={this.dismissDialog}
                            message={this.state.infoDialog.message}
                        />
                    )
                }
                {this.state.roleListModal.isOpen && (
                    <RoleListModal
                        dismiss={this.dismissRoleListModal}
                        name={this.state.roleListModal.name}
                        roles={this.state.roleListModal.roles}
                    />
                )}
            </div>
        );
    }

    private renderRoles = (item: IDocument) => (
        <>
            {item.roles
                .slice(0, 3)
                .map((role) => role.name)
                .join(", ")}
            {item.roles.length > 3 && (
                <UnstyledButton
                    onClick={() => {
                        this.setState({
                            roleListModal: {
                                isOpen: true,
                                name: item.name,
                                roles: item.roles.map((role) => role.name),
                            },
                        });
                    }}
                >
                    <a>({localize("VisaMer")})</a>
                </UnstyledButton>
            )}
        </>
    );

    componentWillUnmount() {
        this._isMounted = false;
    }

    public componentDidMount() {
        this._isMounted = true;
        this.updateSideBar();

        const activityLogPromise = api.post(
            "/api/v1/ActivityLog", {
            type: 6,
            ubertype: -1,
            extrainformation: {
                id: 0,
                targeturl: "/people",
                title: localize("Medarbetare"),
            },
        }
        );

        activityLogPromise.catch((_) => {/* ignored */ });

        api.get('/api/units?take=1000&onlyPopulated=true').then((repsonse: any) => {
            this.setState({
                units: repsonse.data.offices
            })
        }).catch(() => {});

        api.get('/api/users/roles?take=1000&onlyPopulated=true').then((repsonse: any) => {
            this.setState({
                roles: repsonse.data
            })
        }).catch(() => {});
    }

    updateSideBar() {
        const isAdminView = window.location.href.indexOf("/admin/") > -1;

        if (isAdminView) {
            return;
        }

        getSubMenu("people", this.props.dispatch, this.props.currentUser, this.props.instance).then((items: IVisageSidebarMenuItem[]) => {
            this.props.dispatch(setSidebarItems(items, "people"));
        }).catch(() => { });
    }

    public componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.filterOnRole !== this.props.filterOnRole ||
            prevProps.filterOnDepartment !== this.props.filterOnDepartment
        ) {
            this.listRef.current.reFetch();
        }
    }

    private _toggleShowActiveUsers = () => {
        this.setState({ hideActiveUsers: !this.state.hideActiveUsers }, () => {
            this.listRef.current.reFetch();
        });
    };

    private _toggleShowLockedUsers = () => {
        this.setState({ hideLockedUsers: !this.state.hideLockedUsers }, () => {
            this.listRef.current.reFetch();
        });
    };

    private _toggleShowDeactivatedUsers = () => {
        this.setState({ hideDeactivatedUsers: !this.state.hideDeactivatedUsers }, () => {
            this.listRef.current.reFetch();
        });
    };
    private exportCSV = () => {
        const { hideActiveUsers, hideDeactivatedUsers, hideLockedUsers, searchText, orderByColumn, isAscending } =
            this.state;

        let onlyAdministrators = false;
        let onlyEditors = false;
        let onlyExternal = false;

        let roleId = this.props.filterOnRole;
        let unitId = this.props.filterOnDepartment;

        if (roleId === -5) {
            roleId = 0;
            onlyAdministrators = true;
        }

        if (roleId === -10) {
            roleId = 0;
            onlyEditors = true;
        }

        if (unitId === 5) {
            unitId = null;
            onlyExternal = true;
        }

        window.open(
            "/api/users/csv/?includeDeactivated=" +
            !hideDeactivatedUsers +
            "&includeLocked=" +
            !hideLockedUsers +
            "&includeActive=" +
            !hideActiveUsers +
            "&onlyAdministrators=" +
            onlyAdministrators +
            "&onlyEditors=" +
            onlyEditors +
            "&onlyExternal=" +
            onlyExternal +
            "&isAscending=" +
            isAscending +
            "&orderByColumn=" +
            orderByColumn +
            "&roleId=" +
            roleId +
            "&unitId=" +
            unitId +
            "&word=" +
            searchText
        );
    };

    private followUser = (userId) => {
        this.props.dispatch(followUser(userId));
    };

    private unfollowUser = (userId) => {
        this.props.dispatch(unfollowUser(userId));
    };

    private sendNewPassword = (id: number, email: string) => {
        api.put("/api/v1/users/" + id + "/newpassword").then(() => {
            this.setState({
                infoDialog: {
                    isOpen: true,
                    message: `${localize("EttMeddelandeMedLosenordetHarSkickatsTill")} ${email}`,
                },
            });
        });
    };

    private sendInvite = (id) => {
        api.put("/api/v1/users/" + id + "/sendinvite").then(() => {
            this.setState({
                infoDialog: {
                    isOpen: true,
                    message: localize("InbjudanHarNuSkickats"),
                },
            });
        });
    };

    private lockUsers = () => {
        const users = this.state.selectedItems.filter((u) => !u.rights.locked);
        const fn = () => {
            for (const user of users) {
                this.props.dispatch(lockUser(user.id));
            }
        }

        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: localize("LasAnvandare"),
                message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                onConfirm: fn,
            })
        );
    };

    private lockUser = (userId) => {
        const fn = () => {
            this.props.dispatch(lockUser(userId));
        };

        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: localize("LasAnvandare"),
                message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                onConfirm: fn,
            })
        );
    };

    private unlockUser = (userId) => {
        const fn = () => {
            this.props.dispatch(unlockUser(userId));
        };

        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: localize("LasUppAnvandare"),
                message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                onConfirm: fn,
            })
        );
    };

    private unlockUsers = () => {
        const users = this.state.selectedItems.filter((u) => u.rights.locked);
        const fn = () => {
            for (const user of users) {
                this.props.dispatch(unlockUser(user.id));
            }
        }

        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: localize("LasUppAnvandare"),
                message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                onConfirm: fn,
            })
        );
    }

    private disableUser = (userId) => {
        const fn = () => {
            this.props.dispatch(disableUser(userId));
        };

        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: localize("InaktiveraAnvandare"),
                message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                onConfirm: fn,
            })
        );
    };

    private disableUsers = () => {
        const users = this.state.selectedItems.filter((u) => !u.rights.deleted);
        const fn = () => {
            for (const user of users) {
                this.props.dispatch(disableUser(user.id));
            }
        }

        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: localize("InaktiveraAnvandare"),
                message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                onConfirm: fn,
            })
        );
    }

    private enableUser = (userId) => {
        const fn = () => {
            this.props.dispatch(enableUser(userId));
        };

        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: localize("AktiveraAnvandare"),
                message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                onConfirm: fn,
            })
        );
    };

    private enableUsers = () => {
        const users = this.state.selectedItems.filter((u) => u.rights.deleted);
        const fn = () => {
            for (const user of users) {
                this.props.dispatch(enableUser(user.id));
            }
        }

        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: localize("AktiveraAnvandare"),
                message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                onConfirm: fn,
            })
        );
    }

    private dismissDialog = () => {
        this.setState({
            infoDialog: {
                isOpen: false,
                message: "",
            },
        });
    };

    private dismissRoleListModal = () => {
        this.setState({
            roleListModal: {
                isOpen: false,
                name: "",
                roles: [],
            },
        });
    };

    private anonymizeUser = (userId) => {
        const fn = () => {
            api.post(`/api/v1/users/${userId}/anonymize`).then((_response) => {
                this.props.history.push(`/admin/users`);
            });
        };
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: localize("AnonymiseraGDPR"),
                message: localize("USER_FORM_CONFIRM_ANONYMIZE"),
                onConfirm: fn,
            })
        );
    }

    private anonymizeUsers = () => {
        const fn = () => {
            this.setState({ isLoading: true });

            const promises = [];

            for (const user of this.state.selectedItems) {
                promises.push(api.post(`/api/v1/users/${user.id}/anonymize`));
            }

            Promise.all(promises).then(() => {
                this.setState({ isLoading: false });
                this.listRef.current.reFetch();
            });
        };

        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: localize("AnonymiseraGDPR"),
                message: localize("USER_FORM_CONFIRM_ANONYMIZE"),
                onConfirm: fn,
            })
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    currentUser: state.profile.active,
    isLoading: state.people.isLoading,
    people: state.people.items,
    totalCount: state.people.totalCount,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    isUserManager: state.profile.active.roles.includes("usermanager"),
    enableUserResources: state.instance.get("enableUserResources"),
    hideUserFollowFunction: state.instance.get("hideUserFollowFunction"),
    hideUserEmailAddresses: state.instance.get("hideUserEmailAddresses"),
    organisationEnabled: state.app.items.some((app) => app.id === 8 && app.enabled),
    isSmallViewMode: state.ui.isSmallViewMode,
    instance: state.instance,
    enableCompany: state.instance.get("enableCompanyLevel"),
    instanceName: state.instance.get("name"),
    instanceColor: state.instance.get("color"),
    coworkersEnabled: state.app.items.some(
        (app) => app.enabled && app.id === SpintrTypes.SpintrApp.Coworkers,
    ),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(UserCatalogueView));
