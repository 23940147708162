import { Icon, SearchBox } from "@fluentui/react";
import React, { Component } from "react";
import { localize } from "src/l10n";
import "./SpintrSearch.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { UnstyledButton } from "../Buttons";

interface IProps {
    onChange?: any;
    onClear?: any;
    value?: string;
    placeholder?: string;
    classes?: string;
    expandable?: boolean;
}

interface IState {
    value: string;
    expanded: boolean;
}

class SpintrSearch extends Component<IProps, IState> {
    private inputRef = React.createRef<HTMLInputElement>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            value: !!props.value ? props.value : "",
            expanded: false
        };
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: !!this.props.value ? this.props.value : ""
            })
        }
    }

    render() {
        const hasValue = !!this.state.value && this.state.value.length > 0;

        let classNames = ["SpintrSearch searchBox CalypsoButton"];

        if (this.props.expandable) {
            classNames.push("expandable");
        }

        if (this.state.expanded) {
            classNames.push("expanded");
        }

        if (!!hasValue) {
            classNames.push("hasValue")
        }

        if (!!this.props.classes) {
            classNames.push(this.props.classes);
        }

        return (
            <div className={classNames.join(" ")} onClick={() => {
                if (!this.props.expandable || this.state.expanded) {
                    return;
                }

                this.setState({
                    expanded: true
                }, () => {
                    this.inputRef.current.focus();
                });
            }}>
                <Visage2Icon icon="search-normal-1" color={"dark-grey"} size="small" />
                <input
                    ref={this.inputRef}
                    type="text"
                    value={this.state.value}
                    autoFocus={this.props.expandable && this.state.expanded}
                    placeholder={this.props.placeholder || (localize("Sok") + "...")}
                    onChange={(ev) => this.props.onChange(ev, ev.target.value)} />
                {(this.state.value.length > 0 || this.state.expanded) && (
                    <UnstyledButton
                        className="clear-button"
                        onClick={() => {
                            if (this.props.onClear) {
                                this.props.onClear();
                            } else {
                                this.props.onChange(undefined, "");
                            }

                            this.setState({
                                expanded: false
                            });
                        }}>
                        <Icon iconName="Cancel" />
                    </UnstyledButton>
                )}
            </div>
        )
    }
}

export default SpintrSearch;