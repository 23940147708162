import React, {  } from "react";
import "./SystemStatus.scss";
import SystemStatusResourceInfo from "./SystemStatusResourceInfo";
import { SpintrTypes } from "src/typings";
import { Style } from "src/ui/helpers";
import { Label } from "src/ui";
import { decodeHtmlEntities, formatInteractiveText } from "src/utils";
import SystemStatusResourceCTA from "./SystemStatusResourceCTA";
import { localize } from "src/l10n";
import moment from "moment";
import { Link } from "react-router-dom";

interface IProps {
    resouce: any;
    systemStatus: any;
}

const SystemStatus = (props: IProps) => {
    const colors = [
        Style.getHexFromSpintrColor("green"),
        Style.getHexFromSpintrColor("red"),
        Style.getHexFromSpintrColor("green"),
        Style.getHexFromSpintrColor("orange")
    ];

    const renderInfoRow = (key: string, value: string, valueTitle?: string) => {
        return (
            <div>
                <Label size="body-2" color="grey">{key}</Label>
                <Label size="body-2" color="dark-grey" title={valueTitle}>{value}</Label>
            </div>
        )
    }

    return (
        <Link
            className="SystemStatus"
            style={{
                borderColor: colors[props.systemStatus.status]
            }}
            to={"/operations/" + props.resouce.id}>
            <div className="segment">
                <SystemStatusResourceInfo resouce={{
                    ...props.resouce,
                    status: props.systemStatus.status
                }} />
            </div>
            <div className="segment">
                {props.systemStatus.text && (
                    <Label 
                        as="div"
                        color="dark-grey"
                        className="general-row-break interactive-text feed-post-text">
                            {formatInteractiveText(
                                decodeHtmlEntities(props.systemStatus.text)
                            )}
                    </Label>
                )}
                <div className="footer-button">
                    <SystemStatusResourceCTA resource={props.resouce} />
                </div>
            </div>
            <div className="spacer" />
            <div className="segment">
                <div className="info">
                    {renderInfoRow(localize("Status"), localize(
                        props.systemStatus.status === SpintrTypes.SystemStatusType.AllClear
                            ? "ALL_CLEAR"
                            : props.systemStatus.status === SpintrTypes.SystemStatusType.Ongoing
                            ? "Pagaende"
                            : props.systemStatus.status === SpintrTypes.SystemStatusType.Planned
                            ? "Planerad"
                            : "Avklarad"
                    ))}
                    {renderInfoRow(localize("Start"), moment(props.systemStatus.startDate).calendar({ sameElse: "lll" }), moment(props.systemStatus.startDate).format("LLL"))}
                    {props.systemStatus.endDate	&& renderInfoRow(localize("Slut"), moment(props.systemStatus.endDate).calendar({ sameElse: "lll" }), moment(props.systemStatus.endDate).format("LLL"))}
                    {props.systemStatus.responsibleUser	&& renderInfoRow(localize("RESPONSIBLE_PERSON"), props.systemStatus.responsibleUser.name)}
                </div>
            </div>
        </Link>
    )
}

export default SystemStatus;
