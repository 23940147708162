import { AxiosResponse } from "axios";
import moment from "moment";
import {
    Dialog,
    DialogFooter,
    DialogType,
    Modal,
    Pivot,
    PivotItem,
    PrimaryButton,
    ProgressIndicator,
    SearchBox,
    Stack,
    TextField
} from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import ThemeContext from "src/style/ThemeContext";
import { SpintrTypes } from "src/typings";
import { Breadcrumbs, Icon, Label, Loader, UnstyledButton } from "src/ui/components";
import ImageCropper from "src/ui/components/ImageCropper";
import ModalHeader from "src/ui/components/Modal/ModalHeader";
import { debounce, uniqueId } from "src/utils";
import { IApplicationState } from "../reducer";
import DropZone from "./DropZone";
import "./FileSelector.scss";
import api from "../SpintrApi";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";
import PopupHeader from "src/ui/components/PopupHeader";
import LoadMoreButton from "src/ui/components/Buttons/LoadMoreButton/LoadMoreButton";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface Props {
    uberId?: number;
    onClose: any;
    onSelect?: (data: any, sourceId?: number) => void;
    sourcesToDisplay?: any;
    allowMultipleFiles?: boolean;
    fileTypesString?: string;
    fileTypes?: any;
    fileUploadType?: number;
    customRequestUrl?: string;
    handleUploadSeparately?: boolean;
    returnBase64?: (data: any) => void;
    projectId?: number;
    planningItemId?: number;
    cropImage?: boolean;
    cropShape?: "rect" | "round";
    cropAspect?: number;
    cropHeight?: number;
    onlyVideo?: boolean;
    renderSizeString?: any;
    isMediaEnabled: boolean;
    hideMedia?: boolean;
    syncBase64?: (data: any) => void;
    showImageCropper?: boolean;
    imageBase64?: string;
    enableMediaflow?: boolean;
    enableUnsplash?: boolean;
}

interface State {
    isLoading: boolean;
    isLoadingMedia: boolean;
    isLoadingMore?: boolean;
    isLoadingUnsplashImage?: boolean;
    isUploading: boolean;
    filesProgress: any;
    hideErrorDialog: boolean;
    embedVideoString: string;
    imageBase64: any;
    showImageCropper: boolean;
    media: any;
    currentFolder: number;
    activeSourceId: number;
    folderPath: Spintr.IBreadcrumbItem[];
    searchText?: string;
    mediaflowContent?: any;
    mediaflowSelectedImage?: any;
    unsplashImages?: any;
    unsplashSelectedImage?: any;
    unsplashTotalPages?: number;
    unsplashPage: number;
}

class FileSelector extends Component<Props, State> {
    static defaultProps = {
        allowMultipleFiles: false,
        fileTypesString: "*",
        fileTypes: "*",
        fileUploadType: 1,
    };

    public static contextType = ThemeContext;

    constructor(props) {
        super(props);

        let sources = this.getSources();

        this.state = {
            activeSourceId: sources[0].id,
            isLoading: true,
            isLoadingMedia: false,
            isUploading: false,
            filesProgress: [],
            hideErrorDialog: true,
            embedVideoString: "",
            imageBase64: props.imageBase64,
            showImageCropper: !!props.showImageCropper,
            media: {},
            currentFolder: undefined,
            folderPath: [],
            unsplashPage: 1,
        };
    }

    renderProgressBar = () => {
        let sum = 0;

        for (let file of this.state.filesProgress) {
            sum += file.progress;
        }

        const avg = sum / this.state.filesProgress.length;

        return <ProgressIndicator label={localize("LaddarUpp")} percentComplete={avg} />;
    };

    componentDidMount = () => {
        this.setState({ isLoadingMedia: true }, () => {
            api.get(`/api/v1/media`, { params: { take: 1000 } }).then((response) => {
                this.setState({ media: response.data, isLoadingMedia: false });
            }).catch(() => {});
        });

        if (!this.props.isMediaEnabled && !this.props.hideMedia && this.props.enableMediaflow) {
            this.fetchMediaflow("root");
        }
    };

    private fileToBase64 = (file) => {
        return new Promise((resolve) => {
            var reader = new FileReader();
            // Read file content on file loaded event
            reader.onload = function (event) {
                resolve(event.target.result);
            };

            // Convert data to base64
            reader.readAsDataURL(file);
        });
    };

    private handleFilesSelected = (files) => {
        if (this.props.syncBase64) {
            this.fileToBase64(files[0]).then((result) => {
                this.props.syncBase64(result);
            });
        }

        if (this.props.onlyVideo) {
            this.setState({
                isUploading: false,
                filesProgress: [],
            });
            return this.props.onSelect(files[0], 7);
        }

        if (this.props.cropImage) {
            this.fileToBase64(files[0]).then((result) => {
                this.setState({
                    imageBase64: result,
                    showImageCropper: true,
                    filesProgress: [],
                });
            });

            return;
        }

        if (this.state.unsplashSelectedImage) {
            const unsplashUrl = this.addDefaultUnsplashParams(this.state.unsplashSelectedImage.urls.raw);
            unsplashUrl.searchParams.set("w", "656"); // set width to 656, same as if it was uploaded via api/uploads/redactor/image
            this.triggerUnsplashDownload(this.state.unsplashSelectedImage, unsplashUrl.pathname);

            this.props.onSelect([{
                imageUrl: unsplashUrl,
                originalImage: unsplashUrl,
                thumbnailUrl: unsplashUrl,
                externalAuthorName: this.state.unsplashSelectedImage.user.name,
                externalAuthorUrl: this.state.unsplashSelectedImage.user.links.html,
                externalPhotoUrl: this.state.unsplashSelectedImage.links.html,
                isUnsplash: true,
            }]);

            return;
        }

        if (this.props.returnBase64) {
            this.fileToBase64(files[0]).then((result) => {
                this.props.returnBase64(result);
            });

            this.setState({
                isUploading: false,
                filesProgress: [],
            });

            return;
        } else if (this.props.handleUploadSeparately) {
            return this.props.onSelect(files);
        }

        this.upload(files);
    };

    renderInsertEmbed = () => {
        return (
            <div>
                <TextField
                    label={localize("KodForInbaddadVideo")}
                    multiline
                    autoAdjustHeight
                    value={this.state.embedVideoString}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                        this.setState({
                            embedVideoString: newValue || "",
                        });
                    }}
                />
                <Stack horizontalAlign="end">
                    <PrimaryButton
                        style={{ marginTop: 12 }}
                        onClick={() => {
                            this.props.onSelect(this.state.embedVideoString, 6);

                            this.props.onClose();
                        }}
                    >
                        {localize("Infoga")}
                    </PrimaryButton>
                </Stack>
            </div>
        );
    };

    getSources() {
        let sources = [
            {
                id: 0,
                key: "0",
                name: localize("LaddaUpp"),
                render: () => {
                    return (
                        <div>
                            {this.state.isUploading ? (
                                this.renderProgressBar()
                            ) : (
                                <DropZone
                                    // ref={this.dropZone}
                                    renderSizeString={this.props.renderSizeString}
                                    isMultiple={this.props.allowMultipleFiles}
                                    fileTypesString={this.props.fileTypesString}
                                    fileTypes={this.props.fileTypes}
                                    onFilesSelected={this.handleFilesSelected}
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                id: 1,
                key: "1",
                name: localize("Filer"),
                render: () => {
                    return <div>Future content</div>;
                },
            },
            {
                id: 4,
                key: "4",
                name: "SharePoint",
                render: () => {
                    return <div>Future content</div>;
                },
            },
            {
                id: 5,
                key: "5",
                name: "OneDrive",
                render: () => {
                    return <div>Future content</div>;
                },
            },
            {
                id: 6,
                key: "6",
                name: localize("Infoga"),
                render: () => {
                    return this.renderInsertEmbed();
                },
            },
            {
                id: 7,
                key: "7",
                name: localize("Media"),
                render: () => {
                    this.renderMedia();
                },
            },
        ];
        let sourcesToDisplay;
        if (this.props.sourcesToDisplay) {
            sourcesToDisplay = this.props.sourcesToDisplay;
        } else {
            sourcesToDisplay = [0, ...(this.props.isMediaEnabled ? [7] : [])];
        }
        sources = sources.filter((s) => sourcesToDisplay.indexOf(s.id) > -1);

        return sources;
    }

    upload = (files, blobImg = null) => {
        this.setState(
            {
                isUploading: true,
            },
            () => {
                let promises = [];

                for (let file of files) {
                    let body = new FormData();
                    let ticket = new Date().getTime().toString() + uniqueId();

                    body.append("type", this.props.fileUploadType.toString());

                    if (this.props.fileUploadType === 1) {
                        body.append("file", file, file.name);
                        body.append("thumbnailSize", "natural");
                        body.append("ticket", ticket);
                    }

                    if (this.props.fileUploadType === 5) {
                        body.append("file", file, file.name);
                        body.append("planningItemId", this.props.planningItemId.toString());
                        body.append("projectId", this.props.projectId.toString());
                        body.append("ticket", ticket);
                    }

                    if (this.props.fileUploadType === 10) {
                        body.append("file", file);
                    }

                    if (this.props.fileUploadType === 11) {
                        body.append("file", file, file.name);
                        body.append("ticket", ticket);
                    }

                    const config = {
                        onUploadProgress: (progressEvent) => {
                            let item = this.state.filesProgress.find((fp) => fp.ticket === ticket);

                            let itemProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 10) / 10;

                            if (!item) {
                                this.setState({
                                    filesProgress: [
                                        ...this.state.filesProgress,
                                        {
                                            ticket,
                                            progress: itemProgress,
                                        },
                                    ],
                                });
                            } else {
                                this.setState({
                                    filesProgress: this.state.filesProgress.map((fp) => {
                                        if (fp.ticket !== ticket) {
                                            return fp;
                                        } else {
                                            return {
                                                ...fp,
                                                progress: itemProgress,
                                            };
                                        }
                                    }),
                                });
                            }
                        },
                    };

                    const url = this.props.customRequestUrl ? this.props.customRequestUrl : "/api/uploads";

                    promises.push({
                        fileName: file.name,
                        name: file.displayName,
                        promise: api.post(url, body, config),
                    });
                }

                if (this.props.handleUploadSeparately) {
                    return promises.map((p) => {
                        return p.promise;
                    });
                } else {
                    Promise.all(
                        promises.map(async (p) => ({
                            fileName: p.fileName,
                            name: p.name,
                            result: (await p.promise) as AxiosResponse,
                        }))
                    )
                        .then((result) => {
                            let returnArray = [];

                            for (let req of result) {
                                let newObj;

                                if (req.result.data.Result) {
                                    newObj = {
                                        ...req.result.data.Result,
                                        result: req.result.data.Result,
                                        base64: blobImg,
                                        description: req.name,
                                        fileName: req.fileName,
                                    };
                                } else {
                                    newObj = {
                                        ...req.result.data,
                                        result: req.result.data,
                                        base64: blobImg,
                                        description: req.name,
                                        fileName: req.fileName,
                                    };
                                }

                                if (this.state.mediaflowSelectedImage) {
                                    newObj.externalMediaflowId = this.state.mediaflowSelectedImage.id;
                                } else if (this.state.unsplashSelectedImage) {
                                    newObj.externalAuthorName = this.state.unsplashSelectedImage.user.name;
                                    newObj.externalAuthorUrl = this.state.unsplashSelectedImage.user.links.html;
                                    newObj.externalPhotoUrl =  this.state.unsplashSelectedImage.links.html;
                                }

                                returnArray.push(newObj);
                            }

                            this.setState(
                                {
                                    isUploading: false,
                                    filesProgress: [],
                                },
                                () => {
                                    this.props.onSelect(returnArray);
                                }
                            );
                        })
                        .catch(() => {
                            this.setState({
                                hideErrorDialog: false,
                            });
                        });
                }
            }
        );
    };

    updateMedia = (folder) => {
        this.setState({
            isLoadingMedia: true,
        });

        api
            .get(`/api/v1/media`, {
                params: { take: 1000, parentId: folder.id },
            })
            .then((response) => {
                let { folderPath } = this.state;
                let fi = folderPath.findIndex((fp) => {
                    return fp.key === folder.id.toString();
                });

                if (fi > -1) {
                    folderPath = folderPath.slice(0, fi + 1);
                } else {
                    folderPath.push({
                        key: folder.id.toString(),
                        text: folder.name,
                        link: "",
                        onClick: () => {
                            this.updateMedia(folder);
                        },
                    });
                }

                this.setState({
                    media: response.data,
                    currentFolder: folder.id,
                    isLoadingMedia: false,
                    folderPath,
                });
            });
    };

    debouncedFetchSearch = debounce(() => this.fetchMedia(), 500);

    debouncedMediaflowFetchSearch = debounce(() => this.searchMediaflow(), 500);

    debouncedFetchUnsplash = debounce(() => this.fetchUnsplash(), 500);

    searchEvent = (event: React.ChangeEvent, searchText: string) => {
        this.setState(
            {
                searchText,
            },
            () => {
                this.debouncedFetchSearch();
            }
        );
    };

    fetchUnsplashPopularImages = async () => {
        this.setState({ isLoadingMedia: true, unsplashPage: 1 }, () => {
            api.get("/api/v1/images/unsplash/popular").then((response) => {
                this.setState({ unsplashImages: response.data, unsplashTotalPages: 1, isLoadingMedia: false });
            })
        });
    }

    fetchMedia = () => {
        api
            .get(`/api/v1/media`, {
                params: { take: 1000, searchText: this.state.searchText },
            })
            .then((response) => {
                this.setState({
                    media: response.data,
                    currentFolder: undefined,
                    isLoadingMedia: false,
                });
            });
    };

    fetchUnsplash = (page?: number) => {
        if (this.state.searchText.length > 0) {
            if (page > 1) {
                this.setState({
                    isLoadingMore: true,
                });
            }

            api.get(`/api/v1/images/unsplash/search`, {
                params: { searchText: this.state.searchText, page },
            }).then((response) => {
                this.setState({
                    unsplashImages:
                        page > 1 ? this.state.unsplashImages.concat(response.data.results) : response.data.results,
                    unsplashTotalPages: response.data.total_pages,
                    currentFolder: undefined,
                    isLoadingMedia: false,
                    isLoadingMore: false,
                });
            });
        } else {
            this.fetchUnsplashPopularImages();
        }
    };

    unsplashSearchEvent = (event: React.ChangeEvent, searchText: string) => {
        this.setState(
            {
                searchText,
                unsplashPage: 1,
            },
            this.debouncedFetchUnsplash
        );
    };

    triggerUnsplashDownload = async (image: Spintr.IUnsplashImage, imagePath) => {
        await api.post("/api/v1/images/unsplash/trigger", null, {
            params: {
                downloadTriggerUrl: image.links.download_location,
                imagePath,
                authorName: image.user.name,
                authorUrl: image.user.links.html,
                photoUrl: image.links.html
            }});
    }

    private addDefaultUnsplashParams = (originalUrl: string, quality?: string) => {
        const url = new URL(originalUrl);

        url.searchParams.set("fm", "jpg"); // format
        url.searchParams.set("q", quality || "80"); // quality

        return url;
    }

    handleUnsplashImageSelected = async (image: any) => {
        this.setState({isLoadingUnsplashImage: true})

        const response = await fetch(this.addDefaultUnsplashParams(image.urls.raw, "20").toString());
        let blob = await response.blob();

        //@ts-ignore
        blob.name = "image.jpg"
        this.setState({unsplashSelectedImage: image, isLoadingUnsplashImage: false});
        this.handleFilesSelected([blob])
    }

    renderUnsplash = () => {

        if (this.state.isLoadingUnsplashImage) {
            return <Loader />;
        }

        return (
            <div className="media">
                <Label className="title" as="h4">
                    Unsplash
                </Label>
                <SpintrSearch
                    classes="searchBox"
                    placeholder={localize("SEARCH_IN_UNSPLASH_PHOTOS")}
                    value={this.state.searchText}
                    onChange={this.unsplashSearchEvent} />

                {/* HITS */}
                <div className="items">
                    {this.state.unsplashImages?.length > 0 &&
                        this.state.unsplashImages?.map((image) => {
                            const style: React.CSSProperties = {};

                            style.backgroundImage = `url(${image.urls.small})`;

                            return (
                                <UnstyledButton
                                    className="item"
                                    key={image.id}
                                    onClick={() => {
                                        this.handleUnsplashImageSelected(image);
                                    }}
                                >
                                    <div style={{ ...style, backgroundSize: "cover", backgroundPosition: "center" }}>
                                        <div style={{ paddingTop: "56.25%" }} />
                                        <div
                                            className="info gutter-s"
                                            style={{
                                                background:
                                                    "linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",
                                            }}
                                        >
                                            <div className="name fc-white">
                                                <Label
                                                    as="span"
                                                    weight="medium"
                                                    size="body-2"
                                                    title={image.alt_description}
                                                    color="white"
                                                >
                                                    {image.alt_description || '\u200b'}
                                                </Label>
                                            </div>

                                            <div className="sub-info">
                                                <Label
                                                    as="span"
                                                    size="small-1"
                                                    color="grey"
                                                    weight="medium"
                                                    className="authorName"
                                                    title={image.user.name}
                                                >
                                                    {image.user.name}
                                                </Label>
                                                <Label
                                                    as="span"
                                                    size="small-1"
                                                    color="grey"
                                                    weight="regular"
                                                    className="date"
                                                >
                                                    {moment(image.created_at).format("YYYY-MM-DD")}
                                                </Label>
                                            </div>
                                        </div>
                                    </div>
                                </UnstyledButton>
                            );
                        })}
                    {this.state.unsplashTotalPages > this.state.unsplashPage && !this.state.isLoadingMore && (
                        <LoadMoreButton
                            onClick={() =>
                                this.setState(
                                    (prevState) => ({
                                        unsplashPage: prevState.unsplashPage + 1,
                                    }),
                                    () => this.fetchUnsplash(this.state.unsplashPage)
                                )
                            }
                        />
                    )}
                    {this.state.isLoadingMore && <Loader />}
                </div>
            </div>
        );
    };

    mediaflowSearchEvent = (event: React.ChangeEvent, searchText: string) => {
        this.setState(
            {
                searchText,
            },
            this.debouncedMediaflowFetchSearch,
        );
    };

    searchMediaflow = () => {
        if (this.state.searchText.length > 0) {
            api.get("/api/v1/integrations/mediaflow/search", {
                params: {
                    query: this.state.searchText
                }
            }).then((response) => {
                this.setState({
                    mediaflowContent: response.data,
                });
            });
        } else {
            this.fetchMediaflow("root");
        }
    }

    fetchMediaflow = (folder: string) => {
        api.get("/api/v1/integrations/mediaflow/folder/" + folder).then((response) => {
            this.setState({
                mediaflowContent: response.data,
            });
        });
    }

    renderMediaflow = () => {
        return (
            <div className="media">
                <Label className="title" as="h4">
                    Mediaflow
                </Label>

                {this.state.mediaflowContent?.folder && (
                    <Breadcrumbs
                        items={[
                            {
                                text: "Mediaflow",
                                key: "mediaflow",
                                onClick: () => {
                                    this.fetchMediaflow("root");
                                },
                            },
                            ...(this.state.mediaflowContent?.folder?.parents
                                ? [...this.state.mediaflowContent.folder.parents].reverse().map((parent) => ({
                                      text: parent.name,
                                      key: parent.id.toString(),
                                      onClick: () => {
                                          this.fetchMediaflow(parent.id);
                                      },
                                  }))
                                : []),
                            ...(this.state.mediaflowContent?.folder
                                ? [
                                      {
                                          text: this.state.mediaflowContent.folder.name,
                                          key: this.state.mediaflowContent.folder.id.toString(),
                                          onClick: () => {
                                              this.fetchMediaflow(this.state.mediaflowContent.folder.id);
                                          },
                                      },
                                  ]
                                : []),
                        ]}
                        hideInstance
                    />
                )}

                <SpintrSearch
                    classes="searchBox"
                    placeholder={localize("SEARCH_IN_MEDIAFLOW")}
                    value={this.state.searchText}
                    onChange={this.mediaflowSearchEvent}
                />

                <div className="items">
                    {this.state.mediaflowContent?.folders.length === 0 &&
                        this.state.mediaflowContent?.files.length === 0 && (
                            <div className="info gutter-m">
                                <Label size="body-2" style={{ textAlign: "center" }}>
                                    {localize("IngaTraffar")}
                                </Label>
                            </div>
                        )}
                    {this.state.mediaflowContent?.folders?.map((folder) => (
                        <UnstyledButton key={folder.id} className="item" onClick={() => this.fetchMediaflow(folder.id)}>
                            <div className="cover">
                                <Visage2Icon icon="folder" />
                            </div>

                            <div className="info gutter-s">
                                <div className="name">
                                    <Visage2Icon icon="folder" />
                                    <Label as="span" size="body-2" title={folder.name}>
                                        {folder.name}
                                    </Label>
                                </div>
                            </div>
                        </UnstyledButton>
                    ))}
                    {this.state.mediaflowContent?.files?.map((file) => (
                        <UnstyledButton
                            key={file.id}
                            className="item"
                            onClick={async () => {
                                const dlInfoResponse = await api.get(
                                    "/api/v1/integrations/mediaflow/download/" + file.id
                                );

                                const response = await fetch(dlInfoResponse.data.downloadURL);
                                let blob = await response.blob();

                                //@ts-ignore
                                blob.name = file.filename;

                                this.setState({ mediaflowSelectedImage: file });
                                this.handleFilesSelected([blob]);
                            }}
                        >
                            <div style={{ backgroundImage: `url(${file.smallPreview})`, backgroundSize: "cover" }}>
                                <div style={{ paddingTop: "56.25%" }} />

                                <div
                                    className="info gutter-s"
                                    style={{
                                        background: "linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",
                                    }}
                                >
                                    <div className="name fc-white">
                                        <Label
                                            as="span"
                                            weight="medium"
                                            size="body-2"
                                            title={file.filename}
                                            color="white"
                                        >
                                            {file.filename}
                                        </Label>
                                    </div>

                                    <div className="sub-info">
                                        <Label
                                            as="span"
                                            size="small-1"
                                            color="grey"
                                            weight="medium"
                                            className="authorName"
                                            title={file.photographer}
                                        >
                                            {file.photographer}
                                        </Label>
                                        <Label as="span" size="small-1" color="grey" weight="regular" className="date">
                                            {moment(file.uploaded).format("YYYY-MM-DD")}
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </UnstyledButton>
                    ))}
                </div>
            </div>
        );
    };

    renderMedia = () => {
        // TODO: Use MediaFolderView components instead, lots of dupe code
        const filteredImages = this.state.media.images?.filter((image) =>
            this.props.onlyVideo
                ? image.fileType === SpintrTypes.MediaFileType.Video
                : image.fileType !== SpintrTypes.MediaFileType.Video && image.originalImage
        )

        return (
            <div className="media">
                <Label className="title" as="h4">
                    {localize("Mediabank")}
                </Label>

                {this.state.currentFolder && (
                    <Breadcrumbs
                        items={[
                            {
                                text: localize("Mediabanken"),
                                key: "mediabank",
                                onClick: () => {
                                    this.setState({
                                        isLoadingMedia: true,
                                        folderPath: [],
                                    });

                                    this.fetchMedia();
                                },
                            },
                            ...this.state.folderPath,
                        ]}
                        hideInstance
                    />
                )}
                <SpintrSearch
                    classes="searchBox"
                    placeholder={localize("SokIMediabanken")}
                    value={this.state.searchText}
                    onChange={this.searchEvent} />
                <div className="items">
                    {this.state?.searchText !== "" &&
                        (!this.state.media.folders || this.state.media?.folders?.length === 0) &&
                        (!filteredImages || filteredImages.length === 0) &&
                        <div className="info gutter-m">
                            <Label size="body-2" style={{ textAlign: "center" }}>{localize("IngaTraffar")}</Label>
                        </div>
                    }
                    {this.state.media.folders?.map((folder) => {
                        const style: React.CSSProperties = {};

                        if (folder.coverImage) {
                            style.backgroundImage = `url(${folder.coverImage})`;
                        }

                        return (
                            <UnstyledButton
                                className="item"
                                onClick={() => {
                                    this.updateMedia(folder);
                                }}
                                key={folder.id}
                            >
                                <div className="cover" style={style}>
                                    {!folder.coverImage ? <Visage2Icon icon="folder" /> : null}
                                </div>
                                <div className="info gutter-s">
                                    <div className="name">
                                        <Visage2Icon icon="folder" />
                                        <Label as="span" size="body-2" title={folder.name}>
                                            {folder.name}
                                        </Label>
                                    </div>

                                    <div className="sub-info">
                                        <Label
                                            as="span"
                                            size="small-1"
                                            color="mid-grey"
                                            weight="medium"
                                            className="count"
                                        >
                                            {folder.itemCount}{" "}
                                            {folder.itemCount == 0 || folder.itemCount > 1
                                                ? localize("Filer").toLowerCase()
                                                : localize("Fil").toLowerCase()}
                                        </Label>
                                        <Label
                                            as="span"
                                            size="small-1"
                                            color="mid-grey"
                                            weight="regular"
                                            className="date"
                                        >
                                            {moment(folder.date).format("YYYY-MM-DD")}
                                        </Label>
                                    </div>
                                </div>
                            </UnstyledButton>
                        );
                    })}
                    {filteredImages
                        ?.map((image) => {
                            const style: React.CSSProperties = {};

                            if (image.imageUrl) {
                                style.backgroundImage = `url(${image.imageUrl})`;
                            }

                            return (
                                <UnstyledButton
                                    className="item"
                                    key={image.id}
                                    onClick={async () => {
                                        if (!this.props.onlyVideo) {
                                            const response = await api.get(image.originalImage, {
                                                responseType: "blob",
                                            });
                                            const blob = response.data;
                                            blob.name = image.name;
                                            blob.displayName = image.displayName;
                                            this.handleFilesSelected([blob]);
                                        } else {
                                            this.handleFilesSelected([image]);
                                        }
                                    }}
                                >
                                    <div style={{ ...style, backgroundSize: "cover" }}>
                                        <div style={{ paddingTop: "56.25%" }} />
                                        <div
                                            className="info gutter-s"
                                            style={{
                                                background:
                                                    "linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",
                                            }}
                                        >
                                            <div className="name fc-white">
                                                <Label
                                                    as="span"
                                                    weight="medium"
                                                    size="body-2"
                                                    title={image.displayName || image.name}
                                                    color="white"
                                                >
                                                    {image.displayName || image.name}
                                                </Label>
                                            </div>

                                            <div className="sub-info">
                                                <Label
                                                    as="span"
                                                    size="small-1"
                                                    color="grey"
                                                    weight="medium"
                                                    className="authorName"
                                                    title={image.authorName}
                                                >
                                                    {image.authorName}
                                                </Label>
                                                <Label
                                                    as="span"
                                                    size="small-1"
                                                    color="grey"
                                                    weight="regular"
                                                    className="date"
                                                >
                                                    {moment(image.date).format("YYYY-MM-DD")}
                                                </Label>
                                            </div>
                                        </div>
                                    </div>
                                </UnstyledButton>
                            );
                        })}
                </div>
            </div>
        );
    };

    onPivotClick = async (item?: PivotItem) => {
        this.setState({searchText: ""})

        if (item.props.headerText === "Unsplash") {
            await this.fetchUnsplashPopularImages();
        } else if (item.props.headerText === "Mediaflow") {
            this.fetchMediaflow("root");
        }
    }

    render() {
        if (this.state.showImageCropper) {
            return (
                <ImageCropper
                    cropAspect={this.props.cropAspect}
                    cropShape={this.props.cropShape}
                    image={this.state.imageBase64}
                    show={this.state.showImageCropper}
                    onConfirm={async (blobImg, file, cropData) => {
                        const unsplashImage = this.state.unsplashSelectedImage;

                        if (unsplashImage) {
                            const unsplashUrl = this.addDefaultUnsplashParams(
                                this.state.unsplashSelectedImage.urls.raw
                            );

                            unsplashUrl.searchParams.set("rect", `${cropData.croppedAreaPixels.x},${cropData.croppedAreaPixels.y},${cropData.croppedAreaPixels.width},${cropData.croppedAreaPixels.height}`);
                            unsplashUrl.searchParams.set("h",  this.props.cropHeight?.toString() || "1000");

                            var unsplashOrient = cropData.rotation % 360;
                            if (unsplashOrient < 0) {
                                unsplashOrient += 360;
                            }

                            unsplashUrl.searchParams.set("orient", unsplashOrient.toString());

                            await this.triggerUnsplashDownload(this.state.unsplashSelectedImage, unsplashUrl.pathname);

                            this.props.onSelect([{
                                thumbnailUrl: unsplashUrl.toString(),
                                externalAuthorName: unsplashImage.user.name,
                                externalAuthorUrl: unsplashImage.user.links.html,
                                externalPhotoUrl: unsplashImage.links.html,
                                isUnsplash: true,
                            }])

                            this.setState({
                                showImageCropper: false,
                            });
                            return;
                        }

                        if (this.props.returnBase64) {
                            this.props.returnBase64(blobImg);

                            this.setState({
                                showImageCropper: false,
                                isUploading: false,
                                filesProgress: [],
                            });

                            return;
                        } else {
                            this.upload([file], blobImg);
                            this.setState({ showImageCropper: false });
                        }
                    }}
                    onDismiss={() => {
                        this.setState({ showImageCropper: false, isUploading: false, filesProgress: [], unsplashSelectedImage: undefined }, () => { });
                    }}
                />
            )
        }

        return (
            <Modal className="file-selector spintr-modal modalWithPopupHeader" isOpen={true} onDismiss={this.props.onClose}>
                <PopupHeader
                    text={localize("ValjFil")}
                    onClose={this.props.onClose} />
                <div>
                    {!this.state.isUploading && (!this.props.onlyVideo || this.getSources().some((s) => s.id === 0)) && (
                        <DropZone
                            renderSizeString={this.props.renderSizeString}
                            isMultiple={this.props.allowMultipleFiles}
                            fileTypesString={this.props.fileTypesString}
                            fileTypes={this.props.fileTypes}
                            onFilesSelected={this.handleFilesSelected}
                        />
                    )}

                    {this.state.isUploading ? (
                        this.renderProgressBar()
                    ) : this.props.onlyVideo && (
                        this.renderInsertEmbed()
                    )}

                    {!this.props.hideMedia && (
                        <Pivot overflowBehavior={"menu"} onLinkClick={this.onPivotClick}>
                            {this.props.isMediaEnabled && (
                                <PivotItem headerText={localize("Mediabank")}>{this.renderMedia()}</PivotItem>
                            )}
                            {this.props.enableMediaflow && (
                                <PivotItem headerText={"Mediaflow"}>{this.renderMediaflow()}</PivotItem>
                            )}
                            {this.props.enableUnsplash && (
                                <PivotItem headerText={"Unsplash"}>{this.renderUnsplash()}</PivotItem>
                            )}
                        </Pivot>
                    )}
                </div>
                <Dialog
                    hidden={this.state.hideErrorDialog}
                    containerClassName="dialogWithPopupHeader"
                    onDismiss={() => {
                        this.setState({
                            hideErrorDialog: true,
                        });
                    }}
                    modalProps={{
                        isBlocking: true,
                        allowTouchBodyScroll: true
                    }}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: localize("WARNING"),
                        closeButtonAriaLabel: localize("Stang"),
                        subText: localize("TeknisktFel"),
                    }}
                >
                    <PopupHeader
                        text={localize("WARNING")}
                        subText={localize("TeknisktFel")}
                        onClose={() => {
                            this.setState({
                                hideErrorDialog: false,
                            });
                        }} />
                    <DialogFooter>
                        <PrimaryButton
                            // theme={this.context}
                            onClick={() => {
                                this.setState({
                                    hideErrorDialog: true,
                                });
                            }}
                            text={localize("Ok")}
                        />
                    </DialogFooter>
                </Dialog>
            </Modal>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    isMediaEnabled: state.app.items.some((a) => a.id === SpintrTypes.SpintrApp.Media && a.enabled),
});

export default connect(mapStateToProps)(FileSelector);
