import { Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import React, { FormEventHandler, Fragment, ReactElement, useCallback, useMemo } from "react";
import { localize } from "src/l10n";
import { FormControl } from "src/ui/components/Forms";
import { PlannerItemFormProps } from "./PlannerItemForm.types";
import { PlannerModel } from "../types";
import { SpintrTypes } from "src/typings";
import { PlannerFilePicker } from "../PlannerFilePicker";
import { PlannerFileTypes } from "../utils";

function getInventoryMethodOptions(): IDropdownOption<SpintrTypes.PlannerInventoryMethod>[] {
    return [{
        key: SpintrTypes.PlannerInventoryMethod.Manual,
        text: localize("PLANNER_INVENTORY_METHOD_MANUAL"),
        data: SpintrTypes.PlannerInventoryMethod.Manual,
    }, {
        key: SpintrTypes.PlannerInventoryMethod.Digital,
        text: localize("PLANNER_INVENTORY_METHOD_DIGITAL"),
        data: SpintrTypes.PlannerInventoryMethod.Digital,
    }];
}

function PlannerInventoryForm(props: PlannerItemFormProps<Spintr.PlannerInventory>): ReactElement {
    const { errors, model, onModelUpdate } = props

    const onTextFieldChanged = useCallback<FormEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
        (event) => {
            if (!event?.target) {
                return;
            }

            const { name, value } = (event.target as HTMLInputElement | HTMLTextAreaElement);

            onModelUpdate((prevModel) => Object.assign(
                { ...prevModel },
                { [name]: value },
            ) as PlannerModel);
        },
        [onModelUpdate],
    );

    const methodOptions = useMemo(getInventoryMethodOptions, []);

    const onMethodChanged = useCallback(
        (_: unknown, opton: IDropdownOption) => {
            onModelUpdate((prevModel) => Object.assign(
                { ...prevModel },
                { inventoryMethod: opton.data as SpintrTypes.PlannerInventoryMethod },
            ) as PlannerModel);
        },
        [onModelUpdate],
    );

    if (model.itemType !== SpintrTypes.PlannerItemType.Inventory) {
        return null;
    }

    return (
        <Fragment>
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_INVENTORY_LOCATION")}
                    name="warehouseLocation"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.warehouseLocation}
                    value={model.warehouseLocation || ""}
                />
            </FormControl>

            <FormControl>
                <Dropdown
                    label={localize("PLANNER_INVENTORY_METHOD")}
                    multiSelect={false}
                    onChange={onMethodChanged}
                    options={methodOptions}
                    selectedKey={model.inventoryMethod} />
            </FormControl>

            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_INVENTORY_MANAGER")}
                    name="inventoryManager"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.inventoryManager}
                    value={model.inventoryManager || ""}
                />
            </FormControl>

            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_INVENTORY_PLAN")}
                    name="inventoryPlan"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.inventoryPlan}
                    value={model.inventoryPlan || ""}
                />

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={PlannerFileTypes}
                    multiple={true}
                    itemType={model.itemType}
                    fieldType={SpintrTypes.PlannerInventoryFileType.InventoryPlan}
                    onModelUpdate={onModelUpdate}
                    simple={true} />
            </FormControl>
            
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_INVENTORY_REPORT")}
                    name="inventoryReport"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.inventoryReport}
                    value={model.inventoryReport || ""}
                />

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={PlannerFileTypes}
                    multiple={true}
                    itemType={model.itemType}
                    fieldType={SpintrTypes.PlannerInventoryFileType.InventoryReport}
                    onModelUpdate={onModelUpdate}
                    simple={true} />

            </FormControl>

        </Fragment>
    );
}

export default PlannerInventoryForm;
