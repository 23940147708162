import React from "react";
import { SpintrHeaderSearch } from "src/search";

interface IProps {}

const SpintrHeaderMenuSearch = (props: IProps) => {
    return (
        <div className="search-field">
            <SpintrHeaderSearch isModal />
        </div>
    );
}

export default SpintrHeaderMenuSearch;