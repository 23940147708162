import React, { useMemo } from "react";
import "./SystemStatusResourceInfo.scss";
import { Label, SpintrUser } from "src/ui";
import SystemStatusTypePill from "./SystemStatusTypePill";
import getOpenTime from "./utils";

interface IProps {
    resouce: any;
}

const SystemStatusResourceInfo = (props: IProps) => {
    const key = useMemo(() => {
        return getOpenTime(props.resouce.openTimes);
    }, [props.resouce.openTimes]);

    return (
        <div className="SystemStatusResourceInfo">
            <SpintrUser
                key={key}
                personalName={false}
                name={props.resouce.title}
                subText={getOpenTime(props.resouce.openTimes)}
                imageUrl={props.resouce.imageUrl}
                onRenderPrimaryText={() => {
                    return (
                        <div className="title-row">
                            <Label>{props.resouce.title}</Label>
                            <SystemStatusTypePill statusType={props.resouce.status} />
                        </div>
                    )
                }}
            />
        </div>
    )
}

export default SystemStatusResourceInfo;
