export enum MessageFetchType {
    All = 1,
    Messages = 2,
    SystemMessages = 3,
}

/**
 * Contains request parameters for querying messages
 */
export interface IQueryMessagesParams {
    /**
     * The ID of the conversation to fetch messages from. Required.
     */
    conversationId: number;

    /**
     * Fetch only messages with ID less than maxId. Defaults to 0.
     */
    maxId?: number;

    /**
     * Fetch only messages with ID greater than maxId. Defaults to 0.
     */
    sinceId?: number;

    /**
     * Number of messages to request. Defaults to 10.
     */
    take?: number;

    /**
     * Request messages from a provided thread. Defaults to null
     */
    threadId?: number;

    /**
     * Filter messages that don't contain the provided string. Defaults to null.
     */
    filter?: string;

    /**
     * Filters messages by type. Defaults to All (1)
     */
    fetchType?: MessageFetchType;

    flatMode?: boolean;
}

/**
 * Contains request parameters for querying messages
 */
export interface IQueryPinnedMessagesParams {
    /**
     * The ID of the conversation to fetch messages from. Required.
     */
    conversationId: number;
}

/**
 * Contains request body for reacting to a message
 */
export interface IMessageReactionModel {
    /**
     * The ID of the reaction to use
     */
    likeType: number;
}
