import { ChoiceGroup, Stack, TextField } from "@fluentui/react";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { Label, Loader, SpintrUser, UserHovercard } from "src/ui";
import { Style } from "src/ui/helpers";
import { decodeHtmlEntities } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Reach } from ".";
import { UnstyledButton } from "./Buttons";
import CustomDialog from "./Dialogs/CustomDialog";
import FormControl from "./Forms/FormControl";
import FormSection from "./Forms/FormSection";
import FormTokenizedObjectInput from "./Forms/FormTokenizedObjectInput";
import "./PageInfoPanel.scss";
import TableOfContent from "./TableOfContent";

type IContentEditDate = {
    user: Spintr.IUser;
    date: Date;
};

interface IProps {
    uberId: number;
    displayReach?: boolean;
    displayPublishers?: boolean;
    displayTags?: boolean;
    displayLastEdited?: boolean;
    publishers?: Spintr.IUser[];
    lastEdited?: IContentEditDate[];
    tags?: string[];
    isWide?: boolean;
    isSmallViewMode: boolean;
    displayFiles?: boolean;
    displayExternalFiles?: boolean;
    files?: any[];
    externalFiles?: any[];
    onFileUploadClick?: any;
    onFileDeleteClick?: any;
    onExternalFileDeleteClick?: any;
    editMode?: boolean;
    displayTableOfContent?: boolean;
    tableOfContentSearchElementRef?: any;
    customItems?: any;
    factcheckers?: any[];
    approvers?: any[];
    caneaFiles?: any[];
    displayCaneaFiles?: boolean;
    onCaneaDeleteClick?: any;
    appMode: boolean;
    panelClassName?: string;
    attachedFiles: any;
    attachedFolders: any;
    displayLinks?: boolean;
    links?: any[];
    onLinksChange?: any;
    alwaysDisplayMeta?: boolean;
    targets?: any[];
    displayTargets?: boolean;
}

interface IState {
    displayAllVersions: boolean;
    isLoadingVersions: boolean;
    isLoadingExternalFiles: boolean;
    lastEdited?: IContentEditDate[];
    externalFiles: any[];
    isWide: boolean;
    isHidden: boolean;
    links: any[];
    tempLinks: any[];
    showLinksDialog: boolean;
    selectedLinkMethod: number;
    linkTitle: string;
    showAllFiles: boolean;
    displayTargets:boolean;
}

class PageInfoPanel extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            displayAllVersions: false,
            isLoadingVersions: true,
            isLoadingExternalFiles: false,
            externalFiles: [],
            isWide: this.props.isWide && !props.isSmallViewMode,
            isHidden: props.isSmallViewMode && !props.appMode && !props.alwaysDisplayMeta,
            links: props.links ? props.links : [],
            tempLinks: [],
            showLinksDialog: false,
            selectedLinkMethod: 0,
            linkTitle: "",
            showAllFiles: false,
            displayTargets: this.props.displayTargets != undefined ? true : false
        };
    }

    componentDidMount() {
        if (this.props.uberId && this.props.uberId > 0) {
            this.fetchVersions();
        }

        if (this.props.externalFiles?.length > 0 && this.props.displayExternalFiles) {
            this.fetchExternalFiles();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.externalFiles?.length !== prevProps.externalFiles?.length) {
            const oldExternalFiles = [...this.state.externalFiles];

            // Remove files that aren't in the new data
            let attachedExternalFiles = oldExternalFiles.filter((oef) =>
                this.props.externalFiles.some((ef) => ef.externalId === oef.id)
            );

            // Add files that are in the new data
            const newFiles = this.props.externalFiles.filter(
                (ef) => ef.id && (oldExternalFiles.length === 0 || oldExternalFiles.some((oef) => oef.id !== ef.id))
            );
            attachedExternalFiles = [...attachedExternalFiles, ...newFiles];

            this.setState({
                externalFiles: attachedExternalFiles,
            });
        }
    }

    fetchVersions() {
        let onSuccess = (response) => {
            let lastEdited = response.data.map((item) => {
                let formattedItem: IContentEditDate = {
                    date: new Date(item.date),
                    user: item.user,
                };

                return formattedItem;
            });

            this.setState({
                lastEdited,
                isLoadingVersions: false,
            });
        };

        let onError = (response) => {
            console.log(response);
        };

        let params = {
            objectId: this.props.uberId,
            stackedPosts: true,
            take: 3,
            type: 1,
        };

        api
            .get("/api/contentversions", {
                params,
            })
            .then(onSuccess, onError);
    }

    fetchExternalFiles() {
        this.setState({
            isLoadingExternalFiles: true,
        });

        const promises = [];
        for (const file of this.props.externalFiles) {
            promises.push(
                api
                    .get(`/api/files/office365/${file.externalId}`, { params: { source: file.source } })
                    .catch((error) => null)
            );
        }

        const files = [];
        Promise.all(promises).then((datas) => {
            for (const data of datas) {
                if (!data) {
                    continue;
                }

                files.push(data.data);
            }

            this.setState({
                externalFiles: files,
                isLoadingExternalFiles: false,
            });
        });
    }

    pageInfoPanelTypes = [
        {
            id: 0,
            title: localize("SenastAndrad"),
            render: () => {
                if (this.props.uberId === 0) {
                    return (
                        <Label aria-label={localize("NO_DATA_AVAILABLE")} color="dark-grey" size="body-2">
                            <span tabIndex={0}>{localize("NO_DATA_AVAILABLE")}</span>
                        </Label>
                    );
                }

                if (this.state.isLoadingVersions) {
                    return <Loader loaderSize="small" />;
                }

                let renderVersionRow = (item, index) => {
                    return (
                        <div key={index}>
                            <div
                                style={{
                                    display: "inline-block",
                                }}
                            >
                                <Label size="body-2">
                                    {moment(item.date).format("YYYY-MM-DD")}
                                    <span>&nbsp;</span>
                                </Label>
                            </div>
                            <div
                                style={{
                                    display: "inline-block",
                                }}
                            >
                                <UserHovercard userId={item.user.id}>
                                    <Link to={`/goto/${item.user.id}`}>
                                        <Label role="link" className="" size="body-2">
                                            {item.user.name}
                                        </Label>
                                    </Link>
                                </UserHovercard>
                            </div>
                        </div>
                    );
                };

                return (
                    <div>
                        {this.state.displayAllVersions
                            ? this.state.lastEdited.map((item, index) => {
                                return renderVersionRow(item, index + 1);
                            })
                            : renderVersionRow(this.state.lastEdited[0], 0)}
                        {this.state.lastEdited.length > 1 && (
                            <div>
                                <UnstyledButton
                                    onClick={() => {
                                        this.setState({
                                            displayAllVersions: !this.state.displayAllVersions,
                                        });
                                    }}
                                >
                                    <Label className="" size="body-2">
                                        {this.state.displayAllVersions ? localize("Dolj") : (localize("VisaAlla") + " " + localize("Versioner").toLowerCase())}
                                    </Label>
                                </UnstyledButton>
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            id: 1,
            title: localize("Rackvidd"),
            render: () => <Reach uberId={this.props.uberId} />,
        },
        {
            id: 2,
            title: localize("Ansvarig_Utgivare"),
            render: () => {
                return (
                    <div>
                        {this.props.publishers.map((user, i) => {
                            return (
                                <div key={i} style={{ marginTop: i === 0 ? 10 : Style.getSpacingStr(2) }}>
                                    <Label size="body-2">
                                        <SpintrUser
                                            id={user.id}
                                            name={user.name}
                                            subText={user.info}
                                            imageUrl={user.imageUrl ? user.imageUrl : user.image}
                                            state={user.state}
                                            tabIndex={0}
                                            personalName={true}
                                        />
                                    </Label>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            id: 3,
            title: localize("appTags"),
            render: () => {
                return (
                    <div>
                        {this.props.tags.map((tag, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: "inline-block",
                                    }}
                                    className={"no-user-select"}
                                >
                                    <Link to={"/search?q=" + encodeURI(tag)}>
                                        <Label as="span" color="light-blue" size="body-2">
                                            {tag}
                                        </Label>
                                        {this.props.tags[index + 1] ? (
                                            <Label size="body-2" as="span">
                                                ,<span>&nbsp;</span>
                                            </Label>
                                        ) : null}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            id: 4,
            title: localize("NedladdningsbaraFiler"),
            render: () => {
                return (
                    <div>
                        {this.props.files && this.props.files?.length > 0 && (
                            <div>
                                {this.props.files
                                    .filter(f => !!f.name)
                                    .sort((a, b) => a.name.localeCompare(b.name, "sv"))
                                    .map((item, index) => {
                                        if (!this.state.showAllFiles && index > 2) {
                                            return null;
                                        }
                                        if (this.props.editMode) {
                                            return (
                                                <div className="PageInfoPanel-attachedFile" key={index}>
                                                    <div className="icon-wrapper">
                                                        <Visage2Icon icon={item.isDirectory || item.files ? "folder" : "document-text"} />
                                                    </div>
                                                    <Label as="p" size="body-2">
                                                        {decodeHtmlEntities(item.name)}
                                                    </Label>
                                                    {this.props.onFileDeleteClick && (
                                                        <UnstyledButton onClick={() => {
                                                            this.props.onFileDeleteClick(item);
                                                        }}>
                                                            <Visage2Icon icon="trash" />
                                                        </UnstyledButton>
                                                    )}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <a
                                                    className="PageInfoPanel-attachedFile-download"
                                                    key={index}
                                                    href={`/api/servefile/${item.id}`}
                                                >
                                                    <div className="PageInfoPanel-attachedFile">
                                                        <div className="icon-wrapper">
                                                            <Visage2Icon icon={item.isDirectory || item.files ? "folder" : "document-text"} />
                                                        </div>
                                                        <Label as="p" size="body-2" className="">
                                                            {decodeHtmlEntities(item.name)}
                                                        </Label>
                                                    </div>
                                                </a>
                                            );
                                        }
                                    })}
                                {this.props.files.length > 3 && (
                                    <UnstyledButton onClick={() => {
                                        this.setState({
                                            showAllFiles: !this.state.showAllFiles
                                        })
                                    }}>
                                        <Label size="body-2" color="light-blue">{localize(this.state.showAllFiles ? "VisaMindre" : "VisaAlla")}</Label>
                                    </UnstyledButton>
                                )}
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            // TODO: Merge with ^?
            id: 5,
            title: localize("ExternaFiler"),
            render: () => {
                if (this.state.isLoadingExternalFiles) {
                    return <Loader loaderSize="small" />;
                }

                return (
                    <div>
                        {this.state.externalFiles?.length > 0 && (
                            <div>
                                {this.state.externalFiles.map((item, index) => {
                                    if (this.props.editMode) {
                                        return (
                                            <div className="PageInfoPanel-attachedFile" key={index}>
                                                <div className="icon-wrapper">
                                                    <Visage2Icon icon={item.isDirectory || item.files ? "folder" : "document-text"} />
                                                </div>
                                                <Label as="p" size="body-2">
                                                    {item.name}
                                                </Label>
                                                {this.props.onExternalFileDeleteClick && (
                                                    <UnstyledButton onClick={() => {
                                                        this.props.onExternalFileDeleteClick(item);
                                                    }}>
                                                        <Visage2Icon icon="trash" />
                                                    </UnstyledButton>
                                                )}
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className="PageInfoPanel-attachedFile" key={index}>
                                                <div className="icon-wrapper">
                                                    <Visage2Icon icon={item.isDirectory || item.files ? "folder" : "document-text"} />
                                                </div>
                                                <a href={item.webUrl} target="_blank">
                                                    <Label as="p" size="body-2" className="">
                                                        {item.name}
                                                    </Label>
                                                </a>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            id: 6,
            title: "",
            render: () => {
                return (
                    <UnstyledButton onClick={this.props.onFileUploadClick}>
                        <Label as="p" size="body-2" className="">
                            {localize("BifogaFiler")}
                        </Label>
                    </UnstyledButton>
                );
            },
        },
        {
            id: 7,
            render: () => {
                return <TableOfContent tableOfContentSearchElementRef={this.props.tableOfContentSearchElementRef} />;
            },
        },
        {
            id: 8,
            title: localize("Faktagranskare"),
            render: () => {
                return (
                    <div>
                        {this.props.factcheckers.map((user, i) => {
                            return (
                                <div key={i} style={{ marginTop: i === 0 ? 10 : Style.getSpacingStr(2) }}>
                                    <Label size="body-2">
                                        <SpintrUser
                                            id={user.id}
                                            name={user.name}
                                            subText={user.info}
                                            imageUrl={user.imageUrl ? user.imageUrl : user.image}
                                            state={user.state}
                                            tabIndex={0}
                                            personalName={true}
                                        />
                                    </Label>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            id: 9,
            title: localize("GodkannaraAvInnehall"),
            render: () => {
                return (
                    <div>
                        {this.props.approvers.map((user, i) => {
                            return (
                                <div key={i} style={{ marginTop: i === 0 ? 10 : Style.getSpacingStr(2) }}>
                                    <Label size="body-2">
                                        <SpintrUser
                                            id={user.id}
                                            name={user.name}
                                            subText={user.info}
                                            imageUrl={user.imageUrl ? user.imageUrl : user.image}
                                            state={user.state}
                                            tabIndex={0}
                                            personalName={true}
                                        />
                                    </Label>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            id: 10,
            title: localize("FilerFranCanea"),
            render: () => {
                return (
                    <div>
                        {this.props.caneaFiles?.length > 0 && (
                            <div>
                                {this.props.caneaFiles
                                    .sort((a, b) => a.name.localeCompare(b.name, "sv"))
                                    .map((item, index) => {
                                        if (this.props.editMode) {
                                            return (
                                                <div
                                                    className="PageInfoPanel-attachedFile"
                                                    key={index}
                                                >
                                                    <div className="icon-wrapper">
                                                        <Visage2Icon icon={"document-text"} />
                                                    </div>
                                                    <Label as="p" size="body-2">
                                                        {decodeHtmlEntities(item.name)}
                                                    </Label>
                                                    {this.props.onCaneaDeleteClick && (
                                                        <UnstyledButton onClick={() => {
                                                            this.props.onCaneaDeleteClick(item);
                                                        }}>
                                                            <Visage2Icon icon="trash" />
                                                        </UnstyledButton>
                                                    )}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <a
                                                    href={item.url}
                                                    target="_blank"
                                                    className="PageInfoPanel-attachedFile-download"
                                                    key={index}
                                                >
                                                    <div className="PageInfoPanel-attachedFile">
                                                        <div className="icon-wrapper">
                                                            <Visage2Icon icon={"document-text"} />
                                                        </div>
                                                        <Label as="p" size="body-2">
                                                            {decodeHtmlEntities(item.name)}
                                                        </Label>
                                                    </div>
                                                </a>
                                            );
                                        }
                                    })}
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            id: 11,
            title: localize("Lankar"),
            render: () => {
                return (
                    <div>
                        {this.state.links.map((item: any, index: number) => {
                            return (
                                <div className="PageInfoPanel-link">
                                    <a href={item.url} target="_blank">
                                        <Label size="body-2">{item.title}</Label>
                                    </a>
                                    {this.props.editMode && (
                                        <UnstyledButton onClick={() => {
                                            let tempLinks = this.state.links;
                                            tempLinks.splice(index, 1);
                                            this.setState({ links: tempLinks });
                                        }}>
                                            <Visage2Icon icon="trash" />
                                        </UnstyledButton>
                                    )}
                                </div>
                            )
                        })}
                        {this.props.editMode && (
                            <UnstyledButton onClick={() => { this.setState({ showLinksDialog: true }) }}>
                                <Label as="p" size="body-2" className="">
                                    {localize("LaggTillNyLank")}
                                </Label>
                            </UnstyledButton>
                        )}
                    </div>
                )
            },
        },
        {
            id: 12,
            title: localize("Malgrupp"),
            render: () => {
                return (
                    <div>
                  {this.props.targets?.length > 0 ?  <div>
                        {this.props.targets.map((target) => {
                            return (
                                <Label size="body-2">{target.name}</Label>
                            )
                        })}
                    </div>
                    :
                    <Label size="body-2">{localize("Alla")}</Label>
                    }
                    </div>
                )
            },
        },
    ];

    getItems() {
        let items = [];
        if (this.props.displayPublishers && !!this.props.publishers && this.props.publishers.length > 0) {
            items.push({
                ...this.pageInfoPanelTypes[2],
            });
        }

        if (!!this.state.displayTargets) {
            items.push({
                ...this.pageInfoPanelTypes[12],
            });
        }

        if (this.props.factcheckers?.length > 0) {
            items.push({
                ...this.pageInfoPanelTypes[8],
            });
        }

        if (this.props.approvers?.length > 0) {
            items.push({
                ...this.pageInfoPanelTypes[9],
            });
        }

        if (this.props.displayLastEdited) {
            items.push({
                ...this.pageInfoPanelTypes[0],
            });
        }

        if (this.props.displayReach) {
            items.push({
                ...this.pageInfoPanelTypes[1],
            });
        }
        if (this.props.displayTags && !!this.props.tags && this.props.tags.length > 0) {
            items.push({
                ...this.pageInfoPanelTypes[3],
            });
        }

        if (this.props.displayFiles && this.props.files?.length > 0) {
            items.push({
                ...this.pageInfoPanelTypes[4],
            });
        }

        if (this.props.displayExternalFiles) {
            items.push({
                ...this.pageInfoPanelTypes[5],
            });
        }

        if (this.props.displayCaneaFiles) {
            items.push({
                ...this.pageInfoPanelTypes[10],
            });
        }

        if (
            (this.props.displayFiles || this.props.displayExternalFiles || this.props.displayCaneaFiles) &&
            this.props.onFileUploadClick
        ) {
            items.push({
                ...this.pageInfoPanelTypes[6],
            });
        }

        if (this.props.displayTableOfContent && !this.state.isWide) {
            items.push({
                ...this.pageInfoPanelTypes[7],
            });
        }

        if (this.props.customItems) {
            items = items.concat(this.props.customItems);
        }

        if (this.props.displayLinks) {
            items.push({
                ...this.pageInfoPanelTypes[11],
            });
        }

        return items;
    }

    renderItem(item, index) {
        return (
            <div
                key={index}
                style={{
                    marginBottom: Style.getSpacingStr(6),
                    breakInside: this.state.isWide ? "avoid" : "auto",
                    display: this.state.isWide ? "inline-block" : "block",
                    maxWidth: "100%"
                }}
            >
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    {item.icon && (
                        <Visage2Icon icon={item.icon} style={{ marginRight: 12 }} />
                    )}
                    <div>
                {item.title && (
                    <Label
                        size="body-2"
                        color="mid-grey"
                        className="header"
                        style={{
                            marginBottom: Style.getSpacingStr(2),
                        }}
                    >
                        {item.title}
                    </Label>
                )}
                <div>
                    <Label className="content" size="body-2" color="dark-grey" as="div">{item.render()}</Label>
                </div>
                    </div>
                </div>
            </div>
        );
    }

    private toggleHidden = () => {
        this.setState((prevState) => ({
            isHidden: !prevState.isHidden,
        }));
    };

    render() {
        const itemsToRender = this.getItems();
        const state = this.state;

        return (
            <div
                role="complementary"
                className={["pageInfoPanel", this.props?.panelClassName && this.props.panelClassName, state.isWide ? "wide" : ""].filter(Boolean).join(" ")}
                style={{
                    padding: state.isWide || this.props.isSmallViewMode ? 0 : Style.getSpacingStr(5),
                    paddingTop: 0,
                    marginTop: this.props.appMode || this.props.isSmallViewMode ? "16px" : "auto",
                }}
            >
                {this.props.isSmallViewMode && !this.props.appMode && !this.props.alwaysDisplayMeta && (
                    <UnstyledButton onClick={this.toggleHidden}>
                        <Label className="moreInfo">
                            <Visage2Icon className="icon" icon={state.isHidden ? "arrown-down-2" : "arrow-up-2"} style={{ verticalAlign: "middle" }} />
                            {" "}
                            {localize(state.isHidden ? "VisaMeta" : "GomMeta")}
                        </Label>
                    </UnstyledButton>
                )}

                {!state.isHidden &&
                    itemsToRender.map((item, index) => {
                        if (Array.isArray(item)) {
                            return (
                                <Stack key={index} horizontal horizontalAlign="space-between">
                                    {item.map((item2, index2) => this.renderItem(item2, index2))}
                                </Stack>
                            );
                        }
                        return this.renderItem(item, index);
                    })}

                <CustomDialog
                    title={localize("Lankar")}
                    show={this.state.showLinksDialog}
                    primaryButtonDisabled={!this.state.tempLinks || this.state.tempLinks.length === 0 || !this.state.linkTitle}
                    children={
                        <div>
                            <FormSection>
                                <FormControl>
                                    <TextField
                                        label={localize("Titel")}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                            this.setState({
                                                linkTitle: newValue
                                            });
                                        }}

                                    />
                                </FormControl>
                                <FormControl>
                                    <ChoiceGroup
                                        defaultSelectedKey={"externalLink"}
                                        options={[
                                            { key: "externalLink", text: localize("LaggTillNyLank") },
                                            { key: "internalLink", text: localize("Sok") },
                                        ]}
                                        onChange={(e, v) => {
                                            if (v.key === "externalLink") {
                                                this.setState({
                                                    selectedLinkMethod: 0,
                                                    tempLinks: []
                                                });
                                            } else {
                                                this.setState({
                                                    selectedLinkMethod: 1,
                                                    tempLinks: []
                                                });
                                            }
                                        }}

                                    />
                                </FormControl>
                                {this.state.selectedLinkMethod === 0 && (
                                    <FormControl>
                                        <TextField
                                            label={localize("Lank")}
                                            placeholder={localize("LaggTillNyLank")}
                                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                                this.setState({
                                                    tempLinks: [{
                                                        url: newValue
                                                    }]
                                                });
                                            }}
                                        />
                                    </FormControl>
                                )}
                                {this.state.selectedLinkMethod === 1 && (
                                    <FormControl>
                                        <FormTokenizedObjectInput
                                            label={localize("Lank")}
                                            itemLimit={1}
                                            types={[]}
                                            useSearchEngine={true}
                                            items={this.state.tempLinks}
                                            allowEmailAddresses={true}
                                            onChange={(tempLinks) => {
                                                this.setState({
                                                    tempLinks: tempLinks.map((tempLink: any) => {
                                                        return {
                                                            ...tempLink,
                                                            url: "/goto/" + tempLink.key
                                                        }
                                                    }),
                                                });
                                            }}
                                        />
                                    </FormControl>
                                )}
                            </FormSection>
                        </div>
                    }
                    onDismiss={() => {
                        this.setState({
                            tempLinks: [],
                            linkTitle: null,
                            selectedLinkMethod: 0,
                            showLinksDialog: false
                        }, () => { });
                    }}
                    onConfirm={() => {
                        let tempLinks = [...this.state.tempLinks];

                        if (!!tempLinks && tempLinks.length > 0 && !!this.state.linkTitle) {
                            tempLinks[0].title = this.state.linkTitle;
                        }

                        let newLinks = [...this.state.links, ...tempLinks];

                        this.setState({
                            links: newLinks,
                            tempLinks: [],
                            linkTitle: null,
                            selectedLinkMethod: 0,
                            showLinksDialog: false
                        });

                        this.props.onLinksChange(newLinks);
                    }} />
            </div>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    // const files = state.pages.attachedFiles.concat(state.pages.attachedFolders)
    // // const files = state.pages.attachedFiles.concat(
    // //     state.pages.attachedFolders.filter((f) => !!f.data)
    // //     .map((f) => f.data)
    // //     .flat()
    // // )
    // .filter((fi) => !!fi.name && fi.name != "")
    // .filter((item, index, self) => self.findIndex((m) => Number(m.id) === Number(item.id)) === index)
    // .sort((a, b) => a.name.localeCompare(b.name, "sv"))
    // .map(f => { return {...f, id: Number(f.id)}})

    return {
        ...props,
        isSmallViewMode: state.ui.viewMode <= SpintrTypes.ViewMode.TabletPortrait,
        appMode: state.ui.appMode,
        attachedFiles: state.pages.attachedFiles,
        attachedFolders: state.pages.attachedFolders
        // files
    };
};

export default connect(mapStateToProps)(PageInfoPanel);
