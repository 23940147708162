import classnames from "classnames";
import React, { Component, ReactNode, Suspense } from 'react';
import { connect, DispatchProp, MapStateToProps } from 'react-redux';
import { Redirect } from 'react-router';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { CalendarEventPopup } from "src/calendar";
import { setConfirmPopup, setInfoPopup } from 'src/popups/actions';
import { IConfirmPopupState, IInfoPopupState } from 'src/popups/reducer';
import SpintrSupportChatContainer from "src/support-chat/SpintrSupportChatContainer";
import { StandardErrorBoundary } from "src/ui";
import CustomDialog from 'src/ui/components/Dialogs/CustomDialog';
import { SpintrStartView } from '../views';
import RightColumn from "./RightColumn";
import TeamsLogOutButton from "./TeamsLogOutButton";
import 'intro.js/introjs.css';
import "src/style/introjs-spintr.scss";
import InfoDialog from "src/ui/components/Dialogs/InfoDialog";
import { IActiveUserProfile } from "src/profile/reducer";
import getRoutes from "../routes";
import SystemStatusesPrioView from "src/system-status/SystemStatusesPrioView";
import { SpintrTypes } from "src/typings";

interface IOwnProps {
}

interface IStateProps {
    confirmPopup: IConfirmPopupState;
    infoPopup: IInfoPopupState;
    isSmallViewMode: boolean;
    isInTeamsApp: boolean;
    appMode: boolean;
    useDocumentWidthMode: boolean;
    useNarrowFormWidthMode: boolean;
    disableWrapperBackground: boolean;
    hasFormFooterBar: boolean;
    currentUser: IActiveUserProfile;
    visage2FullscreenMode?: boolean;
    systemStatusEnabled?: boolean;
}

type Props = IOwnProps & IStateProps & DispatchProp & RouteComponentProps;

class SpintrMainContentContainer extends Component<Props> {
    public render(): ReactNode {
        const useMainContentNoPadding = this.useMainContentNoPadding(this.props.location.pathname);
        const mainContentWrapperBackground = this.useMainContentWrapperBackground(this.props.location.pathname);

        let defaultPath = "/";
        let supplierPortal = false;
        if (this.props.currentUser.roles.indexOf("supplier") !== -1) {
            defaultPath = this.props.currentUser["supplierHome"];
            supplierPortal = true;
        }

        return (
            <Suspense fallback={() => { return <></>; }}>
                <div
                    className={
                        classnames("SpintrMainContentContainer", {
                            "site-max-width": (
                                !this.isOnStartView(this.props.location.pathname) && 
                                // Should this not be here anymore? 
                                /* !this.isCoworkersView(this.props.location.pathname) && */
                                !this.isMessagesView(this.props.location.pathname) &&
                                !this.isGroupsView(this.props.location.pathname) &&
                                this.props.location.pathname.indexOf("/edit-startpage") !== 0 &&
                                !this.props.visage2FullscreenMode && 
                                !this.isWikiPage(this.props.location.pathname) &&
                                this.props.location.pathname.indexOf("/planner") !== 0
                            ),
                            "site-document-width": this.props.useDocumentWidthMode,
                            "full-width": (
                                this.props.location.pathname.indexOf("/edit-startpage") === 0 ||
                                (this.props.location.pathname.indexOf("/planner") === 0 && this.props.isSmallViewMode)
                            ),
                            "appMode": this.props.appMode,
                            "fullscreenMode": this.isGroupsView(this.props.location.pathname) ||
                                this.isMessagesView(this.props.location.pathname) ||
                                this.props.visage2FullscreenMode,
                            "hasFormFooterBar": this.props.hasFormFooterBar,
                            "supplier-portal": supplierPortal,
                            "site-narrow-form-width": this.props.useNarrowFormWidthMode
                        })
                    }
                >
                    {
                        this.props.isInTeamsApp && (
                            <TeamsLogOutButton />
                        )
                    }
                    <div role="main" className={
                        classnames("main-content-wrapper", {
                            "main-content-wrapper-background": mainContentWrapperBackground,
                            "visage-box-shadow": mainContentWrapperBackground,
                            "no-padding": useMainContentNoPadding
                        })
                    }>
                        <Switch>
                            <Redirect from="/bloggar/*" to="/blogs/*" />
                            <Redirect from="/bloggar" to="/blogs" />
                            <Redirect from="/nyheter/*" to="/news/*" />
                            <Redirect from="/grupper/:groupId/filer/*/:id" to="/groups/:groupId/files/:id" />
                            <Redirect from="/grupper/*" to="/groups/*" />
                            <Redirect from="/handelser/*" to="/posts/*" />
                            <Redirect from="/profil/*" to="/profile/*" />
                            <Redirect from="/grupper" to="/groups" />
                            <Redirect from="/avdelningar/v/:unitId/texter/:pageId+" to="/organisation/v/:unitId/texter/:pageId+" />
                            <Redirect from="/avdelningar/v/:id" to="/organisation/v/:id" />
                            <Redirect from="/avdelningar/:unitId/:pageId+" to="/organisation/v/:unitId/texter/:pageId+" />
                            <Redirect from="/avdelningar" to="/organisation" />
                            <Redirect from="/kalender/:id" to="/calendar/:id" />
                            <Redirect from="/kalender" to="/calendar" />
                            <Redirect from="/calendar" to="/calendar/0/month" exact />
                            <Redirect from="/filer/:source/:id/edit" to="/files/:source/:id/edit" />
                            <Redirect from="/filer/:source/:id/version" to="/files/:source/:id/version" />
                            <Redirect from="/filer/:source/:id" to="/files/:source/:id" />
                            <Redirect from="/filer" to="/files" />
                            <Redirect from="/handelser" to="/" />
                            <Redirect from="/mitt-kontor" to="/my-office" />
                            <Redirect from="/min-avdelning" to="/my-unit" />
                            <Redirect from="/min-enhet" to="/my-unit" />
                            <Redirect from="/bokning" to="/booking" />
                            <Redirect from="/content-tool/*" to="/admin/*" />
                            <Redirect from="/content-tool" to="/admin" />
                            <Redirect from="/kontakter" to={{ pathname: "/people", search: this.props.location.search }} />
                            <Redirect from="/profilinstallningar" to="/profile-settings" />
                            <Redirect from="/nyheter" to="/news" />
                            {getRoutes().map(route => this.renderRoute(route), this)}
                            {this.props.currentUser.roles.indexOf("supplier") === -1 && (
                                <Route
                                    exact={true}
                                    path="/"
                                    render={this.renderComponent.bind(this, SpintrStartView)}
                                />
                            )}
                            <Redirect path="*" to={defaultPath} />
                        </Switch>
                    </div>
                    <CalendarEventPopup />
                    <CustomDialog
                        show={this.props.confirmPopup.isOpen}
                        title={this.props.confirmPopup.title}
                        message={this.props.confirmPopup.message}
                        confirmMessage={this.props.confirmPopup.confirmButtonText}
                        dismissMessage={this.props.confirmPopup.dismissButtonText}
                        onDismiss={() => {
                            this.props.confirmPopup.onDismiss?.();
                            this.props.dispatch(setConfirmPopup({ isOpen: false }));
                        }}
                        onConfirm={() => {
                            this.props.confirmPopup.onConfirm();
                            this.props.dispatch(setConfirmPopup({ isOpen: false }));
                        }}
                    >
                        {this.props.confirmPopup.content}
                    </CustomDialog>
                    <InfoDialog
                        show={this.props.infoPopup.isOpen}
                        message={this.props.infoPopup.message}
                        onDismiss={() => {
                            this.props.infoPopup.onDismiss?.();
                            this.props.dispatch(setInfoPopup({ isOpen: false }));
                        }}
                    />
                    {/* {this.props.currentUser.roles.indexOf("supplier") === -1 && (
                        <SpintrSupportChatContainer />
                    )} */}
                </div>
            </Suspense>
        )
    }

    public renderRoute(route: Spintr.ISpintrUiRoute): ReactNode {
        if (this.props.currentUser.roles.indexOf("supplier") !== -1) {
            if (route.path.substring(0, 10) !== "/suppliers") {
                return null;
            }
        }

        return (
            <Route
                exact={route.exact || false}
                key={'topLevelRoute.' + route.path}
                path={route.path}
                render={this.renderComponent.bind(this, route.component)} />
        )
    }

    public renderComponent(RoutedComponent: any, props = {}): ReactNode {
        return (
            <StandardErrorBoundary>
                <RoutedComponent
                    {...props}
                />
            </StandardErrorBoundary>
        )
    }

    public shouldComponentUpdate(nextProps) {
        if (
            nextProps.useDocumentWidthMode !== this.props.useDocumentWidthMode ||
            nextProps.useNarrowFormWidthMode !== this.props.useNarrowFormWidthMode ||
            nextProps.confirmPopup.isOpen !== this.props.confirmPopup.isOpen ||
            nextProps.infoPopup.isOpen !== this.props.infoPopup.isOpen ||
            nextProps.isSmallViewMode !== this.props.isSmallViewMode ||
            nextProps.disableWrapperBackground !== this.props.disableWrapperBackground ||
            this.useMainContentNoPadding(nextProps.location.pathname) !== this.useMainContentNoPadding(this.props.location.pathname) ||
            this.useMainContentWrapperBackground(nextProps.location.pathname) !== this.useMainContentWrapperBackground(this.props.location.pathname) ||
            this.isOnStartView(nextProps.location.pathname) !== this.isOnStartView(this.props.location.pathname) ||
            this.isGroupsView(nextProps.location.pathname) !== this.isGroupsView(this.props.location.pathname) ||
            this.isMessagesView(nextProps.location.pathname) !== this.isMessagesView(this.props.location.pathname) ||
            this.isCoworkersView(nextProps.location.pathname) !== this.isCoworkersView(this.props.location.pathname) ||
            nextProps.hasFormFooterBar !== this.props.hasFormFooterBar ||
            nextProps.visage2ListView !== this.props.visage2FullscreenMode
        ) {
            return true;
        }

        return false;
    }

    isGroupsView = (pathname) => {
        const groupsViewPattern = /(groups\/|https:\/\/)[0-9]/g;
        const chatViewPattern = /(groups\/chat\/|https:\/\/)[0-9]/g;
        const groupStartViewPathname = "/groups";

        return pathname === groupStartViewPathname ||
            !!pathname.match(chatViewPattern) ||
            !!pathname.match(groupsViewPattern) &&
            pathname.indexOf("/edit") === -1 &&
            pathname.indexOf("/create") === -1;
    }

    isMessagesView = (pathname) => {
        return pathname.indexOf("/messages") == 0;
    }

    isCoworkersView = (pathname) => {
        return (pathname.indexOf("/people") === 0 && pathname.indexOf("/people/") === -1) ||
            (pathname.indexOf("/admin/users") === 0 && pathname.indexOf("/users/") === -1);
    }

    isSupplierView = (pathname) => {
        const supplierViewRegex = new RegExp(/^\/suppliers\/(\d+)$/g);
        return supplierViewRegex.test(pathname);
    }

    isProfileView = (pathname) => {
        return pathname.indexOf("/profile/") === 0;
    }

    useMainContentNoPadding = (pathname) => {
        const isGroupsView = this.isGroupsView(pathname);
        const isMessagesView = this.isMessagesView(pathname);
        const isOnMyGroupsPage = pathname.indexOf("/my-groups") === 0;
        const isSupplierView = this.isSupplierView(pathname);
        const isOperationsView = pathname.indexOf("/operations") === 0;

        const isResponsiveTimeline = pathname.indexOf("/planner") === 0 && this.props.isSmallViewMode;

        return isGroupsView ||
            isMessagesView ||
            isOnMyGroupsPage ||
            isSupplierView ||
            isResponsiveTimeline ||
            this.props.visage2FullscreenMode ||
            pathname.indexOf("/edit-startpage") === 0 ||
            isOperationsView;
    }

    useMainContentWrapperBackground = (pathname) => {
        if (this.props.disableWrapperBackground) {
            return false;
        }

        return pathname !== "/" &&
            pathname.indexOf("/posts/") !== 0 &&
            pathname.indexOf("/app/") !== 0 &&
            pathname.indexOf("/edit-startpage") !== 0 &&
            pathname.indexOf("/planner") !== 0 &&
            !this.isGroupsView(pathname) &&
            !this.isMessagesView(pathname) &&
            !this.isSupplierView(pathname) &&
            !this.isCoworkersView(pathname) &&
            !this.isProfileView(pathname) &&
            !this.isWikiPage(pathname) &&
            !this.props.visage2FullscreenMode;
    }
    
    private isWikiPage(pathname?: string): boolean {
        if (!pathname) {
            return false;
        }

        const formRoute = 
            pathname === "/wikis/create" ||
            pathname.startsWith("/wikis/edit-wiki/") ||
            pathname.startsWith("/wikis/create-article/") ||
            pathname.startsWith("/wikis/edit-article/") ||
            pathname.startsWith("/wikis/create-section/") ||
            pathname.startsWith("/wikis/edit-section/");

        if (formRoute) {
            return false; // Wiki form should use fullpage style
        }

        return pathname.indexOf("/wikis") === 0;
    }

    protected isOnStartView(pathname?: string): boolean {
        const path: string = pathname || this.props.location.pathname;

        if (path === "" || path === "/") {
            return true
        }

        return (
            path.indexOf("/handelser") === 0
        );
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state) => ({
        confirmPopup: state.popups.confirmPopup,
        currentUser: state.profile.active,
        infoPopup: state.popups.infoPopup,
        isSmallViewMode: state.ui.isSmallViewMode,
        isInTeamsApp: state.ui.isInTeamsApp,
        appMode: state.ui.appMode,
        useDocumentWidthMode: state.ui.useDocumentWidthMode,
        useNarrowFormWidthMode: state.ui.useNarrowFormWidthMode,
        disableWrapperBackground: state.ui.disableWrapperBackground,
        hasFormFooterBar: state.ui.formFooterBarVisible,
        visage2FullscreenMode: state.ui.visage2FullscreenMode
    });

// const SpintrMainContentContainerWithRouter = withRouter(SpintrMainContentContainer);

// const ConnectedSpintrMainContentContainerWithRouter = connect(mapStateToProps)(SpintrMainContentContainerWithRouter)

export default withRouter(connect(mapStateToProps)(SpintrMainContentContainer));
