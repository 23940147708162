import Axios from "axios";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { PrioritizedWikiListStateProps, PrioritizedWikiListState } from "./PrioritizedWikiList.types";
import { queryPrioritizedWikis } from "src/api";
import SpintrLoader from "src/ui/components/Loader";
import { SmallBodyBolder } from "src/components";
import { localize } from "src/l10n";
import { PrioritizedWiki } from "../PrioritizedWiki";
import { useSelector } from "react-redux";

function PrioritizedWikiList(): ReactElement {
    const [state, setState] = useState<PrioritizedWikiListState>({
        isLoading: false,
        prioritizedWikis: [],
    });

    const {
        currentUserId,
        hasElevatedPermissions,
    } = useSelector<Spintr.AppState, PrioritizedWikiListStateProps>(
        (appState) => ({
            currentUserId: appState.profile.active.id,
            hasElevatedPermissions:
                appState.profile.active.isAdmin ||
                appState.profile.active.isEditor,
        }),
    );

    useEffect(() => {
        const cancelTokenSource = Axios.CancelToken.source();

        setState((currentState) => ({
            ...currentState,
            isLoading: true,
        }));

        queryPrioritizedWikis(false, cancelTokenSource.token)
            .then((wikis) => setState((currentState) => ({
                ...currentState,
                isLoading: false,
                prioritizedWikis: wikis,
            })))
            .catch((_: any) => setState((currentState) => ({
                ...currentState,
                isLoading: false,
            })));

        return () => cancelTokenSource.cancel();
    }, []);

    const onRenderWiki = useCallback((wiki: Spintr.IWiki) => (
        <li key={wiki.id}>
            <PrioritizedWiki
                canEdit={hasElevatedPermissions || wiki.editor?.id === currentUserId}
                wiki={wiki} />
        </li>
    ), [currentUserId, hasElevatedPermissions]);

    const { isLoading, prioritizedWikis } = state;

    if (isLoading) {
        return <SpintrLoader />;
    } else if (prioritizedWikis.length === 0) {
        return null;
    }    

    return (
        <div className="PrioritizedWikiList">
            <header className="PrioritizedWikiList-heading">
                <SmallBodyBolder color="contentDark" letterSpacing="normal" weight="semiBold">
                    {localize("PRIO_WIKIS")}
                </SmallBodyBolder>
            </header>
            <ol className="PrioritizedWikiList-items">
                {state.prioritizedWikis.map(onRenderWiki)}
            </ol>
        </div>
    );
}

export default PrioritizedWikiList;
