import { SpintrTypes } from "src/typings";
import { UberContent, UberContentColumn } from "./UberContent.types";

const ColumnType = SpintrTypes.UberContentSectionType;

function extractHtmlFromImageColumn(column: UberContentColumn): string {
    if (column?.type === ColumnType.Image || !column?.content) {
        return "";
    }

    let imageContent: string | undefined;
    if (column.content.startsWith("{")) {
        try {
            let deserialized = JSON.parse(column.content);

            imageContent = deserialized.content;
        } catch (err) { }
    } else {
        imageContent = column.content;
    }

    if (!imageContent) {
        return "";
    }

    return `<img src="${column.content}" />`;
}

function extractHtmlFromLinkColumn(column: UberContentColumn): string {
    if (column?.type === ColumnType.Link || !column?.content) {
        return "";
    }
    
    if (!column.content.startsWith("{")) {
        return "";
    }
    
    try {
        const contentObj = JSON.parse(column.content);

        return `<a href="${contentObj.url}">${contentObj.title}</a>`;
    } catch (err) {
        return "";
    }
}

function extractHtmlFromTextColumn(column: UberContentColumn): string {
    if (column?.type === ColumnType.Text || !column?.content) {
        return "";
    }

    return column.content;
}

function extractHtmlFromUserListColumn(column: UberContentColumn): string {
    if (column?.type === ColumnType.Link || !column?.content) {
        return "";
    }
    
    if (!column.content.startsWith("{")) {
        return "";
    }
    
    try {
        const contentObj = JSON.parse(column.content);

        return contentObj.title;
    } catch (err) {
        return "";
    }
}

function extractHtmlFromExpandableField(column: UberContentColumn): string {
    if (column?.type === ColumnType.Link || !column?.content) {
        return "";
    }
    
    if (!column.content.startsWith("{")) {
        return "";
    }
    
    try {
        const contentObj = JSON.parse(column.content);

        return `<p>${contentObj.title}</p><p>${contentObj.text}</p>`;
    } catch (err) {
        return "";
    }
}

function extractHtmlFromProductsBlock(column: UberContentColumn): string {
    if (column?.type === ColumnType.Link || !column?.content) {
        return "";
    }
    
    if (!column.content.startsWith("{")) {
        return "";
    }
    
    try {
        const contentObj = JSON.parse(column.content);

        return `<p>${contentObj.title}</p>`;
    } catch (err) {
        return "";
    }
}

type HtmlExtractor = (column: UberContentColumn) => string;
type HtmlExtractorMap = {
    [key in SpintrTypes.UberContentSectionType]?: HtmlExtractor | undefined;
};

const columnExtractors: HtmlExtractorMap = {
    [ColumnType.ExpandableField]: extractHtmlFromExpandableField,
    [ColumnType.Image]: extractHtmlFromImageColumn,
    [ColumnType.Link]: extractHtmlFromLinkColumn,
    [ColumnType.Products]: extractHtmlFromProductsBlock,
    [ColumnType.Text]: extractHtmlFromTextColumn,
    [ColumnType.UserList]: extractHtmlFromUserListColumn,
};

const columnSeparator = "<br />";
function concatenateHtmlFromUberContentColumns(html: string, column: UberContentColumn): string {
    if (!column?.content) {
        return html;
    }

    const columnContent = columnExtractors[column.type]
        ? columnExtractors[column.type]?.(column)
        : "";

    if (!columnContent) {
        return html;
    }

    return html + columnContent + columnSeparator;
}

export function extractHtmlFromUberContent(uberContent: UberContent): string {
    return (uberContent?.uberContentRows || [])
        .map((row) => (row?.uberContentColumns || []))
        .flat()
        .reduce(concatenateHtmlFromUberContentColumns, "");
}

export function getVideosToBeUploaded(uberContent) {
    return uberContent.uberContentRows.
        flatMap(row => row.uberContentColumns).
        filter(column => column.type === SpintrTypes.UberContentSectionType.Video && column.file).
        map(column => {
            const videoObj = JSON.parse(column.content);
            return { ...videoObj, file: column.file };
        });
}