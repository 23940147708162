import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./SystemStatusTypePill.scss";
import api from "src/spintr/SpintrApi";

interface IProps {}

const SystemStatusHeaderButton: FunctionComponent<IProps> = () => {
    const [statusType, setStatusType] = useState();
    useEffect(() => {
        api.get("/api/v1/systemstatuses/header").then((response) => {
            setStatusType(response.data);
        });
    }, []);

    if (statusType === undefined) {
        return null;
    }

    const statusTypeText =
        statusType === SpintrTypes.SystemStatusType.Ongoing
            ? "ongoing"
            : statusType === SpintrTypes.SystemStatusType.Planned
            ? "planned"
            : "nonePlanned";

    const color =
        statusType === SpintrTypes.SystemStatusType.Planned
            ? "orange"
            : statusType === SpintrTypes.SystemStatusType.Ongoing
            ? "red"
            : "green";

    return (
        <div className={classNames("VisageSidebar-menuItem-wrapper", [statusTypeText])}>
            <div>
                <UnstyledButton className="VisageSidebar-menuItem VisageSidebar-menuItem-small is-in-header">
                    <Visage2Icon
                        icon={
                            statusType === SpintrTypes.SystemStatusType.Done ||
                            statusType === SpintrTypes.SystemStatusType.AllClear
                                ? "tick-circle"
                                : statusType === SpintrTypes.SystemStatusType.Ongoing
                                ? "danger"
                                : "clock"
                        }
                        color={color}
                    />
                    <Label size="body-2" color="dark-grey">
                        {localize(
                            statusType === SpintrTypes.SystemStatusType.AllClear
                                ? "ALL_CLEAR"
                                : statusType === SpintrTypes.SystemStatusType.Ongoing
                                ? "SYSTEM_STATUS_RESOURCE_ACTIVE"
                                : statusType === SpintrTypes.SystemStatusType.Planned
                                ? "SYSTEM_STATUS_RESOURCE_PLANNED"
                                : "SYSTEM_STATUS_RESOURCE_NONE"
                        )}
                    </Label>
                </UnstyledButton>
            </div>
        </div>
    );
};

export default SystemStatusHeaderButton;
