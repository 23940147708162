import React, { useMemo, useState } from 'react';
import "./ComposerAttachmentSelector.scss";
import { ActionMenu, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { localize } from 'src/l10n';

interface IProps {
    onTodoClick: () => void;
    onMemoClick: () => void;
    onFileClick: () => void;
    onImageClick: () => void;
    onVideoClick: () => void;
    onAudioClick: () => void;
    compact?: boolean;
    isInGroup?: boolean;
    isMemo?: boolean;
    isTodoMemo?: boolean;
}

interface IChatMessageAttachmentType {
    icon: string;
    text: string;
    onClick: () => void;
    active?: boolean;
}

const getTypes = (props: IProps) : IChatMessageAttachmentType[][] => {
    const types : IChatMessageAttachmentType[][] = [
        [{
            icon: "task",
            text: localize("MEMO"),
            onClick: props.onTodoClick,
            active: props.isMemo,
        }],
        [{
            icon: "folder",
            text: localize("Fil"),
            onClick: props.onFileClick
        }, {
            icon: "gallery-add",
            text: localize("Bild"),
            onClick: props.onImageClick
        }, {
        //     icon: "video",
        //     text: localize("RECORD_VIDEO"),
        //     onClick: props.onVideoClick
        // }, {
            icon: "microphone-2",
            text: localize("RECORD_VOICE"),
            onClick: props.onAudioClick
        }]
    ];

    if (props.isInGroup) {
        return types;
    }

    return [types[1]];
}

const ComposerAttachmentSelector = (props: IProps) => {
    const types = getTypes(props);

    if (!props.compact) {
        return (
            <div className="ComposerAttachmentSelector">
                {types[0].map((type: IChatMessageAttachmentType, index: number) => {
                    return (
                        <UnstyledButton
                            key={index}
                            onClick={type.onClick}
                            title={type.text}
                            className={type.active ? "active" : ""}
                        >
                            <Visage2Icon icon={type.icon} color={type.active ? "dark-grey" : "grey"} />
                        </UnstyledButton>
                    )
                })}
                {types.length > 1 && types[0].length > 0 && <div className="attachments-separator" />}
                {types.length > 1 && types[1].map((type: IChatMessageAttachmentType, index: number) => {
                    return (
                        <UnstyledButton
                            key={index}
                            onClick={type.onClick}
                            title={type.text}
                            className={type.active ? "active" : ""}
                        >
                            <Visage2Icon icon={type.icon} color={type.active ? "dark-grey" : "grey"} />
                        </UnstyledButton>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="ComposerAttachmentSelector">
            <ActionMenu
                menuClassName="hasSpintrIcons"
                icon="add-circle"
                iconColor="grey"
                categories={types.map((typeArr) => {
                    return {
                        items: typeArr.map((type: IChatMessageAttachmentType) => {
                            return {
                                text: type.text,
                                icon: "Star",
                                onClick: type.onClick,
                                onRenderIcon: () => {
                                    return (
                                        <Visage2Icon size="small" color={type.active ? "dark-grey" : "grey"} icon={type.icon} />
                                    )
                                }
                            }
                        })
                    }
                })}
            />
        </div>
    )
}

export default ComposerAttachmentSelector;
