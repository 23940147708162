import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ConversationsPanel } from "src/chat/components/ConversationsPanel";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { PageHeader } from "src/ui";

interface IProps {
    history: any;
    location: any;
    match: any;
}

class MyGroupsView extends Component<IProps> {
    render() {
        return (
            <div>
                <ConversationsPanel fullscreen />
            </div>
        )
    }
};

export default withRouter(MyGroupsView);
