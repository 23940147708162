/**
 * Returns a human readable file size string from the number of bytes.
 * @param bytes Tne number of bytes
 * @returns A human readable file size string
 */
export function fileSizeToHumanReadable(bytes: number): string {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    
    const fileSize = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
    
    return `${fileSize} ${sizes[i]}`;
}
