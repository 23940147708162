import React, { ReactElement, useCallback } from "react";
import { PlannerColorPickerProps } from "./PlannerColorPicker.types";
import classNames from "classnames";
import { UnstyledButton } from "src/ui";
import { Conditional } from "src/components/Conditional";
import { Icon } from "@fluentui/react";

const barColors: string[] = [
    "#FF6AA9",
    "#FFE664",
    "#FF8064",
    "#4ECAFF",
    "#17B26A",
    "#7F56D9",
    "#C9CBF1",
];

function PlannerColorPicker(props: PlannerColorPickerProps): ReactElement {
    const { onChange, selectedColor } = props;

    const onColorClick = useCallback((color) => {
        if (typeof color !== "string" || color.length === 0) {
            return;
        }

        onChange?.(color);
    }, [onChange]);

    return (
        <div className="PlannerColorPicker">
            {barColors.map((color) => (
                <UnstyledButton
                    key={color}
                    onClick={onColorClick}
                    onClickData={color}
                >
                    <div
                        className={classNames("color", { selected: color === selectedColor })}
                        style={{ backgroundColor: color }}
                    >
                        <Conditional condition={color === selectedColor}>
                            <Icon iconName="CheckMark" />
                        </Conditional>
                    </div>
                </UnstyledButton>
            ))}
        </div>
    )
};

export default PlannerColorPicker;
