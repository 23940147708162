import React, { Fragment, ReactElement } from "react";
import { SpintrTypes } from "src/typings";
import { PlannerItemDetailProps } from "./PlannerItemDetail.types";
import { Conditional } from "src/components/Conditional";
import { CaptionBolder, SmallBody, SmallBodyBolder } from "src/components/Text";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { PlannerFileList } from "../PlannerFileList";

function PlannerCourseDetails({ item }: PlannerItemDetailProps): ReactElement {
    if (item.itemType !== SpintrTypes.PlannerItemType.Course) {
        return null;
    }

    return (
        <Fragment>
            <Conditional condition={item.audienceDescription && item.audienceDescription.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_COURSE_AUDIENCE")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.audienceDescription}
                    </SmallBody>
                </div>
            </Conditional>

            <Conditional condition={item.instructor && item.instructor.length > 0}>
                <section className="PlannerItemDetail-infoBlock">
                    <div className="PlannerItemDetail-infoBlock-content">
                        <div className="PlannerItemDetail-infoBlock-property">
                            <div className="PlannerItemDetail-infoBlock-property-label">
                                <Visage2Icon icon="tag" color="spintrGrey" size="medium" />
                                <CaptionBolder color="contentDark">
                                    {localize("PLANNER_COURSE_INSTRUCTOR")}
                                </CaptionBolder>
                            </div>
                            <div className="PlannerItemDetail-infoBlock-property-value">
                                <SmallBody color="contentNormal">
                                    {item.instructor}
                                </SmallBody>
                            </div>
                        </div>
                    </div>
                </section>
            </Conditional>

            <PlannerFileList
                fieldType={SpintrTypes.PlannerCourseFileType.CourseMaterial}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Course} 
                title={localize("PLANNER_COURSE_MATERIAL")} />

            <PlannerFileList
                fieldType={SpintrTypes.PlannerCourseFileType.CoursePlan}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Course} 
                title={localize("PLANNER_COURSE_PLAN")}
                text={item.coursePlan} />

            <Conditional condition={item.examinationMethod && item.examinationMethod.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_COURSE_EXAMINATION")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.examinationMethod}
                    </SmallBody>
                </div>
            </Conditional>

        </Fragment>
    );
}

export default PlannerCourseDetails;
