import {
    WikiListView,
    WikiPageView,
    WikiArticleView,

    WikiArticleEditView,
    WikiArticleSectionEditView,
    WikiEditView
} from "./views";

const routes: Spintr.ISpintrRoute[] = [
    { path: "/wikis", exact: true, component: WikiListView },
    { path: "/wikis/create", exact: true, component: WikiEditView },
    { path: "/wikis/edit-wiki/:wikiId", exact: true, component: WikiEditView },
    { path: "/wikis/create-article/:wikiId", exact: true, component: WikiArticleEditView },
    { path: "/wikis/edit-article/:articleId", exact: true, component: WikiArticleEditView },
    { path: "/wikis/create-section/:articleId/:sectionIndex?", exact: true, component: WikiArticleSectionEditView },
    { path: "/wikis/edit-section/:sectionId", exact: true, component: WikiArticleSectionEditView },
    { path: "/wikis/:wiki", exact: true, component: WikiPageView },
    { path: "/wikis/:wiki/:article", exact: true, component: WikiArticleView },
];

export default routes;
