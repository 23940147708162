import React, { useCallback, useMemo } from 'react';
import "./ComposerInfo.scss";
import { Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { localize } from 'src/l10n';
import { useDispatch } from 'react-redux';
import { setComposerReplyMessage, setComposerEditMessage } from 'src/chat/redux';
import { stripInteractiveText } from 'src/utils';

interface IProps {
    conversationId: number;
    messageToEdit?: Spintr.IChatMessage;
    messageToReplyTo?: Spintr.IChatMessage;
}

const ComposerInfo = (props: IProps) => {
    const dispatch = useDispatch();

    const onRemoveClick = useCallback(() => {
        dispatch(setComposerReplyMessage(undefined, props.conversationId));
        dispatch(setComposerEditMessage(undefined, props.conversationId));
    }, []);

    const texts = useMemo(() => {
        const body = props.messageToEdit ? props.messageToEdit.text : props.messageToReplyTo.text;

        let headline = props.messageToEdit ? localize("EDITING_MESSAGE") : (localize("REPLYING_TO") + " " + props.messageToReplyTo.user.name);

        if (!!body) {
            headline += ": ";
        }

        return {body, headline}
    }, [props.messageToEdit, props.messageToReplyTo]);

    return (
        <div className="ComposerInfo">
            <div className="inner">
                <div className="left">
                    <Label size="body-2" weight="medium">
                        {texts.headline}{" "}
                    </Label>
                    {texts.body && (
                        <Label size="body-2" className="body-text">
                            {stripInteractiveText(texts.body)}
                        </Label>
                    )}
                </div>
                <UnstyledButton onClick={onRemoveClick}>
                    <Visage2Icon icon="close-circle" />
                </UnstyledButton>
            </div>
        </div>
    )
}

export default ComposerInfo;
