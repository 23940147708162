import React, { ButtonHTMLAttributes, Component, ReactNode } from "react";
import "./SmallCTAButton.scss";
import UnstyledButton from "../UnstyledButton/UnstyledButton";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Label } from "src/ui";

export interface IProps {
    onClick: () => void;
    onClear?: () => void;
    icon: string;
    text: string;
}

const SmallCTAButton = (props: IProps) => {
    return (
        <UnstyledButton className="SmallCTAButton" onClick={props.onClick}>
            <Visage2Icon icon={props.icon} size="small" />
            <Label size="body-2">{props.text}</Label>
            {props.onClear && (
                <UnstyledButton onClick={props.onClear}>
                    <Visage2Icon icon="close-circle" size="small" />
                </UnstyledButton>
            )}
        </UnstyledButton>
    )
}

export default SmallCTAButton;