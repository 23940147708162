import { AxiosResponse } from "axios";
import api from "src/spintr/SpintrApi";

import * as Types from "../message-types";

const baseUrl = "/api/v1/messages";

export type QueryMessageApiHandler =
    (params: Types.IQueryMessagesParams) => Promise<AxiosResponse<Spintr.IChatMessage[]>>;

export const queryMessagesApi: QueryMessageApiHandler =
    (params) => api.get<Spintr.IChatMessage[]>(baseUrl, { params });

export type ReactToMessageApiHandler =
    (messageId: number, likeType: number) => Promise<AxiosResponse<never>>;

export const postReactionToMessage: ReactToMessageApiHandler =
    (messageId, likeType) => api.post<never>(
        baseUrl + "/" + messageId + "/reaction",
        { reactionId: likeType }
    );

export type RemoveReactionApiHandler =
    (messageId: number, likeType: number) => Promise<AxiosResponse<never>>;

export const removeReactionFromMessage: RemoveReactionApiHandler =
    (messageId, likeType) => api.delete<never>(
        baseUrl + "/" + messageId + "/reaction/" + likeType
    );

export type PostMessageApiHandler =
    (message: Spintr.IChatMessage) => Promise<AxiosResponse<Spintr.IChatMessage>>;
export const postMessage: PostMessageApiHandler = 
    (message) => api.post<Spintr.IChatMessage>(
        baseUrl,
        message
    );

export type UpdateMessageApiHandler =
    (message: Spintr.IChatMessage) => Promise<AxiosResponse<Spintr.IChatMessage>>;
export const updateMessageApi: UpdateMessageApiHandler =
(message) => api.put<Spintr.IChatMessage>(
        baseUrl + "/" + message.id,
        message
    );