import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { WikiArticleListProps, WikiArticleListState, WikiArticleListStateProps } from "./WikiArticleList.types";
import { useHistory } from "react-router";
import { localize } from "src/l10n";
import { CancelToken } from "axios";
import api, { queryArticlesAsync, toggleObjectPinnedForUserAsync } from "src/api";
import { ListContentCard } from "../ListContentCard";
import { DataList, DataListColumn, DataListProps } from "../DataList";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmPopup } from "src/popups/actions";
import { Link } from "react-router-dom";
import StandardActionMenu from "src/ui/components/ActionMenu/StandardActionMenu";
import { listActionMenuWidth } from "src/ui/helpers/style";
import { SpintrTypes } from "src/typings";
import { useRealtime } from "src/hooks";
import { decodeHtmlEntities } from "src/utils";

function WikiArticleList(props: WikiArticleListProps): ReactElement {
    const {
        allowEditing,
        canCreateArticles,
        wikiId,
    } = props;
    
    const [state, setState] = useState<WikiArticleListState>({
        data: [],
        totalCount: 0,
    });

    const history = useHistory();
    const dispatch = useDispatch();
    const { subscribe, unsubscribe } = useRealtime();

    const {
        allowChanges,
        currentUserId,
        hasElevatedPermissions,
    } = useSelector<Spintr.AppState, WikiArticleListStateProps>(
        (appState) => ({
            allowChanges: 
                !(appState.instance.get("restrictWikis") as boolean)
                || appState.profile.active.isAdmin
                || appState.profile.active.isEditor,
            currentUserId: appState.profile.active.id,
            hasElevatedPermissions: 
                appState.profile.active.isAdmin ||
                appState.profile.active.isEditor,
        }),
    );

    useEffect(() => {
        subscribe("Object:Favorite", onFavoriteToggled);
        subscribe("Object:Follow", onFollowToggled);

        return () => {
            unsubscribe("Object:Favorite", onFavoriteToggled);
            unsubscribe("Object:Follow", onFollowToggled);
        };
        
    }, [subscribe, unsubscribe]);

    const onFavoriteToggled = (msg) => {
        if (msg.objectType !== SpintrTypes.UberType.WikiArticle) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            data: prevState.data.map(
                (article) => article.id !== msg.id
                    ? article
                    : {
                        ...article,
                        isFavourite: msg.value,
                    },
            )
        }));
    }

    const onFollowToggled = (msg) => {
        if (msg.objectType !== SpintrTypes.UberType.WikiArticle) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            data: prevState.data.map(
                (article) => article.id !== msg.id
                    ? article
                    : {
                        ...article,
                        isFollowed: msg.value,
                    },
            )
        }));
    }
    
    const columns = useMemo<DataListColumn[]>(() => [{
        key: "name",
        name: localize("Namn"),
        fieldName: "name",
        minWidth: 0,
        onRender: (item) => <Link to={item.url}>{item.name}</Link>
    }, {
        key: "actionMenu",
        name: "",
        minWidth: listActionMenuWidth,
        maxWidth: listActionMenuWidth,
        onRender: (item: Spintr.IWikiArticle2) => (
            <StandardActionMenu
                {...getStandardActionMenuProps(item)}
            />
        ),
    }], []);
    
    const fetch = useCallback(async (
        offset:         number,
        limit:          number,
        orderBy:        string,
        ascending:      boolean,
        searchText:     string,
        cancelToken:    CancelToken,
        fetchMore:      boolean
    ) => {
        const orderByColumn = orderBy !== "name" && orderBy !== "articlecount"
            ? "name"
            : orderBy;

        try {
            const result = await queryArticlesAsync(
                wikiId,
                offset,
                limit,
                orderByColumn,
                ascending,
                searchText,
                false,
                cancelToken
            );

            const articles = result.data.articles.map((article) => ({
                ...article,
                preamble: decodeHtmlEntities(article.preamble),
            })) || [];
            const totalCount = parseInt(result.totalCount, 10) || 0

            setState((prevState) => ({
                ...prevState,
                data: fetchMore
                    ? prevState.data.concat(articles)
                    : articles,
                totalCount,
            }));

            return { total: totalCount, };
        } catch (err) {
            console.error(err);

            return {
                data: [],
                total: 0,
            };
        }
    }, [wikiId]);

    const togglePin = useCallback(
        async (item: Spintr.IWikiArticle2) => {
            let pinned = item.pinned || false;
            
            try {
                const result = await toggleObjectPinnedForUserAsync(item.id);

                pinned = result?.isPinned || false;
            } catch (_) { }

            setState((prevState) => ({
                ...prevState,
                data: prevState.data.map(
                    (wiki) => wiki.id !== item.id
                        ? wiki
                        : {
                            ...wiki,
                            pinned,
                        }),
            }));
    }, [setState]);

    const getStandardActionMenuProps = (item: Spintr.IWikiArticle2) => {
        return {
            canFollow: true,
            canAddToFavourites: true,
            objectId: item.id,
            canEdit: allowChanges && (
                allowEditing ||
                hasElevatedPermissions ||
                item.editor?.id === currentUserId
            ),
            canDelete: allowChanges && (
                hasElevatedPermissions ||
                item.editor?.id === currentUserId
            ),
            onEditClick: () => history.push(`/wikis/edit-article/${item.id}`),
            onDeleteClick: () => dispatch(setConfirmPopup({
                isOpen: true,
                message: item.deleted
                    ? localize("VillDuVerkligenAterstallaX")
                        .replace("{{X}}", item.name)
                    : localize("VillDuVerkligenTaBortX")
                        .replace("{{X}}", item.name),
                onConfirm: async () => {
                    await api.put(
                        `/api/v1/wikis/article/toggledelete/${item.id}`
                    );

                    setState((prevState) => ({
                        ...prevState,
                        data: prevState.data.map(
                            (article) => article.id !== item.id
                                ? article
                                : {
                                    ...article,
                                    deleted: !item.deleted,
                                }),
                    }));
                },
            })),
            pinInsteadOfFavourite: true,
            isFavourite: item.isFavourited,
            isFollowing: item.isFollowed,
        }
    }
  
    const onRenderCard = useCallback((item: Spintr.IWikiArticle2) => {
        const categories: Spintr.IActionMenuCategory[] = [{
            title: "",
            items: [{
                key: "pin",
                onClick: () => togglePin(item),
                text: item.pinned
                    ? localize("UNPIN_OBJECT")
                    : localize("PIN_OBJECT"),
            }]
        }];

        const canEdit = allowChanges && (
            allowEditing ||
            hasElevatedPermissions ||
            item.editor?.id === currentUserId
        );

        const canDelete = allowChanges && (
            hasElevatedPermissions ||
            item.editor?.id === currentUserId
        );

        if (canEdit || canDelete) {
            const editorCategory: Spintr.IActionMenuCategory = {
                title: localize("Redaktor"),
                items: [],
            };

            if (canDelete) {
                editorCategory.items.push({
                    key: "delete",
                    text: localize("TaBort"),
                    onClick: () => dispatch(setConfirmPopup({
                        isOpen: true,
                        message: item.deleted
                            ? localize("VillDuVerkligenAterstallaX")
                                .replace("{{X}}", item.name)
                            : localize("VillDuVerkligenTaBortX")
                                .replace("{{X}}", item.name),
                        onConfirm: async () => {
                            await api.put(
                                `/api/v1/wikis/article/toggledelete/${item.id}`
                            );

                            setState((prevState) => ({
                                ...prevState,
                                data: prevState.data.map(
                                    (article) => article.id !== item.id
                                        ? article
                                        : {
                                            ...article,
                                            deleted: !item.deleted,
                                        }),
                            }));
                        },
                    })),
                });
            }

            if (canEdit) {
                editorCategory.items.push({
                    key: "edit",
                    text: localize("Redigera"),
                    onClick: () => history.push(`/wikis/edit-article/${item.id}`),
                });
            }

            categories.push(editorCategory);
        }

        return (
            <ListContentCard
                description={item.preamble}
                imageUrl={item.imageUrl}
                key={item.id}
                standardActionMenuProps={getStandardActionMenuProps(item)}
                title={item.name}
                url={item.url} />
        );
    }, [allowChanges, allowEditing, currentUserId, hasElevatedPermissions, history, setState, togglePin]);

    const onShimmerCardRender = useCallback((index: number) => (
        <ListContentCard
            key={`shimmer-${index}`}
            shimmer={true}
            title="" />
    ), []);
  
    const createButton = useMemo<DataListProps["createButton"]>(
        () => !canCreateArticles ? undefined : ({
            onClick: () => history.push(`/wikis/create-article/${wikiId}`),
        }),
        [canCreateArticles, history, wikiId],
    );

    return (
        <div className="WikiArticleList">
            <DataList
                canChangeListType={true}
                columns={columns} 
                createButton={createButton}
                fetch={fetch}
                data={state}
                infiniteScroll={true}
                initialListType="cards"
                take={12}
                orderByColumn="name"
                renderCard={onRenderCard}
                renderShimmerCard={onShimmerCardRender} />
        </div>
    );
}

export default WikiArticleList;
