import React, {  } from 'react';
import "./ComposerMemoTitle.scss";
import { localize } from 'src/l10n';
import { TextField } from '@fluentui/react';

interface IProps {
    value: string;
    onChange: (value: string) => void;
}

const ComposerMemoTitle = (props: IProps) => {
    return (
        <div className="ComposerMemoTitle">
            <TextField
                borderless
                value={props.value}
                onChange={(event, value: string) => props.onChange(value)}
                placeholder={localize("MEMO_TITLE")}
            />
        </div>
    )
}

export default ComposerMemoTitle;
