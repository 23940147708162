import React, { ReactElement, useCallback, useMemo } from "react";
import { ActionMenu, BackgroundImage } from "src/ui";
import { PrioritizedWikiProps } from "./PrioritizedWiki.types";
import { SemiboldHeading, SmallBody, TagList } from "src/components";
import { Stack, StackItem } from "@fluentui/react";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import { toggleObjectPinnedForUserAsync } from "src/api";
import { localize } from "src/l10n";
import StandardActionMenu from "src/ui/components/ActionMenu/StandardActionMenu";

function wrapWithBackgroundImage(imageUrl: string, content: ReactElement): ReactElement {
    if (!imageUrl) {
        return content;
    }

    return (
        <BackgroundImage imageUrl={imageUrl}>
            {content}
        </BackgroundImage>
    );
}

function PrioritizedWiki({ canEdit, wiki }: PrioritizedWikiProps): ReactElement {
    const history = useHistory();
    const hasImage = wiki.imageUrl && wiki.imageUrl.length > 0;

    const tags = !wiki.tags || wiki.tags.length === 0 ? null : (
        <StackItem>
            <TagList tags={wiki.tags} />
        </StackItem>
    );

    const content = (
        <Stack className={classNames("PrioritizedWiki-content", {
            "hasImage": hasImage,
        })}>
            <StackItem>
                <SemiboldHeading
                    className="PrioritizedWiki-title"
                    color={hasImage ? "white" : "contentDark"}
                >
                    {wiki.name}
                </SemiboldHeading>
            </StackItem>
            <StackItem grow={1}>
                <SmallBody
                    className="PrioritizedWiki-body"
                    color={hasImage ? "white" : "contentDark"}
                    letterSpacing="normal"
                >
                    {wiki.description}
                </SmallBody>
            </StackItem>
            {tags}
        </Stack>
    );

    return (
        <div className="PrioritizedWiki">
            <Link className="PrioritizedWiki-link" to={wiki.url}>
                {wrapWithBackgroundImage(wiki.imageUrl, content)}
            </Link>
            <div className="actions">
                <StandardActionMenu
                    canAddToFavourites
                    canFollow
                    pinInsteadOfFavourite
                    canEdit={canEdit}
                    onEditClick={() => history.push(`/wikis/edit-wiki/${wiki.id}`)}
                    objectId={wiki.id}
                    isFavourite={wiki.isFavourited}
                />
            </div>
        </div>
    );
}

export default PrioritizedWiki;
