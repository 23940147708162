import { Reducer } from "redux";
import SystemStatusAction, { IAddPrioritySystemStatusAction, IFetchPrioritySystemStatusesFulfilledAction, IPostSystemStatusViewedPendingAction, ISetCurrentResourceAction } from "./actions";
import SystemStatusActionTypes from "./action-types";

export interface ISystemStatusState {
    currentResource?: Spintr.ISystemStatusResource;
    prioritySystemStatuses: Spintr.ISocialPostBase[];
    viewedPrioritySystemStatuses: number[];
}

type Handler<TType> = (state: ISystemStatusState, action: TType) => ISystemStatusState;

const initialState: ISystemStatusState = {
    prioritySystemStatuses: [],
    viewedPrioritySystemStatuses: [],
};

const handleSetCurrentResource: Handler<ISetCurrentResourceAction> = (state, action) => ({
    ...state,
    currentResource: action.currentResource,
});

const handleAddPrioritySystemStatus: Handler<IAddPrioritySystemStatusAction> = (state, action) => {
    // Ignore if status already exists (fetched before realtime message was received) or we have already removed it
    if (state.prioritySystemStatuses.some((s) => s.Id === action.systemStatus.Id) ||
        state.viewedPrioritySystemStatuses.includes(action.systemStatus.Id)) {
        return state;
    }

    return {
        ...state,
        prioritySystemStatuses: [...state.prioritySystemStatuses, action.systemStatus],
    };
};

const handleFetchPrioritySystemStatusesFulfilled: Handler<IFetchPrioritySystemStatusesFulfilledAction> = (state, action) => ({
    ...state,
    prioritySystemStatuses: action.payload.data,
});

const handlePostSystemStatusViewedPending: Handler<IPostSystemStatusViewedPendingAction> = (state, action) => {
    const postId = state.prioritySystemStatuses.find((s) => s.content.some((c) => c.Id === action.meta))?.Id;

    return {
        ...state,
        prioritySystemStatuses: state.prioritySystemStatuses.filter((s) => !s.content.some(c => c.Id === action.meta)),
        viewedPrioritySystemStatuses: [...state.viewedPrioritySystemStatuses, postId],
    }
};

const handlerMapping = {
    [SystemStatusActionTypes.SetCurrentResource]: handleSetCurrentResource,
    [SystemStatusActionTypes.AddPrioritySystemStatus]: handleAddPrioritySystemStatus,
    [SystemStatusActionTypes.FetchPrioritySystemStatusesFulfilled]: handleFetchPrioritySystemStatusesFulfilled,
    [SystemStatusActionTypes.PostSystemStatusViewedPending]: handlePostSystemStatusViewedPending,
};


export const reducer: Reducer<ISystemStatusState, SystemStatusAction> =
    (state = initialState, action) => handlerMapping[action.type]
        ? handlerMapping[action.type].call(null, state, action)
        : state;

export default reducer;

