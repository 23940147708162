import React, { Fragment, ReactElement } from "react";
import { PlannerItemDetailProps } from "./PlannerItemDetail.types";
import { SpintrTypes } from "src/typings";
import { Conditional } from "src/components/Conditional";
import { SmallBody, SmallBodyBolder } from "src/components/Text";
import { localize } from "src/l10n";
import { PlannerFileList } from "../PlannerFileList";

function PlannerReleaseDetails({ item }: PlannerItemDetailProps): ReactElement {
    if (item.itemType !== SpintrTypes.PlannerItemType.Release) {
        return null;
    }

    return (
        <Fragment>
            <Conditional condition={item.productName && item.productName.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_RELEASE_NAME")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.productName}
                    </SmallBody>
                </div>
            </Conditional>

            <PlannerFileList
                files={item.files}
                fieldType={SpintrTypes.PlannerReleaseFileType.RolloutPlan}
                itemType={item.itemType}
                title={localize("PLANNER_RELEASE_PLAN")} />

            <Conditional condition={item.salesTarget && item.salesTarget.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_RELEASE_SALES_TARGET")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.salesTarget}
                    </SmallBody>
                </div>
            </Conditional>

            <PlannerFileList
                files={item.files}
                fieldType={SpintrTypes.PlannerReleaseFileType.MarketingMaterial}
                itemType={item.itemType}
                title={localize("PLANNER_RELEASE_MARKETING")} />

            <Conditional condition={item.launchStrategy && item.launchStrategy.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_RELEASE_STRATEGY")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.launchStrategy}
                    </SmallBody>
                </div>
            </Conditional>
        </Fragment>
    );
}

export default PlannerReleaseDetails;
