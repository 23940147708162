import React, { useMemo } from 'react';
import "./ComposerAttachmentAudio.scss";
import { Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import { localize } from 'src/l10n';

interface IProps {
    attachment: Spintr.IChatMessageAttachment;
    onRemove: () => void;
    onUpdate: (attachment: any) => void;
}

const ComposerAttachmentAudio = (props: IProps) => {
    return (
        <div className="ComposerAttachmentAudio">
            <AudioPlayer
                editMode
                onUpdate={(data: any) => {
                    props.onUpdate({
                        ...props.attachment,
                        data
                    })
                }}
            />
            <UnstyledButton title={localize("TaBort")} onClick={props.onRemove}>
                <Visage2Icon icon="trash" color="grey" />
            </UnstyledButton>
        </div>
    )
}

export default ComposerAttachmentAudio;
