import React, { useEffect, useRef } from "react";
import { setResponsiveShortcutsVisible } from "src/ui";
import { connect } from "react-redux";
import ShortcutsPopupView from "src/shortcuts/ShortcutsPopupView";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import DrawerPopoutView from "../DrawerPopoutView";

interface IProps {
    headerText?: string;
    onDismiss?: any;
    location?: any;
    dispatch?: any;
    history?: any;
    isVisible?: boolean;
}
const ResponsiveShortcuts = (props: IProps) => {
    const prevProps = useRef(props);

    const dismiss = () => {
        props.dispatch(setResponsiveShortcutsVisible(false));
    };

    useEffect(() => {
        if (
            !!props.location &&
            !!prevProps.current.location &&
            props.location.pathname !== prevProps.current.location.pathname
        ) {
            dismiss();
        }
    }, [props.location]);

    if (!props.isVisible) {
        return null;
    }

    return (
        <DrawerPopoutView
            icon="menu"
            onDismiss={dismiss}
            headerText={localize("Genvagar")}
            location={props.location}>
            <ShortcutsPopupView setTitle={localize("Genvagar")} />
        </DrawerPopoutView>
    )
};

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    isVisible: (state.ui.responsive || {}).shortcutsVisible,
});

export default connect(mapStateToProps)(ResponsiveShortcuts);
