import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import "./Message.scss";
import { Label, SpintrUser } from 'src/ui';
import MessageReactions from '../MessageReactions/MessageReactions';
import MessageActions from '../MessageActions/MessageActions';
import moment from 'moment';
import MessageContent from '../MessageContent/MessageContent';
import MessageRead from '../MessageRead/MessageRead';
import { IMessageUIData, IConversationReadData } from 'src/chat/redux';
import ParentMessage from '../ParentMessage/ParentMessage';
import { SpintrTypes } from 'src/typings';
import { localize } from 'src/l10n';

interface IProps {
    isSmallViewMode?: boolean;
    currentUserId: number;
    currentUserName: string;
    conversation?: Spintr.IConversation;
    message: Spintr.IChatMessage;
    uiData?: IMessageUIData;
    readData?: IConversationReadData;
    scrollToMessage?: (id: number) => void;
    compact?: boolean;
    isLast?: boolean;
}

interface IState {
    active: boolean;
}

const Message = (props: IProps) => {
    const ref = useRef<HTMLDivElement>();

    const [state, setState] = useState<IState>({
        active: false
    });

    const mainClasses = useMemo(() => {
        const result: string[] = [
            "Message",
            "Message-" + props.message.id
        ];

        result.push(props.message.user.id === props.currentUserId ? "right" : "left");

        if (props.uiData?.isTop) {
            result.push("top");
        }

        if (props.uiData?.isBottom) {
            result.push("bottom");
        }

        if (props.message.isMemo) {
            result.push("memo");
        }

        // if (!props.message.text &&
        //     props.message.objects.length + props.message.attachments.length === 1) {
        //     result.push("single-attachment");
        // }

        const isSingleAttachmentImage = !props.message.text &&
            props.message.objects.length === 0 &&
            props.message.attachments.length === 1 &&
            [SpintrTypes.FileType.Jpg, SpintrTypes.FileType.Png, SpintrTypes.FileType.Gif].includes(props.message.attachments[0].typeId);

        const isSingleObjectImage = !props.message.text &&
            props.message.objects.length === 1 &&
            props.message.attachments.length === 0 &&
            [SpintrTypes.FileType.Jpg, SpintrTypes.FileType.Png, SpintrTypes.FileType.Gif].includes(props.message.objects[0].fileType);

        if (isSingleAttachmentImage || isSingleObjectImage) {
            result.push("single-attachment");
        }

        return result.join(" ");
    }, [props.message, props.uiData]);

    const middleClasses = useMemo(() => {
        const result = ["middle"];

        if (state.active) {
            result.push("active");
        }

        return result.join(" ");
    }, [state.active]);

    const onBubbleClick = useCallback(() => {
        setState((s) => ({
            ...s,
            active: !state.active
        }));
    }, [state.active]);

    const topText = useMemo(() => {
        if (props.message.parent) {
            const isResponder = props.message.user.id === props.currentUserId;
            const isRespondent = props.message.parent.user.id === props.currentUserId;
            const langTag = isResponder && isRespondent ?
                "YOU_RESPONDED_TO_YOURSELF" :
                isResponder ?
                    "YOU_RESPONED_TO_Y" :
                    isRespondent ?
                        "X_RESPONDED_TO_YOU" :
                        "X_REPLIED_TO_Y";

            return (
                <Label size="body-2" color="grey" className="answer-text">
                    {localize(langTag).replace("{0}", props.message.user.name).replace("{1}", props.message.parent.user.name)}
                </Label>
            )
        }

        if (props.uiData?.isTop && props.message.user.id !== props.currentUserId && props.conversation?.participants.length > 2) {
            return (
                <Label>{props.message.user.name}</Label>
            )
        }

        return null;
    }, []);

    return (
        <div
            ref={ref}
            className={mainClasses}>
            {props.uiData?.displayDate && (
                <Label className="date" color="grey" size="body-2">{moment(props.message.date).format("LLL")}</Label>
            )}
            <div className="inner">
                <div className="content">
                    {props.message.user.id !== props.currentUserId && !props.compact && (
                        <div className="image-wrapper">
                            {props.uiData?.isTop && (
                                <SpintrUser
                                    name={props.message.user.name}
                                    imageUrl={props.message.user.imageUrl}
                                    personalName={true}
                                    hideText
                                />
                            )}
                        </div>
                    )}
                    <div className={middleClasses}>
                        {topText}
                        {props.message.parent && (
                            <ParentMessage
                                message={props.message.parent}
                                scrollToMessage={props.scrollToMessage} />
                        )}
                        <div className="bubble-wrapper">
                            <div className="bubble" onClick={props.isSmallViewMode ? onBubbleClick : undefined}>
                                <MessageContent message={props.message} />
                            </div>
                            <MessageActions
                                isSmallViewMode={props.isSmallViewMode}
                                currentUserId={props.currentUserId}
                                currentUserName={props.currentUserName}
                                conversation={props.conversation}
                                message={props.message} />
                        </div>
                        <MessageReactions
                            currentUserId={props.currentUserId}
                            conversation={props.conversation}
                            message={props.message} />
                    </div>
                </div>
                <MessageRead
                    currentUserId={props.currentUserId}
                    conversation={props.conversation}
                    message={props.message}
                    readData={props.readData}
                    fixedHeight={props.isLast} />
            </div>
        </div>
    )
}

export default Message;
