import { takeRight } from 'lodash';
import moment from "moment";
import { Icon } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label, LocalizedString, UnstyledButton } from "src/ui";
import { clearFeed, fetchSocialFeed, SocialFeedFetcher } from "../../actions";
import {
    FeedRequest,
    ICombinedFeedRequest,
    IGroupFeedRequest,
    ISystemStatusFeedRequest,
    IUserFeedRequest,
    SocialFeedType
} from "../../types";
import { SocialFeedFilter } from "../SocialFeedFilter";
import { SocialPostContainer } from "../SocialPostContainer";
import { SocialPostShimmer } from "../SocialPostShimmer";
import "./SocialFeedContainer.scss";
import classNames from 'classnames';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import IdleChecker from 'src/spintr/components/IdleChecker';

interface IOwnProps {
    feedId?: number;
    userId?: number;
    groupId?: number;
    feedType: SocialFeedType;
    identity?: any;
    systemStatusType?: SpintrTypes.SystemStatusType;
    searchText?: string;
    emptyText?: string;
}

interface IStateProps {
    entries: Spintr.ISocialPostBase[];
    hasMore: boolean;
    isLoading: boolean;
    elevatedPrivileges: boolean;
    currentUser?: any;
    productsOptions: {
        enabled: boolean;
        canAddProducts: boolean;
        patterns: RegExp[];
    };
}

interface IDispatchProps {
    fetchSocialFeed: SocialFeedFetcher;
    clearFeed: any;
}

const pageSize: number = 8;
const shimmerArray: number[] = [0, 1, 2, 3, 4, 5, 6, 7];

type Props = IOwnProps & IStateProps & IDispatchProps;

interface IState {
    newPostCount: number;
}

class SocialFeedContainer extends Component<Props, IState> {
    constructor(props : Props) {
        super(props);

        this.state = {
            newPostCount: 0
        }

        this.onLoadMoreClicked = this.onLoadMoreClicked.bind(this);
        this.renderPostContainer = this.renderPostContainer.bind(this);
    }

    public componentDidMount() : void {
        const { entries, isLoading, feedType } = this.props;

        const doRefetch = feedType === SocialFeedType.Profile || feedType === SocialFeedType.Group || SocialFeedType.SystemStatus;
        const doFirstFetch = !doRefetch && entries.length === 0 && !isLoading;
        const doSilentFetch = !doRefetch && !doFirstFetch

        if (doRefetch) {
            this.fetchPosts(true);

            return;
        }
        
        if (doFirstFetch) {
            this.fetchPosts();

            return;
        }

        if (doSilentFetch) {
            this.fetchPosts(true, true);

            return;
        }
    }

    public componentWillUnmount() : void {
        if (this.props.feedType > 0) {
            this.props.clearFeed(this.props.feedType);
        }
    }

    public componentDidUpdate(prevProps) : void {
        if (!!prevProps.entries &&
            prevProps.entries.length > 0 &&
            this.props.entries.length === (prevProps.entries.length + 1) &&
            this.props.entries[1].Id === prevProps.entries[0].Id &&
            this.props.entries[0].UserId !== this.props.currentUser.id &&
            this.props.entries[0].UserId !== this.props.identity?.id &&
            this.props.entries[0].content.find((c) => c.Type === SpintrTypes.UberType.SystemStatus)?.UserId !== this.props.currentUser.id) {
            this.setState({
                newPostCount: this.state.newPostCount + 1
            });
        } else if (this.state.newPostCount > 0 &&
            !!prevProps.entries &&
            prevProps.entries.length > 0 &&
            this.props.entries.length === (prevProps.entries.length + 1) &&
            this.props.entries[0].UserId === this.props.currentUser.id) {
            this.setState({
                newPostCount: 0
            });
        }

        if (this.props.currentUser.settings.startFeedFilters !== prevProps.currentUser.settings.startFeedFilters ||
            this.props.currentUser.settings.startFeedSorting !== prevProps.currentUser.settings.startFeedSorting ||
            this.props.currentUser.settings.activeFeedHashtagId !== prevProps.currentUser.settings.activeFeedHashtagId ||
            this.props.groupId !== prevProps.groupId ||
            this.props.systemStatusType !== prevProps.systemStatusType ||
            this.props.searchText !== prevProps.searchText ||
            this.props.feedId !== prevProps.feedId) {
            this.fetchPosts(true);
        }
    }

    renderDisplayMoreButton() : ReactNode {
        const langTag = this.state.newPostCount > 1 ?
            "FEED_DISPLAY_NEW_EVENTS" :
            "FEED_DISPLAY_NEW_EVENT";

        let text = localize(langTag).replace("{{0}}", this.state.newPostCount.toString());

        return (
            <UnstyledButton className="new-feed-posts" onClick={() => {
                this.setState({
                    newPostCount: 0
                });
            }}>
                <div>
                    <Label as="p" size="body-2" color="light-blue">
                        <Visage2Icon icon="arrow-up-2" color="light-blue" />
                        {
                            text
                        }
                    </Label>
                </div>
            </UnstyledButton>
        )
    }

    public render() : ReactNode {
        let entries = takeRight(this.props.entries, this.props.entries.length - this.state.newPostCount);
        let hasUnread = this.state.newPostCount > 0

        return (
            <div id="SocialFeedContainer">
                <IdleChecker
                    idleLimitMs={300000}
                    onActivityAfterIdle={() => {
                        this.fetchPosts(true, true);
                    }} />
                {
                    this.props.feedType === SocialFeedType.Combined && (
                        <SocialFeedFilter />
                    )
                }
                {
                    hasUnread && this.renderDisplayMoreButton()
                }
                <InfiniteScroll
                    className={classNames("", {
                        "has-unread": hasUnread
                    })}
                    dataLength={this.props.entries.length}
                    next={this.onLoadMoreClicked}
                    hasMore={this.props.hasMore}
                    loader={null}
                >
                    {entries.map(this.renderPostContainer)}
                    {entries.length === 0 && !this.props.hasMore && !this.props.isLoading && this.props.emptyText && (
                        <div className="spintr-list-empty-list">
                            <Label className="spintr-list-empty-list-label" as="p" size="body-2">
                                {this.props.emptyText}
                            </Label>
                        </div>
                    )}
                </InfiniteScroll>
                {this.props.isLoading && (
                    shimmerArray.map((n: number) => (
                        <SocialPostShimmer
                            key={"SocialPostShimmer" + n}
                        />
                    ))
                )}
                {!this.props.isLoading && this.props.hasMore && (
                    <div className="load-more">
                        <a className="primaryFGColor" onClick={this.onLoadMoreClicked}>
                            <LocalizedString text="LaddaInFlera" />
                        </a>
                    </div>
                )}
            </div>
        );
    }

    protected onLoadMoreClicked(): void {
        this.fetchPosts();
    }

    protected async fetchPosts(reFetch?: boolean, silentFetch?: boolean): Promise<void> {
        this.props.fetchSocialFeed(this.getFeedRequest(reFetch, silentFetch));
    }

    protected getFeedRequest(reFetch?: boolean, silentFetch?: boolean): FeedRequest {
        const { feedType } = this.props;

        const uberTypes: SpintrTypes.UberType[] = [
            SpintrTypes.UberType.Status,
            SpintrTypes.UberType.Bookmark,
            SpintrTypes.UberType.Image,
            // SpintrTypes.UberType.BlogPost,
            SpintrTypes.UberType.Poll,
            SpintrTypes.UberType.TextPageUpdate,
            SpintrTypes.UberType.HiddenPost,
        ];

        switch (feedType) {
            default:
            case SocialFeedType.Combined:
                const lastDateObj: Date | undefined = this.props.entries
                    .reduce<Date|undefined>(
                        (date, post) => {
                            const postDate = (post.Date instanceof Date)
                                ? post.Date
                                : new Date(post.Date);

                            if (!date) {
                                return postDate;
                            }

                            return date < postDate ? date : postDate;
                        },
                        undefined
                    );

                let lastDate;
    
                if (!reFetch && lastDateObj instanceof Date) {
                    lastDate = moment.utc(lastDateObj).format("MM/DD/YYYY hh:mm:ss A");
                }
                
                if (silentFetch) {
                    lastDate = undefined;
                }

                let extra = undefined;
                let filteringMode = 0;

                if (this.props.currentUser.settings.startFeedSorting !== "alldepartments" &&
                    this.props.currentUser.settings.startFeedSorting.indexOf("time") === 0) {
                    extra = this.props.currentUser.settings.startFeedSorting.substring(4);
                    filteringMode = 2;
                }

                const combinedReq: ICombinedFeedRequest = {
                    feed: SocialFeedType.Combined,
                    params: {
                        extra,
                        filteringMode,
                        hashId: this.props.currentUser.settings.activeFeedHashtagId,
                        skip: reFetch ? 0 : this.props.entries.length,
                        take: pageSize,
                        uberTypes: this.props.currentUser.settings.startFeedFilters.split(";").filter(ut => !!ut).map(ut => {
                            return parseInt(ut);
                        }),
                        isSilentFetch: silentFetch
                    }
                };

                return combinedReq;

            case SocialFeedType.Profile:
                const profileReq: IUserFeedRequest = {
                    feed: feedType,
                    params: {
                        hashId: 0,
                        skip: reFetch ?
                            0 :
                            this.props.entries.length,
                        uberTypes,
                        feedId: this.props.feedId,
                    }
                };

                return profileReq;

            case SocialFeedType.Group:
                const groupRequest: IGroupFeedRequest = {
                    feed: feedType,
                    params: {
                        feedId: this.props.feedId,
                        skip: reFetch ? 0 : this.props.entries.length,
                        uberTypes
                    }
                }

                return groupRequest;

            case SocialFeedType.SystemStatus:
                if (!uberTypes.includes(SpintrTypes.UberType.SystemStatus)) {
                    uberTypes.push(SpintrTypes.UberType.SystemStatus);
                }

                const systemStatusRequest: ISystemStatusFeedRequest = {
                    feed: feedType,
                    params: {
                        feedId: this.props.feedId,
                        skip: reFetch ?
                            0 :
                            this.props.entries.length,
                        take: pageSize,
                        systemStatusType: this.props.systemStatusType,
                        searchText: this.props.searchText,
                        uberTypes: this.props.feedId ? uberTypes : [SpintrTypes.UberType.SystemStatus],
                        allSystemStatusResources: !this.props.feedId,
                    }
                };

                return systemStatusRequest;
        }
    }

    protected getPostKey(item: Spintr.ISocialPostBase): string {
        return (
            "SFC.Post." + item.Id + "."
            + (item.isFavourited ? "1" : "0")
            + "."
            + (item.isFollowing ? "1" : "0")
        );
    }

    protected renderPostContainer(item: Spintr.ISocialPostBase): ReactNode {
        if (item.hidden && !this.props.elevatedPrivileges) {
            return null;
        }

        return (
            <SocialPostContainer
                key={this.getPostKey(item)}
                post={item}
                identity={this.props.identity}
                feedType={this.props.feedType}
                interactiveTextOptions={{
                    products: {
                        enabled: this.props.productsOptions?.enabled || false,
                        canAddProducts: this.props.productsOptions?.canAddProducts || false,
                        patterns: this.props.productsOptions?.patterns || [],
                    }
                }}
            />
        );
    }
}

export default connect<IStateProps, IDispatchProps, IOwnProps, Spintr.AppState>(
    (state, ownProps: IOwnProps): IStateProps => {
        const socialState = state.socialFeed;

        const elevatedPrivileges = state.profile.active.roles.some((role) => role === "administrators" || role === "editor");

        const productsOptions = {
            enabled: (state.instance.apps || state.instance.get("apps") || [])
                .some((app: any) => app.id === SpintrTypes.SpintrApp.Products && app.enabled === true),
            canAddProducts: elevatedPrivileges,
            patterns: !elevatedPrivileges
                ? []
                : (state.instance.get("articleIdPatterns") || [])
                    .map((pattern: string) => {
                        try {
                            return new RegExp(pattern, "gmi");
                        } catch (_) {
                            return null;
                        }
                    })
                    .filter((pattern: RegExp | null) => pattern !== null),
        };

        switch (ownProps.feedType) {
            default:
            case SocialFeedType.Combined:
                return {
                    entries: socialState.combined.entries,
                    hasMore: socialState.combined.hasMore,
                    isLoading: socialState.combined.isLoading,
                    elevatedPrivileges,
                    currentUser: state.profile.active,
                    productsOptions,
                };

            case SocialFeedType.Profile:
                return {
                    entries: socialState.profile.entries,
                    hasMore: socialState.profile.hasMore,
                    isLoading: socialState.profile.isLoading,
                    elevatedPrivileges,
                    currentUser: state.profile.active,
                    productsOptions,
                };

            case SocialFeedType.Group:
                return {
                    entries: socialState.group.entries,
                    hasMore: socialState.group.hasMore,
                    isLoading: socialState.group.isLoading,
                    elevatedPrivileges, // TODO: Check group privileges?
                    currentUser: state.profile.active,
                    productsOptions,
                };

            case SocialFeedType.SystemStatus:
                return {
                    entries: socialState.systemStatus.entries,
                    hasMore: socialState.systemStatus.hasMore,
                    isLoading: socialState.systemStatus.isLoading,
                    elevatedPrivileges,
                    currentUser: state.profile.active,
                    productsOptions
                };

        }
    },
    { fetchSocialFeed, clearFeed }
)(SocialFeedContainer);
