import React from "react";
import { localize } from "src/l10n";
import { Label, SpintrUser } from "src/ui";
import CompactTable from "src/ui/components/CompactTable/CompactTable";
import "./AcademyLeaderboardCategory.scss";
import Podium from "../Podium/Podium";
import classNames from "classnames";
import { Stack } from "@fluentui/react";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { useSelector } from "react-redux";

interface IProps {
    name: string;
    hasPodium?: boolean;
    podiumData?: any[];
    listData?: any[];
    listColumns?: any[];
}

const AcademyLeaderboardCategory = (props: IProps) => {
    const isSmallViewMode = useSelector<Spintr.AppState, boolean>((state) => state.ui.isSmallViewMode);

    return (
        <div className={classNames("AcademyLeaderboardCategory", { "has-podium": props.hasPodium })}>
            <Label weight="semi-bold" className="category-title">
                {props.name}
            </Label>
            <Stack horizontal={!isSmallViewMode} grow tokens={{ childrenGap: 12 }}>
                {props.listData.length === 0 && <Label color="mid-grey">{localize("NO_DATA_AVAILABLE")}</Label>}
                {props.listData.length > 0 && (
                    <>
                        {props.hasPodium && <Podium data={props.podiumData} />}
                        <SpintrList
                            fetch={() => {}}
                            columns={props.listColumns}
                            data={{
                                data: props.listData,
                                totalCount: props.listData.length,
                            }}
                            isLoading={false}
                            orderByColumn=""
                            disableSort
                            disableCommandBar
                            disableSearch
                            disablePagination
                        />
                    </>
                )}
            </Stack>
        </div>
    );
};

export default AcademyLeaderboardCategory;
