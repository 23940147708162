import React, { ReactElement, useMemo } from "react";
import { useHistory } from "react-router";
import { ActionMenu } from "src/ui";
import { Heading, SmallBody } from "../Text";
import { WikiSectionProps } from "./WikiSection.types";
import { localize } from "src/l10n";
import TinyFormattedContent from "src/ui/components/Tiny/displayment/TinyFormattedContent";
import { useDispatch } from "react-redux";
import { setConfirmPopup } from "src/popups/actions";

function WikiSection(props: WikiSectionProps): ReactElement {
    const { canDelete, canEdit, section, onDelete } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const sectionId = section.id;

    const actionMenuCategories = useMemo(() => {
        const items: Spintr.IActionMenuItem[] = [];

        if (canEdit) {
            items.push({
                key: "text",
                text: localize("Redigera"),
                onClick: function onEditClicked() {
                    history.push({
                        pathname: `/wikis/edit-section/${sectionId}`
                    })
                },
            });
        }

        if (canDelete) {
            items.push({
                key: "delete",
                text: localize("TaBortDennaSektion"),
                onClick: () => {
                    dispatch(setConfirmPopup({
                        isOpen: true,
                        message: localize("VillDuVerkligenTaBortSektionen"),
                        onConfirm: () => {
                            props.onDelete()
                        },
                    }));
                },
            });
        }

        return items.length === 0 ? [] : [{
            title: "",
            items,
        }];
    }, [canDelete, canEdit, sectionId]);

    const html = useMemo(() => ({
        __html: section.content,
    }), [section.content]);

    const actionMenu = actionMenuCategories.length === 0
        ? null
        : <ActionMenu categories={actionMenuCategories} />;

    return (
        <section className="WikiSection">
            <header className="WikiSection-header">
                <Heading 
                    color="contentDark"
                    weight="semiBold"
                >
                    {section.title}
                </Heading>

                <div className="action-menu-wrapper">
                    {actionMenu}
                </div>
            </header>

            <SmallBody
                as="div"
                className="WikiSection-content"
                color="contentDark"
            >
                <TinyFormattedContent content={section.content} />
            </SmallBody>
        </section>
    );
}

export default WikiSection;
