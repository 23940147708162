import { AxiosResponse } from "axios";
import qs from "qs";
import api from "src/spintr/SpintrApi";

const baseUrl = "/api/v1/favorites";

const paramsSerializer = (p: any): string => qs.stringify(p, {
    arrayFormat: "indices",
});

export const fetchFavorites =
    (userId?: number, uberTypes?: number[]): Promise<AxiosResponse<Spintr.IUserFavorite[]>> =>
        api.get<Spintr.IUserFavorite[]>(baseUrl, {
            params: {
                userId,
                uberTypes,
            },
            paramsSerializer,
        });
