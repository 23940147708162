import React, { ReactElement, useMemo } from "react";
import { PlannerFileListProps } from "./PlannerFileList.types";
import { CaptionBody, SmallBody, SmallBodyBolder } from "src/components/Text";
import { Conditional } from "src/components/Conditional";
import { Icon } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { fileSizeToHumanReadable, fileTypeToExtension } from "src/utils";

function PlannerFileList(props: PlannerFileListProps): ReactElement {
    const { fieldType, files: allFiles, itemType, title } = props;

    const files = useMemo(
        () => allFiles
            .filter((file) => file.fieldType === fieldType && file.itemType === itemType)
            .sort((a, b) => a.fileName.localeCompare(b.fileName)),
        [allFiles, fieldType, itemType],
    );

    if (files.length === 0 && (!props.text || props.text.length === 0)) {
        return null;
    }

    return (
        <section className="PlannerFileList">
            <div className="PlannerFileList-header">
                <SmallBodyBolder color="contentDark">
                    {title}
                </SmallBodyBolder>
            </div>
            <Conditional condition={props.text && props.text.length > 0}>
                <SmallBody className="PlannerFileList-text">
                    {props.text}
                </SmallBody>
            </Conditional>
            <div className="PlannerFileList-files">
                {files.map((file) => (
                    <a
                        key={file.id}
                        className="PlannerFileList-file"
                        href={`/api/v1/planneritems/${file.itemId}/files/${file.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="image">
                            <Icon {...getFileTypeIconProps({ extension: fileTypeToExtension(file.fileType), size: 32 })} />
                        </div>
                        <div className="information">
                            <SmallBodyBolder color="contentDark">
                                {file.fileName}
                            </SmallBodyBolder>
                            <CaptionBody>
                                {fileSizeToHumanReadable(file.fileSize)}
                            </CaptionBody>
                        </div>
                        {/*<div className="actions">
                            <UnstyledButton
                                onClick={onRemoveFile}
                                onClickData={file.localId}
                            >
                                <Visage2Icon
                                    color="visageGray"
                                    icon="close-circle"
                                    size="small" />
                            </UnstyledButton>
                        </div>*/}
                    </a>
                ))}
            </div>
        </section>
    );
}

export default PlannerFileList;