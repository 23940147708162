import React, { Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { WikiArticleViewParams, WikiArticleViewState, WikiArticleViewStateProps } from "./WikiArticleView.types";
import api, { fetchRecentlyVisitedWikiArticlesAsync, findWikiByRouteAsync, getRouteAsync, queryWikiArticlesAsync } from "src/api";
import Axios from "axios";
import SpintrLoader from "src/ui/components/Loader";
import { ContentMetadataBox, Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle, Heading, SidebarContentListBox, SmallBody, WikiSection } from "src/components";
import { Icon, Image, Stack, StackItem } from "@fluentui/react";
import { ActionMenu, Breadcrumbs, Submenu, toggleGreyBackground, UnstyledButton } from "src/ui";
import { useDispatch, useSelector } from "react-redux";
import { localize } from "src/l10n";
import { print } from "src/utils/print";
import { Link } from "react-router-dom";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { SpintrTypes } from "src/typings";
import { WikiPageViewRouteParams } from "../WikiPageView";
import { useRealtime } from "src/hooks";
import { fetchFavorites } from "src/favorites";
import { scrollToTop } from "src/utils";
import { Helmet } from "react-helmet";
import StandardActionMenu from "src/ui/components/ActionMenu/StandardActionMenu";
import { setConfirmPopup } from "src/popups/actions";

const submenuSearchProps = { enabled: false };

const initialState: WikiArticleViewState = {
    submenuOpen: false,
    loading: true,
    myRecent: [],
    myRecentLoading: true,
    favoritedWikis: [],
    favoritedWikisLoading: true,
    teamRecent: [],
    teamRecentLoading: true,
};

function WikiArticleView(): ReactElement {
    const history = useHistory();
    const [state, setState] = useState<WikiArticleViewState>(initialState);
    const { wiki: wikiRoute } = useParams<WikiPageViewRouteParams>();
    const dispatch = useDispatch();
    const { subscribe, unsubscribe } = useRealtime();

    const {
        wiki: wikiSlug,
        article: articleSlug,
    } = useParams<WikiArticleViewParams>();

    const {
        currentUserId,
        hasElevatedPermissions,
        restrictWikis,
        tenantName,
        viewMode,
    } = useSelector<Spintr.AppState, WikiArticleViewStateProps>(
        (appState) => ({
            currentUserId: appState.profile.active.id,
            hasElevatedPermissions:
                appState.profile.active.isEditor ||
                appState.profile.active.isAdmin,
            restrictWikis: !(appState.instance.get("restrictWikis") as boolean),
            tenantName: appState.instance.get("name"),
            viewMode: appState.ui.viewMode,
        })
    );

    const wikiId = state.article?.wiki.id;

    useEffect(() => {
        setState((prevState) => ({ ...prevState, submenuOpen: false, loading: true }));

        const cancelTokenSource = Axios.CancelToken.source();

        const promise = getRouteAsync<{ wiki: Spintr.IWikiArticle3 }>(
            `wikis/${wikiSlug}/${articleSlug}`,
            cancelTokenSource.token,
        );

        promise
            .then((result) => setState((prevState) => ({
                ...prevState,
                article: result.wiki,
                loading: false,
            })))
            .catch((_) => setState((prevState) => ({
                ...prevState,
                loading: false,
            })));

        return () => cancelTokenSource.cancel();
    }, [wikiSlug, articleSlug, setState]);

    const onSubmenuFetching = useCallback<SubmenuFetchHandler>(
        async (params) => {
            if (!state.article?.wiki?.id) {
                return 
            }

            let articles: Spintr.WikiArticle[] = [];
            try {
                articles = await queryWikiArticlesAsync(
                    state.article.wiki.id,
                    !params.searchText ? undefined : params.searchText,
                );
            } catch (err) {
                console.error(err);

                return [];
            }

            if (!!params.searchText) {
                const lowerCaseText = params.searchText.toLowerCase();

                articles = articles
                    .filter((article) =>
                        article.title
                            .toLowerCase()
                            .includes(lowerCaseText) ||
                        article.sections.some((section =>
                            section.header
                                .toLowerCase()
                                .includes(lowerCaseText))),
                    )
                    .map((article) => ({
                        ...article,
                        sections: article.sections.filter((section) =>
                            section.header.toLowerCase().includes(lowerCaseText),
                        )
                    }));
            }

            return articles.map((article) => ({
                id: article.id,
                key: article.id.toString(),
                name: article.title,
                active: article.id === state.article?.id,
                isExpanded: !!params.searchText || article.id === state.article?.id,
                hasNodes: article.sections.length > 0,
                url: `/${article.url}`,
                nodes: article.sections
                    .sort((a, b) => a.position - b.position)
                    .map<ISubmenuRow>((section) => ({
                        id: section.id,
                        name: section.header,
                        url: `/${article.url}#section-${section.id}`,
                        active: false,
                        isExpanded: false,
                        hasNodes: false,
                        key: section.id.toString(),
                        nodes: [],
                    }))
            }));
        },
        [state.article?.id],
    );

    const fetchWikiFavorites = () => {
        fetchFavorites(undefined, [SpintrTypes.UberType.Wiki, SpintrTypes.UberType.WikiArticle])
            .then((wikis) => setState((prevState) => ({
                ...prevState,
                favoritedWikis: wikis.data.map((wiki) => ({
                    key: `favorited-${wiki.id}`,
                    iconName: "volume-low-1",
                    text: wiki.title,
                    url: wiki.url,
                    wikiId: wiki.id
                })),
                favoritedWikisLoading: false,
            })))
            .catch((err) => {
                console.error(err);
                setState((prevState) => ({
                    ...prevState,
                    favoritedWikisLoading: false,
                }));
            });
    }

    useEffect(() => {
        dispatch(toggleGreyBackground(true));

        return () => {
            scrollToTop();
            dispatch(toggleGreyBackground(false));
        };
    }, [dispatch, setState]);

    useEffect(() => {
        setState((prevState) => ({ ...prevState, loading: true }));

        const cancelTokenSource = Axios.CancelToken.source();

        findWikiByRouteAsync(wikiRoute, cancelTokenSource.token)
            .then((wiki) => setState((prevState) => ({
                ...prevState,
                wiki,
                loading: false,
            })))
            .catch((_) => setState((prevState) => ({
                ...prevState,
                loading: false,
            })));

        return () => cancelTokenSource.cancel();
    }, [wikiRoute]);

    useEffect(() => {
        if (!wikiId) {
            return;
        }

        const cancelTokenSource = Axios.CancelToken.source();

        fetchRecentlyVisitedWikiArticlesAsync("me", cancelTokenSource.token)
            .then((articles) => setState((prevState) => ({
                ...prevState,
                myRecent: articles.map((article) => ({
                    key: `me-${article.id}`,
                    iconName: "clock",
                    text: article.title,
                    url: article.url,
                })),
                myRecentLoading: false,
            })))
            .catch((err) => {
                console.error(err);
                setState((prevState) => ({
                    ...prevState,
                    myRecentLoading: false,
                }));
            });

        fetchWikiFavorites();

        fetchRecentlyVisitedWikiArticlesAsync("team", cancelTokenSource.token)
            .then((articles) => setState((prevState) => ({
                ...prevState,
                teamRecent: articles.map((article) => ({
                    key: `team-${article.id}`,
                    iconName: "clock",
                    text: article.title,
                    url: article.url,
                })),
                teamRecentLoading: false,
            })))
            .catch((err) => {
                console.error(err);
                setState((prevState) => ({
                    ...prevState,
                    teamRecentLoading: false,
                }));
            });

        return () => cancelTokenSource.cancel();
    }, [wikiId])

    const onFavoriteToggled = useCallback((msg) => {
        if (msg.objectType !== SpintrTypes.UberType.Wiki &&
            msg.objectType !== SpintrTypes.UberType.WikiArticle) {
            return;
        }

        fetchWikiFavorites();
    }, [setState]);

    useEffect(() => {
        subscribe("Object:Favorite", onFavoriteToggled);

        return () => unsubscribe("Object:Favorite", onFavoriteToggled);
    }, [subscribe, unsubscribe]);

    const actionMenuItems = useMemo(() => {
        if (!state.article) {
            return [];
        }

        const publicActions: Spintr.IActionMenuItem[] = [{
            text: localize(
                state.article.isFavourite ? "TaBortFranFavoriter" : "LaggTillIFavoriter"
            ),
            onClick: state.article.isFavourite
                ? () => {
                    api.delete(`/api/v1/favorites/${state.article.id}`).then((response) => {
                        setState((prevState) => ({
                            ...prevState,
                            article: {
                                ...prevState.article,
                                isFavourite: false,
                            },
                        }));
                    });
                }
            : () => {
                    api.post(`/api/v1/favorites`, {
                        id: state.article.id,
                    }).then((response) => {
                        setState((prevState) => ({
                            ...prevState,
                            article: {
                                ...prevState.article,
                                isFavourite: true,
                            },
                        }));
                    });
                },
                icon: state.article.isFavourite ? "FavoriteStarFill" : "FavoriteStar",
        }, {
            text: localize(state.article.isFollowing ? "StangAvNotiser" : "AktiveraNotiser"),
                onClick: state.article.isFollowing
                    ? () => {
                          api.delete(`/api/v1/follow/${state.article.id}`).then((response) => {
                              setState((prevState) => ({
                                  ...prevState,
                                  article: {
                                      ...prevState.article,
                                      isFollowing: false,
                                  },
                              }));
                          });
                      }
                    : () => {
                          api.post(`/api/v1/follow`, { id: state.article.id }).then((response) => {
                              setState((prevState) => ({
                                  ...prevState,
                                  article: {
                                      ...prevState.article,
                                      isFollowing: true,
                                  },
                              }));
                          });
                      },
                icon: "Flag",
        }, {
            icon: "Print",
            onClick: () => print(tenantName, state.article.name),
            text: localize("Skrivutsidan")
        }];

        const categories: Spintr.IActionMenuCategory[] = [{
            items: publicActions,            
        }];

        const hasAccess =
            hasElevatedPermissions ||
            state.article.wiki.allownewarticles ||
            state.article.wiki.allowedit;

        const editorActions: Spintr.IActionMenuItem[] = [];
        if (hasElevatedPermissions || state.article.wiki.allowedit) {
            editorActions.push(...[{
                onClick: () => history.push({
                    pathname: `/wikis/edit-article/${state.article.id}`,
                }),
                text: localize("RedigeraArtikel"),
            }, {
                onClick: () => history.push({
                    pathname: `/wikis/create-section/${state.article.id}`,
                }),
                text: localize("NySektion"),
            }]);
        }

        if (hasElevatedPermissions ||
            state.article.wiki.publisherId == currentUserId ||
            state.article.editor.id == currentUserId)
            {
                editorActions.push({
                    text: localize(
                        state.article.deleted ? "Aterstall" : "TaBort"
                    ),
                    onClick: () => {
                        dispatch(
                            setConfirmPopup({
                                isOpen: true,
                                message: state.article.deleted
                                    ? localize("ArDuSakerAttDuVillAterstallaDennaPost") + "?"
                                    : localize("ArDuSakerPaAttDuVillX").replace(
                                          "{{X}}",
                                          localize("RaderaDetta").toLowerCase()
                                      ),
                                onConfirm: () => {
                                    api.put(`/api/v1/wikis/article/toggledelete/${state.article.id}`).then((response) => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            article: {
                                                ...prevState.article,
                                                deleted: !prevState.article.deleted,
                                            },
                                            showDeleteArticleDialog: false,
                                        }));
                                    });
                                },
                            })
                        );
                    },
                });
            }

        if (hasAccess && (hasElevatedPermissions || !restrictWikis)) {
            categories.push({
                title: localize("Redaktor"),
                items: editorActions
            });
        }

        return categories;
    }, [state.article, hasElevatedPermissions, history, restrictWikis, tenantName]);

    const wikiUrl = useMemo(
        () => !state.article?.url
            ? "#"
            : "/" + state.article.url.aliasurl
                .split("/")
                .slice(0, 2)
                .join("/"),
        [state.article?.url],
    );

    const onSubmenuStateChanged = useCallback((isOpen: boolean) => setState((prevState) => ({
        ...prevState,
        submenuOpen: isOpen,
    })), [setState]);

    const onOpenSubmenuClicked = useMemo(() => onSubmenuStateChanged.bind(null, true), [onSubmenuStateChanged]);
    const onSubmenuDismissed = useMemo(() => onSubmenuStateChanged.bind(null, false), [onSubmenuStateChanged]);

    const breadcrumbItems = useMemo<Spintr.IBreadcrumbItem[]>(
        () => !state.article ? [] : [{
            key: "wiki-root",
            text: localize("Wikis"),
            link: "/wikis",
        }, {
            key: "wiki",
            text: state.article.wiki.title,
            link: wikiUrl,
        }, {
            key: "article",
            text: state.article.name,
            link: `/${state.article.url.aliasurl}`,
        }],
        [state.article, wikiUrl],
    );

    const renderSection = useCallback((
        section: Spintr.IWikiArticleSection,
    ): ReactElement => {    
        const allowChanges = hasElevatedPermissions || !restrictWikis;

        const canEdit = allowChanges && (
            hasElevatedPermissions ||
            state.article?.wiki?.allowedit ||
            section.editor.id === currentUserId
        );

        const canDelete = 
            hasElevatedPermissions ||
            section.editor.id === currentUserId;

        return (
            <div
                className="WikiArticleView-section"
                id={`section-${section.id}`}
                key={`section-${section.id}`}
            >
                <WikiSection
                    canDelete={canDelete}
                    canEdit={canEdit}
                    section={section}
                    onDelete={() => {
                        api.delete("/api/v1/wikis/delete/section", { params: { id: section.id } });
                        setState((prevState) => ({
                            ...prevState,
                            article: {
                                ...prevState.article,
                                sections: prevState.article.sections.filter(
                                    (s) => s.id !== section.id
                                ),
                            }
                        }));
                    }} />
            </div>
        );
    }, [state.article]);

    if (state.loading) {
        return <SpintrLoader />;
    }

    if (!state.article) {
        return null;
    }

    const wikiTypeName = localize("Ubertype15_0_4");

    const articleImage = !state.article.imgUrl ? null : (
        <div className="article-image-container">
            <Image className="article-image"
                   src={state.article.imgUrl} />
        </div>
    );

    const header = (
        <div className="WikiArticleView-header-content">
            <Heading
                className="WikiArticleView-heading"
                color="contentDark"
                weight="semiBold"
            >
                {state.article.name}
            </Heading>

            <SmallBody
                className="WikiArticleView-description"
                color="contentNormal"
            >
                {state.article.description
            }</SmallBody>
        </div>
    );

    const submenu = (
        <div className="menu-wrapper">
            <Submenu
                autoExpand={false}
                fetch={onSubmenuFetching}
                nameProp="name"
                search={submenuSearchProps} />
        </div>
    );

    const inPageHeader = viewMode >= SpintrTypes.ViewMode.SmallestLaptop ? header : null;
    const inPageSubmenu = viewMode >= SpintrTypes.ViewMode.SmallestLaptop
        ? submenu
        : (
            <UnstyledButton
                className="open-submenu-button"
                onClick={onOpenSubmenuClicked}
            >
                <Icon iconName="GlobalNavButton" />
                <SmallBody color="contentDark">
                    {localize("VisaUndermeny")}
                </SmallBody>
            </UnstyledButton>
        );

    const sidebarContent = (
        <Fragment>
            <SidebarContentListBox
                className="sidebar-box"
                items={state.myRecent}
                title={localize("RECENTLY_VISITED")} />
            <SidebarContentListBox
                className="sidebar-box pins-box"
                items={state.favoritedWikis}
                title={localize("GROUP_PINS")} />
            <SidebarContentListBox
                className="sidebar-box"
                items={state.teamRecent}
                title={localize("POPULAR_ARTICLES_TEAM")} />
        </Fragment>
    );

    const sidebar = viewMode >= SpintrTypes.ViewMode.SmallLaptop ? (
        <StackItem className="WikiArticleView-sidebar">
            {sidebarContent}
        </StackItem>
    ) : (
        <Drawer
            className="WikiArticleView-drawer"
            open={state.submenuOpen}
            onOpenChange={onSubmenuStateChanged}
        >
            <DrawerHeader>
                <DrawerHeaderTitle action={
                    <UnstyledButton
                        className="close-button"
                        onClick={onSubmenuDismissed}
                        title={localize("Stang")}
                    >
                        <Icon iconName="ChromeClose" />
                    </UnstyledButton>
                }>
                    {state.article?.name || ""}
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                {header}
                {submenu}
                    <StackItem className="WikiArticleView-sidebar">
            {sidebarContent}
        </StackItem>
            </DrawerBody>
        </Drawer>
    )

    return (
        <div className="WikiArticleView">
            <Helmet>
                <title>{state.article.name}</title>
            </Helmet>
            <Stack className="WikiArticleView-stack" horizontal={true}>
                <section id="WikiArticleView-section">
                    <header className="WikiArticleView-header">
                        <Stack
                            className="WikiArticleView-navigation"
                            horizontal={true}
                            verticalAlign="center"
                        >
                            <StackItem style={{ display: "none" }}>
                                <Link to={wikiUrl} className="wiki-link">
                                    <Visage2Icon
                                        className="back-icon"
                                        icon="arrow-left-2"
                                        size="small" />
                                    <Heading color="contentDark" weight="semiBold" className="back-text">
                                        {wikiTypeName.substring(0, 1).toUpperCase() + wikiTypeName.substring(1)}
                                    </Heading>
                                </Link>
                            </StackItem>
                            <StackItem className="WikiArticleView-breadcrumbs">
                                <Breadcrumbs
                                    items={breadcrumbItems}
                                />
                            </StackItem>
                        </Stack>

                        {inPageHeader}

                        <div className="WikiArticleView-header-actions">
                            <ActionMenu
                                canReport
                                hasReportIcon
                                objectId={state.article.id}
                                categories={actionMenuItems}
                            />
                        </div>
                    </header>

                    <Stack
                        className="WikiArticleView-content"
                        horizontal={viewMode >= SpintrTypes.ViewMode.SmallestLaptop}>
                        <Stack
                            tokens={{ childrenGap: 12 }}>

                            {viewMode >= SpintrTypes.ViewMode.SmallestLaptop &&
                                <StackItem className="WikiArticleView-metadata">
                                    <ContentMetadataBox
                                        className="article-info"
                                        contentId={state.article.id}
                                        contentManager={{
                                            departmentName: state.article.editor.info,
                                            id: state.article.editor.id,
                                            name: state.article.editor.name,
                                            imageUrl: state.article.editor.imageUrl,
                                        }}
                                        createdAt={new Date(state.article.created)}
                                        lastModified={new Date(state.article.lastchange)}
                                        showReach={true}
                                        links={state.article.links} />

                                    {/* AI stuff */}
                                </StackItem>
                            }

                            <StackItem className="WikiArticleView-outline">
                                {inPageSubmenu}
                            </StackItem>
                        </Stack>
                        <StackItem className="WikiArticleView-sections">
                            {articleImage}
                            {(state.article.sections || []).map(renderSection)}
                        </StackItem>
                        {viewMode < SpintrTypes.ViewMode.SmallestLaptop &&
                            <StackItem className="WikiArticleView-metadata">
                                <ContentMetadataBox
                                    className="article-info"
                                    contentId={state.article.id}
                                    contentManager={{
                                        departmentName: state.article.editor.info,
                                        id: state.article.editor.id,
                                        name: state.article.editor.name,
                                        imageUrl: state.article.editor.imageUrl,
                                    }}
                                    createdAt={new Date(state.article.created)}
                                    lastModified={new Date(state.article.lastchange)}
                                    showReach={true}
                                    links={state.article.links} />
                                {/* AI stuff */}
                            </StackItem>
                        }

                    </Stack>
                </section>
                {sidebar}
            </Stack>
        </div>
    );
}

export default WikiArticleView;
