import React, { Fragment, ReactElement } from "react";
import { SpintrTypes } from "src/typings";
import { PlannerItemDetailProps } from "./PlannerItemDetail.types";
import { Conditional } from "src/components/Conditional";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { CaptionBolder, SmallBody } from "src/components/Text";
import { localize } from "src/l10n";
import { getInventoryMethodText } from "../utils";
import { PlannerFileList } from "../PlannerFileList";

function PlannerInventoryDetails({ item }: PlannerItemDetailProps): ReactElement {
    if (item.itemType !== SpintrTypes.PlannerItemType.Inventory) {
        return null;
    }

    const displayInfoBox = (
        (item.warehouseLocation && item.warehouseLocation.length > 0) ||
        item.inventoryMethod ||
        (item.inventoryManager && item.inventoryManager.length > 0)
    );

    return (
        <Fragment>
            
            <Conditional condition={displayInfoBox}>
                <section className="PlannerItemDetail-infoBlock">
                    <div className="PlannerItemDetail-infoBlock-content">

                        <Conditional condition={item.warehouseLocation && item.warehouseLocation.length > 0}>
                            <div className="PlannerItemDetail-infoBlock-property">
                                <div className="PlannerItemDetail-infoBlock-property-label">
                                    <Visage2Icon icon="tag" color="spintrGrey" size="medium" />
                                    <CaptionBolder color="contentDark">
                                        {localize("PLANNER_INVENTORY_LOCATION")}
                                    </CaptionBolder>
                                </div>
                                <div className="PlannerItemDetail-infoBlock-property-value">
                                    <SmallBody color="contentNormal">
                                        {item.warehouseLocation}
                                    </SmallBody>
                                </div>
                            </div>
                        </Conditional>

                        <Conditional condition={item.inventoryMethod}>
                            <div className="PlannerItemDetail-infoBlock-property">
                                <div className="PlannerItemDetail-infoBlock-property-label">
                                    <Visage2Icon icon="tag" color="spintrGrey" size="medium" />
                                    <CaptionBolder color="contentDark">
                                        {localize("PLANNER_INVENTORY_METHOD")}
                                    </CaptionBolder>
                                </div>
                                <div className="PlannerItemDetail-infoBlock-property-value">
                                    <SmallBody color="contentNormal">
                                        {getInventoryMethodText(item.inventoryMethod)}
                                    </SmallBody>
                                </div>
                            </div>
                        </Conditional>

                        <Conditional condition={item.inventoryManager && item.inventoryManager.length > 0}>
                            <div className="PlannerItemDetail-infoBlock-property">
                                <div className="PlannerItemDetail-infoBlock-property-label">
                                    <Visage2Icon icon="tag" color="spintrGrey" size="medium" />
                                    <CaptionBolder color="contentDark">
                                        {localize("PLANNER_INVENTORY_MANAGER")}
                                    </CaptionBolder>
                                </div>
                                <div className="PlannerItemDetail-infoBlock-property-value">
                                    <SmallBody color="contentNormal">
                                        {item.inventoryManager}
                                    </SmallBody>
                                </div>
                            </div>
                        </Conditional>
                    </div>
                </section>
            </Conditional>

            <PlannerFileList
                fieldType={SpintrTypes.PlannerInventoryFileType.InventoryPlan}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Inventory} 
                title={localize("PLANNER_INVENTORY_PLAN")}
                text={item.inventoryPlan} />
    
            <PlannerFileList
                fieldType={SpintrTypes.PlannerInventoryFileType.InventoryReport}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Inventory} 
                title={localize("PLANNER_INVENTORY_REPORT")}
                text={item.inventoryReport} />

        </Fragment>
    );
}

export default PlannerInventoryDetails;
