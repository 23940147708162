export enum SystemStatusActionTypes {
    SetCurrentResource = "SYSTEM_STATUS_SET_CURRENT_RESOURCE",
    AddPrioritySystemStatus = "SYSTEM_STATUS_ADD_PRIORITY_STATUS",
    FetchPrioritySystemStatuses = "SYSTEM_STATUS_FETCH_PRIORITY_SYSTEM_STATUSES",
    FetchPrioritySystemStatusesPending = "SYSTEM_STATUS_FETCH_PRIORITY_SYSTEM_STATUSES_PENDING",
    FetchPrioritySystemStatusesRejected = "SYSTEM_STATUS_FETCH_PRIORITY_SYSTEM_STATUSES_REJECTED",
    FetchPrioritySystemStatusesFulfilled = "SYSTEM_STATUS_FETCH_PRIORITY_SYSTEM_STATUSES_FULFILLED",
    PostSystemStatusViewed = "SYSTEM_STATUS_POST_VIEWED",
    PostSystemStatusViewedPending = "SYSTEM_STATUS_POST_VIEWED_PENDING",
    PostSystemStatusViewedRejected = "SYSTEM_STATUS_POST_VIEWED_REJECTED",
    PostSystemStatusViewedFulfilled = "SYSTEM_STATUS_POST_VIEWED_FULFILLED",
};

export default SystemStatusActionTypes;