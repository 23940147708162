export enum ConversationActionTypes {
    QueryConversations = "QUERY_CONVERSATIONS",
    QueryConversationsPending = "QUERY_CONVERSATIONS_PENDING",
    QueryConversationsRejected = "QUERY_CONVERSATIONS_REJECTED",
    QueryConversationsFulfilled = "QUERY_CONVERSATIONS_FULFILLED",

    GetConversation = "GET_CONVERSATION",
    GetConversationPending = "GET_CONVERSATION_PENDING",
    GetConversationRejected = "GET_CONVERSATION_REJECTED",
    GetConversationFulfilled = "GET_CONVERSATION_FULFILLED",

    AddRemoteConversation = "CONVERSATION_ADD_REMTOTE",

    SetTabState = "CONVERSATION_SET_TAB_STATE",

    CreateConversation = "CONVERSATION_CREATE",
    CreateConversationFulfilled = "CONVERSATION_CREATE_FULFILLED",
    CreateConversationPeding = "CONVERSATION_CREATE_PENDING",
    CreateConversationRejected = "CONVERSATION_CREATE_REJECTED",

    MarkAsRead = "CONVERSATION_MARK_READ",
    MarkAsReadFulfilled = "CONVERSATION_MARK_READ_FULFILLED",
    MarkAsReadPending = "CONVERSATION_MARK_READ_PENDING",
    MarkAsReadRejected = "CONVERSATION_MARK_READ_REJECTED",

    LastReadReceived = "CONVERSATION_LAST_READ_RX",
    AddIsTyping = "ADD_IS_TYPING",
    RemoveIsTyping = "REMOVE_IS_TYPING",

    UpdateGroupActivity = "UPDATE_GROUP_ACTIVITY",
    UpdateGroupActivityPending = "UPDATE_GROUP_ACTIVITY_PENDING",

    SetUnreadCountPersonConversations = "SET_UNREAD_COUNT_PERSON_CONVERSATIONS",
    SetUnreadCountGroupConversations = "SET_UNREAD_COUNT_GROUP_CONVERSATIONS",

    SetComposerReplyMessage = "SET_COMPOSER_REPLY_MESSAGE",
    SetComposerEditMessage = "SET_COMPOSER_EDIT_MESSAGE",

    UpdateConversation = "UPDATE_CONVERSATION"
}
