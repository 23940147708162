import React, { ReactElement } from "react";
import { MetadataFieldProsp } from "./MetadataField.types";
import { Stack, StackItem } from "@fluentui/react";
import { CaptionBolder } from "../Text";
import classNames from "classnames";

function MetadataField(props: MetadataFieldProsp): ReactElement {
    const {
        children,
        label,
        labelBlock,
    } = props;

    return (
        <Stack
            className={classNames("MetadataField", {
                "singleLine": !labelBlock,
            })}
            horizontal={!labelBlock}
        >
            <StackItem className="MetadataField-fieldName">
                <CaptionBolder
                    as="label"
                    className="MetadataField-label"
                    color={labelBlock ? "contentLight" : "contentNormal"}
                >
                    {label}
                </CaptionBolder>
            </StackItem>
            <StackItem className="MetadataField-fieldValue">
                <CaptionBolder
                    as="div"
                    color="contentDark"
                    className="MetadataField-content"
                >
                    {children}
                </CaptionBolder>
            </StackItem>
        </Stack>
    );
}

export default MetadataField;
