import React, { useMemo } from 'react';
import "./ComposerAttachmentExternalFile.scss";
import { Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { SpintrTypes } from 'src/typings';
import { ExternalFile } from 'src/chat';
import { localize } from 'src/l10n';

interface IProps {
    object: any;
    onRemove: () => void;
}

const ComposerAttachmentExternalFile = (props: IProps) => {
    return (
        <div className="ComposerAttachmentExternalFile">
            <ExternalFile
                key={props.object.tmpId}
                hideActions
                externalFile={props.object.data} />
            {props.onRemove && (
                <UnstyledButton className="close-button" onClick={props.onRemove} title={localize("TaBort")}>
                    <Visage2Icon icon="close-circle" />
                </UnstyledButton>
            )}
        </div>
    )
}

export default ComposerAttachmentExternalFile;
