import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { localize } from "src/l10n";
import { VisageSidebarDrawerId, VisageSidebarMode, setSidebarDrawerId, setSidebarMode } from "src/sidebar";
import api from "src/spintr/SpintrApi";
import { ContentWithInfoPanel, Label, Loader, PageHeader, SpintrUser, UnstyledButton, setDisableWrapperBackground } from "src/ui";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";
import "./SystemStatusView.scss";
import { SocialFeedContainer, SocialFeedType, visage2SocialPostTypes } from "src/social-feed";
import { SpintrTypes } from "src/typings";
import { Icon, Stack, StackItem } from "@fluentui/react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import classNames from "classnames";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Style } from "src/ui/helpers";
import { setCurrentResource } from "./actions";
import { InlineComposer } from "src/spintr/components/Composer";
import { circleSmall } from "src/ui/helpers/style";

interface IRouteParams {
    id: string;
}

interface IProps {
    currentUser?: Spintr.IActiveUser;
    resource?: Spintr.ISystemStatusResource;
    viewMode?: SpintrTypes.ViewMode;
    isSmallViewMode?: boolean;
}

const SystemStatusView: FunctionComponent<IProps> = (props) => {
    const dispatch = useDispatch();
    const params = useParams<IRouteParams>();
    const [searchText, setSearchText] = useState<string>();
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>();
    const [filterType, setFilterType] = useState<SpintrTypes.SystemStatusType>();
    const [archiveCount, setArchiveCount] = useState<any[]>();
    const [displaySystemInfo, setDisplaySystemInfo] = useState<boolean>(!props.isSmallViewMode);
    const [feedKey, setFeedKey] = useState(0);
    const previousResource = useRef<Spintr.ISystemStatusResource>();
    const resource = props.resource;
    const id = parseInt(params.id, 10);

    const searchTextSubject = useMemo(() => new Subject<string>(), []);
    useEffect(() => {
        dispatch(setCurrentResource(undefined));
        setFilterType(undefined);
        setSearchText("");
        setArchiveCount(undefined);

        api.get("/api/v1/systemstatuses/resources/" + id).then((response) => {
            const resource = response.data;

            if (resource.faq.length === 1) {
                resource.faq[0].expanded = true;
            }

            dispatch(setCurrentResource(resource));
        });
    }, [id]);

    useEffect(() => {
        dispatch(setSidebarMode(VisageSidebarMode.drawer));
        dispatch(setSidebarDrawerId(VisageSidebarDrawerId.systemStatus));
        dispatch(setDisableWrapperBackground(true));

        const subscription = searchTextSubject.pipe(debounceTime(250)).subscribe({
            next: (text) => {
                setDebouncedSearchText(text);
            },
        });

        return () => {
            dispatch(setDisableWrapperBackground(false));
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (
            resource?.id &&
            resource.id === previousResource?.current?.id &&
            (resource.title !== previousResource.current.title ||
                resource.imageUrl !== previousResource.current.imageUrl)
        ) {
            // Reloads the feed component
            setFeedKey(feedKey + 1);
        }

        if (resource !== undefined) {
            previousResource.current = resource;
        }
    }, [resource]);

    const onFilterType = (type: SpintrTypes.SystemStatusType) => {
        setFilterType(type);
    };

    const toggleSystemInfo = () => {
        setDisplaySystemInfo(!displaySystemInfo);
    };

    const toggleExpanded = (idx) => {
        const tempResource = {
            ...resource,
            faq: [...resource.faq],
        };

        tempResource.faq[idx] = {
            ...tempResource.faq[idx],
            expanded: !tempResource.faq[idx].expanded,
        };

        dispatch(setCurrentResource(tempResource));
    };

    const renderSystemInfo = () => (
        <div className="right">
            <div className="system-info">
                <Stack className="header" horizontal horizontalAlign="space-between" verticalAlign="center">
                    <Label size="h4" weight="medium">{localize("SYSTEM_INFORMATION")}</Label>
                    {props.isSmallViewMode && (
                        <UnstyledButton onClick={toggleSystemInfo}>
                            <Icon iconName="ChromeClose" />
                        </UnstyledButton>
                    )}
                </Stack>
                <div className="content">
                    {resource.description && (
                        <div className="description">
                            <Label size="h6" color="mid-grey" weight="medium" className="title">
                                {localize("Beskrivning")}
                            </Label>
                            <Label size="body-2" color="grey">
                                {resource.description}
                            </Label>
                        </div>
                    )}
                    <div className="owners content-box">
                        <Label size="h6" color="mid-grey" weight="medium" className="title">
                            {localize("SYSTEM_OWNERS")}
                        </Label>
                        {resource.owners.map((owner) => (
                            <SpintrUser
                                key={owner.id}
                                name={owner.name}
                                subText={owner.info}
                                imageUrl={owner.image}
                                nameLink={"/profile/" + owner.id}
                                personalName
                            />
                        ))}
                    </div>
                    {/* <div className="archive content-box">
                        <Label as="span" size="h6" color="mid-grey" weight="medium">
                            {localize("SYSTEM_ARCHIVE")}
                        </Label>
                        {filterTypes.map((ft) => (
                            <UnstyledButton
                                className={classNames({ active: filterType === ft.type })}
                                onClick={onFilterType}
                                onClickData={ft.type}
                                key={ft.type || 0}
                            >
                                <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                                    <Label
                                        size="h6"
                                        color={filterType === ft.type ? "light-blue" : "dark-grey"}
                                        weight={filterType === ft.type ? "semi-bold" : "normal"}
                                    >
                                        {ft.name}
                                    </Label>
                                    <Label size="h6" weight={filterType === ft.type ? "semi-bold" : "normal"}>
                                        {ft.count}
                                    </Label>
                                </Stack>
                            </UnstyledButton>
                        ))}
                    </div> */}
                    {resource.faq.length > 0 && (
                        <div className="faq">
                            {resource.faq.map((faq, idx) => (
                                <div key={faq.id} className={faq.expanded ? "expanded" : "minimized"}>
                                    <UnstyledButton onClick={() => toggleExpanded(idx)}>
                                        <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                                            <Label size="h6" color="dark-grey" weight="medium">
                                                {faq.question}
                                            </Label>
                                            <Visage2Icon icon={faq.expanded ? "minus" : "add"} color="black" />
                                        </Stack>
                                    </UnstyledButton>

                                    {faq.expanded && (
                                        <Label size="h6" color="grey" className="answer">
                                            {faq.answer}
                                        </Label>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    const filterTypes = useMemo(
        () =>
            resource || archiveCount
                ? [
                      {
                          name: localize("Alla"),
                          count: resource
                              ? resource.ongoingCount + resource.plannedCount + resource.doneCount
                              : archiveCount
                              ? archiveCount.reduce((acc, ac) => acc + ac.count, 0)
                              : 0,
                      },
                      {
                          name: localize("Pagaende"),
                          type: SpintrTypes.SystemStatusType.Ongoing,
                          count: resource
                              ? resource.ongoingCount
                              : archiveCount
                              ? archiveCount.find((ac) => ac.type === SpintrTypes.SystemStatusType.Ongoing)?.count || 0
                              : 0,
                      },
                      {
                          name: localize("Planerade"),
                          type: SpintrTypes.SystemStatusType.Planned,
                          count: resource
                              ? resource.plannedCount
                              : archiveCount
                              ? archiveCount.find((ac) => ac.type === SpintrTypes.SystemStatusType.Planned)?.count || 0
                              : 0,
                      },
                      {
                          name: localize("Avklarade"),
                          type: SpintrTypes.SystemStatusType.Done,
                          count: resource
                              ? resource.doneCount
                              : archiveCount
                              ? archiveCount.find((ac) => ac.type === SpintrTypes.SystemStatusType.Done)?.count || 0
                              : 0,
                      },
                  ]
                : [],
        [resource?.ongoingCount, resource?.plannedCount, resource?.doneCount, archiveCount]
    );

    if (id && !resource) {
        return <Loader />;
    }

    return (
        <div className="SystemStatusView">
            <ContentWithInfoPanel
                visagePageStyle
                template={1}
                renderInfoPanel={() => {
                    return renderSystemInfo();
                }}
            >
                <div className="left">
                    <div className="header">
                        <PageHeader
                            renderTitle={() => {
                                return (
                                    <SpintrUser
                                        name={resource.title}
                                        subText={(resource.doneCount + resource.ongoingCount + resource.plannedCount) + " " + localize("Poster").toLowerCase()}
                                        imageUrl={resource?.imageUrl}
                                        personalName={false}
                                    />
                                )
                            }}
                            buttons={[{
                                key: "add",
                                text: props.isSmallViewMode ?
                                    localize("Skapa") :
                                    localize("START_COMPOSER_PLACEHOLDER_TEXT_SYSTEM_STATUS").replace("{{X}}", resource?.title),
                                onClick: () => {
                                    const inlineCompsoerButtonEl = document.querySelector("#InlineComposerButton") as HTMLElement;

                                    if (inlineCompsoerButtonEl) {
                                        inlineCompsoerButtonEl.click();
                                    }
                                },
                                iconProps: { iconName: "Add" },
                                className: "commandBarAddButton",
                                theme: "primary"
                            }]}
                        />
                        <InlineComposer
                            key={feedKey}
                            feedId={
                                resource?.feedId || props.currentUser.systemStatusResources?.[0]?.feedId
                            }
                            identity={
                                resource?.userCanPost
                                    ? {
                                            id:
                                                resource?.id ||
                                                props.currentUser.systemStatusResources?.[0]?.id,
                                            name:
                                                resource?.title ||
                                                props.currentUser.systemStatusResources?.[0]?.title,
                                            images: {
                                                feedComposer:
                                                    resource?.imageUrl ||
                                                    props.currentUser.systemStatusResources?.[0]
                                                        ?.imageUrl,
                                            },
                                        }
                                    : undefined
                            }
                            placeholderColor="dark-grey"
                            type={
                                resource?.userCanPost
                                    ? visage2SocialPostTypes.find(
                                            (t) => t.type === SpintrTypes.StatusType.SystemStatus
                                        )
                                    : undefined
                            }
                            spintrUserSize={circleSmall}
                            hidePostTypes
                        />
                    </div>
                    <div className="feed">
                        <SocialFeedContainer
                            key={feedKey}
                            feedId={resource?.feedId}
                            feedType={SocialFeedType.SystemStatus}
                            systemStatusType={filterType}
                            searchText={debouncedSearchText}
                            emptyText={localize("EMPTY_SYSTEM_STATUS")}
                        />
                    </div>
                </div>
            </ContentWithInfoPanel>
        </div>
    );
};

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    currentUser: state.profile.active,
    resource: state.systemStatus.currentResource,
    viewMode: state.ui.viewMode,
    isSmallViewMode: state.ui.isSmallViewMode,
});

export default connect(mapStateToProps)(SystemStatusView);
