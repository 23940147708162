import { SpintrTypes } from "src/typings";
import GroupChatView from "./views/GroupChatView";
import GroupFeedView from "./views/GroupFeedView";
import GroupPlanningView from "./views/planning/GroupPlanningView";
import GroupNotesView from "./views/GroupNotesView";
import GroupFilesView from "./views/GroupFilesView";
import GroupCalendarView from "./views/GroupCalendarView";
import GroupMembersView from "./views/GroupMembersView";
import { GroupSupportView } from "./views/GroupSupportView";

export const groupFunctions = [
    {
        icon: "message",
        text: "Chatt",
        route: "chat",
        component: GroupChatView,
        id: SpintrTypes.GroupFunction.Chat,
    },
    {
        icon: "heart",
        component: GroupSupportView,
        id: SpintrTypes.GroupFunction.Support,
        route: `support`,
        text: "Support",
    },
    {
        icon: "task",
        text: "Planering",
        route: "planning",
        component: GroupPlanningView,
        id: SpintrTypes.GroupFunction.Planning,
    },
    {
        icon: "document",
        text: "appNotes",
        route: "notes",
        component: GroupNotesView,
        id: SpintrTypes.GroupFunction.Notes,
    },
    {
        icon: "folder",
        text: "Filer",
        route: "files",
        component: GroupFilesView,
        id: SpintrTypes.GroupFunction.Files,
    },
    {
        icon: "calendar",
        text: "Kalender",
        route: "calendar",
        component: GroupCalendarView,
        id: SpintrTypes.GroupFunction.Calendar,
    },
    {
        icon: "people",
        text: "Medlemmar",
        route: "members",
        component: GroupMembersView,
        id: SpintrTypes.GroupFunction.Members,
    },
    {
        icon: "bookmark",
        text: "SocialtFlode",
        route: "feed",
        component: GroupFeedView,
        id: SpintrTypes.GroupFunction.Feed,
    },
];