import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import "./Composer.scss";
import { connect, useDispatch } from 'react-redux';
import { IApplicationState } from 'src/spintr/reducer';
import { IActiveUserProfile } from 'src/profile/reducer';
import { circleSmall } from 'src/ui/helpers/style';
import { Label, SpintrUser, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { localize } from 'src/l10n';
import InteractiveTextInput from 'src/spintr/components/InteractiveTextInput';
import ComposerAttachmentSelector from '../ComposerAttachmentSelector/ComposerAttachmentSelector';
import { saveMessage, setComposerEditMessage, setComposerReplyMessage, updateMessage } from 'src/chat/redux';
import generateUniqueId, { generateUniqueNumberId } from 'src/utils/uniqueId';
import api from 'src/spintr/SpintrApi';
import { generateUuid, uniqueId } from 'src/utils';
import ComposerInfo from '../ComposerInfo/ComposerInfo';
import ComposerMemoHeader from '../ComposerMemoHeader/ComposerMemoHeader';
import ComposerMemoTitle from '../ComposerMemoTitle/ComposerMemoTitle';
import { SpintrTypes } from 'src/typings';
import axios, { AxiosResponse } from 'axios';
import FilePicker from 'src/spintr/components/FilePicker';
import ComposerMemoTodoList from '../ComposerMemoTodoList/ComposerMemoTodoList';
import ComposerAttachments from '../ComposerAttachments/ComposerAttachments';
import SpintrLoader from 'src/ui/components/Loader';

interface IProps {
    conversationId: number;
    conversation: Spintr.IConversation;
    compact?: boolean;
    currentUser?: IActiveUserProfile;
    isSmallViewMode?: boolean;
    messageToEdit?: Spintr.IChatMessage;
    messageToReplyTo?: Spintr.IChatMessage;
    blocked?: boolean;
    blockedBy?: boolean;
}

interface IState {
    content: string;
    hasContent: boolean;
    compact: boolean;
    lastIsTypingPost: Date;
    inputKey: string;
    isMemo: boolean;
    isTodoMemo: boolean;
    memoTitle: string;
    attachments: Spintr.IChatMessageAttachment[];
    objects: any[];
    isFilePickerOpen: boolean;
    isLoading: boolean;
}

interface IUploadAttachmentsResult {
    attachments: Spintr.IChatMessageAttachment[];
    objects: any[];
}

const getIntitialState = (props: IProps) => {
    return {
        content: "",
        hasContent: false,
        compact: props.compact || props.isSmallViewMode,
        lastIsTypingPost: undefined,
        inputKey: generateUniqueId(),
        isMemo: false,
        isTodoMemo: false,
        memoTitle: "",
        attachments: [],
        objects: [],
        isFilePickerOpen: false,
        isLoading: false
    }
}

const Composer = (props: IProps) => {
    const textInputRef = useRef<InteractiveTextInput>();
    const fileInputRef = useRef<HTMLInputElement>();

    const dispatch = useDispatch();

    const fileSources = useMemo(() => {
        let fileSources = [
            SpintrTypes.FolderRootSource.Upload,
            SpintrTypes.FolderRootSource.SpintrPrivate,
            SpintrTypes.FolderRootSource.SpintrPublic,
            SpintrTypes.FolderRootSource.Office365Public
        ];

        return fileSources;
    }, []);

    const [state, setState] = useState<IState>(getIntitialState(props));

    useEffect(() => {
        const onPaste = (e: ClipboardEvent) => {
            const files = Array.from(e.clipboardData.items).filter(x => x.kind === "file").map(x => x.getAsFile());

            if (files.length > 0) {
                onLocalFilesSelected(files);
            }
        }

        document.addEventListener('paste', onPaste);

        return () => {
            document.removeEventListener('paste', onPaste);
        };
    }, []);

    const postAttachments = useCallback((): Promise<IUploadAttachmentsResult> => {
        return new Promise((resolve, reject) => {
            const promises = [];
            const types = [];

            const result: IUploadAttachmentsResult = {
                attachments: [],
                objects: []
            }

            for (let a of state.attachments) {
                if (a.id && a.id > 0) {
                    result.attachments.push(a);
                    continue;
                }

                types.push(SpintrTypes.UberType.File);
                let body = new FormData();

                body.append("type", "4");
                body.append("file", a.data);
                body.append("ticket", new Date().getTime().toString() + uniqueId());
                body.append("conversationId", props.conversationId.toString());

                // if (props.conversation.group) {
                //     body.append("feedId", props.conversation.group.feedId.toString());
                //     body.append("folderId", props.conversation.group.folderId.toString());
                // }

                promises.push(api.post("/api/uploads", body));
            }

            for (let o of state.objects) {
                switch (o.type) {
                    case SpintrTypes.UberType.Milestone:
                        types.push(SpintrTypes.UberType.Milestone);

                        const request = !!o.id ?
                            api.put("/api/v1/groupmilestones/" + o.id, {
                                id: o.id,
                                groupId: props.conversation.group.id,
                                title: "Memo",
                                todos: o.todos
                            }) :
                            api.post("/api/v1/groupmilestones", {
                                id: o.id,
                                groupId: props.conversation.group.id,
                                title: "Memo",
                                todos: o.todos
                            });

                        promises.push(request);
                        break;
                    case SpintrTypes.UberType.ExternalFile:
                        if (o.id) {
                            result.objects.push(o);
                            break;
                        }

                        types.push(SpintrTypes.UberType.ExternalFile);

                        promises.push(api.post("/api/v1/conversations/" + props.conversationId + "/externalfile", {
                            conversationType: props.conversation.type,
                            file: {
                                id: o.data.id,
                                source: o.data.source
                            }
                        }));

                        break;
                    default:
                        result.objects.push(o);
                        break;
                }
            }

            if (promises.length === 0) {
                return resolve(result);
            }

            axios.all(promises).then((responses) => {
                for (let i = 0; i < responses.length; i++) {
                    switch (types[i]) {
                        case SpintrTypes.UberType.Milestone:
                            result.objects.push(responses[i].data);
                            break;
                        case SpintrTypes.UberType.ExternalFile:
                            result.objects.push(responses[i].data);
                            break;
                        default:
                            result.attachments.push({
                                id: responses[i].data.Result.fileId,
                                name: responses[i].data.Result.fileName,
                                size: responses[i].data.Result.fileSize,
                                typeId: responses[i].data.Result.fileType,
                                path: responses[i].data.Result.fileUrl,
                                thumbnailUrl: responses[i].data.Result.thumbnailUrl,
                            });
                            break;
                    }
                }

                resolve(result);
            }).catch(() => {
                reject();
            });
        });
    }, [state.attachments, state.objects, props.conversation]);

    const postMessage = useCallback(() => {
        if (state.isLoading) {
            return null;
        }

        if (state.attachments.length > 0) {
            setState((s: IState) => {
                return {
                    ...s,
                    isLoading: true
                }
            });
        }

        postAttachments().then((uploadAttachmentsResult) => {
            const message: Spintr.IChatMessage = {
                id: props.messageToEdit ? props.messageToEdit.id : (generateUniqueNumberId() * -1),
                conversationId: props.conversationId,
                parentId: props.messageToReplyTo?.id || 0,
                date: new Date(),
                text: state.content,
                replyCount: 0,
                replies: [],
                attachments: uploadAttachmentsResult.attachments,
                objects: uploadAttachmentsResult.objects,
                reactions: [],
                user: {
                    id: props.currentUser.id,
                    name: props.currentUser.name,
                    imageUrl: props.currentUser.images["Message"],
                    info: "",
                    typeName: "",
                    type: 1
                },
                referenceId: generateUuid(),
                isMemo: state.isMemo,
                memoTitle: state.memoTitle
            };

            if (props.messageToEdit) {
                dispatch(updateMessage(message));
            } else {
                dispatch(saveMessage(message, props.currentUser.id));
            }

            dispatch(setComposerReplyMessage(undefined, props.conversationId));
            dispatch(setComposerEditMessage(undefined, props.conversationId));

            setState(getIntitialState(props));
        }).catch(() => { });
    }, [textInputRef, state.content, state.memoTitle, state.isMemo, state.attachments, state.objects, state.isLoading]);

    const onChange = useCallback(() => {
        if (!textInputRef.current) {
            return;
        }

        const content = textInputRef.current.getContent();

        const hasChanged = state.content !== content;

        setState((s: IState) => {
            return {
                ...s,
                content,
                hasContent: !!content
            }
        });

        const now = new Date();

        if (state.hasContent && hasChanged && (!state.lastIsTypingPost || now.getTime() - state.lastIsTypingPost.getTime() > 2000)) {
            api.post("/api/conversations/" + props.conversationId + "/typing").then(() => {}).catch(() => {});

            setState((s: IState) => {
                return {
                    ...s,
                    lastIsTypingPost: now
                }
            });
        }
    }, [textInputRef, state.hasContent, state.lastIsTypingPost, props.messageToEdit, props.messageToReplyTo]);

    const onTodoClick = useCallback(() => {
        if (state.objects.some(x => x.type === SpintrTypes.UberType.Milestone)) {
            return;
        }

        setState((s: IState) => {
            return {
                ...s,
                isMemo: true,
                isTodoMemo: true,
                objects: [
                    ...s.objects,
                    {
                        id: 0,
                        tmpId: generateUniqueNumberId(),
                        type: SpintrTypes.UberType.Milestone,
                        todos: []
                    }
                ]
            }
        });
    }, [state.attachments, state.objects]);

    const onMemoClick = useCallback(() => {
        setState((s: IState) => {
            return {
                ...s,
                isMemo: true,
                isTodoMemo: false,
                objects: s.objects.filter(x => x.type !== SpintrTypes.UberType.Milestone)
            }
        });
    }, [state.isMemo, state.attachments, state.objects]);

    const onFileClick = useCallback(() => {
        setState((s: IState) => {
            return {
                ...s,
                isFilePickerOpen: true
            }
        });
    }, [state.isFilePickerOpen]);

    const onFilePickerClose = useCallback(() => {
        setState((s: IState) => {
            return {
                ...s,
                isFilePickerOpen: false
            }
        });
    }, [state.isFilePickerOpen])

    const onImageClick = useCallback(() => {
        fileInputRef.current.click();
    }, [state.attachments, state.objects]);

    const onVideoClick = useCallback(() => {

    }, [state.attachments, state.objects]);

    const onAudioClick = useCallback(() => {
        setState((s: IState) => {
            return {
                ...s,
                attachments: [
                    ...s.attachments,
                    {
                        id: 0,
                        tmpId: generateUniqueNumberId(),
                        typeId: SpintrTypes.FileType.Aac,
                        name: "",
                        size: 0,
                        path: ""
                    },
                ]
            }
        });
    }, [state.attachments, state.objects]);

    const onMemoTitleChange = useCallback((value: string) => {
        setState((s: IState) => {
            return {
                ...s,
                memoTitle: value
            }
        });
    }, [state.memoTitle]);

    const onMemoClose = useCallback(() => {
        setState((s: IState) => {
            return {
                ...s,
                isMemo: false,
                isTodoMemo: false,
                objects: s.objects.filter(x => x.type !== SpintrTypes.UberType.Milestone)
            }
        });
    }, [state.attachments, state.objects]);

    const onImagesSelected = useCallback((files) => {
        setState((s: IState) => {
            return {
                ...s,
                attachments: [
                    ...s.attachments,
                    ...files.map((file: any) => ({
                        tmpId: generateUniqueNumberId(),
                        typeId: SpintrTypes.FileType.Png,
                        data: file
                    }))
                ]
            }
        });
    }, [state.attachments, state.objects]);

    const onLocalFilesSelected = useCallback((files) => {
        setState((s: IState) => {
            return {
                ...s,
                isFilePickerOpen: false,
                attachments: [
                    ...s.attachments,
                    ...files.map((file: any) => ({
                        tmpId: generateUniqueNumberId(),
                        typeId: file.type.indexOf("image/") > -1 ?
                            SpintrTypes.FileType.Png :
                            file.type.indexOf("audio/") > -1 ?
                                SpintrTypes.FileType.Aac :
                                SpintrTypes.FileType.Undefined,
                        name: file.name,
                        size: file.size,
                        data: file
                    }))
                ]
            }
        });
    }, [state.attachments, state.objects]);

    const onFilePickerSelect = useCallback((data: any[], source) => {
        if (source === 1) {
            setState((s: IState) => {
                return {
                    ...s,
                    isFilePickerOpen: false,
                    objects: [
                        ...s.objects,
                        ...data.map((file: any) => ({
                            id: file.id,
                            tmpId: generateUniqueNumberId(),
                            data: file,
                            type: SpintrTypes.UberType.File,
                            name: file.name
                        }))
                    ]
                }
            });
        } else if (source === 4 || source === 5) {
            setState((s: IState) => {
                return {
                    ...s,
                    isFilePickerOpen: false,
                    objects: [
                        ...s.objects,
                        ...data.map((file: any) => ({
                            id: file.id,
                            tmpId: generateUniqueNumberId(),
                            data: {
                                ...file,
                                source
                            },
                            type: SpintrTypes.UberType.ExternalFile
                        }))
                    ]
                }
            });
        }
    }, []);

    const onMemoTodoListChange = useCallback((todos: any[]) => {
        setState((s: IState) => {
            return {
                ...s,
                objects: s.objects.map((x) => {
                    if (x.type === SpintrTypes.UberType.Milestone) {
                        return {
                            ...x,
                            todos
                        }
                    }

                    return x;
                })
            }
        });
    }, [state.attachments, state.objects]);

    useEffect(() => {
        if (props.messageToEdit) {
            const content = props.messageToEdit.text;

            setState((s: IState) => {
                return {
                    ...s,
                    content,
                    hasContent: !!content,
                    inputKey: generateUniqueId(),
                    isMemo: props.messageToEdit.isMemo,
                    isTodoMemo: !!props.messageToEdit.objects.find(x => x.type === SpintrTypes.UberType.Milestone),
                    memoTitle: props.messageToEdit.memoTitle,
                    attachments: props.messageToEdit.attachments,
                    objects: props.messageToEdit.objects
                }
            });
        }

        if (props.messageToEdit || props.messageToReplyTo) {
            setTimeout(() => {
                if (!textInputRef.current) {
                    return;
                }

                textInputRef.current.giveFocus();
            });
        }

        if (!props.messageToEdit && !props.messageToReplyTo) {
            setState(getIntitialState(props));
        }
    }, [props.messageToEdit, props.messageToReplyTo]);

    useEffect(() => {
        setTimeout(() => {
            if (textInputRef.current) {
                textInputRef.current.giveFocus();
            }
        }, 0);
    }, [textInputRef, state.inputKey]);

    const disabled = useMemo(() => {
        return props.blocked || props.blockedBy;
    }, [props.blocked, props.blockedBy]);

    const classNames = useMemo(() => {
        const result = ["Composer"];

        if (state.isMemo) {
            result.push("expanded");
        }

        if (state.isLoading) {
            result.push("is-loading")
        }

        if (disabled) {
            result.push("disabled");
        }

        return result.join(" ");
    }, [state.isMemo, state.isLoading, disabled]);

    const placeholder = useMemo(() => {
        if (props.blocked) {
            return localize("YOU_HAVE_BLOCKED_X").replace("{{X}}", props.conversation.title);
        }

        if (props.blockedBy) {
            return localize("X_HAS_BLOCKED_YOU").replace("{{X}}", props.conversation.title);
        }

        if (state.isMemo) {
            return localize("WRITE_YOUR_MEMO_HERE") + "...";
        }

        return localize("CHAT_NEW_MESSAGE") + "...";
    }, [props.blocked, props.blockedBy, state.isMemo]);

    return (
        <div className={classNames}>
            {(props.messageToEdit || props.messageToReplyTo) && (
                <ComposerInfo
                    conversationId={props.conversationId}
                    messageToEdit={props.messageToEdit}
                    messageToReplyTo={props.messageToReplyTo} />
            )}
            <div className="row">
                {!state.compact && (
                    <SpintrUser
                        name={props.currentUser.name}
                        imageUrl={props.currentUser.images.Message}
                        size={circleSmall}
                        personalName={true}
                        hideText
                    />
                )}
                <div className="inner">
                    {state.isMemo && <ComposerMemoHeader onClose={onMemoClose} />}
                    {state.isMemo &&
                        <ComposerMemoTitle
                            value={state.memoTitle}
                            onChange={onMemoTitleChange} />
                    }
                    <div className="inner-row">
                        <div className="wrapper">
                            <div className="text-input">
                                <InteractiveTextInput
                                    initialContent={state.content}
                                    key={state.inputKey}
                                    ref={textInputRef}
                                    onEnter={postMessage}
                                    onChange={onChange}
                                    placeholder={placeholder}
                                    readOnly={disabled}
                                    bigChatViewPopoutPosition
                                />
                            </div>
                            {state.isTodoMemo &&
                                <ComposerMemoTodoList
                                    todos={state.objects.find(x => x.type === SpintrTypes.UberType.Milestone).todos}
                                    onChange={onMemoTodoListChange} />
                            }
                        </div>
                        {!state.isMemo && !disabled && <ComposerAttachmentSelector
                            onTodoClick={onTodoClick}
                            onMemoClick={onMemoClick}
                            onFileClick={onFileClick}
                            onImageClick={onImageClick}
                            onVideoClick={onVideoClick}
                            onAudioClick={onAudioClick}
                            compact={state.compact}
                            isInGroup={!!props.conversation?.group}
                            isMemo={state.isMemo}
                            isTodoMemo={state.isTodoMemo} />}
                    </div>
                    <input
                        ref={fileInputRef}
                        type="file"
                        multiple
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => onImagesSelected(Array.from(e.target.files))} />
                    <ComposerAttachments
                        attachments={state.attachments}
                        objects={state.objects}
                        onUpdate={(attachments: Spintr.IChatMessageAttachment[], objects: any[]) => {
                            setState((s: IState) => {
                                return {
                                    ...s,
                                    attachments,
                                    objects
                                }
                            });
                        }}
                    />
                    {state.isMemo && !disabled && (
                        <div className="footer-attachments-selector">
                            <ComposerAttachmentSelector
                                onTodoClick={onTodoClick}
                                onMemoClick={onMemoClick}
                                onFileClick={onFileClick}
                                onImageClick={onImageClick}
                                onVideoClick={onVideoClick}
                                onAudioClick={onAudioClick}
                                compact={state.compact}
                                isMemo={state.isMemo}
                                isTodoMemo={state.isTodoMemo} />
                        </div>
                    )}
                </div>
                <UnstyledButton
                    title={localize("Skicka")}
                    disabled={disabled}
                    onClick={postMessage}>
                    <div className="send-button">
                        {!state.isLoading && (
                            <Visage2Icon icon="send-2" color={state.hasContent ? "visageMidBlue" : "grey"} />
                        )}
                        {state.isLoading && (
                            <SpintrLoader loaderSize="small" />
                        )}
                    </div>
                </UnstyledButton>
            </div>
            {state.isFilePickerOpen &&
                <FilePicker
                    onClose={onFilePickerClose}
                    onSelect={onFilePickerSelect}
                    showFiles
                    allowMultipleFiles
                    sourcesToDisplay={fileSources}
                    source={SpintrTypes.FolderRootSource.Upload}
                    onLocalFilesSelected={(files) => onLocalFilesSelected(Array.from(files))}
                />
            }
        </div>
    )
}

const mapStateToProps = (state: IApplicationState, props: IProps) => {
    return {
        ...props,
        currentUser: state.profile.active,
        isSmallViewMode: state.ui.isSmallViewMode,
        messageToEdit: state.chat.conversations.messageToEdit[props.conversationId],
        messageToReplyTo: state.chat.conversations.messageToReplyTo[props.conversationId]
    };
};

export default connect(mapStateToProps)(Composer);
