import React from "react";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./AcademyImage.scss";

interface IProps {
    imageUrl: string;
}

const AcademyImage = (props: IProps) => {
    return (
        <div
            className="AcademyImage list primaryBGColor"
            style={{ backgroundImage: props.imageUrl ? `url("${props.imageUrl}")` : undefined }}
        >
            {!props.imageUrl && <Visage2Icon icon="teacher" color="white" />}
        </div>
    );
};

export default AcademyImage;
