import { AxiosError, AxiosRequestConfig, CancelToken } from "axios";
import api from "src/spintr/SpintrApi";

const baseUrl = "/api/v1/objects";

export async function toggleObjectPinnedForUserAsync(
    objectId:       number,
    cancelToken?:   CancelToken | undefined
): Promise<{ isPinned: boolean }> {
    const requestConfig: AxiosRequestConfig = {
        cancelToken,
    };

    const repsonse = await api.post<{ pinned: boolean }>(
        `${baseUrl}/toggle-pin-user/${objectId}`,
        requestConfig
    );

    return { isPinned: repsonse.data.pinned };
}

export type ObjectTreeNode = {
    id:                 number;
    name:               string;
    type:               Spintr.UberType;
    children:           ObjectTreeNode[];
};

export async function getObjectTreeAsync(
    objectId:       number,
    copyHierarchy?: boolean | undefined,
    cancelToken?:   CancelToken | undefined
): Promise<ObjectTreeNode> {
    const requestConfig: AxiosRequestConfig = {
        cancelToken,
        params: { copyHierarchy },
    };

    const response = await api.get<ObjectTreeNode>(
        `${baseUrl}/${objectId}/tree`,
        requestConfig
    );

    return response.data;
}

export type ObjectCopiedResponse = {
    copyId:         number;
    originalId:     number;
};

export async function copyObjectAsync(
    objectId:       number,
    parentId?:      number | undefined,
    publish?:       boolean | undefined,
    cancelToken?:   CancelToken | undefined
): Promise<ObjectCopiedResponse> {
    const requestConfig: AxiosRequestConfig = {
        cancelToken,
    };

    let result: ObjectCopiedResponse;
    try {
        const response = await api.post<ObjectCopiedResponse>(
            `${baseUrl}/${objectId}/copy`,
            {
                targetId: parentId,
                publish,
            },
            requestConfig
        );

        result = response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError) {
            throw error;
        }
        
        const data = axiosError.response.data;

        throw data;
    }

    return result;
}

export async function translateObjectAsync(
    objectId:       number,
    sourceLanguage: string,
    targetLanguage: string,
    cancelToken?:   CancelToken | undefined
): Promise<ObjectCopiedResponse> {
    const requestConfig: AxiosRequestConfig = {
        cancelToken,
    };

    let result: ObjectCopiedResponse;
    try {
        const response = await api.post(
            `${baseUrl}/${objectId}/translate`,
            {
                sourceLanguage,
                targetLanguage,
            },
            requestConfig
        );

        result = response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError) {
            throw error;
        }
        
        const data = axiosError.response.data;

        throw data;
    }

    return result;
}
