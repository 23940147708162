import { Checkbox, ChoiceGroup, Dropdown, TextField } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { IAcademyQuestion, IAcademyQuestionAlternative } from "src/academy/types";
import { localize } from "src/l10n";
import { ActionMenu, Label, UnstyledButton } from "src/ui";
import { FormControl } from "src/ui/components/Forms";
import { uniqueId } from "src/utils";
import "./AcademyTestFormQuestion.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import SmallCreateButton from "../SmallCreateButton/SmallCreateButton";
import { connect } from "react-redux";

interface IProps {
    index: number;
    question: IAcademyQuestion;
    onUpdate: (question: IAcademyQuestion) => void;
    onRemove: () => void;
    onMove: (up: boolean) => void;
    academyAIEnabled?: boolean;
    hideHeader?: boolean;
    isTask?: boolean;
}

interface IState {
    question: IAcademyQuestion;
    isExpanded: boolean;
}

const AcademyTestFormQuestion = (props: IProps) => {
    const [state, setState] = useState<IState>({
        question: props.question,
        isExpanded: props.hideHeader,
    });

    useEffect(() => {
        props.onUpdate(state.question);
    }, [state.question]);

    return (
        <div className="AcademyTestFormQuestion">
            {!props.hideHeader && (
                <UnstyledButton className="top-row" onClick={() => {
                    setState((s: IState) => {
                        return {
                            ...s,
                            isExpanded: !s.isExpanded
                        }
                    });
                }}>
                    <div className="question-order">
                        <UnstyledButton onClick={() => props.onMove(true)}>
                            <Visage2Icon icon="arrow-up-2" size="small" />
                        </UnstyledButton>
                        <UnstyledButton onClick={() => props.onMove(false)}>
                            <Visage2Icon icon="arrow-down-1" size="small" />
                        </UnstyledButton>
                    </div>
                    <Visage2Icon icon="information" size="small" type="bold" />
                    <Label size="body-2" weight="medium">{state.question.text || (localize("Fraga") + " " + (props.question.number))}</Label>
                    <UnstyledButton onClick={props.onRemove}>
                        <Visage2Icon icon="trash" size="small" />
                    </UnstyledButton>
                </UnstyledButton>
            )}
            {state.isExpanded && (
                <div className="content">
                    {/*{props.academyAIEnabled && (
                        <FormControl>
                            <Dropdown
                                label={localize("Typ")}
                                selectedKey={state.question.type.toString()}
                                onChange={(e, v) => {
                                    setState((s: IState) => {
                                        return {
                                            ...s,
                                            question: {
                                                ...s.question,
                                                type: Number(v.key),
                                                alternatives: v.key === "1" ?
                                                    [] :
                                                    [{
                                                        id: 0,
                                                        tmpId: uniqueId(),
                                                        text: "",
                                                        isCorrect: true
                                                    }, {
                                                        id: 1,
                                                        tmpId: uniqueId(),
                                                        text: "",
                                                        isCorrect: false
                                                    }]
                                            }
                                        }
                                    });
                                }}
                                options={[
                                    {
                                        key: "1",
                                        text: localize("Fritextfraga"),
                                    },
                                    {
                                        key: "2",
                                        text: localize("Enkelvalsfraga"),
                                    },
                                ]}
                            />
                        </FormControl>
                    )} */}
                    <FormControl className="question-field">
                        <TextField
                            className="textField"
                            value={state.question.text}
                            placeholder={localize(props.isTask ? "Beskrivning" : "Fraga") + "..."}
                            label={localize(props.isTask ? "Beskrivning" : "Fraga")}
                            onChange={(ev, val) => {
                                setState((s: IState) => {
                                    return {
                                        ...s,
                                        question: {
                                            ...s.question,
                                            text: val
                                        }
                                    }
                                });
                            }}
                            maxLength={128}
                        />
                    </FormControl>
                    {state.question.type === 1 && (
                        <FormControl>
                            <TextField
                                className="textField"
                                value={state.question.correctAnswer}
                                placeholder={localize("CORRECT_ANSWER") + "..."}
                                label={localize("CORRECT_ANSWER")}
                                onChange={(ev, val) => {
                                    setState((s: IState) => {
                                        return {
                                            ...s,
                                            question: {
                                                ...s.question,
                                                correctAnswer: val
                                            }
                                        }
                                    });
                                }}
                                maxLength={128}
                            />
                        </FormControl>
                    )}
                    {state.question.type === 2 && (
                        <div>
                            {state.question.alternatives.length > 0 && (
                                <FormControl label={localize(props.isTask ? "TASKS" : "Alternativ")} className="alternatives">
                                    {state.question.alternatives.map((x: IAcademyQuestionAlternative, index: number) => {
                                        return (
                                            <div className="alternative" key={x.tmpId}>
                                                {!props.isTask && (
                                                    <Checkbox
                                                        checked={!!x.isCorrect}
                                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                            setState((s: IState) => {
                                                                return {
                                                                    ...s,
                                                                    question: {
                                                                        ...s.question,
                                                                        alternatives: s.question.alternatives.map((alternative: IAcademyQuestionAlternative, alternativeIndex: number) => {
                                                                            return {
                                                                                ...alternative,
                                                                                isCorrect: checked ?
                                                                                    alternative.tmpId === x.tmpId :
                                                                                    alternativeIndex === 0
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                    />
                                                )}
                                                <div className="textFieldWrapper">
                                                    <TextField
                                                        className="textField"
                                                        value={x.text}
                                                        borderless
                                                        placeholder={localize(props.isTask ? "TASK" : "Alternativ_Singular") + " " + (index + 1) + "..."}
                                                        onChange={(ev, val) => {
                                                            setState((s: IState) => {
                                                                return {
                                                                    ...s,
                                                                    question: {
                                                                        ...s.question,
                                                                        alternatives: s.question.alternatives.map((alternative: IAcademyQuestionAlternative) => {
                                                                            if (alternative.tmpId === x.tmpId) {
                                                                                return {
                                                                                    ...alternative,
                                                                                    text: val
                                                                                }
                                                                            } else {
                                                                                return alternative;
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                        maxLength={128}
                                                    />
                                                </div>
                                                <UnstyledButton onClick={() => {
                                                    setState((s: IState) => {
                                                        return {
                                                            ...s,
                                                            question: {
                                                                ...s.question,
                                                                alternatives: s.question.alternatives.filter(a => a.tmpId !== x.tmpId)
                                                            }
                                                        }
                                                    });
                                                }}>
                                                    <Visage2Icon icon="trash" size="small" />
                                                </UnstyledButton>
                                            </div>
                                        )
                                    })}
                                </FormControl>
                            )}
                            <SmallCreateButton
                                text={localize(props.isTask ? "ADD_TASK" : "ADD_ALTERNATIVE")}
                                onClick={() => {
                                    setState((s: IState) => {
                                        return {
                                            ...s,
                                            question: {
                                                ...s.question,
                                                alternatives: [
                                                    ...state.question.alternatives,
                                                    {
                                                        id: state.question.alternatives.reduce((max, a) => (a.id > max ? a.id : max), 0) + 1,
                                                        tmpId: uniqueId(),
                                                        text: ""
                                                    }
                                                ]
                                            }
                                        }
                                    })
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    academyAIEnabled: state.instance.get("academyAIEnabled")
});

export default connect(mapStateToProps)(AcademyTestFormQuestion);
