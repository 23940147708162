import React, { Component } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { saveBoardPositions } from 'src/groups/planning-actions';
import "src/groups/views/planning/GroupPlanningView.scss";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { Label, UnstyledButton } from "src/ui";
import PlanningAddCategory from "./PlanningAddCategory";
import PlanningCategory from "./PlanningCategory";

interface IProps {
    dispatch?: any;
    categories: any;
    groupId: number;
    isSmallViewMode?: boolean;
}

class PlanningBoard extends Component<IProps> {
    state = {
        showAddCategory: false
    };

    toggleshowAddCategory = () =>
        this.setState({ showAddCategory: !this.state.showAddCategory });

    handleDragEnd = (result) => {
        const { source, destination, type } = result;
        if (!destination) {
            return;
        }

        let updatedCategories = [
            ...this.props.categories
        ];

        if (type === "COLUMN") {
            const fromIndex = source.index;
            const toIndex = destination.index;

            const c = updatedCategories[fromIndex];

            updatedCategories.splice(fromIndex, 1);
            updatedCategories.splice(toIndex, 0, c);
        } else if (type === "DEFAULT") {
            const oldCategoryId = parseInt(source.droppableId);
            const oldListIndex = source.index;
            const newCategoryId = parseInt(destination.droppableId);
            const newListIndex = destination.index;

            let item;

            updatedCategories = updatedCategories.map(c => {
                if (c.id === oldCategoryId) {
                    item = c.items.splice(oldListIndex, 1)[0];
                }

                return c;
            });

            updatedCategories = updatedCategories.map(c => {
                if (c.id === newCategoryId) {
                    c.items.splice(newListIndex, 0, item);
                }

                return c;
            });
        }

        updatedCategories = updatedCategories.map((c, i) => {
            return {
                ...c,
                position: i,
                items: c.items.map((item, i2) => {
                    return {
                        ...item,
                        position: i2
                    }
                })
            }
        });

        this.props.dispatch(saveBoardPositions(api, this.props.groupId, updatedCategories));
    };

    render() {
        const direction = this.props.isSmallViewMode ?
            "vertical" :
            "horizontal";

        return (
            <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable droppableId="board" direction={direction} type="COLUMN">
                    {(provided, _snapshot) => (
                        <div className="Planning-Board" ref={provided.innerRef}>
                            {
                                this.props.categories.map((category, index) => {
                                    return <PlanningCategory groupId={this.props.groupId} category={category} categoryId={category.id} key={category.id} index={index} />;
                                })
                            }
                            {
                                provided.placeholder
                            }
                            <div className="Planning-AddCategoryButton visage-box-shadow">
                                {
                                    this.state.showAddCategory ?
                                        <PlanningAddCategory groupId={this.props.groupId} toggleshowAddCategory={this.toggleshowAddCategory} /> :
                                        <UnstyledButton onClick={this.toggleshowAddCategory}>
                                            <Label className="Planning-AddCategoryButtonLabel primaryFGColor" size="body-2">
                                                {
                                                    localize("SkapaBrada")
                                                }
                                            </Label>
                                        </UnstyledButton>
                                }
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        isSmallViewMode: state.ui.isSmallViewMode,
    };
};

export default connect(mapStateToProps)(PlanningBoard);