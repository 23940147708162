import React, {  } from "react";
import "./SystemStatusResourceCTA.scss";
import { Label, SpintrUser } from "src/ui";
import { connect } from "react-redux";
import { Style } from "src/ui/helpers";
import { localize } from "src/l10n";

interface IProps {
    currentUser?: Spintr.IActiveUser;
    resouce: any;
    compact?: boolean;
}

const SystemStatusResourceCTA = (props: IProps) => {
    return (
        <div className={[
            "SystemStatusResourceCTA",
            ...(props.compact ? ["compact"] : [])
        ].join(" ")}>
            <SpintrUser
                personalName={true}
                name={props.currentUser.name}
                imageUrl={props.currentUser.images["topBar"]}
                hideText
                size={Style.cirlceTiny}
            />
            <Label size="body-2" weight="medium" color="grey">
                {localize("COMPOSER_QUESTION")}
            </Label>
        </div>
    )
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    currentUser: state.profile.active,
});

export default connect(mapStateToProps)(SystemStatusResourceCTA);

