import { Checkbox, DefaultButton, Modal, PrimaryButton, TooltipHost } from "@fluentui/react";
import React, { CSSProperties, Component, useState } from "react";
import PopupHeader from "src/ui/components/PopupHeader";
import SocialComposerTypes from "../Composer/SocialComposerTypes";
import { localize } from "src/l10n";
import Visage2ComposerHeader from "./Visage2ComposerHeader";
import { DispatchProp, MapStateToProps, connect } from "react-redux";
import Visage2ComposerTextInput from "./Visage2ComposerTextInput";
import "./Visage2Composer.scss";
import { EditorState } from "draft-js";
import Visage2ComposerFrameFontSelector from "./Visage2ComposerFrameFontSelector";
import { IVisage2SocialPostType, addRemotePost, updateRemotePost, visage2ComposerAttachButtons, visage2SocialPostTypes } from "src/social-feed";
import { Label, Loader, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { SpintrTypes } from "src/typings";
import SocialPostTypeContent from "src/social-feed/components/SocialPostTypeContent/SocialPostTypeContent";
import Visage2ComposerPoll from "./Visage2ComposerPoll";
import Visage2ComposerImages from "./Visage2ComposerImages";
import { draftToHtml, extractBookmarkUrl } from "../Composer/utils";
import api from "src/spintr/SpintrApi";
import Visage2ComposerBookmark from "./Visage2ComposerBookmark";
import Visage2ComposerColorSelector from "./Visage2ComposerColorSelector";
import Visage2ComposerPeoplePicker from "./Visage2ComposerPeoplePicker";
import Visage2ComposerExternalFile from "./Visage2ComposerExternalFile";
import Visage2ComposerSystemStatus from "./Visage2ComposerSystemStatus";
import { getContentStateFromString } from "src/utils/getContentStateFromString";
import { AxiosResponse } from "axios";
import { fetchPrioritySystemStatuses } from "src/system-status/actions";
import { Product } from "src/products/types";
import { decodeHtmlEntities } from "src/utils";

const stepTitles = ["START_COMPOSER_HEADER_TEXT",
    "CHAT_ADD_PEOPLE",
    "CHOOSE_FONT",
    "START_COMPOSER_HEADER_TEXT"];

export const defaultFrameColors: Spintr.ISocialStatusFrameGradient[] = [
    { from: '#239DCC', to: '#582F8C' },
    { from: '#45C8CE', to: '#BE1AA7' },
    { from: '#D29A7B', to: '#C60047' },
    { from: '#1D8C07', to: '#E0BC31' },
    { from: '#AEE46A', to: '#2523B4' },
    { from: '#C2E38C', to: '#328E08' },
    { from: '#3B26B0', to: '#B755D2' },
    { from: '#F5A623', to: '#F5A623' },
    { from: '#D0021B', to: '#D0021B' },
    { from: '#4A4A4A', to: '#4A4A4A' },
    { from: '#4A90E2', to: '#4090E2' },
    { from: '#417505', to: '#417505' },
    { from: '#008B95', to: '#008B95' },
    { from: '#50E3C2', to: '#50E3C2' }
];

export const defaultFrameFonts: string[] = [
    "\"Roboto\", sans-serif",
    "\"Merriweather\", serif",
    "\"Indie Flower\", cursive",
    "\"Pacifico\", cursive",
]

export interface IComposerPost {
    feedId: number;
    color: Spintr.ISocialStatusFrameGradientBackground;
    font: number;
    type: number;
}

type OwnProps = {
    types?: SocialComposerTypes[];
    type?: IVisage2SocialPostType;
    post?: any;
    feedId?: number;
    onClosing?: () => void;
    editMode?: boolean;
    isUnit?: boolean;
    publishToGeneralFeedChecked?: boolean;
    enableProjectPostsOnStartpage?: boolean;
    dispatch?: any;
    identity?: any;
    isStartPage?: boolean;
    systemStatusEnabled?: boolean;
    systemStatusResources?: Spintr.ISystemStatusResource[];
    displayResourceSelector?: boolean;
}

type StateProps = {
    currentUser:                    any;
    enableSharepoint?:              boolean | undefined;
    disableNewDealPostType?:        boolean | undefined;
    publishToGeneralFeedChecked:    boolean | undefined;
    enableProjectPostsOnStartpage:  boolean | undefined;
    productsOptions: {
        enabled: boolean;
        patterns: RegExp[];
    };
}

type Props = DispatchProp & OwnProps & StateProps;

interface IState {
    step: ComposerStage;
    editorState: EditorState;
    post: any;
    displayTypeSelector: boolean;
    feedId?: number;
    isFetchingBookmark?: boolean;
    ignoredBookmarkUrls: string[];
    publishOnStartPage: boolean;
    identity: any;
}

export enum ComposerStage {
    EditingPost = 0,
    EditingKudos = 1,
    SelectFrameFont = 2,
    Loading = 3
}

export const getEmptyPostObject = () => {
    return {
        Id: 0,
        Type: SpintrTypes.UberType.Status,
        StatusType: SpintrTypes.StatusType.Text,
        content: [],
    };
}

class Visage2Composer extends Component<Props, IState> {
    constructor(props: Props) {
        super(props);

        const post = props.post || getEmptyPostObject();

        if (props.type && !props.post) {
            const emptyType = this.getEmptyType(props.type);

            post.content = [emptyType];
        }

        this.state = {
            feedId: props.feedId ? props.feedId : props.currentUser.feedId,
            step: ComposerStage.EditingPost,
            editorState: props.post ?
                EditorState.createWithContent(getContentStateFromString(props.post.Text)) :
                EditorState.createEmpty(),
            post,
            displayTypeSelector: !this.props.editMode,
            ignoredBookmarkUrls: [],
            publishOnStartPage: this.props.isUnit ? !!this.props.publishToGeneralFeedChecked : true,
            identity: props.identity,
        }

        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }

    getStepTitleTag = () => {
        let tag = stepTitles[this.state.step];

        if (this.props.editMode && tag == "START_COMPOSER_HEADER_TEXT") {
            tag = "START_COMPOSER_EDIT_HEADER_TEXT"
        }

        return tag;
    }

    onPostUpdate = (post: Spintr.ISocialPostBase) => {
        this.setState({
            post
        });
    }

    setStep(step: ComposerStage) {
        this.setState({
            step
        });
    }

    renderTypeContent(post: Spintr.ISocialPostBase, index) {
        return (
            <div className="type-content">
                <SocialPostTypeContent
                    post={post}
                    editMode
                    disableRemove={this.props.editMode}
                    onClose={() => {
                        this.setState({
                            post: {
                                ...this.state.post,
                                content: this.state.post.content.filter(x =>
                                    x.StatusType !== SpintrTypes.StatusType.Kudos &&
                                    x.StatusType !== SpintrTypes.StatusType.BusinessDeal &&
                                    x.StatusType !== SpintrTypes.StatusType.SomethingFun),
                                StatusType: SpintrTypes.StatusType.Text
                            }
                        });
                    }}
                    onUpdate={(updatedPost: Spintr.ISocialPostBase) => {
                        this.onPostUpdate({
                            ...this.state.post,
                            content: this.state.post.content.map((c, i) => {
                                if (i === index) {
                                    return updatedPost;
                                } else {
                                    return c;
                                }
                            })
                        })
                    }}
                    goToEditUsers={() => {
                        this.setState({
                            step: ComposerStage.EditingKudos
                        });
                    }} />
                {
                    post.StatusType !== SpintrTypes.StatusType.SomethingFun && (
                        <Visage2ComposerColorSelector
                            post={post}
                            onPostUpdate={(updatedPost: Spintr.ISocialPostBase) => {
                                this.onPostUpdate({
                                    ...this.state.post,
                                    content: this.state.post.content.map((c, i) => {
                                        if (i === index) {
                                            return updatedPost;
                                        } else {
                                            return c;
                                        }
                                    })
                                })
                            }} />
                    )
                }
            </div>
        )
    }

    onAttachedContentUpdate(updatedItem: any, index: number) {
        this.setState({
            post: {
                ...this.state.post,
                content: this.state.post.content.map((contentItem: any, contentIndex: number) => {
                    if (contentIndex === index) {
                        return updatedItem;
                    } else {
                        return contentItem;
                    }
                })
            }
        });
    }

    removeAttachedContent(index: number) {
        const allItems = [...this.state.post.content];

        allItems.splice(index, 1);

        this.setState({
            post: {
                ...this.state.post,
                content: allItems
            }
        });
    }

    renderAttachedContentItem(item, index) {
        if (item.StatusType === SpintrTypes.StatusType.Kudos ||
            item.StatusType === SpintrTypes.StatusType.BusinessDeal ||
            item.StatusType === SpintrTypes.StatusType.SomethingFun) {
            return this.renderTypeContent(item, index);
        }

        if (item.StatusType === SpintrTypes.StatusType.SystemStatus) {
            return (
                <Visage2ComposerSystemStatus
                    post={this.state.post}
                    content={item}
                    onAttachedContentUpdate={(updatedItem: any) => {
                        this.onAttachedContentUpdate(updatedItem, index);
                    }}
                    onPostUpdate={this.onPostUpdate}
                />
            )
        }

        if (item.Type === SpintrTypes.UberType.Poll) {
            return (
                <Visage2ComposerPoll
                    post={item}
                    onAttachedContentUpdate={(updatedItem: any) => {
                        this.onAttachedContentUpdate(updatedItem, index);
                    }}
                    onRemove={() => {
                        this.removeAttachedContent(index);
                    }}
                    disableEditing={this.props.editMode}
                />
            )
        }

        if (item.Type === SpintrTypes.UberType.ImagePost ||
            item.Type === SpintrTypes.UberType.Image) {
            return (
                <div>
                    <Visage2ComposerImages
                        post={item}
                        onAttachedContentUpdate={(updatedItem: any) => {
                            this.onAttachedContentUpdate(updatedItem, index);
                        }}
                        onRemove={() => {
                            this.removeAttachedContent(index);
                        }}
                        disableEditing={this.props.editMode}
                        isVideo={item.Type === SpintrTypes.UberType.Image}
                    />
                </div>
            )
        }

        if (item.Type === SpintrTypes.UberType.Bookmark) {
            return (
                <div>
                    <Visage2ComposerBookmark
                        post={item}
                        onAttachedContentUpdate={(updatedItem: any) => {
                            this.onAttachedContentUpdate(updatedItem, index);
                        }}
                        onRemove={() => {
                            this.setState({
                                ignoredBookmarkUrls: [
                                    ...this.state.ignoredBookmarkUrls,
                                    item.BookmarkUrl
                                ]
                            }, () => {
                                this.removeAttachedContent(index);
                            });
                        }}
                        disableEditing={this.props.editMode}
                    />
                </div>
            )
        }

        if (item.Type === SpintrTypes.UberType.File) {
            return (
                <Visage2ComposerExternalFile
                    post={item}
                    onAttachedContentUpdate={(updatedItem: any) => {
                        this.onAttachedContentUpdate(updatedItem, index);
                    }}
                    onRemove={() => {
                        this.removeAttachedContent(index);
                    }}
                />
            )
        }

        return null;
    }

    renderAttachedContent() {
        const content = this.state.post.content
            .filter((item: any) => item.Type !== SpintrTypes.UberType.Product);

        if (content.length === 0) {
            return null;
        }

        return (
            <div className="attached-content">
                {content.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            {this.renderAttachedContentItem(item, index)}
                        </div>
                    )
                })}
            </div>
        )
    }

    getEmptyType(type: IVisage2SocialPostType) {
        const c = defaultFrameColors[0];

        var postObject = {
            ...getEmptyPostObject(),
            Type: type.uberType,
            StatusType: type.type,
            color: c,
            background: {
                start: {
                    color: c.from
                },
                stop: {
                    color: c.to
                }
            },
            displayDecorations: true,
            publishStartDate: undefined,
            startDate: undefined,
            endDate: undefined,
        };

        if (type.type === SpintrTypes.StatusType.SystemStatus) {
            postObject.publishStartDate = new Date();
            postObject.startDate = new Date();
        }

        return postObject;
    }

    renderTypeSelector() {
        if (!this.state.displayTypeSelector) {
            return null;
        }

        const types = visage2SocialPostTypes.filter(
            (x) =>
                !x.hidden &&
                (!this.props.disableNewDealPostType || x.type !== SpintrTypes.StatusType.BusinessDeal)
        );
        
        return (
            <div className="composer-section-wrapper">
                {this.state.displayTypeSelector && (
                    <div className="type-selector">
                        {types.map((type: any, index: number) => {
                            return (
                                <UnstyledButton
                                    key={index}
                                    className="type-selector-type"
                                    onClick={() => {
                                        this.setType(type);
                                    }}
                                    style={{
                                        backgroundColor: type.bgColor
                                    }}>
                                    <div className="icon-wrapper">
                                        <Visage2Icon icon={type.icon} hexColor={type.iconColor} type="custom" size="small" />
                                    </div>
                                    <Label size="body-2">
                                        {localize(type.titleTag)}
                                    </Label>
                                </UnstyledButton>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }

    setType = (type?: any) => {
        if (!type) {
            this.setState({
                post: {
                    StatusType: SpintrTypes.StatusType.Text,
                    content: [],
                }
            });
            return;
        }

        const emptyType = this.getEmptyType(type);

        this.setState({
            post: {
                ...this.state.post,
                content: [
                    emptyType,
                ]
            },
        });
    }

    renderAttachSelector() {
        if (this.state.post.content.length > 2 || this.props.editMode || this.state.post.content.some((c) => c.StatusType === SpintrTypes.StatusType.SystemStatus)) {
            return null;
        }

        let types = visage2ComposerAttachButtons;

        const microsoft365Enabled = this.props.currentUser.office365Connected && this.props.enableSharepoint;
        const googleEnabled = this.props.currentUser.googleConnected;

        if (!microsoft365Enabled && !googleEnabled) {
            types = types.filter(x => x.type !== "file");
        }

        return (
            <div className="composer-section-wrapper horizontal">
                <div className="composer-section-header">
                    <Label size="body-2">{localize("ATTACH_CONTENT")}</Label>
                </div>
                {/* <div className={"type-selector type-count-" + types.length}>
                    {types.map((type: any, index: number) => {
                        return (
                            <UnstyledButton
                                key={index}
                                className="type-selector-type"
                                onClick={() => {
                                    let obj : Spintr.ISocialPostBase = {
                                        ...getEmptyPostObject(),
                                        Type: type.uberTypeId
                                    };

                                    if (type.uberTypeId === SpintrTypes.UberType.Poll) {
                                        obj.options = [{
                                            id: 0,
                                            option: "",
                                            answers: []
                                        }, {
                                            id: 0,
                                            option: "",
                                            answers: []
                                        }]
                                    }

                                    console.log("obj", obj);

                                    this.setState({
                                        post: {
                                            ...this.state.post,
                                            content: [
                                                ...(this.state.post.content || []),
                                                obj
                                            ]
                                        }
                                    });
                                }}
                                style={{
                                    backgroundColor: type.color
                                }}>
                                <div className="icon-wrapper">
                                    <Visage2Icon icon={type.icon} hexColor={type.iconColor} type="bold" />
                                </div>
                                <Label size="body-2" weight="medium">
                                    {localize(type.titleTag)}
                                </Label>
                            </UnstyledButton>
                        )
                    })}
                </div> */}
                <div className="attach-selector">
                    {types.map((type: any, index: number) => {
                        return (
                            <TooltipHost
                                key={index}
                                content={localize(type.titleTag)}
                                className="marketplace-tooltip"
                                calloutProps={{ beakWidth: 8 }}>
                                <UnstyledButton
                                    onClick={() => {
                                        let obj : any = {
                                            ...getEmptyPostObject(),
                                            Type: type.uberTypeId
                                        };

                                        if (type.uberTypeId === SpintrTypes.UberType.Poll) {
                                            obj.options = [{
                                                id: 0,
                                                option: "",
                                                answers: []
                                            }, {
                                                id: 0,
                                                option: "",
                                                answers: []
                                            }]
                                        } else if (type.uberTypeId === SpintrTypes.UberType.ImagePost ||
                                            type.uberTypeId === SpintrTypes.UberType.Image) {
                                            obj.photos = [];
                                        }

                                        this.setState({
                                            post: {
                                                ...this.state.post,
                                                content: [
                                                    ...(this.state.post.content || []),
                                                    obj
                                                ]
                                            }
                                        });
                                    }}>
                                    <div className="attach-type" style={{
                                        backgroundColor: type.color
                                    }}>
                                        <Visage2Icon icon={type.icon} hexColor={type.iconColor} />
                                    </div>
                                </UnstyledButton>
                            </TooltipHost>
                        )
                    })}
                </div>
            </div>
        )
    }

    checkForBookMark() {
        const result = extractBookmarkUrl(this.state.editorState.getCurrentContent());

        if (result === false || !result?.url) {
            return;
        }

        let url = result.url;

        if (this.state.ignoredBookmarkUrls.indexOf(url) > - 1) {
            return;
        }

        switch (result.type) {
            case 2:
                const ytArr = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/.exec(result.url);
                if (!ytArr || ytArr.length === 0) {
                    break;
                }

                const ytId = ytArr[1];
                url = `https://www.youtube.com/embed/${ytId}`;
                break;

            case 3:
                const vimeoId = /[^/]*$/.exec(result.url)[0];
                url = `https://player.vimeo.com/video/${vimeoId}`;
                break;

            case 6:
                const svtPlayParts = result.url.split("video/");
                if (svtPlayParts && svtPlayParts.length > 1) {
                    const videoId = svtPlayParts[1].split("/")[0];

                    url = `http://www.svtplay.se/video/${videoId}?type=embed&external=true`;
                    break;
                }

                const clipId = result.url
                    .split("klipp/")[1]
                    .split("/")[0];

                    url = `http://www.svtplay.se/klipp/${clipId}?type=embed&external=true`;
                break;

            case 7:
                const trackId = /[^/]*$/.exec(result.url)[0];
                url = `https://embed.spotify.com/?uri=spotify:track:${trackId}`;
                break;
        }

        if (!url) {
            return;
        }

        this.setState({
            isFetchingBookmark: true
        }, () => {
            api.get<Spintr.IBookmarkProjection>("/api/bookmarks/fetchimages", {
                params: {
                    currentHost: window.document.location.hostname,
                    url
                }
            }).then((response) => {
                if (this.postHasBookmark()) {
                    this.setState({
                        isFetchingBookmark: false
                    });

                    return;
                }
    
                this.setState({
                    isFetchingBookmark: false,
                    post: {
                        ...this.state.post,
                        content: [
                            ...this.state.post.content,
                            {
                                ...getEmptyPostObject(),
                                Type: SpintrTypes.UberType.Bookmark,
                                BookmarkTitle: response.data.title,
                                BookmarkDescription: decodeHtmlEntities(response.data.description),
                                BookmarkUrl: response.data.url,
                                BookmarkImage: response.data.images.length > 0 ? response.data.images[0] : undefined,
                                BookmarkImages: response.data.images,
                                BookmarkIcon: response.data.icon,
                                BookmarkType: result.type
                            }
                        ]
                    }
                });
            }).catch(() => {});
        });
    }

    postHasBookmark() {
        return !!this.state.post.content.find(x => x.Type === SpintrTypes.UberType.Bookmark);
    }

    getStatusType() {
        if (this.state.post.content.find(x => x.StatusType === SpintrTypes.StatusType.BusinessDeal)) {
            return SpintrTypes.StatusType.BusinessDeal;
        } else if (this.state.post.content.find(x => x.StatusType === SpintrTypes.StatusType.SomethingFun)) {
            return SpintrTypes.StatusType.SomethingFun;
        } else if (this.state.post.content.find(x => x.StatusType === SpintrTypes.StatusType.Kudos)) {
            return SpintrTypes.StatusType.Kudos;
        } else if (this.state.post.content.find(x => x.StatusType === SpintrTypes.StatusType.SystemStatus)) {
            return SpintrTypes.StatusType.SystemStatus;
        } else {
            return this.state.post.StatusType;
        }
    }

    getPostFormData() {
        let formData = new FormData();

        let envelope : any = {
            ...this.state.post,
            feedId: this.state.feedId,
            text: draftToHtml(this.state.editorState.getCurrentContent()),
            flag: this.state.publishOnStartPage ? 0 : 1
        };

        if (envelope.StatusType === SpintrTypes.StatusType.Frame) {
            envelope.background = `GRADTB${envelope.background?.start?.color}${envelope.background?.stop?.color}`;
        }

        const editableTypes = [SpintrTypes.UberType.BusinessDeal, SpintrTypes.UberType.Kudos, SpintrTypes.UberType.SomethingFun, SpintrTypes.UberType.SystemStatus];

        envelope.content = envelope.content.map((item: any) => {
            if (this.props.editMode && !editableTypes.includes(item.Type)) {
                return item;
            }

            if (item.Type === SpintrTypes.UberType.Poll) {
                return {
                    feedId: envelope.feedId,
                    options: item.options.map((o) => { return o.option }),
                    question: envelope.text,
                    typeDiscriminator: 14,
                }
            } else if (item.Type === SpintrTypes.UberType.ImagePost) {
                return {
                    feedId: envelope.feedId,
                    galleryId: this.props.currentUser.galleryId,
                    tickets: item.photos.map((p) => { return p.imageTicket.ticket }),
                    description: envelope.text,
                    typeDiscriminator: 2,
                }
            } else if (item.Type === SpintrTypes.UberType.Image) {
                formData.append("files", item.Video, item.Video.name);

                return {
                    feedId: envelope.feedId,
                    galleryId: this.props.currentUser.galleryId,
                    description: envelope.text,
                    typeDiscriminator: 17,
                }
            } else if (item.Type === SpintrTypes.UberType.Bookmark) {
                return {
                    typeDiscriminator: 20,
                    text: envelope.text,
                    title: item.BookmarkTitle,
                    type: item.BookmarkType,
                    description: item.BookmarkDescription,
                    url: item.BookmarkUrl,
                    icon: item.BookmarkIcon,
                    images: item.BookmarkImages,
                    feedId: envelope.feedId
                }
            } else if (item.Type === SpintrTypes.UberType.File) {
                return {
                    typeDiscriminator: 6,
                    externalId: item.file.id,
                    source: item.file.source ?? 4,
                    isDirectory: false
                }
            } else if (item.StatusType === SpintrTypes.StatusType.BusinessDeal) {
                return {
                    customer: item.customer,
                    value: item.value,
                    comment: "",
                    typeDiscriminator: 22,
                    background: `GRADTB${item.background?.start?.color}${item.background?.stop?.color}`,
                    displayDecorations: item.displayDecorations,
                    id: item.Id,
                }
            } else if (item.StatusType === SpintrTypes.StatusType.Kudos) {
                return {
                    comment: item.comment,
                    targetUserIds: (item.targetUsers || []).map((u) => { return u.id }),
                    typeDiscriminator: 24,
                    background: `GRADTB${item.background?.start?.color}${item.background?.stop?.color}`,
                    displayDecorations: item.displayDecorations,
                    id: item.Id,
                }
            } else if (item.StatusType === SpintrTypes.StatusType.SomethingFun) {
                return {
                    comment: "",
                    typeDiscriminator: 23,
                    ticket: !!item.photos && item.photos.length > 0 ? item.photos[0]?.imageTicket?.ticket : "",
                    displayDecorations: item.displayDecorations,
                    id: item.Id,
                }
            } else if (item.StatusType === SpintrTypes.StatusType.SystemStatus) {
                return {
                    typeDiscriminator: 25,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    publishStartDate: item.publishStartDate,
                    prioritized: item.prioritized,
                    id: item.Id,
                    responsibleUser: item.responsibleUser
                }
            }

            return item;
        });

        formData.append("jsonString", JSON.stringify(envelope));

        return formData;
    }

    post() {
        this.setState({
            step: ComposerStage.Loading
        }, () => {
            const method = this.props.editMode ? api.put : api.post;
            method("/api/v1/status" + (this.props.editMode ? "/" + this.props.post.Id : ""), this.getPostFormData())
                .then((response: AxiosResponse) => {
                    if (this.props.editMode) {
                        this.props.dispatch(updateRemotePost(response.data));
                    } else {
                        this.props.dispatch(addRemotePost(response.data, this.props.enableProjectPostsOnStartpage));
                    }

                    if (this.state.post.content.some((c) => c.StatusType === SpintrTypes.StatusType.SystemStatus)) {
                        this.props.dispatch(fetchPrioritySystemStatuses());
                    }

                    this.props.onClosing();
                })
                .catch(() => {
                    this.setState({
                        step: ComposerStage.EditingPost,
                    });
                });
        });
    }

    canPost() {
        const text = draftToHtml(this.state.editorState.getCurrentContent());

        if (!!text || this.state.post?.content?.length > 0) {
            return true;
        }

        return false;
    }

    getPlaceholder(type: SpintrTypes.StatusType) {
        const placeholderTag = visage2SocialPostTypes.find(x => x.type === type)?.placeholderTag || "COMPOSER_PLACEHOLDER";
        let placeholderText = localize(placeholderTag);

        if (type === SpintrTypes.StatusType.SystemStatus) {
            placeholderText = placeholderText.replace("{{X}}", this.state.identity.name);
        }

        return placeholderText;
    }

    setIdentity = (identity: any) => {
        this.setState({
            identity,
        });
    }

    private onEditorStateChange(editorState: EditorState) {
        this.setState({ editorState }, () => {
            if (this.postHasBookmark() ||this.state.isFetchingBookmark) {
                return;
            }

            this.checkForBookMark();
        });
    }

    onProductAdded = (product: Product): void => {
        this.setState((prevState) => ({
            ...prevState,
            post: {
                ...prevState.post,
                content: prevState.post.content.some((item: any) => item.productId === product.id)
                    ? prevState.post.content
                    : [...prevState.post.content, {
                        ...getEmptyPostObject(),
                        productId: product.id,
                        Type: SpintrTypes.UberType.Product,
                        typeDiscriminator: 26,
                    }],
            },
        }));
    }

    onProductRemoved = (product: Product): void => {
        this.setState((prevState) => ({
            ...prevState,
            post: {
                ...prevState.post,
                content: [
                    ...prevState.post.content
                        .filter((item: any) => item.productId !== product.id),
                ],
            },
        }));
    }

    render() {
        const statusType = this.getStatusType();

        let classNames = ["Visage2Composer", "spintr-modal", "modalWithPopupHeader"];

        if (this.canPost()) {
            classNames.push("can-post");
        }

        return (
            <Modal
                isOpen={true}
                onDismiss={this.props.onClosing}
                // isBlocking={this.state.step !== ComposerStage.EditingPost}
                isBlocking={false}
                containerClassName={"Visage2ComposerContainer"}
                className={classNames.join(" ")}
            >
                <PopupHeader
                    text={localize(this.getStepTitleTag())}
                    hideCloseButton={this.state.step !== ComposerStage.EditingPost}
                    onClose={this.props.onClosing} />
                {this.state.step === ComposerStage.Loading && (
                    <Loader />
                )}
                {this.state.step === ComposerStage.EditingPost && (
                    <div id="SocialComposer">
                        <Visage2ComposerHeader
                            feedId={this.state.feedId}
                            imageUrl={this.state.identity ? this.state.identity.images.feedComposer : this.props.currentUser.images["topBar"]}
                            userName={this.state.identity?.name || this.props.currentUser.name}
                            post={this.state.post}
                            onPostUpdate={this.onPostUpdate}
                            hideTargetSelector={this.props.editMode}
                            isStartPage={this.props.isStartPage}
                            currentUser={this.props.currentUser}
                            systemStatusResources={this.props.systemStatusResources}
                            systemStatusEnabled={this.props.systemStatusEnabled}
                            displayResourceSelector={this.props.displayResourceSelector}
                            setType={this.setType}
                            setIdentity={this.setIdentity}
                            setFeedId={(id) => {
                                this.setState({
                                    feedId: id
                                });
                            }} />
                        <div className="composer-scroll">
                            <div className="composer-scroll-inner">
                                <Visage2ComposerTextInput
                                    editorState={this.state.editorState}
                                    post={this.state.post}
                                    onPostUpdate={this.onPostUpdate}
                                    onProductAdded={this.onProductAdded}
                                    onProductRemoved={this.onProductRemoved}
                                    placeholder={this.getPlaceholder(statusType)}
                                    onEditorStateUpdate={this.onEditorStateChange}
                                    setStep={this.setStep.bind(this)}
                                    productsOptions={{
                                        enabled: this.props.productsOptions.enabled,
                                        patterns: this.props.productsOptions.patterns,
                                    }} />
                                {statusType === SpintrTypes.StatusType.Frame && (
                                    <Visage2ComposerColorSelector
                                        post={this.state.post}
                                        onPostUpdate={this.onPostUpdate} />
                                )}
                                {this.renderAttachedContent()}
                            </div>
                        </div>
                        {statusType === SpintrTypes.StatusType.Text && this.renderTypeSelector()}
                        {this.renderAttachSelector()}
                        {this.props.isUnit && !this.props.editMode && (
                            <Checkbox
                                className="publish-on-startpage-checkbox"
                                label={localize("PubliceraAvenPaStartsidan")}
                                checked={this.state.publishOnStartPage}
                                onChange={(ev, val: boolean) => {
                                    this.setState({
                                        publishOnStartPage: val
                                    });
                                }}
                            />
                        )}
                        <div className="composer-footer">
                            {/* <DefaultButton text={localize("Avbryt")} onClick={this.props.onClosing} /> */}
                            <PrimaryButton
                                className="composer-footer-button"
                                text={localize(this.props.editMode ? "Spara" : "START_COMPOSER_SUBMIT_TEXT")}
                                onClick={this.post.bind(this)} />
                        </div>
                    </div>
                )}
                {this.state.step === ComposerStage.EditingKudos && (
                    <Visage2ComposerPeoplePicker
                        post={this.state.post.content.find(x => x.StatusType === SpintrTypes.StatusType.Kudos)}
                        onPostUpdate={(updatedPost) => {
                            this.setState({
                                post: {
                                    ...this.state.post,
                                    content: this.state.post.content.map((item) => {
                                        if (item.StatusType === SpintrTypes.StatusType.Kudos) {
                                            return updatedPost;
                                        } else {
                                            return item;
                                        }
                                    })
                                }
                            })
                        }}
                        setStep={this.setStep.bind(this)} />
                )}
                {this.state.step === ComposerStage.SelectFrameFont && (
                    <Visage2ComposerFrameFontSelector
                        editorState={this.state.editorState}
                        post={this.state.post}
                        onPostUpdate={this.onPostUpdate}
                        setStep={this.setStep.bind(this)} />
                )}
            </Modal>
        )
    }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, Spintr.AppState> = (state) => ({
    currentUser: state.profile.active,
    enableSharepoint: state.instance.get("enableSharepoint"),
    disableNewDealPostType: state.instance.get("disableNewDealPostType"),
    publishToGeneralFeedChecked: state.instance.get("publishToGeneralFeedChecked"),
    enableProjectPostsOnStartpage: state.instance.get("enableProjectPostsOnStartpage"),
    productsOptions: {
        enabled: (state.instance.apps || state.instance.get("apps") || [])
            .some((app: any) => app.id === SpintrTypes.SpintrApp.Products && app.enabled === true),
        patterns: (state.instance.get("articleIdPatterns") || [])
            .map((pattern: string) => {
                try {
                    return new RegExp(pattern, "gmi");
                } catch (_) {
                    return null;
                }
            })
            .filter((pattern: RegExp | null) => pattern !== null),
    }
});

const connector = connect<StateProps, DispatchProp, OwnProps, Spintr.AppState>(mapStateToProps);

export default connector(Visage2Composer);
