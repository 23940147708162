import moment from "moment";
import { localize } from "src/l10n";

const getStrFromOpenTime = (openTime) => {
    if (!openTime.isOpen) {
        return localize("Stangd");
    }

    return localize("OPEN") +
        " " +
        moment(openTime.startTime).format("HH:mm") +
        " - " +
        moment(openTime.endTime).format("HH:mm"); 
}

const getOpenTime = (openTimes) => {
    if (!openTimes || openTimes.length === 0) {
        return "";
    }

    const now = new Date();

    const dayOfWeek = now.getDay();

    for (let openTime of openTimes) {
        if (openTime.dayOfWeek === dayOfWeek) {
            return getStrFromOpenTime(openTime);
        }
    }

    for (let openTime of openTimes) {
        if (openTime.dayOfWeek === 0) {
            return getStrFromOpenTime(openTime);
        }
    }

    return localize("Stangd");
}

export default getOpenTime;