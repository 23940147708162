import React, { useMemo } from 'react';
import "./ComposerAttachmentGeneral.scss";
import { Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { SpintrTypes } from 'src/typings';
import { localize } from 'src/l10n';

interface IProps {
    name: string;
    onRemove: () => void;
}

const ComposerAttachmentGeneral = (props: IProps) => {
    return (
        <div className="ComposerAttachmentGeneral">
            <Label size="body-3" title={props.name}>{props.name}</Label>
            <UnstyledButton className="close-button" onClick={props.onRemove} title={localize("TaBort")}>
                <Visage2Icon icon="close-circle" />
            </UnstyledButton>
        </div>
    )
}

export default ComposerAttachmentGeneral;
