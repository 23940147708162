import React, { Fragment, ReactElement } from "react";
import { SpintrTypes } from "src/typings";
import { PlannerItemDetailProps } from "./PlannerItemDetail.types";
import { Conditional } from "src/components/Conditional";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { CaptionBolder, SmallBody, SmallBodyBolder } from "src/components/Text";
import { localize } from "src/l10n";
import { PlannerFileList } from "../PlannerFileList";

function PlannerDeliveryDetails({ item }: PlannerItemDetailProps): ReactElement {
    if (item.itemType !== SpintrTypes.PlannerItemType.Delivery) {
        return null;
    }

    const displayOrderBox = (
        item.orderId && item.orderId.length > 0 ||
        item.supplier && item.supplier.length > 0);

    return (
        <Fragment>
            <Conditional condition={displayOrderBox}>
                <section className="PlannerItemDetail-infoBlock">
                    <div className="PlannerItemDetail-infoBlock-content">

                        <Conditional condition={item.supplier && item.supplier.length > 0}>
                            <div className="PlannerItemDetail-infoBlock-property">
                                <div className="PlannerItemDetail-infoBlock-property-label">
                                    <Visage2Icon icon="tag" color="spintrGrey" size="medium" />
                                    <CaptionBolder color="contentDark">
                                        {localize("PLANNER_DELIVERY_SUPPLIER")}
                                    </CaptionBolder>
                                </div>
                                <div className="PlannerItemDetail-infoBlock-property-value">
                                    <SmallBody color="contentNormal">
                                        {item.supplier}
                                    </SmallBody>
                                </div>
                            </div>
                        </Conditional>

                        <Conditional condition={item.orderId && item.orderId.length > 0}>
                            <div className="PlannerItemDetail-infoBlock-property">
                                <div className="PlannerItemDetail-infoBlock-property-label">
                                    <Visage2Icon icon="tag" color="spintrGrey" size="medium" />
                                    <CaptionBolder color="contentDark">
                                        {localize("PLANNER_DELIVERY_ORDERID")}
                                    </CaptionBolder>
                                </div>
                                <div className="PlannerItemDetail-infoBlock-property-value">
                                    <SmallBody color="contentNormal">
                                        {item.orderId}
                                    </SmallBody>
                                </div>
                            </div>
                        </Conditional>
                    </div>
                </section>
            </Conditional>
            
            <Conditional condition={item.goodsDescription && item.goodsDescription.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_DELIVERY_GOODS")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.goodsDescription}
                    </SmallBody>
                </div>
            </Conditional>

            <PlannerFileList
                fieldType={SpintrTypes.PlannerDeliveryFileType.DeliveryPlan}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Delivery} 
                title={localize("PLANNER_DELIVERY_PLAN")}
                text={item.deliveryPlan} />

            <PlannerFileList
                fieldType={SpintrTypes.PlannerDeliveryFileType.ProofOfDelivery}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Delivery} 
                title={localize("PLANNER_DELIVERY_PROOF")}
                text={item.proofOfDelivery} />
        </Fragment>
    );
}

export default PlannerDeliveryDetails;
