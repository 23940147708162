import { Checkbox, Dropdown, Pivot, PivotItem, PrimaryButton, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { Action } from "redux";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { FileSelector } from "src/spintr/components";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { Breadcrumbs, ContentWithSubmenu, PageHeader, SpintrUser } from "src/ui";
import { FormControl, FormFooterBar, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import { Style } from "src/ui/helpers";
import { scrollToTop, validateRequiredTextField } from "src/utils";

interface IProps {
    history: any;
    match: any;
    isAdmin: boolean;
    isEditor: boolean;
    restrictWikis?: boolean;
    currentUserId: number;
    currentUserName: string;
    currentUserDepartmentName: string;
    currentUserImageUrl: string;
    requiredTagCount: number;
    enableCompany?: boolean;
    enableUnsplash?: boolean;
    dispatch: (action: Action) => void;
}

interface IState {
    isLoading: boolean;
    wiki: any;
    hasExtendedRights: boolean;
    saveError: string[];
    enableFieldValidation?: boolean;
    selectedAppearance: any;
    showImageSelector: boolean;
}

class WikiEditView extends Component<IProps, IState> {
    private isNew = !!!this.props.match.params.wikiId;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: !this.isNew,
            hasExtendedRights: this.isNew || this.props.isAdmin || this.props.isEditor,
            saveError: [],
            wiki: {
                allownewarticles: true,
                allowediting: true,
                published: true,
                isprioritized: false,
                editor: {
                    id: this.props.currentUserId,
                    name: this.props.currentUserName,
                    info: this.props.currentUserDepartmentName,
                    imageUrl: this.props.currentUserImageUrl,
                    type: SpintrTypes.UberType.User,
                },
                editorid: 0,
                name: "",
                description: "",
                targets: [],
                tags: [],
                deleted: false,
            },
            selectedAppearance: 2,
            showImageSelector: false
        };
    }

    componentDidMount() {
        if (this.props.restrictWikis) {
            if (!this.props.isAdmin && !this.props.isEditor) {
                this.props.history.goBack();
                return;
            }
        }

        const id = this.props.match.params.wikiId;
        if (id) {
            api.get(`/api/wikis/${id}`).then((response) => {
                var hasExtendedRights = this.state.hasExtendedRights ||
                    this.props.currentUserId === response.data.wiki.editor.id;

                this.setState({
                    isLoading: false,
                    wiki: response.data.wiki,
                    hasExtendedRights,
                    // selectedAppearance: !!response.data.wiki.imageUrl ?
                    //     2 :
                    //     1
                });
            });
        }
    }

    componentDidUpdate() { }

    formFooterButtons() {
        return [
            ...(!this.isNew && this.state.hasExtendedRights
                ? [
                    {
                        text: localize(this.state.wiki.deleted ? "Aterstall" : "TaBort"),
                        onClick: () => {
                            if (!this.state.wiki.deleted) {
                                this.props.dispatch(
                                    setConfirmPopup({
                                        isOpen: true,
                                        title: localize("ArDuPaSakerAttDuVillTaBortDennaWiki") + "?",
                                        onConfirm: () => {
                                            api
                                                .put(`/api/v1/wikis/${this.props.match.params.wikiId}/toggledelete`)
                                                .then((response) => {
                                                    this.props.history.push({ pathname: "/wikis" });
                                                });
                                        },
                                    })
                                );
                                return;
                            }

                            this.setState({ isLoading: true });
                            api
                                .put(`/api/v1/wikis/${this.props.match.params.wikiId}/toggledelete`)
                                .then((response) => {
                                    this.props.history.push({
                                        pathname: `/goto/${this.props.match.params.wikiId}`,
                                    });
                                });
                        },
                    },
                ]
                : []),
        ];
    }

    onCancelClick() {
        if (this.isNew) {
            this.props.history.push({
                pathname: "/wikis",
            });
        } else {
            this.props.history.push({
                pathname: this.state.wiki.url,
            });
        }
    }

    onSaveClick() {
        this.setState({
            enableFieldValidation: true
        });

        let saveErrors = [];

        if (!this.state.wiki.name) {
            saveErrors.push("DuMasteFyllaIEttNamn");
        }

        if (!this.state.wiki.description) {
            saveErrors.push("DuMasteAngeEnBeskrivning");
        }

        if (
            this.props.requiredTagCount > 0 &&
            (this.state.wiki.tags.length < this.props.requiredTagCount)
        ) {
            saveErrors.push(
                `${localize("DuMasteAngeMinst")} ${this.props.requiredTagCount} ${localize("Taggar").toLowerCase()}`
            );
        }

        if (saveErrors.length > 0) {
            this.setState(
                {
                    saveError: saveErrors,
                },
                () => {
                    scrollToTop();
                }
            );
            return;
        }

        this.setState({
            isLoading: true,
        });

        const postData = {
            ...this.state.wiki,
            tags: this.state.wiki.tags.map((tag) => tag?.name || tag).join(", "),
        }

        if (this.isNew) {
            api
                .post("/api/wikis", postData)
                .then((response) => {
                    // TODO: Go to article creation
                    this.props.history.push({
                        pathname: `/wikis/create-article/${response.data.wiki.id}`,
                    });
                })
                .catch(() => {
                    this.setState({ isLoading: false });
                });
        } else {
            api
                .put("/api/wikis", postData)
                .then((response) => {
                    this.props.history.goBack();
                })
                .catch(() => {
                    this.setState({ isLoading: false });
                });
        }
    }

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }
        let adminView = false;
        if (window.location.href.indexOf("admin") > -1) {
            adminView = true;
        }
        const wiki = this.state.wiki;

        return (
            <div>
                <Breadcrumbs
                    displayInstance
                    items={[
                        ...(adminView ? [{
                            text: localize("Administration"),
                            key: 2,
                            link: "/admin",
                        }] : []), {
                            text: localize("Wikis"),
                            key: 3,
                            link: adminView ? "/admin/wikis" : "/wikis",
                        }, {
                            text: this.isNew ? localize("SkapaWiki") : localize("RedigeraWiki"),
                            key: 4,
                            link: window.location.pathname,
                        },
                    ]}
                />
                <Helmet>
                    <title>{localize(this.isNew ? "SkapaWiki" : "RedigeraWiki")}</title>
                </Helmet>
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <div className="page-margin-bottom">
                    <PageHeader
                        title={this.isNew ? localize("SkapaWiki") : localize("RedigeraWiki")}
                        subText={this.isNew ? localize("SkapaWikiText") : undefined}
                        displayMandatoryText />
                    <Pivot>
                        <PivotItem headerText={localize("Allmant")} style={{ marginBottom: "6em" }}>
                            <form>
                                <FormControl>
                                    <TextField
                                        label={localize("WikinsNamn")}
                                        value={wiki.name}
                                        onChange={(ev, val) => {
                                            this.setState((prevState) => ({
                                                wiki: {
                                                    ...prevState.wiki,
                                                    name: val,
                                                },
                                            }));
                                        }}
                                        maxLength={64}
                                        required
                                        aria-required
                                        validateOnFocusIn
                                        validateOnFocusOut
                                        validateOnLoad={!!this.state.enableFieldValidation}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        label={localize("WikinsBeskrivning")}
                                        value={wiki.description}
                                        onChange={(ev, val) => {
                                            this.setState((prevState) => ({
                                                wiki: {
                                                    ...prevState.wiki,
                                                    description: val,
                                                },
                                            }));
                                        }}
                                        required
                                        multiline
                                        autoAdjustHeight
                                        aria-required
                                        validateOnFocusIn
                                        validateOnFocusOut
                                        validateOnLoad={!!this.state.enableFieldValidation}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                                <FormSection title={localize("Utseende")}>
                                    {/* <FormControl>
                                        <Dropdown
                                            label={localize("Valj") + " " + localize("Utseende").toLowerCase()}
                                            selectedKey={this.state.selectedAppearance}
                                            onChange={(_e, v) => {
                                                this.setState({
                                                    selectedAppearance: v.key
                                                });
                                            }}
                                            options={[
                                                {
                                                    key: 1,
                                                    text: localize("Ikon"),
                                                },
                                                {
                                                    key: 2,
                                                    text: localize("Bild"),
                                                },
                                            ]}
                                        />
                                    </FormControl> */}
                                    {
                                        this.state.selectedAppearance === 2 && (
                                            <div>
                                                <FormControl label={localize("Bild")}>
                                                    {
                                                        !this.state.wiki.imageUrl && (
                                                            <PrimaryButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showImageSelector: true
                                                                    });
                                                                }}
                                                            >
                                                                {localize("ValjBild")}
                                                            </PrimaryButton>
                                                        )
                                                    }
                                                    {this.state.wiki.imageUrl && (
                                                        <div>
                                                            <SpintrUser
                                                                size={80}
                                                                hideText={true}
                                                                name={this.state.wiki.name}
                                                                imageUrl={this.state.wiki.imageUrl}
                                                                personalName={false} />
                                                            <FormControl>
                                                                <PrimaryButton
                                                                    style={{ marginTop: Style.getSpacingStr(3) }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            wiki: {
                                                                                ...this.state.wiki,
                                                                                imageUrl: "",
                                                                                imageTicket: {
                                                                                    ticket: "",
                                                                                    remove: true
                                                                                }
                                                                            }
                                                                        });
                                                                    }}
                                                                >
                                                                    {localize("TaBortNuvarande")}
                                                                </PrimaryButton>
                                                            </FormControl>
                                                        </div>
                                                    )}
                                                    {this.state.showImageSelector && (
                                                        <FileSelector
                                                            onClose={() => {
                                                                this.setState({
                                                                    showImageSelector: false,
                                                                });
                                                            }}
                                                            onSelect={(data) => {
                                                                this.setState({
                                                                    showImageSelector: false,
                                                                    wiki: {
                                                                        ...this.state.wiki,
                                                                        imageUrl: data[0].thumbnailUrl,
                                                                        imageTicket: {
                                                                            ticket: data[0].ticket,
                                                                            remove: false,
                                                                            image: data[0].thumbnailUrl,
                                                                            hotlink: data[0].isUnsplash,
                                                                        },
                                                                        icon: ""
                                                                    }
                                                                });
                                                            }}
                                                            allowMultipleFiles={false}
                                                            fileTypesString={"image/png, image/jpeg"}
                                                            fileTypes={["png", "jpeg", "jpg"]}
                                                            sourcesToDisplay={[0]}
                                                            fileUploadType={1}
                                                            enableUnsplash={this.props.enableUnsplash}
                                                        />
                                                    )}
                                                </FormControl>
                                            </div>
                                        )
                                    }
                                    {/* {
                                        this.state.selectedAppearance === 1 && (
                                            <div>
                                                <IconPicker selectedIcon={this.state.wiki.icon} onSelect={(i) => {
                                                    this.setState({
                                                        wiki: {
                                                            ...this.state.wiki,
                                                            icon: i.name,
                                                            imageUrl: "",
                                                            imageTicket: {
                                                                ticket: "",
                                                                remove: true
                                                            }
                                                        }
                                                    })
                                                }} />
                                            </div>
                                        )
                                    } */}
                                </FormSection>
                            </form>
                        </PivotItem>
                        <PivotItem headerText={localize("Installningar")} style={{ marginBottom: "6em" }}>
                            <form>
                                <FormSection
                                    title={`${localize("Installningar")} ${localize("For").toLowerCase()} ${localize(
                                        "Wikin"
                                    ).toLowerCase()}`}
                                >
                                    <FormControl>
                                        <Checkbox
                                            label={localize("Publicerad")}
                                            checked={wiki.published}
                                            onChange={(ev, checked) => {
                                                this.setState((prevState) => ({
                                                    wiki: {
                                                        ...prevState.wiki,
                                                        published: checked,
                                                    },
                                                }));
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Checkbox
                                            label={localize("Prioriterad")}
                                            checked={wiki.isprioritized}
                                            onChange={(ev, checked) => {
                                                this.setState((prevState) => ({
                                                    wiki: {
                                                        ...prevState.wiki,
                                                        isprioritized: checked,
                                                    },
                                                }));
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Checkbox
                                            label={localize("NyaArtiklarArTillatna")}
                                            checked={wiki.allownewarticles}
                                            onChange={(ev, checked) => {
                                                this.setState((prevState) => ({
                                                    wiki: {
                                                        ...prevState.wiki,
                                                        allownewarticles: checked,
                                                    },
                                                }));
                                            }}
                                        />
                                    </FormControl>

                                    {this.state.hasExtendedRights && (
                                        <FormControl>
                                            <Checkbox
                                                label={localize("RedigeringArTillaten")}
                                                checked={wiki.allowediting}
                                                onChange={(ev, checked) => {
                                                    this.setState((prevState) => ({
                                                        wiki: {
                                                            ...prevState.wiki,
                                                            allowediting: checked,
                                                        },
                                                    }));
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                </FormSection>
                                <FormSection title={localize("Utgivare")}>
                                    <FormTokenizedObjectInput
                                        label={localize("AnsvarigUtgivare")}
                                        itemLimit={1}
                                        types={[SpintrTypes.UberType.User]}
                                        items={wiki.editor ? [wiki.editor] : []}
                                        onChange={(editor) => {
                                            this.setState((prevState) => ({
                                                wiki: {
                                                    ...prevState.wiki,
                                                    editorid: editor[0] && editor[0].key,
                                                    editor: editor.map((e) => {
                                                        return {
                                                            name: e.name,
                                                            info: e.subText,
                                                            type: SpintrTypes.UberType.User,
                                                            id: e.key,
                                                            imageUrl: e.imageUrl,
                                                        };
                                                    })[0],
                                                },
                                            }));
                                        }}
                                    />
                                </FormSection>
                                <FormSection title={localize("Taggar")}>
                                    <FormTokenizedObjectInput
                                        label={localize("SokBlandTaggar")}
                                        items={wiki.tags.map((tag) => {
                                            if (typeof tag === "string") {
                                                return {
                                                    name: tag,
                                                };
                                            }

                                            return tag;
                                        })}
                                        onChange={(tags) => {
                                            this.setState((prevState) => ({
                                                wiki: {
                                                    ...prevState.wiki,
                                                    tags,
                                                }
                                            }));
                                        }}
                                        required={this.props.requiredTagCount > 0}
                                        hideImage
                                        showTags
                                        enableNewItems
                                    />
                                </FormSection>
                                <FormSection title={localize("SynligFor")}>
                                    <FormTokenizedObjectInput
                                        showAllWhenEmpty
                                        label={localize("RiktadTillBeskrivning")}
                                        types={[
                                            SpintrTypes.UberType.Department,
                                            SpintrTypes.UberType.Office,
                                            SpintrTypes.UberType.Role,
                                            SpintrTypes.UberType.TargetGroup,
                                            ...(this.props.enableCompany ? [SpintrTypes.UberType.Company] : []),
                                        ]}
                                        items={wiki.targets.map(t => {
                                            return {
                                                ...t,
                                                key: t.key ? t.key : t.id,
                                                //@ts-ignore
                                                id: t.id ? t.id : key
                                            }
                                        })}
                                        onChange={(targets) => {
                                            this.setState((prevState) => ({
                                                wiki: {
                                                    ...prevState.wiki,
                                                    targets: targets.map((e) => {
                                                        return {
                                                            //@ts-ignore
                                                            id: e.id ? e.id : e.key,
                                                            //@ts-ignore
                                                            key: e.key ? e.key : e.id,
                                                            name: e.name,
                                                            type: e.type,
                                                            info: e.subText,
                                                            imageUrl: e.imageUrl,
                                                        };
                                                    }),
                                                },
                                            }));
                                        }}
                                    />
                                </FormSection>
                            </form>
                        </PivotItem>
                    </Pivot>


                    <FormFooterBar
                        buttons={this.formFooterButtons()}
                        onCancelClick={this.onCancelClick.bind(this)}
                        onSaveClick={this.onSaveClick.bind(this)}
                        saveText={localize(this.isNew ? "Publicera" : "Publicera")}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
        currentUserName: state.profile.active.name,
        currentUserDepartmentName: state.profile.active.department.name,
        currentUserImageUrl: state.profile.active.images["250x999"],
        requiredTagCount: state.instance.get("requiredTagCount"),
        enableCompany: state.instance.get("enableCompanyLevel"),
        enableUnsplash: state.instance.get("enableUnsplash"),
        restrictWikis: state.instance.get("restrictWikis"),
    };
};

export default connect(mapStateToProps)(WikiEditView);
