import React, { CSSProperties, ReactElement, useMemo } from "react";
import { CaptionBody } from "src/components";
import { localize } from "src/l10n";
import { TimelineHeaderProps } from "./TimelineHeader.types";
import { TimelineHeaderCell } from "../TimelineHeaderCell";
import { Conditional } from "src/components/Conditional";
import { UnstyledButton } from "src/ui";
import classNames from "classnames";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { calculateBarPosition } from "../utils";

function TimelineHeader({
    axisWidth,
    columnDurations,
    columnWidth,
    timelineDuration,
    timelineMode,
    timelineWidth,
    todayMs,
}: TimelineHeaderProps): ReactElement {
    const dateContainerStyle = useMemo<CSSProperties>(
        () => ({
            width: `${timelineWidth}px`,
        }),
        [timelineWidth],
    );

    const _ = useMemo<[number, number]>(() => {
        const columnIndex = columnDurations.reduce((acc, columnDuration, index) => {
            if (acc > -1) {
                return acc;
            }

            if (todayMs < columnDuration.startMilliseconds || columnDuration.endMilliseconds < todayMs) {
                return -1;
            }

            return index;
        }, -1);

        return [columnIndex, todayMs];
    }, [columnDurations, todayMs]);

    const headerCelLData = useMemo(() => columnDurations.map((columnDuration, index) => {
        const { left, right } = calculateBarPosition(
            timelineWidth,
            timelineDuration,
            columnDuration.startMilliseconds,
            columnDuration.endMilliseconds,
        );

        return {
            columnDuration,
            index,
            style: {
                left: `${left}px`,
                right: `${right}px`,
            } as CSSProperties,
        };
    }), [columnDurations, timelineDuration, timelineWidth]);

    return (
        <header className="TimelineHeader" role="rowgroup">
            <div className="TimelineHeader-row" role="row">
                <div
                    className="TimelineHeader-cell"
                    role="rowheader"
                    style={{
                        overflow: axisWidth > 0 ? undefined : "hidden",
                        width: `${axisWidth}px`,
                    }}
                >
                    <CaptionBody
                        className="TimelineHeader-axis-label"
                        color="contentLight"
                    >
                        {localize("Kategorier")}
                    </CaptionBody>
                </div>
                <div
                    className="TimelineHeader-date-labels-container"
                    style={dateContainerStyle}
                >
                    <div className="TimelineHeader-date-labels">
                        {headerCelLData.map((cellData) => (
                            <TimelineHeaderCell
                                columnDuration={cellData.columnDuration}
                                key={cellData.index}
                                style={cellData.style}
                                timelineMode={timelineMode} />
                        ))}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default TimelineHeader;
