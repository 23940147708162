import React, { useCallback, useEffect, useRef, useState } from "react";
import { Label, setResponsiveShortcutsVisible } from "src/ui";
import "./DrawerPopoutView.scss";
import { Panel } from "@fluentui/react";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    headerText?: string;
    onDismiss?: any;
    children?: any;
    location?: any;
    history?: any;
    isVisible?: boolean;
    containerClassName?: string;
    icon?: string;
}

const DrawerPopoutView = (props: IProps) => {
    const prevProps = useRef(props);

    const dismiss = useCallback(() => {
        props.onDismiss();
        document.querySelector("html").classList.remove("overflow-hidden");
    }, []);

    useEffect(() => {
        document.querySelector("html").classList.add("overflow-hidden");
    }, []);

    useEffect(() => {
        if (
            !!props.location &&
            !!prevProps.current.location &&
            props.location.pathname !== prevProps.current.location.pathname
        ) {
            dismiss();
        }
    }, [props.location]);

    const renderHeaderText = () => {
        return (
            <div className="DrawerHeader">
                {props.icon && <Visage2Icon icon={props.icon} className="ShortcutsIcon" />}
                <Label weight="semi-bold" size="h4">{props.headerText}</Label>
            </div>
        )
    }

    return (
        <div>
            <Panel
                onRenderHeader={renderHeaderText}
                isOpen
                allowTouchBodyScroll
                className={props.containerClassName}
                onDismiss={dismiss}
                isLightDismiss>
                <div className="isBigView">
                    {props.children}
                </div>
            </Panel>
        </div>
    );
};

export default (DrawerPopoutView);
