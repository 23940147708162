import React from "react";
import { localize } from "src/l10n";
import { Label, SpintrUser } from "src/ui";
import CompactTable from "src/ui/components/CompactTable/CompactTable";
import "./Podium.scss";
import { Stack, StackItem } from "@fluentui/react";

interface IProps {
    data: any[];
}

const Podium = (props: IProps) => {
    const renderPodiumUser = (idx) => (
        <>
            {props.data[idx] && (
                <div className="user">
                    <div className="image">
                        <SpintrUser size={44} hideText name={props.data[idx].user.name} imageUrl={props.data[idx].user.image} personalName />
                        <div className="medal">{idx === 0 ? "🥇" : idx === 1 ? "🥈" : "🥉"}</div>
                    </div>
                    <Label size="h4" weight="medium">
                        {props.data[idx].user.name}
                    </Label>
                    <Label size="h5" color="grey">
                        {props.data[idx].count} {localize(props.data[idx].count === 1 ? "COURSE" : "COURSES").toLowerCase()}
                    </Label>
                </div>
            )}
        </>
    );

    return (
        <div className="Podium">
            <Stack className="bars" horizontal grow verticalAlign="end">
                <StackItem grow>
                    <div className="second">
                        {renderPodiumUser(1)}
                        <div className="bar">
                            <Label size="big" weight="semi-bold">
                                2
                            </Label>
                        </div>
                    </div>
                </StackItem>
                <StackItem grow>
                    <div className="first">
                        {renderPodiumUser(0)}
                        <div className="bar">
                            <Label size="big" weight="semi-bold">
                                1
                            </Label>
                        </div>
                    </div>
                </StackItem>
                <StackItem grow>
                    <div className="third">
                        {renderPodiumUser(2)}
                        <div className="bar">
                            <Label size="big" weight="semi-bold">
                                3
                            </Label>
                        </div>
                    </div>
                </StackItem>
            </Stack>
        </div>
    );
};

export default Podium;
