import { LocationDescriptor } from 'history';
import moment from 'moment';
import React, { Component, MouseEvent, ReactNode } from 'react';
import { connect, DispatchProp, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { hitEnterOrSpace } from 'src/files/utils';
import { localize } from 'src/l10n';
import { Label } from 'src/ui/components/Label/Label';
import { circleSmall } from 'src/ui/helpers/style';
import { SpintrUser, UnstyledButton } from '../../ui';
import {
    markNotificationAsRead,
    markNotificationAsUnread,
    
} from '../actions';
import CommentNotificationText from './CommentNotificationText';
import ContentReviewAccepted from './ContentReviewAccepted';
import ContentReviewDeclined from './ContentReviewDeclined';
import EventDeletedNotification from './EventDeletedNotification';
import EventInviteNotification from './EventInviteNotification';
import FollowNotification from './FollowNotification';
import GroupInvitationNotification from './GroupInvitationNotification';
import GroupJoinRequestNotification from './GroupJoinRequestNotification';
import GroupMemberAcceptedInviteNotification from './GroupMemberAcceptedInviteNotification';
import ImageTagNotification from './ImageTagNotification';
import InAppTipNotification from './InAppTipNotification';
import LikeNotificationText from './LikeNotificationText';
import MentionNotificationText from './MentionNotificationText';
import MessageReplyNotification from './MessageReplyNotification';
import NewContentReview from './NewContentReview';
import NoteUpdatedNotificationText from './NoteUpdatedNotificationText';
import './NotificationItem.scss';
import PageExpiredNotification from './PageExpiredNotification';
import ProfilePageNotification from './ProfilePageNotification';
import QaAnsweredNotification from './QaAnsweredNotification';
import QaAnswerUpdatedNotification from './QaAnswerUpdatedNotification';
import QaCreatedNotification from './QaCreatedNotification';
import ReachNotificationText from './ReachNotificationText';
import TodoExpiredNotification from './TodoExpiredNotification';
import UserBlockedNotification from './UserBlockedNotification';
import UserJoinedEventNotification from './UserJoinedEventNotification';
import UserLeftFeedbackNotification from './UserLeftFeedbackNotification';
import ZippedFolderReadyNotification from './ZippedFolderReadyNotification';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import PhoenixSurveyNotification from './PhoenixSurveyNotification';
import SystemStatusNotification from './SystemStatusNotification';

interface IOwnProps extends RouteComponentProps {
    notification: Spintr.INotification;
}

interface IStateProps {
    currentUserId?: number;
    dispatch?: any;
}

interface IDispatchProps {
    markNotificationAsRead: (id: number) => void;
    markNotificationAsUnread: (id: number) => void;
}

type Props = IOwnProps & IStateProps & IDispatchProps;

class NotificationItem extends Component<Props> {
    constructor(props: Props) {
        super(props);

        this.markAsRead = this.markAsRead.bind(this);
        this.onToggleReadClick = this.onToggleReadClick.bind(this);
    }

    getDestination(): string | LocationDescriptor<{}> {
        const url = this.props.notification.url;

        if (!url) return;

        if (url.startsWith("/api/servefile/") || url.startsWith("http")) {
            return url;
        }

        if (url.indexOf("?") > -1) {
            return {
                pathname: url.split("?")[0],
                search: "?" + url.split("?")[1],
            };
        } else {
            return {
                pathname: url.split("?")[0],
            };
        }
    }

    onClick() {
        this.markAsRead();
        let url = this.getDestination();

        if (typeof url == "string" && (url.startsWith("/api/servefile/") || url.startsWith("http"))) {
            window.open(url, "_blank");
        } else {
            this.props.history.push(url);
        }
    }

    public render(): ReactNode {
        let className = "NotificationItem type" + this.props.notification.type;

        if (!this.props.notification.isRead) {
            className = className + " unread";
        }

        const isSystemStatus = this.props.notification.type == 57 ||
            this.props.notification.type == 58 ||
            this.props.notification.type == 59 ||
            this.props.notification.type == 60 ||
            this.props.notification.type == 61 ||
            this.props.notification.type == 62;

        return (
            <div
                className={className}
                tabIndex={0}
                onKeyUp={(e) => {
                    hitEnterOrSpace(e, this.onClick);
                }}
            >
                <div role="button" onClick={this.onClick.bind(this)}>
                    <div className="item">
                        <div className="NotificationItem-image">
                            <SpintrUser
                                // @ts-ignore
                                name={isSystemStatus ? this.props.notification.resourceName : this.props.notification.userName}
                                imageUrl={this.props.notification.imageUrl}
                                hideText={true}
                                personalName={!isSystemStatus}
                                size={circleSmall}
                            />
                        </div>
                        {this.renderInformation()}
                    </div>
                </div>
            </div>
        );
    }

    protected getNotificationComponent() {
        switch (this.props.notification.type) {
            case 0:
                return ProfilePageNotification;
            case 1:
                return ImageTagNotification;
            case 2:
                return FollowNotification;
            case 3:
            case 5:
                return CommentNotificationText;
            case 4:
                return LikeNotificationText;
            case 6:
                return MentionNotificationText;
            case 7:
                return GroupJoinRequestNotification;
            // gmacc = 8
            case 9:
                return EventInviteNotification;
            case 13:
                return GroupInvitationNotification;
            // conreq = 14
            // todoass = 16
            case 17:
                return GroupMemberAcceptedInviteNotification;
            case 19:
                return UserJoinedEventNotification;
            case 20:
                return TodoExpiredNotification;
            case 21:
                return EventDeletedNotification;
            case 22:
                return NewContentReview;
            case 23:
                return ContentReviewAccepted;
            case 24:
                return ContentReviewDeclined;
            case 25:
                return QaAnsweredNotification;
            case 26:
                return QaCreatedNotification;
            case 27:
                return NoteUpdatedNotificationText;
            case 28:
                return NoteUpdatedNotificationText;
            case 29:
                return QaAnswerUpdatedNotification;
            case 35:
                return PageExpiredNotification;
            case 36:
                return ZippedFolderReadyNotification;
            case 37:
                return ReachNotificationText;
            // contpub = 38
            // contunp = 39
            // contupd = 40
            // zipfail = 50
            case 51:
                return InAppTipNotification;
            case 53:
                return MessageReplyNotification;
            case 54:
                return UserLeftFeedbackNotification;
            case 56:
                return UserBlockedNotification;
            case 63:
                return PhoenixSurveyNotification;
            case 57:
            case 58:
            case 59:
            case 60:
            case 61:
            case 62:
                return SystemStatusNotification;
            default:
                // 41
                return null;
        }
    }

    protected markAsRead(event?: MouseEvent<HTMLElement>): void {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        this.props.markNotificationAsRead(this.props.notification.id);
    }

    protected renderInformation() {
        const NotificationComponent = this.getNotificationComponent();

        return (
            <div className="information underline-wrapper">
                <div className="upper">
                    <Label as="p" size="body-2" weight="medium">
                        {NotificationComponent !== null && (
                            <NotificationComponent
                                notification={this.props.notification}
                                // @ts-ignore
                                currentUserId={this.props.currentUserId}
                                history={this.props.history}
                                dispatch={this.props.dispatch}
                            />
                        )}
                        {NotificationComponent === null && <span>{this.props.notification.text}</span>}
                    </Label>
                    {!this.props.notification.isRead && (
                        <div className="unreadDot" />
                    )}
                </div>
                <div className="lower">
                    <div className="date">
                        <Label as="p" size="body-3" color="mid-grey">
                            {moment(this.props.notification.date).fromNow()}
                        </Label>
                    </div>
                    <UnstyledButton
                        ariaLabel={
                            this.props.notification.isRead
                                ? localize("chat_mark_unread")
                                : localize("chat_mark_read")
                        }
                        className="read-toggle"
                        onClick={this.onToggleReadClick}
                        title={
                            this.props.notification.isRead
                                ? localize("chat_mark_unread")
                                : localize("chat_mark_read")
                        }
                    >
                        <Visage2Icon
                            icon={
                                this.props.notification.isRead
                                    ? "eye"
                                    : "eye-slash"
                            }
                            color="mid-grey"
                            size="medium"
                        />
                    </UnstyledButton>
                </div>
            </div>
        );
    }

    private onToggleReadClick(): void {
        if (this.props.notification.isRead) {
            this.props.markNotificationAsUnread(this.props.notification.id);
        } else {
            this.props.markNotificationAsRead(this.props.notification.id);
        }
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> = (state) => ({
    currentUserId: state.profile.active.id,
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch) => ({
    markNotificationAsRead: (id) => dispatch(markNotificationAsRead(id)),
    markNotificationAsUnread: (id) => dispatch(markNotificationAsUnread(id)),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationItem));
