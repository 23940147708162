import React, { Fragment, ReactElement } from "react";
import { SpintrTypes } from "src/typings";
import { PlannerItemDetailProps } from "./PlannerItemDetail.types";
import { localize } from "src/l10n";
import { PlannerFileList } from "../PlannerFileList";
import { SmallBody, SmallBodyBolder } from "src/components/Text";
import { Conditional } from "src/components/Conditional";

function PlannerMaintenanceDetails({ item }: PlannerItemDetailProps): ReactElement {
    if (item.itemType !== SpintrTypes.PlannerItemType.Maintenance) {
        return null;
    }

    return (
        <Fragment>
            <Conditional condition={item.maintenanceType && item.maintenanceType.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_MAINTENANCE_TYPE")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.maintenanceType}
                    </SmallBody>
                </div>
            </Conditional>
            
            <Conditional condition={item.effectedEquipment && item.effectedEquipment.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_MAINTENANCE_EQUIPMENT")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.effectedEquipment}
                    </SmallBody>
                </div>
            </Conditional>
            
            <Conditional condition={item.expectedDowntime && item.expectedDowntime.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_MAINTENANCE_DOWNTIME")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.expectedDowntime}
                    </SmallBody>
                </div>
            </Conditional>

            <PlannerFileList
                fieldType={SpintrTypes.PlannerMaintenanceFileType.MaintenancePlan}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Maintenance} 
                title={localize("PLANNER_MAINTENANCE_PLAN")} />

            <PlannerFileList
                fieldType={SpintrTypes.PlannerMaintenanceFileType.MaintenanceReport}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Maintenance} 
                title={localize("PLANNER_MAINTENANCE_REPORT")}
                text={item.maintenanceReport} />
        </Fragment>
    );
}

export default PlannerMaintenanceDetails;
