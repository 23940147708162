import React, { useMemo } from 'react';
import "./ComposerAttachmentFile.scss";
import { Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { SpintrTypes } from 'src/typings';
import { localize } from 'src/l10n';

interface IProps {
    attachment: Spintr.IChatMessageAttachment;
    onRemove?: () => void;
}

const ComposerAttachmentFile = (props: IProps) => {
    return (
        <div className="ComposerAttachmentFile">
            <Visage2Icon icon="folder" size="small" />
            <Label size="body-3" title={props.attachment.name} color="dark-grey">{props.attachment.name}</Label>
            {props.onRemove && (
                <UnstyledButton className="close-button" onClick={props.onRemove} title={localize("TaBort")}>
                    <Visage2Icon icon="close-circle" />
                </UnstyledButton>
            )}
        </div>
    )
}

export default ComposerAttachmentFile;
