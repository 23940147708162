import React, { FunctionComponent } from "react";
import SystemStatusesPanel from "./SystemStatusesPanel";

interface IProps {
    history: any;
    location: any;
    match: any;
}

const ResponsiveSystemStatuses: FunctionComponent<IProps> = () => {
    return (
        <div>
            <SystemStatusesPanel />
        </div>
    );
};

export default ResponsiveSystemStatuses;
