import { DefaultButton, FontIcon, Modal, PrimaryButton, Separator, Stack, StackItem, TextField } from "@fluentui/react";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { localize } from "src/l10n";
import { setConfirmPopup } from 'src/popups/actions';
import { SpintrTypes } from 'src/typings';
import { Label, Loader } from "src/ui";
import { FormControl, FormTokenizedObjectInput } from 'src/ui/components/Forms';
import ConfirmDialog from 'src/ui/components/Forms/ConfirmDialog';
import ErrorMessagebar from 'src/ui/components/Messagebars/ErrorMessagebar';
import { Style } from 'src/ui/helpers';
import { isAnythingDirty } from 'src/utils';
import { UnstyledButton } from '../../../ui/components';
import { ExternalFile } from '../ExternalFile';
import "../ExternalFile/ExternalFile.scss";
import api from "src/spintr/SpintrApi";
import PopupHeader from "src/ui/components/PopupHeader";
import "./ShareModal.scss";

interface IProps {
    objectId: any;
    onDismiss: any;
    isExternalFile?: boolean;
    externalFileSource?: any;
    currentUser?: any;
    enableCompany?: boolean;
    isAdmin?: boolean;
    isEditor?: boolean;
    dispatch?: any;
}

interface IState {
    shareTo: any[];
    shareMessage: string;
    isSharing: boolean;
    saveError: string[];
    showConfirmDialog: boolean;
}

class ShareModal extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            shareTo: [{
                id: this.props.currentUser.id,
                name: localize('MittFlode').substring(0, 1).toUpperCase() + localize('MittFlode').substring(1)
            }],
            shareMessage: "",
            isSharing: false,
            saveError: [],
            showConfirmDialog: false
        };
    }

    afterSave() {
        this.props.onDismiss();
    }

    onSaveError() {
        this.setState({
            saveError: [localize("TeknisktFel")]
        });
    }

    confirmSave = () => {
        this.setState({ showConfirmDialog: true })
    }

    save() {
        if (this.state.shareTo.length === 0) {
            return;
        }

        this.setState({
            isSharing: true
        }, () => {
            if (this.props.isExternalFile) {
                if (this.state.shareTo[0].id === this.props.currentUser.id) {
                    api.post("/api/status", {
                        feedId: this.props.currentUser.feedId,
                        text: this.state.shareMessage,
                        externalFiles: [{
                            externalId: this.props.objectId,
                            source: this.props.externalFileSource
                        }]
                    }).then(this.afterSave.bind(this)).catch(this.onSaveError.bind(this));
                } else if (this.state.shareTo[0].type === SpintrTypes.UberType.User) {
                    api.get("/api/profiles/" + this.state.shareTo[0].id).then((response) => {
                        api.post("/api/v1/conversations/" + response.data.conversationId + "/externalfile", {
                            conversationType: 3,
                            file: {
                                id: this.props.objectId,
                                source: this.props.externalFileSource
                            }
                        }).then(this.afterSave.bind(this)).catch(this.onSaveError.bind(this));
                    }).catch(this.onSaveError.bind(this));
                } else if (this.state.shareTo[0].type === SpintrTypes.UberType.Group) {
                    api.get("/api/groups/" + this.state.shareTo[0].id).then((response) => {
                        api.post("/api/v1/conversations/" + response.data.conversationId + "/externalfile", {
                            conversationType: 3,
                            file: {
                                id: this.props.objectId,
                                source: this.props.externalFileSource
                            }
                        }).then(this.afterSave.bind(this)).catch(this.onSaveError.bind(this));
                    }).catch(this.onSaveError.bind(this));
                }
            } else {
                api.post("/api/status/share", {
                    objectId: this.props.objectId,
                    targetIds: [this.state.shareTo[0].id],
                    text: this.state.shareMessage
                }).then(this.afterSave.bind(this)).catch(this.onSaveError.bind(this));
            }
        })
    }

    onCloseModal() {
        const close = () => {
            this.props.onDismiss();
        }

        if (isAnythingDirty()) {
            this.props.dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: close
            }));
        } else {
            close();
        }
    }

    render() {
        let userHasSpecialPrivileges = this.props.isAdmin ||
            this.props.isEditor;;

        let targetTypes = [];

        if (userHasSpecialPrivileges &&
            !this.props.isExternalFile) {
            targetTypes = [
                SpintrTypes.UberType.User,
                SpintrTypes.UberType.Group,
                SpintrTypes.UberType.Department,
                SpintrTypes.UberType.Office,
                ...(this.props.enableCompany ? [SpintrTypes.UberType.Company] : []),
            ]
        } else {
            targetTypes = [
                SpintrTypes.UberType.User,
                SpintrTypes.UberType.Group
            ]
        }

        let displayTextInput = !this.props.isExternalFile ||
            (this.props.isExternalFile &&
                this.state.shareTo.length > 0 &&
                this.state.shareTo[0].id === this.props.currentUser.id);

        return (
            <Modal
                isOpen={true}
                className="spintr-modal modalWithPopupHeader share-modal"
                onDismiss={this.onCloseModal.bind(this)}
            >
                <div>
                    {this.state.saveError.length > 0 && (
                        <ErrorMessagebar
                            errorList={this.state.saveError}
                            onDismiss={this.onCloseModal.bind(this)}
                        />
                    )}
                    <PopupHeader
                        text={localize("Dela")}
                        onClose={this.onCloseModal.bind(this)} />
                    <Label className="info-label">{localize("SHARE_INFO")}</Label>
                    <div>
                        {
                            this.state.isSharing ?
                                <Loader /> :
                                <div>
                                    <form>
                                        <FormControl>
                                            <FormTokenizedObjectInput
                                                items={this.state.shareTo}
                                                itemLimit={1}
                                                types={targetTypes}
                                                label={localize("DelaTill")}
                                                onChange={(items) => {
                                                    this.setState({
                                                        shareTo: items.map(i => {
                                                            return {
                                                                ...i,
                                                                id: i.key
                                                            }
                                                        })
                                                    })
                                                }}
                                                shareableGroupsOnly
                                            />
                                        </FormControl>
                                        {
                                            displayTextInput ?
                                                <FormControl>
                                                    <TextField
                                                        label={localize("Meddelande")}
                                                        value={this.state.shareMessage}
                                                        multiline={true}
                                                        onChange={(_event: React.FormEvent, shareMessage: string) => {
                                                            this.setState(() => ({ shareMessage }));
                                                        }}
                                                    />
                                                </FormControl> :
                                                null
                                        }
                                        {
                                            this.props.isExternalFile ?
                                                <div style={{
                                                    marginBottom: Style.getSpacingStr(3)
                                                }}>
                                                    <ExternalFile
                                                        externalId={this.props.objectId}
                                                        source={this.props.externalFileSource}
                                                        hideShare={true} />
                                                </div> :
                                                null
                                        }
                                        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                            <DefaultButton
                                                text={localize("Avbryt")}
                                                onClick={this.onCloseModal.bind(this)}
                                            />
                                            <PrimaryButton
                                                disabled={this.state.isSharing}
                                                text={localize("Dela")}
                                                onClick={this.confirmSave}
                                            />
                                        </Stack>
                                    </form>
                                </div>
                        }
                    </div>
                    <ConfirmDialog
                        show={this.state.showConfirmDialog}
                        title={localize("SkapaInnehall")}
                        message={localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("DelaInnehall").toLowerCase())}
                        onDismiss={() => {
                            this.setState({ showConfirmDialog: false });
                        }}
                        onConfirm={() => {
                            this.setState({ showConfirmDialog: false }, () => {
                                this.save()
                            });
                        }}
                    />
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    enableCompany: state.instance.get("enableCompanyLevel"),
});

export default connect(mapStateToProps)(ShareModal);