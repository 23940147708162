import React, { ReactElement, useCallback, useMemo } from "react";
import { PlannerCommandBarProps } from "./PlannerCommandBar.types";
import { localize } from "src/l10n";
import { PageHeader, UnstyledButton } from "src/ui";
import classNames from "classnames";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { IContentHeaderButton } from "src/ui/components/PageHeader";

function PlannerCommandBar({
    hasWriteAccess,
    isSmallViewMode,
    onCreateClick,
    onTimelineModeChange,
    onSearchChange,
    onViewModeChange,
    searchText,
    timelineMode,
    viewMode,
}: PlannerCommandBarProps): ReactElement {
    const onSearchChanged = useCallback(
        (newValue?: string) => onSearchChange?.(newValue ?? ""),
        [onSearchChange],
    );

    const mainItems = useMemo(() => {
        const items: IContentHeaderButton[] = [];

        if (onViewModeChange) {
            items.push({
                key: "view-mode-switch",
                onRender: isSmallViewMode ? undefined : function renderViewModeSwitch(): ReactElement {
                    return (
                        <div className="viewMode-switch" key="view-mode-switch">
                            <UnstyledButton
                                className={classNames("switch-button", {
                                    active: viewMode === "LIST",
                                })}
                                onClick={() => onViewModeChange("LIST")}
                            >
                                <Visage2Icon
                                    color={viewMode === "LIST" ? "visageMidBlue" : "primaryContent"}
                                    icon="menu-1"
                                    size="small" />
                            </UnstyledButton>
                            <UnstyledButton
                                className={classNames("switch-button", {
                                    active: viewMode === "TIMELINE",
                                })}
                                onClick={() => onViewModeChange("TIMELINE")}
                            >
                                <Visage2Icon
                                    color={viewMode === "TIMELINE" ? "visageMidBlue" : "primaryContent"}
                                    icon="calendar-2"
                                    size="small" />
                            </UnstyledButton>
                        </div>
                    );
                },
                className: "timelineModeButton commandBarButton",
                text: localize("LIST_DISPLAY_VIEW_AS"),
                icon: "arrow-down-1",
                subMenuProps: {
                    items: [{
                        key: "viewmode-list",
                        onClick: () => onViewModeChange?.("LIST"),
                        text: localize("LIST_DISPLAY_LIST"),
                    }, {
                        key: "viewmode-timeline",
                        onClick: () => onViewModeChange?.("TIMELINE"),
                        text: localize("PLANNER_TIMELINE"),
                    }]
                }
            });
        }
        
        if (viewMode === "TIMELINE") {
            let textKey: string;
            switch (timelineMode) {
                case "DAYS":
                    textKey = "TIMELINE_DAYS";
                    break;

                default:
                case "WEEKS":
                    textKey = "TIMELINE_WEEKS";
                    break;

                case "MONTHS":
                    textKey = "TIMELINE_MONTHS";
                    break;

                case "QUARTERS":
                    textKey = "TIMELINE_QUARTERS";
                    break;
            }

            items.push({
                key: "timeline-mode",
                className: "timelineModeButton commandBarButton",
                text: localize(textKey),
                icon: "arrow-down-1",
                subMenuProps: {
                    items: [{
                        key: "timeline-days",
                        onClick: () => onTimelineModeChange?.("DAYS"),
                        text: localize("TIMELINE_DAYS"),
                    }, {
                        key: "timeline-weeks",
                        onClick: () => onTimelineModeChange?.("WEEKS"),
                        text: localize("TIMELINE_WEEKS"),
                    }, {
                        key: "timeline-months",
                        onClick: () => onTimelineModeChange?.("MONTHS"),
                        text: localize("TIMELINE_MONTHS")
                    }, {
                        key: "timeline-quarters",
                        onClick: () => onTimelineModeChange?.("QUARTERS"),
                        text: localize("TIMELINE_QUARTERS")
                    }]
                }
            });
        }

        if (hasWriteAccess) {
            let createClasses = "commandBarAddButton commandBarButton";
            if (!isSmallViewMode) {
                createClasses += " primaryBGColor primaryTextContrast";
            }

            items.push({
                key: "create-button",
                className: createClasses,
                iconProps: { iconName: "Add" },
                text: localize("SkapaNy"),
                onClick: onCreateClick,
                theme: "primary"
            });  
        }

        return items;
    }, [hasWriteAccess, onSearchChanged, onTimelineModeChange, onViewModeChange, searchText, timelineMode, viewMode, isSmallViewMode]);

    return (
        <div className="PlannerCommandBar">
            <PageHeader
                buttons={mainItems}
                displaySearch={true}
                onSearchQueryChange={onSearchChanged}
                title={localize("PLANNER")} />
        </div>
    );
}

export default PlannerCommandBar;
