import React, { Fragment, ReactElement } from "react";
import { SpintrTypes } from "src/typings";
import { PlannerItemDetailProps } from "./PlannerItemDetail.types";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { CaptionBolder, SmallBody, SmallBodyBolder } from "src/components/Text";
import { localize } from "src/l10n";
import { Conditional } from "src/components/Conditional";
import moment from "moment";
import { PlannerFileList } from "../PlannerFileList";

function PlannerEventDetails({ item }: PlannerItemDetailProps): ReactElement {
    if (item.itemType !== SpintrTypes.PlannerItemType.Event) {
        return null;
    }

    const displayInfoBox = (
        item.numberOfSeats !== undefined ||
        item.numberOfParticipants !== undefined ||
        item.registrationDeadline !== undefined
    );

    return (
        <Fragment>
            <Conditional condition={item.hostsAndSpeakers && item.hostsAndSpeakers.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_EVENT_HOSTS_SPEAKERS")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.hostsAndSpeakers}
                    </SmallBody>
                </div>
            </Conditional>

            <Conditional condition={displayInfoBox}>
                <section className="PlannerItemDetail-infoBlock">
                    <div className="PlannerItemDetail-infoBlock-content">
                        <Conditional condition={item.numberOfSeats !== undefined}>
                            <div className="PlannerItemDetail-infoBlock-property">
                                <div className="PlannerItemDetail-infoBlock-property-label">
                                    <Visage2Icon icon="tag" color="spintrGrey" size="medium" />
                                    <CaptionBolder color="contentDark">
                                        {localize("PLANNER_EVENT_SEAT_COUNT")}
                                    </CaptionBolder>
                                </div>
                                <div className="PlannerItemDetail-infoBlock-property-value">
                                    <SmallBody color="contentNormal">
                                        {item.numberOfSeats}
                                    </SmallBody>
                                </div>
                            </div>
                        </Conditional>

                        <Conditional condition={item.numberOfParticipants !== undefined}>
                            <div className="PlannerItemDetail-infoBlock-property">
                                <div className="PlannerItemDetail-infoBlock-property-label">
                                    <Visage2Icon icon="tag" color="spintrGrey" size="medium" />
                                    <CaptionBolder color="contentDark">
                                        {localize("PLANNER_EVENT_PARTICIPANT_COUNT")}
                                    </CaptionBolder>
                                </div>
                                <div className="PlannerItemDetail-infoBlock-property-value">
                                    <SmallBody color="contentNormal">
                                        {item.numberOfParticipants}
                                    </SmallBody>
                                </div>
                            </div>
                        </Conditional>

                        <Conditional condition={item.registrationDeadline !== undefined}>
                            <div className="PlannerItemDetail-infoBlock-property">
                                <div className="PlannerItemDetail-infoBlock-property-label">
                                    <Visage2Icon icon="tag" color="spintrGrey" size="medium" />
                                    <CaptionBolder color="contentDark">
                                        {localize("PLANNER_EVENT_DEADLINE")}
                                    </CaptionBolder>
                                </div>
                                <div className="PlannerItemDetail-infoBlock-property-value">
                                    <SmallBody color="contentNormal">
                                        {moment(item.registrationDeadline).format("YYYY-MM-DD HH:mm")}
                                    </SmallBody>
                                </div>
                            </div>
                        </Conditional>
                    </div>
                </section>
            </Conditional>
            
            <PlannerFileList
                fieldType={SpintrTypes.PlannerEventFileType.Agenda}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Event} 
                title={localize("PLANNER_EVENT_AGENDA")} />
            
            <PlannerFileList
                fieldType={SpintrTypes.PlannerEventFileType.EventMaterial}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Event}
                title={localize("PLANNER_EVENT_MATERIAL")} />
            
            <PlannerFileList
                fieldType={SpintrTypes.PlannerEventFileType.LogisticsInfo}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Event} 
                text={item.logisticsInformation}
                title={localize("PLANNER_EVENT_LOGISTICS")} />

        </Fragment>
    );
}

export default PlannerEventDetails;
