
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import "./CalypsoContentWithSidebar.scss";

interface IProps {
    isSmallViewMode?: any;
    children: ReactElement;
    renderSideBar: () => ReactElement;
    innerRef?: any;
}

const CalypsoContentWithSidebar = (props: IProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (props.innerRef) {
            props.innerRef.current = {
                toggleIsExpanded() {
                    setIsExpanded(!isExpanded);

                    return isExpanded ? "sidebar-left" : "sidebar-right";
                }
            }
        }
    }, [isExpanded]);

    const className = useMemo(() => {
        const result = ["CalypsoContentWithSidebar"];

        if (isExpanded) {
            result.push("expanded");
        }

        return result.join(" ");
    }, [isExpanded]);

    return (
        <div className={className}>
            <div className="content">
                {props.children}
            </div>
            <div className="sidebar">
                {props.renderSideBar()}
            </div>
        </div>
    )
};

const mapStateToProps = (state, props) => {
    return {
        ...props,
        isSmallViewMode: state.ui.isSmallViewMode,
    };
};

export default (connect(mapStateToProps)(CalypsoContentWithSidebar));
