import React from 'react';
import "./MessageContent.scss";
import { Label } from 'src/ui';
import { formatInteractiveText } from 'src/utils';
import MessageAttachments from '../MessageAttachments/MessageAttachments';
import MessageObjects from '../MessageObjects/MessageObjects';
import MemoHeader from '../MemoHeader/MemoHeader';

interface IProps {
    message: Spintr.IChatMessage;
}

const MessageContent = (props: IProps) => {
    return (
        <div className={"MessageContent"}>
            {props.message.isMemo && (
                <MemoHeader memoTitle={props.message.memoTitle} />
            )}
            {props.message.text && (
                <Label
                    className="interactive-text general-row-break">
                    {formatInteractiveText(props.message.text)}
                </Label>
            )}
            <MessageObjects message={props.message} />
            <MessageAttachments message={props.message} />
        </div>
    )
}

export default MessageContent;
