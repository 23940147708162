import React, { Component } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { queryConversations } from "src/chat/redux/conversation-actions";
import { setIsFollowing } from "src/groups/actions";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { ActionMenu, UnstyledButton } from "src/ui";
import { scrollToTop } from "src/utils";
import { joinGroup, leaveGroup } from "../group-actions";
import GroupHeaderInformation from "./GroupHeaderInformation";
import GroupMenu from "./GroupMenu";
import GroupSearchPopupView from "./GroupSearchPopupView";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "src/groups/views/GroupHeader.scss";
import DrawerPopoutView from "src/spintr/components/DrawerPopoutView";
import GroupHistoryView from "./GroupHistoryView";
import CalypsoButton from "src/ui/components/Buttons/CalypsoButton/CalypsoButton";

interface IProps {
    group?: any;
    currentUser?: any;
    groupHistoryExpanded?: boolean;
    smallViewMode?: boolean;
    dispatch?: (action: Action) => void;
    history: any;
    isInTeamsApp?: boolean;
    standaloneGroupHeader?: boolean;
}

interface IState {
    displayPins: boolean;
    displaySearch: boolean;
    saveError: string[];
}

class GroupHeader extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            displayPins: false,
            displaySearch: false,
            saveError: [],
        };
    }

    canInvite() {
        const isGroupAdmin = !!this.props.group.allMembers.find(
            (m) => m.id === this.props.currentUser.id && m.isAdministrator
        );

        return  !this.props.group.disableManualMembership &&
            !this.props.currentUser.isGroupUser &&
            (isGroupAdmin || this.props.group.availability === 1 || this.props.group.membersCanInvite);
    }

    renderActionMenu() {
        const isGroupAdmin = !!this.props.group.allMembers.find(
            (m) => m.id === this.props.currentUser.id && m.isAdministrator
        );

        const isFollowing = this.props.group.isFollowing;

        let items = [];

        if (isGroupAdmin) {
            items.push({
                text: localize("Installningar"),
                onClick: () => {
                    this.props.history.push({
                        pathname: "/groups/" + this.props.group.id + "/edit",
                    });
                },
            });
        }

        items.push({
            text: localize("Medlemmar"),
            onClick: () => {
                this.props.history.push({
                    pathname: "/groups/" + this.props.group.id + "/members",
                });
            },
        });

        if (this.canInvite()) {
            items.push({
                text: localize("LaggTillMedlemmar"),
                onClick: () => {
                    this.props.history.push({
                        pathname: "/groups/" + this.props.group.id + "/members/invite",
                    });
                },
            });
        }

        if (isGroupAdmin && this.props.group.version === 2) {
            items.push({
                text: localize("LaggTillNyTextsida"),
                onClick: () => {
                    this.props.history.push({
                        pathname: "/pages/create",
                        search: "?groupId=" + this.props.group.id,
                    });
                },
            });
        }

        items.push({
            icon: isFollowing ? "favoriteStarFill" : "favoriteStar",
            text: isFollowing ? localize("SlutaFoljDennaGrupp") : localize("FoljDennaGrupp"),
            onClick: () => {
                let req = isFollowing
                    ? api.delete("/api/v1/follow/" + this.props.group.id)
                    : api.post("/api/v1/follow", { id: this.props.group.id });

                this.props.dispatch(setIsFollowing(this.props.group.id, !isFollowing));

                req.then(() => { });
            },
        });

        if (this.props.group.isMember) {
            items.push({
                text: localize("LamnaGruppen"),
                onClick: async () => {
                    const fn = async () => {
                        api.delete(`/api/v1/groupmembers/${this.props.group.id}`).then(async (resp) => {
                            await this.props.dispatch(leaveGroup(this.props.group.id));

                            await this.props.history.push({
                                pathname: "/groups",
                            });

                            this.props.dispatch(queryConversations({
                                conversationsSkip: 0,
                                groupsSkip: 0,
                                usersSkip: 0,
                                take: 40,
                            }));
                        }).catch((error) => {
                            let saveError = ["TeknisktFel"];

                            if (error &&
                                error.response &&
                                error.response.data &&
                                error.response.data.errorlist) {
                                saveError = error.response.data.errorlist;
                            }

                            this.setState({
                                saveError,
                            }, () => {
                                scrollToTop();
                            });
                        });
                    }

                    this.props.dispatch(setConfirmPopup({
                        isOpen: true,
                        title: localize("LamnaGruppen"),
                        message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                        onConfirm: fn
                    }));
                },
            });
        } else
            items.push({
                text: localize("GaMed"),
                onClick: () => {
                    api.post(`/api/v1/groups/${this.props.group.id}/join`);
                    this.props.dispatch(joinGroup(this.props.group.id));
                },
            });

        return (
            <ActionMenu
                categories={[{
                    items
                }]}
                renderButton={() => {
                    return (
                        <CalypsoButton icon="more-square" title={localize("Alternativ")} />
                    )
                }}
            />
        )

        return (
            <ActionMenu
                categories={[
                    {
                        items,
                    },
                ]}
            />
        );
    }

    renderActions() {
        return (
            <div className="GroupHeader-actions">
                {!this.props.smallViewMode && this.props.group.version === 2 ? (
                    <CalypsoButton
                        icon="clock"
                        title={localize("Aktivitetslogg")}
                        classNames={["GroupHeader-action"]}
                        onClick={() => {
                            this.setState({
                                displayPins: true
                            });
                        }} />
                ) : null}
                {!this.props.smallViewMode && (
                    <CalypsoButton
                        icon="search-normal-1"
                        title={localize("Sok")}
                        classNames={["GroupHeader-action"]}
                        onClick={() => {
                            this.setState({
                                displaySearch: true
                            });
                        }} />
                )}
                {this.renderActionMenu()}
            </div>
        );
    }

    render() {
        const classes = ["GroupHeader"];

        if (this.props.standaloneGroupHeader) {
            classes.push("standalone");
            classes.push("visage-box-shadow");
        }

        return (
            <div className={classes.join(" ")}>
                <div className="top-row">
                    <GroupHeaderInformation
                        id={this.props.group.id}
                        imageUrl={this.props.group.imageUrl}
                        name={this.props.group.name}
                        description={this.props.group.description}
                        status={this.props.group.status}
                        availability={this.props.group.availability}
                        createdDate={this.props.group.created}
                        memberCount={this.props.group.membersCount}
                        type={this.props.group.type}
                        members={this.props.group.allMembers}
                        history={this.props.history}
                        canInvite={this.canInvite()}
                    />
                </div>
                <div className="bottom-row">
                    <div className="menu">
                        <GroupMenu history={this.props.history} group={this.props.group} />
                    </div>
                    <div className="buttons">
                        {this.renderActions()}
                    </div>
                </div>
                {this.state.displayPins && (
                    <DrawerPopoutView
                        icon="clock"
                        containerClassName={"group-popout"}
                        headerText={localize("Handelselogg")}
                        onDismiss={() => {
                            this.setState({
                                displayPins: false
                            });
                        }}>
                        <GroupHistoryView history={this.props.history} conversationId={this.props.group.conversationId} />
                    </DrawerPopoutView>
                )}
                {this.state.displaySearch && (
                    <DrawerPopoutView
                        containerClassName={"group-popout"}
                        headerText={localize("Sok")}
                        onDismiss={() => {
                            this.setState({
                                displaySearch: false
                            });
                        }}>
                        <GroupSearchPopupView groupId={this.props.group.id} />
                    </DrawerPopoutView>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps): IProps => ({
    ...props,
    currentUser: state.profile.active,
    group: state.groups.group,
    smallViewMode: state.ui.isSmallViewMode,
    isInTeamsApp: state.ui.isInTeamsApp,
    standaloneGroupHeader: state.ui.standaloneGroupHeader
});

export default connect(mapStateToProps)(GroupHeader);
