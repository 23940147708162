import React, { useState } from 'react';
import "./ParentMessage.scss";
import MessageContent from '../MessageContent/MessageContent';
import { UnstyledButton } from 'src/ui';

interface IProps {
    message: Spintr.IChatMessage;
    scrollToMessage: (id: number) => void;
}

const ParentMessage = (props: IProps) => {

    return (
        <UnstyledButton
            className="ParentMessage"
            onClick={() => props.scrollToMessage(props.message.id)}>
            <div className="bubble-wrapper">
                <div className="bubble">
                    <MessageContent message={props.message} />
                </div>
            </div>
        </UnstyledButton>
    )
}

export default ParentMessage;
