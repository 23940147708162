import { Dropdown, IDropdownOption, PrimaryButton, TimePicker } from "@fluentui/react";
import moment from "moment";
import React from "react";
import { localize } from "src/l10n";
import SpintrDatePicker from "src/spintr/components/SpintrDatePicker";
import { Label, UnstyledButton } from "src/ui";
import { capitalizeFirstLetter, uniqueId } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./OpenTimesEditor.scss";

interface IProps {
    openTimes: any[];
    onUpdate: any;
}

const OpenTimesEditor = (props: IProps) => {
    return (
        <div className="OpenTimesEditor">
            {props.openTimes.map((x: any, index: number) => {
                return (
                    <div key={index} className="row">
                        <Dropdown
                            label={localize("Dag")}
                            styles={{
                                dropdown: { width: 300 }
                            }}
                            required
                            placeholder={localize("Dag")}
                            selectedKey={x.dayOfWeek.toString()}
                            onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                                props.onUpdate(props.openTimes.map((openTime: any) => {
                                    if (openTime.id === x.id) {
                                        return  {
                                            ...openTime,
                                            dayOfWeek: item.key
                                        }
                                    }
    
                                    return openTime;
                                }));
                            }}
                            options={[
                                {
                                    key: "0",
                                    text: localize("ALL_DAYS")
                                },
                                {
                                    key: "1",
                                    text: capitalizeFirstLetter(localize("DayOfWeek1")),
                                },
                                {
                                    key: "2",
                                    text: capitalizeFirstLetter(localize("DayOfWeek2")),
                                },
                                {
                                    key: "3",
                                    text: capitalizeFirstLetter(localize("DayOfWeek3")),
                                },
                                {
                                    key: "4",
                                    text: capitalizeFirstLetter(localize("DayOfWeek4")),
                                },
                                {
                                    key: "5",
                                    text: capitalizeFirstLetter(localize("DayOfWeek5")),
                                },
                                {
                                    key: "6",
                                    text: capitalizeFirstLetter(localize("DayOfWeek6")),
                                },
                                {
                                    key: "7",
                                    text: capitalizeFirstLetter(localize("DayOfWeek7")),
                                },
                            ]}
                        />
                        <Dropdown
                            label={localize("OPEN")}
                            styles={{
                                dropdown: { width: 300 }
                            }}
                            required
                            placeholder={localize("OPEN")}
                            selectedKey={x.isOpen ? "0" : "1"}
                            onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                                props.onUpdate(props.openTimes.map((openTime: any) => {
                                    if (openTime.id === x.id) {
                                        return  {
                                            ...openTime,
                                            isOpen: item.key === "0"
                                        }
                                    }
    
                                    return openTime;
                                }));
                            }}
                            options={[
                                {
                                    key: "0",
                                    text: localize("Yes")
                                },
                                {
                                    key: "1",
                                    text: localize("No")
                                }
                            ]}
                        />
                        {x.isOpen && (
                            <SpintrDatePicker
                                showTimeSelectOnly
                                label={localize("Fran")}
                                value={x.startTime}
                                onChangeHandler={(date) => {
                                    props.onUpdate(props.openTimes.map((openTime: any) => {
                                        if (openTime.id === x.id) {
                                            return  {
                                                ...openTime,
                                                startTime: date
                                            }
                                        }
        
                                        return openTime;
                                    }));
                                }}
                            />
                        )}
                        {x.isOpen && (
                            <SpintrDatePicker
                                showTimeSelectOnly
                                label={localize("Till")}
                                value={x.endTime}
                                onChangeHandler={(date) => {
                                    props.onUpdate(props.openTimes.map((openTime: any) => {
                                        if (openTime.id === x.id) {
                                            return  {
                                                ...openTime,
                                                endTime: date
                                            }
                                        }
        
                                        return openTime;
                                    }));
                                }}
                            />
                        )}
                        <UnstyledButton
                            title={localize("Stang")}
                            onClick={() => {
                                props.onUpdate(props.openTimes.filter(x => x.id !== x.id));
                            }}>
                            <Visage2Icon icon="close-circle" />
                        </UnstyledButton>
                    </div>
                )
            })}
            <PrimaryButton onClick={() => {
                props.onUpdate([
                    ...props.openTimes,
                    {
                        id: uniqueId(),
                        dayOfWeek: "0",
                        isOpen: true,
                        startTime: new Date(2024, 1, 1, 8, 0, 0, 0),
                        endTime: new Date(2024, 1, 1, 17, 0, 0, 0)
                    }
                ]);
            }}>
                <Label>{localize("ADD_DAY")}</Label>
            </PrimaryButton>
        </div>
    );
}

export default OpenTimesEditor;
