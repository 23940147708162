import { Draft, produce } from "immer";
import { Reducer } from "redux";

import { SpintrActionTypes } from "src/spintr/action-types";
import { SpintrTypes } from "src/typings";
import { SocialFeedActionTypes } from "./action-types";
import {
    IFetchSocialFeedFulfilledAction,
    IFetchSocialFeedPendingAction,
    SocialFeedAction,
} from "./actions";
import {
    ICombinedFeedParams,
    IGeneralFeedParams,
    IGroupFeedParams,
    ISystemStatusFeedParams,
    IUserFeedParams,
    SocialFeedType
} from "./types";

export interface ICommonFeedState<TFeedRequest> {
    hasMore: boolean;
    isLoading: boolean;
    lastFetched?: Date;
    lastParams?: TFeedRequest;
    entries: Spintr.ISocialPostBase[];
}

export interface ICombinedFeedState extends ICommonFeedState<ICombinedFeedParams> {
}

export interface IGeneralFeedState extends ICommonFeedState<IGeneralFeedParams> {
}

export interface IUserFeedState extends ICommonFeedState<IUserFeedParams> {
}

export interface IGroupFeedState extends ICommonFeedState<IGroupFeedParams> {
}

export interface ISystemStatusFeedState extends ICommonFeedState<ISystemStatusFeedParams> {
}

export interface ISocialFeedState {
    combined: ICombinedFeedState;
    general: IGeneralFeedState;
    profile: IUserFeedState;
    group: IGroupFeedState;
    systemStatus: ISystemStatusFeedState;
    filters?: any;
}

const initialState: ISocialFeedState = {
    combined: {
        entries: [],
        hasMore: true,
        isLoading: false,
    },
    general: {
        entries: [],
        hasMore: true,
        isLoading: false,
    },
    profile: {
        entries: [],
        hasMore: true,
        isLoading: false,
    },
    group: {
        entries: [],
        hasMore: true,
        isLoading: false,
    },
    systemStatus: {
        entries: [],
        hasMore: true,
        isLoading: false,
    }
};

type EntryMerger = (
    statePosts: Spintr.ISocialPostBase[],
    newPosts: Spintr.ISocialPostBase[],
) => Spintr.ISocialPostBase[];
const mergeEntries: EntryMerger = (statePosts, newPosts) => {
    let posts = statePosts.slice();

    newPosts.forEach((post) => {
        if (posts.some((p) => p.Id === post.Id)) {
            return;
        }

        posts.push(post);
    });

    posts = posts.sort((a, b) => {
        if (a.isPinned === b.isPinned) {
            return a.Date > b.Date ? -1 : 1;
        }

        return a.isPinned > b.isPinned ? -1 : 1;
    });

    return posts;
}

type SocialFeedPendingHandler = (
    action: IFetchSocialFeedPendingAction,
    draft: Draft<ISocialFeedState>,
    state: ISocialFeedState,
) => void;
const handleSocialFeedPending: SocialFeedPendingHandler =
    (action, draft, state) => {
        switch (action.meta.feed) {
            case SocialFeedType.Combined:
                draft.combined = {
                    ...state.combined,
                    entries: action.meta.params.isSilentFetch || action.meta.params.skip > 0 ?
                        state.combined.entries :
                        [],
                    isLoading: action.meta.params.isSilentFetch ? false : true,
                    lastParams: action.meta.params
                };
                break;

            case SocialFeedType.Profile:
                draft.profile = {
                    ...state.profile,
                    isLoading: true,
                    lastParams: action.meta.params
                };
                break;

            case SocialFeedType.General:
                draft.general = {
                    ...state.general,
                    isLoading: true,
                    lastParams: action.meta.params
                };
                break;

            case SocialFeedType.Group:
                draft.group = {
                    ...state.group,
                    entries: action.meta.params.skip === 0 ? [] : state.group.entries,
                    isLoading: true,
                    lastParams: action.meta.params
                }
                break;

            case SocialFeedType.SystemStatus:
                draft.systemStatus = {
                    ...state.systemStatus,
                    entries: action.meta.params.skip === 0 ? [] : state.systemStatus.entries,
                    isLoading: true,
                    lastParams: action.meta.params,
                };
                break;
        }
    };

type SocialFeedFulfilledHandler = (
    action: IFetchSocialFeedFulfilledAction,
    draft: Draft<ISocialFeedState>,
    state: ISocialFeedState,
) => void;

const handleSocialFeedFulfilled: SocialFeedFulfilledHandler =
    (action, draft, state) => {
        const hasMore = action.payload.headers["x-has-more"] === "true";

        action.payload.data = action.payload.data.map((post) => ({
            ...post,
            Date: new Date(post.Date),
        }));

        switch (action.meta.feed) {
            case SocialFeedType.Combined:
                draft.combined = {
                    ...state.combined,

                    entries: action.meta.params.skip > 0 ?
                        mergeEntries(
                            state.combined.entries,
                            action.payload.data
                        ) :
                        action.payload.data,
                    hasMore,
                    isLoading: false,
                };
                break;

            case SocialFeedType.Profile:
                draft.profile = {
                    ...state.profile,

                    entries: action.meta.params.skip === 0 ?
                        action.payload.data :
                        mergeEntries(
                            state.profile.entries,
                            action.payload.data
                        ),
                    hasMore,
                    isLoading: false,
                };
                break;

            case SocialFeedType.General:
                draft.general = {
                    ...state.general,

                    entries: mergeEntries(
                        state.general.entries,
                        action.payload.data
                    ),
                    hasMore,
                    isLoading: false,
                };
                break;

            case SocialFeedType.Group:
                draft.group = {
                    ...draft.group,

                    entries: mergeEntries(state.group.entries, action.payload.data),
                    hasMore,
                    isLoading: false,
                };
                break;

            case SocialFeedType.SystemStatus:
                draft.systemStatus = {
                    ...draft.systemStatus,

                    entries: mergeEntries(
                        state.systemStatus.entries,
                        action.payload.data
                    ),
                    hasMore,
                    isLoading: false,
                }
                break;
        }
    };

function deletePost<TType>(id: number, feed: ICommonFeedState<TType>): ICommonFeedState<TType> {
    if (!feed.entries.length) {
        return feed;
    }

    const postIndex = feed.entries.findIndex(
        (entry) => entry.Id === id
    );

    if (postIndex === -1) {
        return feed;
    }

    const entries = feed.entries.slice();
    entries.splice(postIndex, 1);

    return {
        ...feed,

        entries,
    };
}

function pinPost<TType>(id: number, value: boolean, feed: ICommonFeedState<TType>): ICommonFeedState<TType> {
    if (!feed.entries.length) {
        return feed;
    }

    const postIndex = feed.entries.findIndex(
        (entry) => entry.Id === id
    );

    if (postIndex === -1) {
        return feed;
    }

    let entries = feed.entries.map((e: any) => {
        if (e.Id === id) {
            return {
                ...e,
                isPinned: value
            };
        } else {
            return e;
        }
    });

    entries = entries.sort((a, b) => {
        if (a.isPinned === b.isPinned) {
            return a.Date > b.Date ? -1 : 1;
        }

        return a.isPinned > b.isPinned ? -1 : 1;
    });

    return {
        ...feed,
        entries
    }
}

function setFavorite<TType>(id: number, state: boolean, feed: ICommonFeedState<TType>): ICommonFeedState<TType> {
    if (!feed.entries.length) {
        return feed;
    }

    const postIndex = feed.entries.findIndex(
        (entry) => entry.Id === id
    );

    if (postIndex === -1) {
        return feed;
    }

    return {
        ...feed,

        entries: [
            ...feed.entries.slice(0, postIndex),
            {
                ...feed.entries[postIndex],

                isFavourited: state,
            },
            ...feed.entries.slice(postIndex + 1)
        ]
    };
};

function setFollowing<TType>(id: number, state: boolean, feed: ICommonFeedState<TType>): ICommonFeedState<TType> {
    if (!feed.entries.length) {
        return feed;
    }

    const postIndex = feed.entries.findIndex(
        (entry) => entry.Id === id
    );

    if (postIndex === -1) {
        return feed;
    }

    return {
        ...feed,

        entries: [
            ...feed.entries.slice(0, postIndex),
            {
                ...feed.entries[postIndex],

                isFollowing: state,
            },
            ...feed.entries.slice(postIndex + 1)
        ]
    };
};


function handlePollVote<TType>(item: Spintr.IPollVoteMessage, feed: ICommonFeedState<TType>): ICommonFeedState<TType> {
    if (!feed.entries.length) {
        return feed;
    }

    return {
        ...feed,
        entries: feed.entries.map((entry) => {
            if (!entry.content.find(x => x.Id === item.pollId)) {
                return entry;
            }

            return {
                ...entry,
                content: entry.content.map((c) => {
                    if (c.Id !== item.pollId) {
                        return c;
                    }

                    return {
                        ...c,
                        options: c.options.map((o) => {
                            return {
                                ...o,
                                answers: (item.value && item.optionId === o.id ?
                                    [...o.answers, item.userId] :
                                    [...o.answers].filter(x => x !== item.userId)).filter((answer, index, answers) => answers.indexOf(answer) === index)
                            }
                        })
                    }
                })
            }
        })
    }
};

function verifyQuestion<TType>(meta: any, state: boolean, feed: ICommonFeedState<TType>): ICommonFeedState<TType> {
    if (!feed.entries.length) {
        return feed;
    }

    const postIndex = feed.entries.findIndex(
        (entry) => entry.Id === meta.id
    );

    if (postIndex === -1) {
        return feed;
    }

    return {
        ...feed,

        entries: [
            ...feed.entries.slice(0, postIndex),
            {
                ...feed.entries[postIndex],
                StatusType: meta.value ? 6 : 0
            },
            ...feed.entries.slice(postIndex + 1)
        ]
    };
};

export const SocialFeedReducer: Reducer<ISocialFeedState, SocialFeedAction> =
    (state = initialState, action: SocialFeedAction): ISocialFeedState => produce(
        state, (draft: Draft<ISocialFeedState>): void => {
            switch (action.type) {
                case SocialFeedActionTypes.ClearFeed:
                    // @ts-ignore
                    switch (action.meta.feedType) {
                        case SocialFeedType.Combined:
                            draft.combined = {
                                ...state.combined,
                                entries: [],
                                hasMore: false,
                                isLoading: false,
                                lastParams: null
                            };
                            break;

                        case SocialFeedType.Profile:
                            draft.profile = {
                                ...state.profile,
                                entries: [],
                                hasMore: false,
                                isLoading: false,
                                lastParams: null
                            };
                            break;

                        case SocialFeedType.General:
                            draft.general = {
                                ...state.general,
                                entries: [],
                                hasMore: false,
                                isLoading: false,
                                lastParams: null
                            };
                            break;

                        case SocialFeedType.Group:
                            draft.group = {
                                ...draft.group,
                                entries: [],
                                hasMore: false,
                                isLoading: false,
                                lastParams: null
                            };
                            break;

                        case SocialFeedType.SystemStatus:
                            draft.systemStatus = {
                                ...draft.systemStatus,
                                entries: [],
                                hasMore: false,
                                isLoading: false,
                                lastParams: null,
                            };
                            break;
                    }

                    break;

                case SocialFeedActionTypes.SetFilters:
                    draft.filters = action.data;
                    break;

                case SocialFeedActionTypes.FetchSocialFeedPending:
                    handleSocialFeedPending(action, draft, state);
                    break;

                case SocialFeedActionTypes.FetchSocialFeedFulfilled:
                    handleSocialFeedFulfilled(action, draft, state);
                    break;

                case SocialFeedActionTypes.LocalPostCreated:
                case SocialFeedActionTypes.RemotePostCreated:
                    if (!action.post) {
                        break;
                    }

                    action.post.Date = new Date(action.post.Date);

                    if (action.post.feedOwnerId &&
                        state.profile.lastParams &&
                        state.profile.lastParams.feedId &&
                        action.post.feedOwnerId.toString() === state.profile.lastParams.feedId.toString()) {
                        draft.profile = {
                            ...state.profile,
                            entries: mergeEntries(
                                state.profile.entries,
                                [action.post]
                            ),
                        };
                    }

                    if (action.post.FeedId &&
                        state.group.lastParams &&
                        state.group.lastParams.feedId &&
                        action.post.FeedId === state.group.lastParams.feedId) {
                        draft.group = {
                            ...state.group,
                            entries: mergeEntries(
                                state.group.entries,
                                [action.post]
                            ),
                        };
                    }

                    if (action.post.FeedId &&
                        state.systemStatus.lastParams &&
                        (
                            !state.systemStatus.lastParams.feedId ||
                            action.post.FeedId === state.systemStatus.lastParams.feedId
                        )) {
                        draft.systemStatus = {
                            ...state.systemStatus,
                            entries: mergeEntries(
                                state.systemStatus.entries,
                                [action.post],
                            ),
                        };
                    }

                    if ((action.post.feedType !== SpintrTypes.FeedType.Group || action.enableProjectPostsOnStartpage) &&
                        !action.post.restrictToTargetFeed) {
                        // TODO: If it is a systemstatus, only merge if the resource is followed
                        draft.combined = {
                            ...state.combined,
                            entries: mergeEntries(
                                state.combined.entries,
                                [action.post]
                            ),
                        };
                    }

                    break;

                case SocialFeedActionTypes.RemotePostUpdated:
                    draft.combined = {
                        ...state.combined,

                        entries: state.combined.entries.map(e => {
                            if (e.Id === action.post.Id) {
                                return {
                                    ...e,
                                    ...action.post
                                };
                            } else if (!!e.content.find(x => x.Id === action.post.Id)) {
                                return {
                                    ...e,
                                    content: e.content.map(c => {
                                        if (c.Id === action.post.Id) {
                                            return {
                                                ...c,
                                                ...action.post
                                            };
                                        } else {
                                            return c;
                                        }
                                    })
                                }
                            } else {
                                return e;
                            }
                        })
                    };

                    draft.general = {
                        ...state.general,

                        entries: state.general.entries.map(e => {
                            if (e.Id === action.post.Id) {
                                return {
                                    ...e,
                                    ...action.post
                                };
                            } else {
                                return e;
                            }
                        })
                    };

                    draft.profile = {
                        ...state.profile,

                        entries: state.profile.entries.map(e => {
                            if (e.Id === action.post.Id) {
                                return {
                                    ...e,
                                    ...action.post
                                };
                            } else {
                                return e;
                            }
                        })
                    };

                    draft.group = {
                        ...state.group,

                        entries: state.group.entries.map(e => {
                            if (e.Id === action.post.Id) {
                                return {
                                    ...e,
                                    ...action.post
                                };
                            } else {
                                return e;
                            }
                        })
                    };

                    draft.systemStatus = {
                        ...state.systemStatus,

                        entries: state.systemStatus.entries.map(e => {
                            if (e.Id === action.post.Id) {
                                return {
                                    ...e,
                                    ...action.post
                                };
                            } else {
                                return e;
                            }
                        })
                    };

                    break;

                case SocialFeedActionTypes.RemotePostDeleted:
                    draft.combined = deletePost(action.id, draft.combined);
                    draft.general = deletePost(action.id, draft.general);
                    draft.profile = deletePost(action.id, draft.profile);
                    draft.group = deletePost(action.id, draft.group);
                    draft.systemStatus = deletePost(action.id, draft.systemStatus);
                    break;

                case SocialFeedActionTypes.RemotePostPinned:
                    draft.combined = pinPost(action.id, action.value, draft.combined);
                    draft.general = pinPost(action.id, action.value, draft.general);
                    draft.profile = pinPost(action.id, action.value, draft.profile);
                    draft.group = pinPost(action.id, action.value, draft.group);
                    draft.systemStatus = pinPost(action.id, action.value, draft.systemStatus);
                    break;

                case SocialFeedActionTypes.RemoteVerifyQuestion:
                case SocialFeedActionTypes.VerifyQuestionPending:
                    draft.combined = verifyQuestion(action.meta, true, draft.combined);
                    draft.general = verifyQuestion(action.meta, true, draft.general);
                    draft.profile = verifyQuestion(action.meta, true, draft.profile);
                    draft.group = verifyQuestion(action.meta, true, draft.group);
                    draft.systemStatus = verifyQuestion(action.meta, true, draft.systemStatus);
                    break;

                case SpintrActionTypes.AddToFavoritePending:
                    draft.combined = setFavorite(action.meta, true, draft.combined);
                    draft.general = setFavorite(action.meta, true, draft.general);
                    draft.profile = setFavorite(action.meta, true, draft.profile);
                    draft.group = setFavorite(action.meta, true, draft.group);
                    draft.systemStatus = setFavorite(action.meta, true, draft.systemStatus);
                    break;

                case SpintrActionTypes.AddToFavoriteRejected:
                    draft.combined = setFavorite(action.meta, false, draft.combined);
                    draft.general = setFavorite(action.meta, false, draft.general);
                    draft.profile = setFavorite(action.meta, false, draft.profile);
                    draft.group = setFavorite(action.meta, false, draft.group);
                    draft.systemStatus = setFavorite(action.meta, false, draft.systemStatus);
                    break;

                case SpintrActionTypes.RemoveFromFavoritesPending:
                    draft.combined = setFavorite(action.meta, false, draft.combined);
                    draft.general = setFavorite(action.meta, false, draft.general);
                    draft.profile = setFavorite(action.meta, false, draft.profile);
                    draft.group = setFavorite(action.meta, false, draft.group);
                    draft.systemStatus = setFavorite(action.meta, false, draft.systemStatus);
                    break;

                case SpintrActionTypes.RemoveFromFavoritesRejected:
                    draft.combined = setFavorite(action.meta, true, draft.combined);
                    draft.general = setFavorite(action.meta, true, draft.general);
                    draft.profile = setFavorite(action.meta, true, draft.profile);
                    draft.group = setFavorite(action.meta, true, draft.group);
                    draft.systemStatus = setFavorite(action.meta, true, draft.systemStatus);
                    break;

                case SpintrActionTypes.StartFollowingObjectPending:
                    draft.combined = setFollowing(action.meta, true, draft.combined);
                    draft.general = setFollowing(action.meta, true, draft.general);
                    draft.profile = setFollowing(action.meta, true, draft.profile);
                    draft.group = setFollowing(action.meta, true, draft.group);
                    draft.systemStatus = setFollowing(action.meta, true, draft.systemStatus);
                    break;

                case SpintrActionTypes.StartFollowingObjectRejected:
                    draft.combined = setFollowing(action.meta, false, draft.combined);
                    draft.general = setFollowing(action.meta, false, draft.general);
                    draft.profile = setFollowing(action.meta, false, draft.profile);
                    draft.group = setFollowing(action.meta, false, draft.group);
                    draft.systemStatus = setFollowing(action.meta, false, draft.systemStatus);
                    break;

                case SpintrActionTypes.StopFollowingObjectPending:
                    draft.combined = setFollowing(action.meta, false, draft.combined);
                    draft.general = setFollowing(action.meta, false, draft.general);
                    draft.profile = setFollowing(action.meta, false, draft.profile);
                    draft.group = setFollowing(action.meta, false, draft.group);
                    draft.systemStatus = setFollowing(action.meta, false, draft.systemStatus);
                    break;

                case SpintrActionTypes.StopFollowingObjectRejected:
                    draft.combined = setFollowing(action.meta, true, draft.combined);
                    draft.general = setFollowing(action.meta, true, draft.general);
                    draft.profile = setFollowing(action.meta, true, draft.profile);
                    draft.group = setFollowing(action.meta, true, draft.group);
                    draft.systemStatus = setFollowing(action.meta, true, draft.systemStatus);
                    break;

                case SocialFeedActionTypes.RemotePollVote:
                    draft.combined = handlePollVote(action.item, draft.combined);
                    draft.general = handlePollVote(action.item, draft.general);
                    draft.profile = handlePollVote(action.item, draft.profile);
                    draft.group = handlePollVote(action.item, draft.group);
                    draft.systemStatus = handlePollVote(action.item, draft.systemStatus);
                    break;
            }
        }
    );