import React, { FunctionComponent } from "react";
import { localize } from "src/l10n";

interface IProps {
    notification: Spintr.INotification;
}

const PhoenixSurveyNotification: FunctionComponent<IProps> = (props) => {
    return (
        <div className="PhoenixSurveyNotification">
            {localize("NEW_PHOENIX_SURVEY")}
        </div>
    );
};

export default PhoenixSurveyNotification;
