import { AxiosError } from "axios";
import { Action } from "redux";

export enum PlannerActionTypes {
    PlannerItemCreated = "PLANNER_ITEM_CREATED",
    PlannerItemDeleted = "PLANNER_ITEM_DELETED",
    PlannerItemUpdated = "PLANNER_ITEM_UPDATED",

    FindPlannerItem = "FIND_PLANNER_ITEM",
    FindPlannerItemPending = "FIND_PLANNER_ITEM_PENDING",
    FindPlannerItemRejected = "FIND_PLANNER_ITEM_REJECTED",
    FindPlannerItemFulfilled = "FIND_PLANNER_ITEM_FULFILLED",

    QueryPlannerCategories = "QUERY_PLANNER_CATEGORIES",
    PlannerCategoryQueryPending = "PLANNER_CATEGORY_QUERY_PENDING",
    PlannerCategoryQueryRejected = "PLANNER_CATEGORY_QUERY_REJECTED",
    PlannerCategoryQueryFulfilled = "PLANNER_CATEGORY_QUERY_FULFILLED",

    QueryPlannerItems = "PLANNER_ITEM_QUERY",
    PlannerItemQueryPending = "PLANNER_ITEM_QUERY_PENDING",
    PlannerItemQueryRejected = "PLANNER_ITEM_QUERY_REJECTED",
    PlannerItemQueryFulfilled = "PLANNER_ITEM_QUERY_FULFILLED",
};

export type PlannerItemCreatedAction = Action<PlannerActionTypes.PlannerItemCreated> & {
    item: Spintr.PlannerItem;
};

export type PlannerItemDeletedAction = Action<PlannerActionTypes.PlannerItemDeleted> & {
    id: number;
};

export type PlannerItemUpdatedAction = Action<PlannerActionTypes.PlannerItemUpdated> & {
    item: Spintr.PlannerItem;
};


export type FindPlannerItemAction = Action<PlannerActionTypes.FindPlannerItem> & {
    payload: Promise<Spintr.PlannerItem>;
};

export type FindPlannerItemPendingAction = Action<PlannerActionTypes.FindPlannerItemPending>;

export type FindPlannerItemRejectedAction = Action<PlannerActionTypes.FindPlannerItemRejected> & {
    error: true;
    payload: AxiosError;
};

export type FindPlannerItemFulfilledAction = Action<PlannerActionTypes.FindPlannerItemFulfilled> & {
    payload: Spintr.PlannerItem;
};

export type QueryPlannerCategoriesAction = Action<PlannerActionTypes.QueryPlannerCategories> & {
    payload: Promise<Spintr.QueryEnvelope<Spintr.PlannerItemCategory>>;
};

export type PlannerCategoryQueryPendingAction = Action<PlannerActionTypes.PlannerCategoryQueryPending>;

export type PlannerCategoryQueryRejectedAction = Action<PlannerActionTypes.PlannerCategoryQueryRejected> & {
    error: true;
    payload: AxiosError;
};

export type PlannerCategoryQueryFulfilledAction = Action<PlannerActionTypes.PlannerCategoryQueryFulfilled> & {
    payload: Spintr.QueryEnvelope<Spintr.PlannerItemCategory>;
};

export type QueryPlannerItemsAction = Action<PlannerActionTypes.QueryPlannerItems> & {
    payload: Promise<Spintr.QueryEnvelope<Spintr.PlannerItem>>;
};

export type PlannerItemQueryPendingAction = Action<PlannerActionTypes.PlannerItemQueryPending>;

export type PlannerItemQueryRejectedAction = Action<PlannerActionTypes.PlannerItemQueryRejected> & {
    error: true;
    payload: AxiosError;
};

export type PlannerItemQueryFulfilledAction = Action<PlannerActionTypes.PlannerItemQueryFulfilled> & {
    payload: Spintr.QueryEnvelope<Spintr.PlannerItem>;
};

export type PlannerAction
    = PlannerItemCreatedAction
    | PlannerItemDeletedAction
    | PlannerItemUpdatedAction
    | FindPlannerItemAction
    | FindPlannerItemPendingAction
    | FindPlannerItemRejectedAction
    | FindPlannerItemFulfilledAction   
    | QueryPlannerCategoriesAction
    | PlannerCategoryQueryPendingAction
    | PlannerCategoryQueryRejectedAction
    | PlannerCategoryQueryFulfilledAction 
    | QueryPlannerItemsAction
    | PlannerItemQueryPendingAction
    | PlannerItemQueryRejectedAction
    | PlannerItemQueryFulfilledAction;
