import { Dropdown, Stack } from "@fluentui/react";
import React from "react";
import { localize } from "src/l10n";
import { ActionMenu, Label, SpintrUser } from "src/ui";
import { circleLarge } from "src/ui/helpers/style";
import { SpintrTypes } from "src/typings";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Style } from "src/ui/helpers";
import { visage2SocialPostTypes } from "src/social-feed";

interface IProps {
    onClosing?: () => void;
    setFeedId: any;
    onPostUpdate: any;
    imageUrl: string;
    userName: string;
    feedId: number;
    post: Spintr.ISocialPostBase;
    hideTargetSelector: boolean;
    isStartPage?: boolean;
    displayResourceSelector?: boolean;
    currentUser?: Spintr.IActiveUser;
    systemStatusEnabled?: boolean;
    systemStatusResources?: Spintr.ISystemStatusResource[];
    setIdentity?: (identity: any) => void;
    setType?: (type?: any) => void;
}

const Visage2ComposerHeader = (props: IProps) => {
    return (
        <div className="Visage2ComposerHeader">
            <Stack horizontal horizontalAlign="space-between">
                <Stack horizontal verticalAlign="center">
                    <SpintrUser
                        name={props.userName}
                        imageUrl={props.imageUrl}
                        size={circleLarge}
                        personalName={true}
                    />
                    {/* TODO: move to component */}
                    {props.displayResourceSelector && props.systemStatusResources?.length > 0 && (
                        <ActionMenu
                            categories={[
                                ...(props.isStartPage ? [{
                                    items: [
                                        {
                                            key: props.currentUser.id,
                                            text: props.currentUser.name,
                                            onClick: () => {
                                                props.setIdentity(undefined);
                                                props.setType();
                                                props.setFeedId(props.currentUser.feedId);
                                            },
                                        },
                                    ],
                                }]: []),
                                {
                                    title: localize("CHOOSE_RESOURCE"),
                                    items: props.systemStatusResources.map((ssr) => ({
                                        key: ssr.id,
                                        text: ssr.title,
                                        title: ssr.title,
                                        onClick: () => {
                                            props.setIdentity({
                                                ...ssr,
                                                name: ssr.title,
                                                images: {
                                                    feedComposer: ssr.imageUrl,
                                                },
                                            });

                                            if (!props.post.content.some((c) => c.StatusType === SpintrTypes.StatusType.SystemStatus)) {
                                                props.setType(
                                                    visage2SocialPostTypes.find(
                                                        (t) => t.type === SpintrTypes.StatusType.SystemStatus
                                                    )
                                                );
                                            }

                                            props.setFeedId(ssr.feedId);
                                        },
                                    })),
                                },
                            ]}
                            icon="arrow-down-1"
                        />
                    )}
                </Stack>
            </Stack>
        </div>
    );
};

export default Visage2ComposerHeader;
