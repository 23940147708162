import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import AcademyLeaderboardCategory from "src/academy/AcademyLeaderboardCategory/AcademyLeaderboardCategory";
import { IAcademyLeaderboard, IAcademyLeaderboardCourse, IAcademyLeaderboardUser } from "src/academy/types";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import SpintrLoader from "src/ui/components/Loader";
import "./AcademyLeaderboard.scss";
import { SpintrUser } from "src/ui";
import { Stack } from "@fluentui/react";
import { useSelector } from "react-redux";

interface IProps {}

interface IState {
    isLoading: boolean;
    startDate: Date;
    data?: IAcademyLeaderboard;
}

const AcademyLeaderboard = (props: IProps) => {
    const getStartDate = (subtractMonths: number): Date => {
        const d = new Date();
        d.setMonth(d.getMonth() - subtractMonths);
        return d;
    };

    const [state, setState] = useState<IState>({
        isLoading: true,
        startDate: getStartDate(1),
    });

    const isSmallViewMode = useSelector<Spintr.AppState, boolean>((state) => state.ui.isSmallViewMode);

    const fetch = useCallback(() => {
        api.get("/api/v1/academy/leaderboard", {
            params: {
                startDate: state.startDate,
            },
        })
            .then((response: AxiosResponse) => {
                setState((s: IState) => {
                    return {
                        ...s,
                        data: response.data,
                        isLoading: false,
                    };
                });
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        fetch();
    }, []);

    if (state.isLoading) {
        return <SpintrLoader />;
    }

    return (
        <div className="AcademyLeaderboard">
            <AcademyLeaderboardCategory
                name={localize("MOST_PASSED_COURSES")}
                listColumns={[
                    {
                        name: "#",
                        fieldName: "position",
                        maxWidth: 15,
                        minWidth: 15,
                    },
                    {
                        name: localize("Anvandare"),
                        fieldName: "user",
                        onRender: (item) => (
                            <>
                                {item.position >= 1 && item.position <= 3 && (
                                    <span className="medal">
                                        {item.position === 1 ? "🥇" : item.position === 2 ? "🥈" : "🥉"}
                                    </span>
                                )}
                                <SpintrUser name={item.user.name} imageUrl={item.user.image} personalName={true} />
                            </>
                        ),
                    },
                    {
                        name: localize("COURSES"),
                        fieldName: "count",
                        minWidth: 60,
                        maxWidth: 60,
                    },
                ]}
                listData={state.data.topUsers}
                podiumData={state.data.topUsers}
                hasPodium
            />
            <Stack horizontal={!isSmallViewMode} grow tokens={{ childrenGap: 12 }}>
                <AcademyLeaderboardCategory
                    name={localize("MOST_POPULAR_COURSES")}
                    listColumns={[
                        {
                            name: "#",
                            fieldName: "position",
                            maxWidth: 15,
                            minWidth: 15,
                        },
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                        },
                        ...(!isSmallViewMode
                            ? [
                                  {
                                      name: localize("Kategori"),
                                      fieldName: "category",
                                  },
                              ]
                            : []),
                        {
                            name: localize("Deltagare_stor"),
                            fieldName: "count",
                        },
                    ]}
                    listData={state.data.popularCourses.map((x: IAcademyLeaderboardCourse, idx: number) => ({
                        name: x.value.name,
                        category: x.value.category?.name,
                        position: idx + 1,
                        count: x.key,
                    }))}
                />
                <AcademyLeaderboardCategory
                    name={localize("HIGHEST_RATED_COURSES")}
                    listColumns={[
                        {
                            name: "#",
                            fieldName: "position",
                            maxWidth: 15,
                            minWidth: 15,
                        },
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                        },
                        ...(!isSmallViewMode
                            ? [
                                  {
                                      name: localize("Kategori"),
                                      fieldName: "category",
                                  },
                              ]
                            : []),
                        {
                            name: localize("RATING"),
                            fieldName: "score",
                        },
                    ]}
                    listData={state.data.highestRatedCourses.map((x: IAcademyLeaderboardCourse, idx: number) => ({
                        course: x.value,
                        name: x.value.name,
                        category: x.value.category?.name,
                        position: idx + 1,
                        score: x.key,
                    }))}
                />
            </Stack>
        </div>
    );
};

export default AcademyLeaderboard;
