import React, { forwardRef, ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { DataListProps, DataListState } from "./DataList.types";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import classNames from "classnames";
import { ICommandBarItemProps } from "@fluentui/react";
import { localize } from "src/l10n";
import { UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { useSelector } from "react-redux";
import { app } from "@microsoft/teams-js";
import { SpintrTypes } from "src/typings";

const DataList = forwardRef(function DataList(props: any, ref): ReactElement {
    const [state, setState] = useState<DataListState>({
        listType: props.initialListType || "list",
    });

    const {
        buttons,
        canChangeListType,
        columns,
        createButton,
        isDescending,
        onSortChanged,
        orderByColumn,
    } = props;

    const viewMode = useSelector<Spintr.AppState, Spintr.ViewMode>(
        (appState) => appState.ui.viewMode,
    );

    const listRef = useRef<SpintrList>(null);

    const onListTypeChange = useCallback(
        (listType: DataListState["listType"]) => setState((prevState) => ({
            ...prevState,
            listType: listType,
        })),
        [setState],
    );

    const buttonOptions = useMemo<ICommandBarItemProps[]>(() => {
        const list = buttons || [];

        if (canChangeListType) {
            const renderListTypeSwitch = (): ReactElement => {
                return (
                    <div className="list-type-switch">
                        <UnstyledButton
                            className={classNames("switch-button", {
                                active: state.listType === "list",
                            })}
                            onClick={() => onListTypeChange("list")}
                        >
                            <Visage2Icon
                                color={state.listType === "list" ? "visageMidBlue" : "primaryContent"}
                                icon="menu-1"
                                size="small" />
                        </UnstyledButton>
                        <UnstyledButton
                            className={classNames("switch-button", {
                                active: state.listType === "cards",
                            })}
                            onClick={() => onListTypeChange("cards")}
                        >
                            <Visage2Icon
                                color={state.listType === "cards" ? "visageMidBlue" : "primaryContent"}
                                icon="element-3"
                                size="small" />
                        </UnstyledButton>
                    </div>
                );
            }

            list.push({
                key: "list-type-switch",
                name: localize("LIST_DISPLAY_VIEW_AS"),
                onRender: viewMode < SpintrTypes.ViewMode.TabletPortrait
                    ? undefined
                    : renderListTypeSwitch,
                subMenuProps: viewMode >= SpintrTypes.ViewMode.TabletPortrait
                    ? undefined
                    : {
                        items: [{
                            key: "list",
                            onClick: () => onListTypeChange("list"),
                            text: localize("LIST_DISPLAY_LIST"),
                        }, {
                            key: "cards",
                            onClick: () => onListTypeChange("cards"),
                            text: localize("LIST_DISPLAY_CARDS"),
                        }],
                    }
            });
        }

        const sortableColumns = columns.filter((column) => column.isSortable);
        if (sortableColumns.length > 1) {
            list.push({
                className: "sort-order-menu",
                key: "sort-order-menu",
                name: localize("Sortering"),
                subMenuProps: {
                    items: sortableColumns.map((column) => ({
                        key: column.key,
                        text: column.name,
                        onClick: () => {
                            if (!onSortChanged) {
                                return;
                            }

                            onSortChanged(
                                column.key,
                                orderByColumn === column.key ? !isDescending : true,
                            );
                        },
                    }))
                },
            });
        }

        if (createButton) {
            const baseClassNames = viewMode < SpintrTypes.ViewMode.TabletPortrait
                ? ""
                : "create-wiki-button primaryBGColor primaryTextContrast commandBarAddButton";

            list.push({
                className: classNames(baseClassNames, createButton.className),
                iconProps: { iconName: createButton.iconName || "Add" },
                key: "create",
                onClick: createButton.onClick,
                text: createButton.text || localize("SkapaNy")
            })
        }

        return list;
    }, [
        buttons,
        canChangeListType,
        columns,
        createButton,
        isDescending,
        state.listType,
        onListTypeChange,
        onSortChanged,
        orderByColumn,
        viewMode,
    ]);

    return (
        <SpintrList {...props}
            buttons={buttonOptions}
            className={classNames("DataList", props.className)}
            listType={state.listType}
            ref={ref}
        />
    );
});

export default DataList;