import React, { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { getTypeName, localize } from "src/l10n";
import { SpintrTypes } from 'src/typings';
import { possessiveName } from "src/utils";
import { getUserPart } from "../utils";

interface IProps {
    notification: Spintr.INotification;
    currentUserId?: number;
}

const CommentNotificationText: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.ICommentNotification;
    if (!notification) {
        return;
    }

    const hasAuthors = notification.authors && notification.authors.length > 0;
    const isOwnObject = 
        (!hasAuthors && notification.ownerId === props.currentUserId) ||
        (hasAuthors && notification.authors.indexOf(props.currentUserId) > -1);

    const userPart = getUserPart(notification);
    const typeText = getTypeName(notification.objectType, {
        alternative: (
            ([16, 17].indexOf(notification.objectType) > -1 && notification.feedTypeId === 3) || 
            (notification.objectType === 3 && notification.subType === SpintrTypes.StatusType.Question)
        ),
        case: isOwnObject ? "possessive" : undefined,
    }).toLowerCase();

    return (
        <Fragment>
            {
                  userPart 
                + " " 
                + localize(
                    notification.type === 3
                        ? notification.subType === SpintrTypes.StatusType.Question ? "HarBesvarat" : "HarKommenterat"
                        : notification.subType === SpintrTypes.StatusType.Question ? "HarOcksåBesvarat" : "HarOcksåKommenterat"
                  )
                + " "
            }
            {!isOwnObject && (possessiveName(notification.ownerName) + " ")}
            {notification.objectName && notification.objectName.length > 0 && (
                <Fragment>
                    {typeText + " "}
                    <Link to={notification.url}>
                        {notification.objectName}
                    </Link>
                </Fragment>
            )}
            {(!notification.objectName || notification.objectName.length === 0) && (
                <Link to={notification.url}>
                    {typeText}
                </Link>
            )}.
        </Fragment>
    );
};

export default CommentNotificationText;
