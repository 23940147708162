import React, { ReactElement } from "react";
import { DrawerFooterProps } from "./DrawerFooter.types";
import classNames from "classnames";

function DrawerFooter(props: DrawerFooterProps): ReactElement {
    const Tag = props.as || "footer";

    return (
        <Tag className={classNames("DrawerFooter", props.className)}>
            {props.children}
        </Tag>
    );
}

export default DrawerFooter;
