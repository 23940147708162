import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { ActionMenu, Label, Loader, PageHeader, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./SystemStatusesPanel.scss";
import { DefaultButton, Dropdown, Image, Modal, PrimaryButton, Stack, TextField } from "@fluentui/react";
import PopupHeader from "src/ui/components/PopupHeader";
import { FormControl, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import { IActiveUserProfile } from "src/profile/reducer";
import { FileSelector } from "src/spintr/components";
import { Style } from "src/ui/helpers";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SpintrTypes } from "src/typings";
import { setCurrentResource } from "./actions";
import SpintrLoader from "src/ui/components/Loader";
import SystemStatusResourceInfo from "./SystemStatusResourceInfo";
import SystemStatusForm from "./SystemStatusForm";

interface IProps {
    currentUser?: IActiveUserProfile;
}

interface ISystemStatusResourceCategory {
    id: number;
    name: string;
    isActive?: boolean;
    resources: any[];
}

const SystemStatusesPanel: FunctionComponent<IProps> = ({ currentUser }) => {
    const history = useHistory();

    const [resources, setResources] = useState([]);
    const [showNewResourceModal, setShowNewResourceModal] = useState(false);
    const [newResource, setNewResource] = useState<Spintr.ISystemStatusResource>();
    const [isLoading, setIsLoading] = useState(false);

    const fetchResources = useCallback((searchText?: string) => {
        api.get("/api/v1/systemstatuses/resources", {
            params: {
                searchText,
            },
        }).then((response) => {
            setResources(response.data);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchResources();
    }, []);

    const toggleShowNewResourceModal = (id?: number) => {
        // @ts-ignore
        setNewResource({
            id,
            targets: [],
            owners: [
                {
                    id: currentUser.id,
                    key: currentUser.id,
                    name: currentUser.name,
                    imageUrl: currentUser.images.feedComposer,
                    subText: currentUser.department.name,
                },
            ],
            faq: [],
            categoryId: -1
        });
        setShowNewResourceModal(!showNewResourceModal);
    };

    const data : ISystemStatusResourceCategory[] = useMemo(() => {
        const activeResources = resources.filter(x => x.status > 0);
        let data: ISystemStatusResourceCategory[] = [];

        for (let resource of resources) {
            if (activeResources.find(x => x.id === resource.id)) {
                continue;
            }

            const foundCategory = data.find(x => x.id === resource.categoryId);

            if (foundCategory) {
                foundCategory.resources.push(resource);
            } else {
                data.push({
                    id: resource.categoryId,
                    name: resource.categoryName || localize("Alla"),
                    isActive: false,
                    resources: [resource]
                })
            }
        }

        data = data.sort((a, b) => (a.name > b.name ? 1 : -1));

        return [
            ...(activeResources.length > 0 ?
                [{
                    id: 0,
                    name: localize("Aktiva"),
                    isActive: true,
                    resources: activeResources
                }] :
                []
            ),
            ...data
        ];
    }, [resources]);

    return (
        <div className="SystemStatusesPanel">
            <div className="header">
                <PageHeader
                    title={localize("OPERATIONS")}
                    displaySearch
                    displayBackButton
                    onBackButtonClick={() => {
                        history.push({
                            pathname: "/operations"
                        });
                    }}
                    onSearchQueryChange={(value: string) => {
                        fetchResources(value);
                    }}
                    buttons={currentUser.isAdmin ? [{
                        key: "add",
                        title: localize("SkapaNy"),
                        icon: "add",
                        onClick: () => {
                            toggleShowNewResourceModal();
                        },
                        theme: "primary"
                    }] : []}
                />
            </div>
            {isLoading && <SpintrLoader />}
            {!isLoading && (
                <div className="categories">
                    {data.map((c: ISystemStatusResourceCategory, index: number) => (
                        <div key={index} className="category">
                            <Label
                                color="primaryContent"
                                size="small-1"
                                weight="medium"
                                className="ConversationsPanel-subline">
                                {c.name}
                            </Label>
                            <div className="resources">
                                {c.resources.map((r: any) => (
                                    <Link
                                        key={r.id}
                                        className={[
                                            "ConversationItem",
                                            ...(window.location.pathname === "/operations/" + r.id ? ["active"] : [])
                                        ].join(" ")}
                                        to={"/operations/" + r.id}>
                                        <SystemStatusResourceInfo resouce={r} />
                                        {r.userCanEdit && (
                                            <ActionMenu
                                                categories={[{
                                                    items: [{
                                                        text: localize("Redigera"),
                                                        onClick: () => {
                                                            toggleShowNewResourceModal(r.id);
                                                        },
                                                    }],
                                                }]}
                                            />
                                        )}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <Modal
                className="spintr-modal modalWithPopupHeader system-status-resource-modal"
                isOpen={showNewResourceModal}
                onDismiss={() => {
                    setShowNewResourceModal(false);
                }}
            >
                <PopupHeader
                    text={localize("SYSTEM_STATUS_RESOURCES_MODAL_HEADER_" + (newResource?.id ? "EDIT" : "CREATE"))}
                    onClose={() => {
                        setShowNewResourceModal(false);
                    }}
                />
                <div className="popup-inner">
                    <SystemStatusForm
                        id={newResource?.id}
                        onDone={() => {
                            setShowNewResourceModal(false);
                            fetchResources();
                        }}
                        onCancel={() => {
                            setShowNewResourceModal(false);
                        }} />
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    currentUser: state.profile.active,
});

export default connect(mapStateToProps)(SystemStatusesPanel);
