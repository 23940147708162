import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { localize } from "src/l10n";
import { VisageSidebarMode, setSidebarMode } from "src/sidebar";
import api from "src/spintr/SpintrApi";
import { Label, PageHeader } from "src/ui";
import "./SystemStatusStartView.scss";
import { SpintrTypes } from "src/typings";
import { Dropdown, Modal } from "@fluentui/react";
import CalypsoContentWithSidebar from "src/ui/components/CalypsoContentWithSidebar";
import SpintrLoader from "src/ui/components/Loader";
import SystemStatusResource from "./SystemStatusResource";
import SystemStatus from "./SystemStatus";
import { FormControl, FormSection } from "src/ui/components/Forms";
import PopupHeader from "src/ui/components/PopupHeader";
import SystemStatusForm from "./SystemStatusForm";

interface IProps {
    currentUser?: Spintr.IActiveUser;
    viewMode?: SpintrTypes.ViewMode;
    isSmallViewMode?: boolean;
}

interface ISystemStatusResourceCategory {
    id: number;
    name: string;
    isActive?: boolean;
    resources: any[];
}

const SystemStatusStartView: FunctionComponent<IProps> = (props) => {
    const contentRef = useRef<any>();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState<string>();
    const [expandSidebarIcon, setExpandSidebarIcon] = useState("sidebar-left");
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [categoryId, setCategoryId] = useState<number>(0);
    const [status, setStatus] = useState<number>(-1);
    const [showNewResourceModal, setShowNewResourceModal] = useState(false);
    const [newResource, setNewResource] = useState<Spintr.ISystemStatusResource>();

    const fetch = () => {
        setIsLoading(true);

        const params = {
            searchText,
            categoryId: undefined,
            status: undefined
        };

        if (categoryId > 0) {
            params.categoryId = categoryId;
        }

        if (status > -1) {
            params.status = status;
        }

        api.get("/api/v1/systemstatuses/resources", {
            params
        }).then((response) => {
            setData(response.data);
            setIsLoading(false);
        }).catch(() => {});
    }

    const toggleShowNewResourceModal = (id?: number) => {
        // @ts-ignore
        setNewResource({
            id,
            targets: [],
            owners: [
                {
                    id: props.currentUser.id,
                    key: props.currentUser.id,
                    name: props.currentUser.name,
                    imageUrl: props.currentUser.images.feedComposer,
                    subText: props.currentUser.department.name,
                },
            ],
            faq: [],
            categoryId: -1
        });
        setShowNewResourceModal(!showNewResourceModal);
    };

    useEffect(() => {
        fetch();
    }, [searchText, categoryId, status]);

    useEffect(() => {
        dispatch(setSidebarMode(VisageSidebarMode.noMenu));

        return () => {
            dispatch(setSidebarMode(VisageSidebarMode.menu));
        };
    }, []);

    const categories : ISystemStatusResourceCategory[] = useMemo(() => {
        const activeResources = data.filter(x => x.status > 0);
        let categories: ISystemStatusResourceCategory[] = [];

        for (let resource of data) {
            if (activeResources.find(x => x.id === resource.id)) {
                continue;
            }

            const foundCategory = categories.find(x => x.id === (resource.categoryId || -1));

            if (foundCategory) {
                foundCategory.resources.push(resource);
            } else {
                categories.push({
                    id: resource.categoryId || -1,
                    name: resource.categoryName || localize("Alla"),
                    isActive: false,
                    resources: [resource]
                })
            }
        }

        categories = categories.sort((a, b) => (a.name > b.name ? 1 : -1));

        return [
            ...(activeResources.length > 0 ?
                [{
                    id: 0,
                    name: localize("Aktiva"),
                    isActive: true,
                    resources: activeResources
                }] :
                []
            ),
            ...categories
        ];
    }, [data]);

    return (
        <CalypsoContentWithSidebar
            innerRef={contentRef}
            renderSideBar={() => {
                return (
                    <div>
                        <FormSection>
                            <FormControl>
                                <Dropdown
                                    label={localize("Status")}
                                    selectedKey={status}
                                    onChange={(_e, v) => {
                                        setStatus(Number(v.key));
                                    }}
                                    options={[{
                                        key: -1,
                                        text: localize("Alla")
                                    }, {
                                        key: SpintrTypes.SystemStatusType.AllClear,
                                        text: localize("ALL_CLEAR")
                                    }, {
                                        key: SpintrTypes.SystemStatusType.Ongoing,
                                        text: localize("Pagaende")
                                    }, {
                                        key: SpintrTypes.SystemStatusType.Done,
                                        text: localize("Avklarad")
                                    }, {
                                        key: SpintrTypes.SystemStatusType.Planned,
                                        text: localize("Planerad")
                                    }]}
                                    styles={{ dropdown: { width: 250 } }}
                                />
                            </FormControl>
                            <FormControl>
                                <Dropdown
                                    label={localize("Kategori")}
                                    selectedKey={categoryId}
                                    onChange={(_e, v) => {
                                        setCategoryId(Number(v.key));
                                    }}
                                    options={[
                                        {
                                            key: 0,
                                            text: localize("Alla")
                                        },
                                        ...categories.filter(x => x.id > 0).map((c) => {
                                            return {
                                                key: c.id,
                                                text: c.name
                                            }
                                        })
                                    ]}
                                    styles={{ dropdown: { width: 250 } }}
                                />
                            </FormControl>
                        </FormSection>
                    </div>
                )
            }}>
            <div className="SystemStatusStartView">
                <PageHeader
                    title={localize("OPERATIONS")}
                    displaySearch
                    onSearchQueryChange={(value: string) => {
                        setSearchText(value);
                    }}
                    buttons={[
                        {
                            key: "add",
                            text: localize("SkapaNyResurs"),
                            onClick: () => {
                                toggleShowNewResourceModal();
                            },
                            iconProps: { iconName: "Add" },
                            className: "commandBarAddButton",
                            theme: "primary"
                        },
                        {
                            key: "expand",
                            title: localize("Filtrera"),
                            onClick: () => {
                                setExpandSidebarIcon(contentRef.current.toggleIsExpanded())
                            },
                            icon: expandSidebarIcon
                        },
                    ]}
                />
                {isLoading && <SpintrLoader />}
                {!isLoading && (
                    <div className="categories">
                        {categories.map((c: ISystemStatusResourceCategory) => (
                            <div key={c.id} className="category">
                                <Label color="primaryContent" size="body-2">{c.name}</Label>
                                <div className="resources">
                                    {c.resources.map((r: any) => (
                                        <div key={r.id}>
                                            {r.status === 0 && (
                                                <SystemStatusResource key={r.id} resouce={r} />
                                            )}
                                            {r.status > 0 && r.activeSystemStatuses.map((s: any) => (
                                                <SystemStatus key={s.id} resouce={r} systemStatus={s} />
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {!isLoading && categories.length === 0 && (
                    <div className="spintr-list-empty-list">
                        <Label className="spintr-list-empty-list-label" as="p" size="body-2" color="dark-grey">
                            {localize("IngaPoster")}
                        </Label>
                    </div>
                )}
            </div>
            <Modal
                className="spintr-modal modalWithPopupHeader system-status-resource-modal"
                isOpen={showNewResourceModal}
                onDismiss={() => {
                    setShowNewResourceModal(false);
                }}
            >
                <PopupHeader
                    text={localize("SYSTEM_STATUS_RESOURCES_MODAL_HEADER_" + (newResource?.id ? "EDIT" : "CREATE"))}
                    onClose={() => {
                        setShowNewResourceModal(false);
                    }}
                />
                <div className="popup-inner">
                    <SystemStatusForm
                        id={newResource?.id}
                        onDone={() => {
                            setShowNewResourceModal(false);
                            fetch();
                        }}
                        onCancel={() => {
                            setShowNewResourceModal(false);
                        }} />
                </div>
            </Modal>
        </CalypsoContentWithSidebar>
    )
};

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    currentUser: state.profile.active,
    viewMode: state.ui.viewMode,
    isSmallViewMode: state.ui.isSmallViewMode,
});

export default connect(mapStateToProps)(SystemStatusStartView);
