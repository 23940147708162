import React, { ReactElement } from "react";
import { DrawerBodyProps } from "./DrawerBody.types";
import classNames from "classnames";

function DrawerBody(props: DrawerBodyProps): ReactElement {
    const { className, children } = props;

    return (
        <div className={classNames("DrawerBody", className)}>
            {children}
        </div>
    );
}

export default DrawerBody;
