import React from "react";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Doughnut } from 'react-chartjs-2';
import "./AcademyCourseSidebarProgressCircle.scss";

interface IProps {
    isDone?: boolean;
    isEligable?: boolean;
    progressPercent?: number;
    isTest?: boolean;
    isDND?: boolean;
    isDNDTest?: boolean;
    isError?: boolean;
}


const AcademyCourseSidebarProgressCircle = (props: IProps) => {
    if (props.isDND) {
        return (
            <div className="AcademyCourseSidebarProgressCircle dnd">
                <Visage2Icon icon="teacher" size="small" />
            </div>
        )
    }

    if (props.isDNDTest) {
        return (
            <div className="AcademyCourseSidebarProgressCircle dnd">
                <Visage2Icon icon="star" size="small" />
            </div>
        )
    }

    if (props.isDone) {
        return (
            <div className="AcademyCourseSidebarProgressCircle done">
                <Visage2Icon icon="tick-circle" color="green" type="bold" size="small" />
            </div>
        )
    }

    if (props.isError) {
        return (
            <div className="AcademyCourseSidebarProgressCircle error">
                <Visage2Icon icon="close-circle" color="red" type="bold" size="small" />
            </div>
        );
    }

    if (!props.isEligable) {
        return (
            <div className="AcademyCourseSidebarProgressCircle locked">
                <Visage2Icon icon="lock" size="small" />
            </div>
        )
    }

    if (props.isTest) {
        return (
            <div className="AcademyCourseSidebarProgressCircle in-progress">
                <Visage2Icon icon="edit-2" size="small" />
            </div>
        )
    }

    return (
        <div className="AcademyCourseSidebarProgressCircle in-progress">
            <div className="chart-wrapper">
                <Doughnut
                    options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        cutout: 6,
                        elements: {
                            arc: {
                                borderWidth: 0
                            }
                        },
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip: {
                                enabled: false
                            },
                        },
                    }}
                    data={{
                        labels: ["a", "b", "c", "d"],
                        datasets: [{
                            data: [props.progressPercent, 100 - props.progressPercent],
                            backgroundColor: ["#22234A", "#FFFFFF"]
                        }]
                    }}
                />
            </div>
        </div>
    )
}

export default AcademyCourseSidebarProgressCircle;
