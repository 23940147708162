import { HoverCard, HoverCardType, IPlainCardProps } from "@fluentui/react";
import moment from "moment";
import React, { Component, ReactNode } from "react";
import { DispatchProp, connect } from "react-redux";
import { ShareModal } from "src/chat";
import { addLike, removeLike } from 'src/interactions/actions';
import { localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import { SpintrTypes } from "src/typings";
import { Label, SpintrUser, UnstyledButton, setReactionPickerConfig } from "src/ui";
import PageActionButton from "src/ui/components/Buttons/PageActionButton/PageActionButton";
import { UsersListPopup } from "src/users/views";
import { capitalizeFirstLetter, getShortFromNow, mixpanelTrack } from "src/utils";
import generateUniqueId from "src/utils/uniqueId";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { likeTypes } from "../types";
import CommentPreview from "./CommentPreview";
import "./InteractionsBar.scss";

interface IOwnProps {
    color?: spintrColors,
    comments?: Spintr.IComment[],
    likers?: Spintr.IUser[];
    commentPreview?: Spintr.IComment,
    uberId: number,
    staticCommentCount?: number,
    date?: Date,
    hideComments?: boolean,
    onCommentClick?: any
    identity?: any;
    sendToMixpanel?: boolean;
    enableReactions?: boolean;
    displayBigButtons?: boolean;
    displayPillButtons?: boolean;
    uberIsComment?: boolean;
    displayShareButton?: boolean;
    isUnread?: boolean;
    onReplyClick?: any;
    hideCommentButton?: boolean;
}

interface IStateProps {
    currentUser: IActiveUserProfile,
    isSmallViewMode: boolean
}

interface IState {
    hideLikersPopup: boolean,
    uniqueId: string;
    showShareModal: boolean;
    animateLikeImage: boolean;
}

type Props = IOwnProps & IStateProps & DispatchProp;

class InteractionsBar extends Component<Props, IState> {
    private _isMounted: boolean = false;
    protected likeIcon?: any;
    protected commentIcon?: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            hideLikersPopup: true,
            uniqueId: generateUniqueId(),
            showShareModal: false,
            animateLikeImage: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    public renderLikes(): ReactNode {
        if (!this.props.likers) {
            return null;
        }

        const color: spintrColors = this.props.color || "mid-grey";
        const likers: Spintr.IUser[] = this.props.likers || [];
        const hasLiked = this.hasLiked(likers);
        const likersCount = (this.props.likers || []).length;

        const iconColor: spintrColors = hasLiked
            ? "red"
            : color;

        const plainCardProps: IPlainCardProps = {
            onRenderPlainCard: (likers) => {
                let limit = 5;

                return (
                    <div className="likersHoverCard">
                        {
                            likers.map((liker, index) => {
                                if (index > (limit - 1)) {
                                    return null;
                                }

                                return (
                                    <SpintrUser
                                        name={liker.name}
                                        imageUrl={liker.imageUrl}
                                        state={liker.state}
                                        id={liker.id}
                                        key={liker.id}
                                        hideText={true}
                                        personalName={true}
                                        size={25}
                                    />
                                )
                            })
                        }
                        {
                            likers.length > limit && (
                                <SpintrUser
                                    name={"+" + (likers.length - limit).toString()}
                                    imageInitials={"+" + (likers.length - limit).toString()}
                                    hideText={true}
                                    personalName={true}
                                    size={25} />
                            )
                        }
                    </div>
                )
            },
            renderData: likers
        };

        return (
            <div className={"LikeContainer" + (hasLiked ? " has-liked" : "")}>
                <UnstyledButton ariaLabel={localize("Gilla")} className="like-action no-user-select" onClick={this.onLikeIconClick.bind(this)}>
                    <Visage2Icon
                        icon="heart"
                        size="small"
                        type={hasLiked ? "bold" : "outline"}
                        color={iconColor} />
                </UnstyledButton>

                {
                    likersCount > 0 && (
                        <HoverCard
                            className="UserHovercard-wrapper"
                            plainCardProps={plainCardProps}

                            //onCardVisible={this.fetchIfNeeded.bind(this)}
                            instantOpenOnClick
                            type={HoverCardType.plain}>
                            <div className="like-count-wrapper no-user-select">
                                <UnstyledButton onClick={this.onLikersClick.bind(this)}>
                                    <Label
                                        as="span"
                                        className="like-count"
                                        color={color}
                                        size="body-2">
                                        {
                                            likersCount
                                        }
                                    </Label>
                                </UnstyledButton>
                            </div>
                        </HoverCard>
                    )
                }
            </div>
        );
    }

    hasLiked(likers: Spintr.IUser[]) {
        const hasLiked: boolean = this.props.identity ?
            likers.some(user => user.id === this.props.identity.id) :
            likers.some(user => user.id === this.props.currentUser.id);

        return hasLiked;
    }

    componentDidUpdate(prevProps: any) {
        const isLiking = this.hasLiked(this.props.likers || []);
        const wasLiking = this.hasLiked(prevProps.likers || []);

        let doAnimation = isLiking && !wasLiking;

        if (!doAnimation && isLiking && wasLiking) {
            const userId = this.props.identity ? this.props.identity.id : this.props.currentUser.id;
            const like = (this.props.likers || []).find(user => user.id === userId);
            const previousLike = (prevProps.likers || []).find(user => user.id === userId);

            doAnimation = like.likeType !== previousLike.likeType;
        }

        if (doAnimation) {
            this.setState({
                animateLikeImage: true
            }, () => {
                setTimeout(() => {
                    if (!this._isMounted) {
                        return;
                    }

                    this.setState({
                        animateLikeImage: false
                    });
                }, 2000);
            });
        }
    }

    onLikeIconClick(likeType: number) {
        if (!likeType) {
            likeType = 0;
        }
        const likers: Spintr.IUser[] = this.props.likers || [];
        const hasLiked = this.hasLiked(likers);

        let currentLikeType = 0;

        if (hasLiked) {
            const currentUserLike = likers.find((l: any) => l.id === this.props.currentUser.id);

            if (!!currentUserLike) {
                currentLikeType = currentUserLike.likeType || 0;
            }
        }

        // TODO: Add impersonating for unit pages (currently broken in backend)
        if (hasLiked && likeType === currentLikeType) {
            this.props.dispatch(removeLike(this.props.uberId, { ...this.props.currentUser, likeType }, this.props.identity));
        } else {
            this.props.dispatch(addLike(this.props.uberId, { ...this.props.currentUser, likeType }, this.props.identity));

            if (this.props.sendToMixpanel) {
                mixpanelTrack("Like");
            }
        }
    }

    onLikersClick() {
        const likers: Spintr.IUser[] = this.props.likers || [];

        this.setState({
            hideLikersPopup: false
        });
    }

    onCommentIconClick() {
        if (this.props.onCommentClick) {
            this.props.onCommentClick();
        }
    }

    getCommentCount(self) {
        const comments: Spintr.IComment[] = self.props.comments || [];

        let commentCount = 0;

        if (self.props.staticCommentCount) {
            commentCount = self.props.staticCommentCount;
        } else {
            commentCount = comments.length;

            for (let comment of comments) {
                if (!!comment.comments &&
                    comment.comments.length > 0) {
                    commentCount += comment.comments.length;
                }
            }
        }

        return commentCount;
    }

    public renderReplyButton(): ReactNode {
        if (this.props.hideCommentButton) {
            return null;
        }

        return (
            <div className="Commentors">
                <UnstyledButton ariaLabel={localize("Kommentera")} onClick={this.onCommentIconClick.bind(this)}>
                    <div className="comment-icon reply-icon">
                        <Visage2Icon icon="message" size="small" />
                    </div>
                </UnstyledButton>
            </div>
        )
    }

    public renderCommentCount(): ReactNode {
        if (!this.props.comments || this.props.hideComments) {
            return null;
        }

        const commentCount = this.getCommentCount(this);
        const hasComments = commentCount > 0;
        const color: spintrColors = this.props.color || "mid-grey";
        const iconColor = color;

        return (
            <div className="Commentors">
                {!this.props.hideCommentButton && (
                    <UnstyledButton ariaLabel={localize("Kommentera")} onClick={this.onCommentIconClick.bind(this)}>
                        <div className="comment-icon">
                            <Visage2Icon
                                icon="message"
                                size="small"
                                color={iconColor} />
                        </div>
                    </UnstyledButton>
                )}
                {
                    hasComments ?
                        <div className="comment-counter no-user-select">
                            <Label
                                as="span"
                                color={color}
                                size="body-2">
                                {
                                    commentCount
                                }
                            </Label>
                        </div> :
                        null
                }
            </div>
        );
    }

    public renderCommentPreview(): ReactNode {
        if (!this.props.commentPreview) {
            return null;
        }

        const color: spintrColors = this.props.color || "mid-grey";

        return (
            <CommentPreview
                color={color}
                comment={this.props.commentPreview} />
        );
    }

    public renderUnreadText(): ReactNode {
        if (!this.props.isUnread) {
            return null;
        }

        const color: spintrColors = this.props.color || "mid-grey";

        return (
            <Label size="body-2" className="unread-label" color={color}>
                <div className="unread-dot primaryBGColor" />
                {capitalizeFirstLetter(localize("olast"))}
            </Label>
        )
    }

    public renderLikersPopup() {
        const likers: Spintr.IUser[] = this.props.likers || [];

        return (
            <UsersListPopup
                users={likers}
                title={this.props.enableReactions ? localize("PersonerSomHarReageratPaDetta") : localize("PersonerSomGillarDetta")}
                popupIsHidden={this.state.hideLikersPopup}
                categorizeByLikeType={this.props.enableReactions}
                closePopup={() => {
                    this.setState({
                        hideLikersPopup: true
                    });
                }} />
        )
    }

    isHovering = () => {
        const elements = document.getElementsByClassName("like-action-" + this.state.uniqueId);

        if (!!elements &&
            elements.length > 0) {
            const el = elements[0];

            const isHover = e => e.parentElement.querySelector(':hover') === e;

            return isHover(el);
        };

        return false;
    }

    onReactionButtonHover = () => {
        setTimeout(() => {
            if (!this._isMounted) {
                return;
            }

            this.showReactionPickerIfStillHovering();
        }, 200);
    }

    onReactionButtonBlur = () => {
        setTimeout(() => {
            if (!this._isMounted) {
                return;
            }

            this.hideReactionPickerIfStillNotHovering();
        }, 200);
    }

    getReactionPickerConfig = (isVisible?: boolean) => {
        return {
            isVisible,
            elementId: "like-action-" + this.props.uberId,
            uberId: this.props.uberId,
            yOffset: this.props.displayBigButtons ? -30 : -40,
            xOffset: this.props.displayBigButtons ? 10 : -70,
            identity: this.props.identity,
            sendToMixpanel: !!this.props.sendToMixpanel
        }
    }

    showReactionPickerIfStillHovering = () => {
        if (!this.isHovering()) {
            return;
        }

        this.props.dispatch(setReactionPickerConfig(this.getReactionPickerConfig(true)));
    }

    hideReactionPickerIfStillNotHovering = () => {
        if (this.isHovering()) {
            return;
        }

        this.props.dispatch(setReactionPickerConfig(this.getReactionPickerConfig(false)));
    }

    renderSmallerReactionButton() {
        const likers: Spintr.IUser[] = this.props.likers || [];
        const hasLiked = this.hasLiked(likers);

        let likeTypeId = 0;

        if (hasLiked) {
            const currentUserLike = likers.find((l: any) => l.id === this.props.currentUser.id);

            if (!!currentUserLike) {
                likeTypeId = currentUserLike.likeType || 0;
            }
        }

        let likeType = likeTypes.find((lt: any) => lt.id === likeTypeId);

        if (!likeType) {
            likeType = likeTypes[0];
        }

        return (
            <UnstyledButton
                ariaLabel={localize("Gilla")}
                id={"like-action-" + this.props.uberId}
                className={"smallerReactionButton no-user-select like-action like-action-" + this.state.uniqueId + (hasLiked ? " has-liked" : "")}
                onMouseEnter={this.props.isSmallViewMode ? undefined : this.onReactionButtonHover}
                onMouseLeave={this.props.isSmallViewMode ? undefined : this.onReactionButtonBlur}
                onClick={() => {
                    if (this.props.isSmallViewMode) {
                        this.props.dispatch(setReactionPickerConfig(this.getReactionPickerConfig(true)));
                    } else {
                        this.onLikeIconClick(likeTypeId);
                    }
                }}>
                <Label
                    weight={hasLiked ? "medium" : "regular"}
                    color={"mid-grey"}
                    className={"LikeType" + likeType.id}
                    size="body-2">
                    {localize(likeType.name)}
                </Label>
            </UnstyledButton>
        )
    }

    renderPillButtons = () => {
        const commentCount = this.getCommentCount(this);
        const commentText = commentCount === 0
            ? ""
            : commentCount + " " + capitalizeFirstLetter(localize(commentCount === 1
                ? "Kommentar" : "Kommentarer"))
        const likers: Spintr.IUser[] = this.props.likers || [];
        const likeText = likers.length === 0
            ? ""
            : likers.length.toString() + " " + (likers.length === 1
                ? capitalizeFirstLetter(localize("Ubertype5_0_4"))
                : localize("Gillningar"));
        const hasLiked = this.hasLiked(likers);

        return (
            <div className="FeedInteractionsBar-PillButtons">
                <PageActionButton
                    labelClassname={"label"}
                    label={likeText}
                    iconName={hasLiked ? "VisageThumbLike20Filled" : "VisageThumbLike20Regular"}
                    onClick={
                        () => { this.onLikeIconClick(0) }} />
                <PageActionButton labelClassname={"label"} label={commentText} iconName="VisageChat20Regular" link="#comments" />
                <PageActionButton labelClassname={"label"} label={localize("Dela")} iconName="VisageShare20Regular" onClick={() => { this.setState({ showShareModal: true }) }} />
            </div>
        )
    }

    renderButtons() {
        const likers: Spintr.IUser[] = this.props.likers || [];
        const hasLiked = this.hasLiked(likers);

        let likeTypeId = 0;

        if (hasLiked) {
            const currentUserLike = likers.find((l: any) => l.id === this.props.currentUser.id);

            if (!!currentUserLike) {
                likeTypeId = currentUserLike.likeType || 0;
            }
        }

        let likeType = likeTypes.find((lt: any) => lt.id === likeTypeId);

        if (!likeType) {
            likeType = likeTypes[0];
        }

        return (
            <div className="FeedInteractionsBar-buttons">
                <div className="FeedInteractionsBar-button">
                    <UnstyledButton
                        ariaLabel={localize("Gilla")}
                        id={"like-action-" + this.props.uberId}
                        className={"like-action no-user-select like-action-" + this.state.uniqueId + (hasLiked ? " has-liked" : "") + (this.state.animateLikeImage ? " animate-like" : "")}
                        onMouseLeave={this.props.isSmallViewMode ? undefined : this.onReactionButtonBlur}
                        onClick={() => {
                            if (this.props.isSmallViewMode) {
                                this.props.dispatch(setReactionPickerConfig(this.getReactionPickerConfig(true)));
                            } else {
                                this.onLikeIconClick(likeTypeId);
                            }
                        }}
                        onMouseEnter={() => {
                            if (!!this.likeIcon) {
                                this.likeIcon?.play();
                            }

                            if (!this.props.isSmallViewMode) {
                                this.onReactionButtonHover();
                            }
                        }}>
                        {
                            hasLiked && (
                                <img src={likeType.icon} />
                            )
                        }
                        {
                            !hasLiked && (
                                <Visage2Icon icon="heart" size="small" color="dark-grey" />
                            )
                        }
                        <Label
                            weight={hasLiked ? "medium" : "normal"}
                            color={hasLiked ? "light-blue" : "dark-grey"}
                            className={"LikeType" + likeType.id}
                            size="body-2">
                            {
                                localize(likeType.name)
                            }
                        </Label>
                    </UnstyledButton>
                </div>
                {!this.props.hideCommentButton && (
                    <div className="FeedInteractionsBar-button">
                        <UnstyledButton
                            ariaLabel={localize("Kommentera")}
                            onClick={this.onCommentIconClick.bind(this)}
                            onMouseEnter={() => {
                                if (!!this.commentIcon) {
                                    this.commentIcon?.play();
                                }
                            }}>
                            <Visage2Icon icon="message" size="small" color="dark-grey" />
                            <Label size="body-2" color="dark-grey">{localize("Kommentera")}</Label>
                        </UnstyledButton>
                    </div>
                )}
                {/* <div className="FeedInteractionsBar-button">
                    <UnstyledButton ariaLabel={localize("Dela")} onClick={() => {
                        this.setState({
                            showShareModal: true
                        });
                    }}>
                        <Visage2Icon
                            icon="export-3"
                            size="big"
                            color={"mid-grey"} />
                        <Label size="body-2" color="mid-grey">{localize("Dela")}</Label>
                    </UnstyledButton>
                </div> */}
            </div>
        )
    }

    renderCommentButton() {
        if (this.props.hideCommentButton) {
            return null;
        }

        return (
            <div className="commentButtonWrapper">
                {/* <div className="interactionsDivider" /> */}
                <UnstyledButton
                    ariaLabel={localize("Svara")}
                    className={"commentButton"}
                    onClick={this.onCommentIconClick.bind(this)}>
                    <Label
                        color={"mid-grey"}
                        size="body-2">
                        {localize("Svara")}
                    </Label>
                </UnstyledButton>
            </div>
        )
    }

    getTimeString() {
        if (this.props.isSmallViewMode) {
            return getShortFromNow(this.props.date);
        } else {
            return moment(this.props.date).fromNow();
        }
    }

    renderReactionsInfo() {
        const commentCount = this.getCommentCount(this);
        const likers: Spintr.IUser[] = this.props.likers || [];

        if (this.props.displayBigButtons &&
            commentCount === 0 &&
            likers.length === 0) {
            return null;
        }

        let likeTypesToDisplay = [];

        for (let likeType of likeTypes) {
            likeTypesToDisplay.push({
                ...likeType,
                count: 0
            });
        }

        for (let liker of likers) {
            const likeType = likeTypesToDisplay.find(lt => lt.id === (liker.likeType ?? 0));

            if (!!likeType) {
                likeType.count++;
            }
        }

        likeTypesToDisplay = likeTypesToDisplay.filter(lt => lt.count > 0).sort((a, b) => {
            if (a.count < b.count) { return 1; }
            if (a.count > b.count) { return -1; }
            return 0;
        });

        let likersText = "";

        likersText = likers.length.toString();

        // if (likers.length === 1) {
        //     likersText = "1";
        // } else if (likers.length > 1) {
        //     if (!!likers.find((l: any) => l.id === this.props.currentUser.id)) {
        //         likersText = localize("Du") + " " + localize("Och_small") + " " + (likers.length - 1) + " " + localize("till_more");
        //     } else {
        //         likersText = likers[likers.length - 1].name + " " + localize("Och_small") + " " + (likers.length - 1) + " " + localize("till_more");
        //     }
        // }

        let classNames = ["FeedInteractionsBar-info"];

        if (this.props.displayBigButtons) {
            classNames.push("displayBigButtons");
        }

        return (
            <div className="reactions-bar">
                <div className={classNames.join(" ")}>
                    <div className="left">
                        {
                            !this.props.displayBigButtons && this.renderSmallerReactionButton()
                        }
                        {
                            !this.props.displayBigButtons && this.renderCommentButton()
                        }
                        {!this.props.displayBigButtons && this.props.date && (
                            <div className="date">
                                <div className="interactionsDivider" />
                                <Label size="body-2" color="dark-grey">
                                    {this.getTimeString()}
                                </Label>
                            </div>
                        )}
                        {
                            likers.length > 0 && (
                                <UnstyledButton onClick={this.onLikersClick.bind(this)} className="currentReactions">
                                    <div>
                                        {
                                            likeTypesToDisplay.map((lt: any, index: number) => {
                                                return (
                                                    <div key={lt.id} className="likeType">
                                                        <img src={lt.icon} style={{
                                                            //zIndex: likeTypesToDisplay.length - index
                                                        }} />
                                                    </div>
                                                )
                                            })
                                        }
                                        <Label size={"body-2"} color="dark-grey">
                                            {likers.length}
                                        </Label>
                                    </div>
                                    {/* <Label size={"body-2"} color="dark-grey">
                                        {likersText}
                                    </Label> */}
                                </UnstyledButton>
                            )
                        }
                    </div>
                    {
                        commentCount > 0 && this.props.displayBigButtons && (
                            <div className="right">
                                <Visage2Icon icon="message" size="small" color="dark-grey" />
                                <Label size="body-2" color="dark-grey">{commentCount + " " + localize(commentCount === 1 ? "Kommentar" : "Kommentarer")}</Label>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    renderShareButton() {
        const color: spintrColors = this.props.color || "mid-grey";

        return (
            <UnstyledButton className="shareButton" onClick={() => {
                this.setState({
                    showShareModal: true
                });
            }}>
                <Visage2Icon
                    icon="export-3"
                    size="small"
                    color={color} />
            </UnstyledButton>
        )
    }

    public render(): ReactNode {
        let wrapperClassList = ["InteractionsBarWrapper"];
        let classList = ["InteractionsBar"];

        if (this.props.displayBigButtons) {
            wrapperClassList.push("displayBigButtons")
        }

        return (
            <div className={wrapperClassList.join(" ")}>
                {this.state.showShareModal && (
                    <ShareModal
                        objectId={this.props.uberId}
                        onDismiss={() => {
                            this.setState({
                                showShareModal: false
                            });
                        }}
                    />
                )}
                {
                    !this.props.enableReactions && !this.props.displayPillButtons && (
                        <div className={classList.join(" ")}>
                            {this.renderLikes()}
                            {this.renderCommentCount()}
                            {this.props.displayShareButton && this.renderShareButton()}
                            {this.renderUnreadText()}
                            {this.renderCommentPreview()}
                        </div>
                    )
                }
                {
                    !!this.props.enableReactions && this.renderReactionsInfo()
                }
                {this.props.displayPillButtons && this.renderPillButtons()}
                {this.props.displayBigButtons && this.renderButtons()}
                {this.renderLikersPopup()}
            </div>
        );
    }
}

export default connect<IStateProps, {}, IOwnProps, Spintr.AppState>(
    (state): IStateProps => ({
        currentUser: state.profile.active,
        isSmallViewMode: state.ui.viewMode <= SpintrTypes.ViewMode.PhoneLandscape,
    }),
)(InteractionsBar);
