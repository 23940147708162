import React, { Fragment, ReactElement, useMemo } from "react";
import { TimelineHeaderCellProps } from "./TimelineHeaderCell.types";
import { ColumnDuration, TimelineMode } from "../types";
import { CaptionBody } from "src/components/Text";
import moment from "moment";
import { isSameDate } from "src/utils";

const dayLength = 86400000;

function getCellContent(columnDuration: ColumnDuration, timelineMode: TimelineMode): ReactElement {
    switch (timelineMode) {
        case "DAYS":
            let classNames = "date-wrapper";

            const startMoment = moment(columnDuration.startMilliseconds);

            const isToday = isSameDate(startMoment.toDate(), new Date());
            if (isToday) {
                classNames += " today primaryBGColor";
            } else if (startMoment.day() === 0 || startMoment.day() === 6) {
                classNames += " weekend";
            }

            return (
                <div className={classNames}>
                    <CaptionBody className={"month-text" + (isToday ? " primaryTextContrast" : "")} weight="medium">
                        {startMoment.format("MMM")}
                    </CaptionBody>
                    <CaptionBody className={"day-text" + (isToday ? " primaryTextContrast" : "")} weight="medium">
                        {startMoment.date()}
                    </CaptionBody>
                </div>
            );

        case "WEEKS":
            const dates = [...Array(6)]
                .reduce<number[]>(
                    (acc, _) =>  [ ...acc, acc[acc.length - 1] + dayLength],
                    [columnDuration.startMilliseconds])
                .map((startMilliseconds) => new Date(startMilliseconds));

            let monthText = moment(dates[0]).format("MMM");
            if (dates[0].getMonth() !== dates[6].getMonth()) {
                monthText += " - " + moment(dates[6]).format("MMM");
            }

            let weekClasses = "week-header";
            let monthNameClasses = "month-name";
            
            const currentWeek = columnDuration.startMilliseconds <= Date.now() && columnDuration.endMilliseconds >= Date.now();
            if (currentWeek) {
                weekClasses += " primaryBGColor primaryTextContrast";
                monthNameClasses += " primaryTextContrast";
            }

            const mapWeekDates = (date: Date): JSX.Element => {
                let classNames = "date-cell";

                const isToday = isSameDate(date, new Date());
                if (isToday) {
                    classNames += " today primaryTextContrast";
                } else if (date.getDay() === 0 || date.getDay() === 6) {
                    classNames += " weekend";
                    if (currentWeek) {
                        classNames += " primaryTextContrast";
                    }
                } else if (currentWeek) {
                    classNames += " primaryTextContrast";
                }

                return (
                    <CaptionBody
                        className={classNames}
                        color="contentNormal"
                        key={date.getTime()}
                    >
                        <div className={"date-number"}>
                            {date.getDate()}
                        </div>
                    </CaptionBody>
                );
            };

            return (
                <div className={weekClasses}>
                    <CaptionBody className={monthNameClasses} color="contentNormal">
                        {monthText}
                    </CaptionBody>
                    <div className="date-list">
                        {dates.map(mapWeekDates)}
                    </div>
                </div>
            );

        case "MONTHS":
            const currentMonth = columnDuration.startMilliseconds <= Date.now() && columnDuration.endMilliseconds >= Date.now();

            let monthClasses = "month-header";
            if (currentMonth) {
                monthClasses += " primaryBGColor primaryTextContrast";
            }

            return (
                <CaptionBody className={monthClasses} color="contentNormal">
                    {moment(new Date(columnDuration.startMilliseconds)).format("MMM")}
                </CaptionBody>
            );

        case "QUARTERS":
            const currentQuarter = columnDuration.startMilliseconds <= Date.now() && columnDuration.endMilliseconds >= Date.now();

            let quarterClasses = "quarter-header";
            if (currentQuarter) {
                quarterClasses += " primaryBGColor primaryTextContrast";
            }
            
            const firstMonth = moment(new Date(columnDuration.startMilliseconds)).format("MMM");
            const lastMonth = moment(new Date(columnDuration.endMilliseconds)).format("MMM");

            return (
                <CaptionBody className={quarterClasses} color="contentNormal">
                    {firstMonth} - {lastMonth}
                </CaptionBody>
            );

        default:
            return null;
    }
}

function TimelineHeaderCell(props: TimelineHeaderCellProps): ReactElement {
    const content = useMemo(
        () => getCellContent(props.columnDuration, props.timelineMode),
        [props.columnDuration, props.timelineMode]
    );
    
    return (
        <div
            className="TimelineHeaderCell"
            style={props.style}
            tabIndex={-1}
        >
            {content}
        </div>
    );
}

export default TimelineHeaderCell;
