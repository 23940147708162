import React, { Component } from 'react';
import { localize } from 'src/l10n';
import api from 'src/spintr/SpintrApi';
import { Label, Loader } from '.';

interface IProps {
    uberId: number;
}

type IUberReach = {
    totalUserCount: number;
    visitCount: number;
};

interface IState {
    isLoading: boolean;
    uberReach?: IUberReach;
}

class Reach extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        if (this.props.uberId && this.props.uberId > 0) {
            this.fetch();
        }
    }

    fetch() {
        let onSuccess = (response) => {
            let uberReach: IUberReach = {
                totalUserCount: response.data.reachTotal,
                visitCount: response.data.reach,
            };

            this.setState({
                uberReach,
                isLoading: false,
            });
        };

        let onError = (response) => {
            console.log(response);
        };

        let params = {
            uberId: this.props.uberId,
        };

        api
            .get("/api/statistics/uberreach", {
                params,
            })
            .then(onSuccess, onError);
    }

    render() {
        if (this.props.uberId === 0) {
            return (
                <Label color="dark-grey" size="body-2">
                    {localize("NO_DATA_AVAILABLE")}
                </Label>
            );
        }

        if (this.state.isLoading) {
            return <Loader loaderSize="small" />;
        }

        const { visitCount } = this.state.uberReach;
        const percentage = (visitCount * 100) / this.state.uberReach.totalUserCount;

        return (
            <div className="Reach" tabIndex={0}>
                <Label size="body-2" as="div" color="dark-grey">
                    {`${visitCount} ` +
                        (visitCount === 1
                            ? localize("PersonHarSett").toLowerCase()
                            : localize("PersonerHarSett").toLowerCase()) +
                        ` (${isNaN(percentage) ? "0" : Math.round(percentage)}%)`}
                </Label>
            </div>
        );
    }
}

export default Reach;
