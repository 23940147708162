import React, {  } from 'react';
import "./ComposerMemoHeader.scss";
import { Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { localize } from 'src/l10n';

interface IProps {
    onClose: () => void;
}

const ComposerMemoHeader = (props: IProps) => {
    return (
        <div className="ComposerMemoHeader">
            <div className="inner">
                <Visage2Icon icon="task-square" />
                <Label weight="medium">{localize("MEMO")}</Label>
            </div>
            <UnstyledButton onClick={props.onClose}>
                <Visage2Icon icon="close-circle" />
            </UnstyledButton>
        </div>
    )
}

export default ComposerMemoHeader;
