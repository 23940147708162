import React, { useEffect, useMemo, useState } from 'react';
import "./MessageReactions.scss";
import { likeTypes } from 'src/interactions/types';
import { Label, UnstyledButton } from 'src/ui';
import { localize } from 'src/l10n';
import { UsersListPopup } from 'src/users/views';

interface IProps {
    currentUserId: number;
    conversation: Spintr.IConversation;
    message: Spintr.IChatMessage;
}

interface IReactionCollection {
    likeType: number;
    userIds: number[];
}

interface IState {
    displayPopup: boolean;
}

const MessageReactions = (props: IProps) => {
    const [state, setState] = useState<IState>({
        displayPopup: false
    });

    const reactions = useMemo(() => {
        const output: IReactionCollection[] = [];

        for (let r of props.message.reactions) {
            let match = output.find(rtd => rtd.likeType === r.likeType);
    
            if (match) {
                match.userIds.push(r.user.id);
            } else {
                output.push({
                    likeType: r.likeType,
                    userIds: [r.user.id]
                })
            }
        }
    
        return output;
    }, [props.message.reactions])

    if (reactions.length === 0) {
        return null;
    }

    return (
        <div className="MessageReactions">
            <UnstyledButton
                className="reactions"
                onClick={() => {
                    setState((s: IState) => {
                        return {
                            ...s,
                            displayPopup: true
                        }
                    });
                }}>
                {reactions.map((x: IReactionCollection) => {
                    let likeType = likeTypes.find((lt: any) => lt.id === x.likeType);

                    if (!likeType) {
                        likeType = likeTypes[0];
                    }

                    return (
                        <div key={x.likeType} className="reaction">
                            <img src={likeType.icon} />
                            <Label size="body-2">{x.userIds.length}</Label>
                        </div>
                    )
                })}
            </UnstyledButton>
            {state.displayPopup && (
                <UsersListPopup
                    users={props.message.reactions.map((r) => {
                        const user: Spintr.IUser = {
                            id: r.user.id,
                            name: r.user.name,
                            imageUrl: props.conversation?.participants.find(x => x.id === r.user.id)?.imageUrl || "",
                            likeType: r.likeType,
                            info: "",
                            type: 1,
                            typeName: ""
                        };

                        return user;
                    })}
                    title={localize("PersonerSomHarReageratPaDetta")}
                    popupIsHidden={false}
                    categorizeByLikeType={true}
                    closePopup={() => {
                        setState((s: IState) => {
                            return {
                                ...s,
                                displayPopup: false
                            }
                        });
                    }} />
            )}
        </div>
    )
}

export default MessageReactions;
