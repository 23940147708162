import axios, { CancelTokenSource } from "axios";
import { Icon } from "@fluentui/react";
import React, {
    ChangeEvent,
    Component,
    createRef,
    ReactNode,
    RefObject
} from "react";
import {
    connect,
    MapDispatchToProps,
    MapStateToProps
} from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { localize } from "src/l10n";
import {
    ClearResultsAction,
    clearSearchResults,
    ExecuteSearchAction,
    executeSearchAction
} from "src/search/actions";
import { ISearchParams, submitFeedback } from "src/search/api";
import { ISearchFeedback } from "src/search/types";
import { ISearchHeaders } from "src/search/utils";
import {
    Label,
    Loader,
    setResponsiveSearchVisible, UnstyledButton
} from "src/ui";
import { getQueryStringMap } from "src/utils";
import { SearchResultObjectHit } from "..";
import "./ResponsiveSearch.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IDispatchProps {
    clearSearchResults: ClearResultsAction;
    executeSearchAction: ExecuteSearchAction;
    setResponsiveSearchVisible: (visible: boolean) => void;
}

interface IStateProps {
    headers?: ISearchHeaders;
    isLoading: boolean;
    isVisible: boolean;
    lastParams?: ISearchParams;
    office365Enabled: boolean;
    results: Spintr.ISearchResult[];
}

interface IState {
    queryText: string;
}

type Props = IDispatchProps & IStateProps & RouteComponentProps;

class ResponsiveSearch extends Component<Props, IState> {
    protected readonly querySubject: Subject<string>;
    protected readonly querySubscription: Subscription;
    protected readonly inputRef: RefObject<HTMLInputElement>;
    protected readonly searchPlaceholder: string;
    protected lastSearch?: Date;
    protected cancelTokenSource: CancelTokenSource;

    constructor(props: Props) {
        super(props);

        this.searchPlaceholder = localize("Sok");

        const state: IState = {
            queryText: "",
        };

        this.inputRef = createRef();

        const onSearchPage = this.isOnSearchPage();
        if (onSearchPage) {
            const qs = getQueryStringMap(props.location);
            if (qs.q) {
                state.queryText = qs.q || "";
            }

            /*
            if (qs.g) {
                state.groupId = parseInt(qs.g, 10);
            }
            */
        }

        this.state = state;

        this.onClearClick = this.onClearClick.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onNextQuery = this.onNextQuery.bind(this);
        this.onOverlayClick = this.onOverlayClick.bind(this);
        this.onResultClick = this.onResultClick.bind(this);

        this.querySubject = new Subject<string>();
        this.querySubscription = this.querySubject
            .pipe(debounceTime(500))
            .subscribe(this.onNextQuery);
    }

    public componentDidMount(): void {
        if (this.state.queryText.length === 0) {
            return;
        }

        this.querySubject.next(this.state.queryText);
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.isVisible && !prevProps.isVisible) {
            if (this.inputRef.current) {
                this.inputRef.current.focus();
            }
        }
    }

    public render(): ReactNode {
        const showBody = this.props.isVisible && (
            this.props.isLoading || 
            (this.props.results || []).length > 0
        );

        return (
            <div id="ResponsiveSearch" className={!this.props.isVisible ? "hidden" : ""}>
                <div className="overlay" onClick={this.onOverlayClick} />
                <div className="search-bar">
                    <div className="inner">
                        <div className="search-icon">
                            <Visage2Icon icon="search-normal-1" />
                        </div>
                        <input
                            aria-label={this.searchPlaceholder}
                            autoComplete="off"
                            className="search-input"
                            onChange={this.onInputChange}
                            placeholder={this.searchPlaceholder + "..."}
                            ref={this.inputRef}
                            spellCheck={false}
                            type="text"
                            value={this.state.queryText}
                        />
                        <UnstyledButton
                            className="clear-button"
                            onClick={this.onClearClick}
                        >
                            <Visage2Icon icon="close-circle" />
                        </UnstyledButton>
                    </div>
                </div>
                <div className={"search-body" + (showBody ? "" : " hidden")}>
                    {this.renderBody()}
                </div>
            </div>
        );
    }

    protected renderBody(): ReactNode {
        if (this.props.isLoading) {
            return (
                <Loader />         
            );
        }

        return (
            <div className="result-presentation">
                {(this.props.results || []).length > 0 && (
                    <div className="intranet results">
                        <div className="heading">
                            <Label
                                color="mid-grey"
                                size="small-2"
                                weight="medium"
                            >
                                {localize("Traffar")}
                            </Label>
                        </div>
                        <div className="hits">
                            <ol>
                                {this.props.results.map((hit) => (
                                    <li
                                        className="hit"
                                        key={hit.id}
                                    >
                                        <SearchResultObjectHit
                                            item={hit}
                                            onClick={this.onResultClick}
                                        />
                                    </li>
                                ))}
                            </ol>
                        </div>
                        {this.props.headers && this.props.results && this.props.headers.totalCount > this.props.results.length && (
                            <div className="show-more">
                                <Link
                                    className="search-page-link"
                                    to={"/search?q=" + this.state.queryText}
                                >
                                    <Label size="body-2" weight="normal">
                                        {localize("SEARCH_SHOW_INTERNAL_HITS")}
                                    </Label>
                                </Link>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    protected async executeSearch(now: Date = null): Promise<void> {
        if (!this.props.executeSearchAction) {
            return
        }

        if (typeof this.cancelTokenSource != typeof undefined) {
            this.cancelTokenSource.cancel("Operation canceled due to new request.")
        }

        this.cancelTokenSource = axios.CancelToken.source()

        const lastParams = this.props.lastParams || {};
        await this.props.executeSearchAction({
            sort: 0,

            ...lastParams,

            objectType: 0,
            query: this.state.queryText,
        }, this.cancelTokenSource.token);
        
        if (!this.lastSearch || now > this.lastSearch) {
            this.lastSearch = now;
        }
    }

    protected isOnSearchPage(): boolean {
        return this.props.location.pathname
            .toLowerCase()
            .indexOf("/search") === 0;
    }

    protected onClearClick(): void {
        this.setState(
            { queryText: "" },
            () => {
                this.props.setResponsiveSearchVisible(false);
                this.props.clearSearchResults();
            },
        );
    }

    protected onOverlayClick(): void {
        this.onClearClick();
    }

    protected onResultClick(item: Spintr.ISearchResult): void {
        if (!item || !this.props.lastParams || !this.props.headers) {
            return;
        }

        const isInternal = item.url.startsWith(window.location.origin) || item.url.startsWith("/");

        if (isInternal) {
            this.props.history.push(item.url);
        } else {
            window.open(item.url);
        }

        const feedback: ISearchFeedback = {
            objectId: item.id,
            query: this.props.lastParams.query,
            totalHits: this.props.headers.totalCount
        };

        this.setState(
            { queryText: "" },
            () => {
                submitFeedback(feedback);

                this.props.clearSearchResults();
                this.props.setResponsiveSearchVisible(false);
            }
        );
    }
    
    protected onNextQuery(query: string): void {
        const now: Date = new Date();

        if (query.length === 0) {
            if (this.props.clearSearchResults) {
                this.props.clearSearchResults();
            }
            return;
        }

        this.executeSearch(now);
    }

    protected onInputChange(event: ChangeEvent<HTMLInputElement>): void {
        this.setState(
            { queryText: event.target.value },
            () => this.querySubject.next(this.state.queryText),
        );
    }
}

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = {
    clearSearchResults,
    executeSearchAction,
    setResponsiveSearchVisible,
};

const mapStateToProps: MapStateToProps<IStateProps, {}, Spintr.AppState> =
    (state) => ({
        headers: state.search.currentHeaders,
        isLoading: state.search.isLoadingResults,
        isVisible: (state.ui.responsive || {}).searchVisible,
        lastParams: state.search.lastSuccessfulParams,
        office365Enabled: (
            state.instance.get("office365Enabled") as boolean
            &&
            state.profile.active.office365Connected
        ),
        results: state.search.currentResults,
    });

const ResponsiveSearchWithRouter = withRouter(ResponsiveSearch);

const ConnectedResponsiveSearchWithRouter = connect(
    mapStateToProps, mapDispatchToProps,
)(ResponsiveSearchWithRouter);

export default ConnectedResponsiveSearchWithRouter;