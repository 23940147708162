import { AxiosRequestConfig, CancelToken } from "axios";
import api from "src/spintr/SpintrApi";

const baseUrl = "/api/v1/routes";

export async function getRouteAsync<TType>(
    route: string,
    cancelToken: CancelToken
): Promise<TType> {
    const requestCfg: AxiosRequestConfig = {
        params: {
            route,
        },
        cancelToken,
    }

    const response = await api.get<TType>(
        `${baseUrl}`,
        requestCfg,
    );

    return response.data;
}
