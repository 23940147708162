import React, { ReactElement, useState } from "react";
import { TagListProps } from "./TagList.types";
import { TagItem } from "../TagItem";
import { localize } from "src/l10n";
import { CaptionBody } from "../Text";

function TagList({ tags, initialDisplayAll }: TagListProps): ReactElement {
    const [displayAll, setDisplayAll] = useState(initialDisplayAll);

    if (!tags || tags.length === 0) {
        return null;
    }

    const initialVisibleCount = 3;
    const visibleTags = displayAll ? tags : tags.slice(0, initialVisibleCount);
    const hiddenTagCount = tags.length - initialVisibleCount;

    return (
        <div className="TagList">
            <ol className="TagList-items">
                {visibleTags.map((tag) => (
                    <li key={tag.id}>
                        <TagItem tag={tag} />
                    </li>
                ))}
                {!displayAll && hiddenTagCount > 0 && (
                    <li className="TagItem" onClick={(e) => {
                        e.preventDefault(); // Prevent parent Link from being triggered
                        setDisplayAll(true);
                    }}>
                        <CaptionBody letterSpacing="dense" weight="medium">
                            {`+${hiddenTagCount} ${localize("Mer2").toLowerCase()}`}
                        </CaptionBody>
                    </li>
                )}
            </ol>
        </div>
    )
}

export default TagList;
