import moment from 'moment';
import { Callout } from "@fluentui/react";
import React, { Component } from 'react';
import { localize } from "src/l10n";
import { Label, SpintrUser, UnstyledButton } from "src/ui";
import { Style } from 'src/ui/helpers';
import { circleMediumSmall, circleLarge } from 'src/ui/helpers/style';
import "src/groups/views/GroupHeaderInformation.scss";
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';

interface IProps {
    id: number;
    imageUrl: string;
    name: string;
    status: number;
    availability: number;
    description: string;
    createdDate: string;
    memberCount: number;
    type: number;
    members: any[];
    history?: any;
    canInvite: boolean;
}

interface IState {
}

class GroupHeaderInformation extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    getSubText() {
        if (this.props.type === 2) {
            return localize("CUSTOMER_SUCCESS_GROUP");
        }

        if (this.props.availability === 1) {
            return localize("OppenGrupp");
        } else if (this.props.availability === 2) {
            return localize("StangdGrupp");
        } else {
            return localize("HemligGrupp");
        }
    }

    render() {
        return (
            <div className="GroupHeaderInformation">
                <div className="GroupHeaderInformation-inner">
                    <div className="image">
                        <SpintrUser
                            name={this.props.name}
                            subText={this.getSubText()}
                            imageUrl={this.props.imageUrl}
                            size={circleMediumSmall}
                            hideText={true}
                            personalName={false}
                        />
                    </div>
                    <div className="text">
                        <div className="upper">
                            <Label className="name" size="body-1" weight="medium" color="visageGray">
                                {this.props.name}
                            </Label>
                            <div className="lower">
                                <Label className="message" size="body-2" color="visageGray3">
                                    {this.getSubText()}
                                </Label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider" />
                <UnstyledButton className="members" onClick={() => {
                    this.props.history.push("/groups/" + this.props.id + "/members");
                }}>
                    {this.props.members.slice(0, 4).map((member: any) => {
                        return (
                            <div key={member.id}>
                                <SpintrUser
                                    personalName
                                    id={member.id}
                                    name={member.name}
                                    imageUrl={member.imageUrl}
                                    hideText
                                    size={20}
                                />
                            </div>
                        )
                    })}
                    {this.props.members.length > 4 && (
                        <div className="plus">
                            <Label size="small-2" color="visageMidBlue">{"+" + (this.props.members.length - 4)}</Label>
                        </div>
                    )}
                </UnstyledButton>
                {this.props.canInvite && <div className="divider" />}
                {this.props.canInvite && (
                    <UnstyledButton className="add-member" onClick={() => {
                        this.props.history.push("/groups/" + this.props.id + "/members/invite");
                    }}>
                        <Visage2Icon icon="user-add" color="visageMidBlue" size="small" />
                        <Label color="visageMidBlue" weight="medium" size="body-2">{localize("LaggTillMedlemmar")}</Label>
                    </UnstyledButton>
                )}
            </div>
        )
    }
}

export default GroupHeaderInformation;
