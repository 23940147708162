import React, { Fragment, ReactElement } from "react";
import { PlannerItemDetailProps } from "./PlannerItemDetail.types";
import { SpintrTypes } from "src/typings";
import { Conditional } from "src/components/Conditional";
import { CaptionBody, CaptionBolder, SmallBody, SmallBodyBolder } from "src/components/Text";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { PlannerFileList } from "../PlannerFileList";
import { getMarketingChannelTypeText } from "../utils";

function PlannerCampaignDetails({ item }: PlannerItemDetailProps): ReactElement {
    if (item.itemType !== SpintrTypes.PlannerItemType.Campaign) {
        return null;
    }
    
    return (
        <Fragment>
            <Conditional condition={item.campaignGoals && item.campaignGoals.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_CAMPAIGN_GOALS")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.campaignGoals}
                    </SmallBody>
                </div>
            </Conditional>

            <section className="PlannerItemDetail-infoBlock">
                <div className="PlannerItemDetail-infoBlock-content">
                    <div className="PlannerItemDetail-infoBlock-property">
                        <div className="PlannerItemDetail-infoBlock-property-label">
                            <Visage2Icon icon="tag" color="spintrGrey" size="medium" />
                            <CaptionBolder color="contentDark">
                                {localize("PLANNER_CAMPAIGN_MARKETING_CHANNELS")}
                            </CaptionBolder>
                        </div>
                        <div className="PlannerItemDetail-infoBlock-property-value">
                            <SmallBody color="contentNormal">
                                {item.marketingChannels?.map((channel) => getMarketingChannelTypeText(channel)).join(", ")}
                            </SmallBody>
                        </div>
                    </div>
                </div>
            </section>

            <PlannerFileList
                fieldType={SpintrTypes.PlannerCampaignFileType.CampaignMaterial}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Campaign} 
                title={localize("PLANNER_CAMPAIGN_MATERIAL")} />

            <Conditional condition={item.targetAudience && item.targetAudience.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_CAMPAIGN_AUDIENCE")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.targetAudience}
                    </SmallBody>
                </div>
            </Conditional>

            <Conditional condition={item.primaryMessage && item.primaryMessage.length > 0}>
                <div className="PlannerItemDetail-textBlock">
                    <SmallBodyBolder color="contentDark">
                        {localize("PLANNER_CAMPAIGN_MESSAGE")}
                    </SmallBodyBolder>
                    <SmallBody color="contentNormal" weight="regular">
                        {item.primaryMessage}
                    </SmallBody>
                </div>
            </Conditional>

            <PlannerFileList
                fieldType={SpintrTypes.PlannerCampaignFileType.CampaignPlan}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Campaign} 
                title={localize("PLANNER_CAMPAIGN_PLAN")}
                text={item.campaignPlan} />

            <Conditional condition={
                (item.expectedReach && item.expectedReach.length > 0) ||
                (item.campaignPartners && item.campaignPartners.length > 0)
            }>
                <section className="PlannerItemDetail-infoBlock">                
                    <div className="PlannerItemDetail-infoBlock-content">
                        <Conditional condition={item.expectedReach && item.expectedReach.length > 0}>
                            <div className="PlannerItemDetail-infoBlock-property">
                                <div className="PlannerItemDetail-infoBlock-property-label">
                                    <Visage2Icon icon="tag" color="spintrGrey" size="medium" />
                                    <CaptionBolder color="contentDark">
                                        {localize("PLANNER_CAMPAIGN_REACH")}
                                    </CaptionBolder>
                                </div>
                                <div className="PlannerItemDetail-infoBlock-property-value">
                                    <SmallBody color="contentNormal">
                                        {item.expectedReach}
                                    </SmallBody>
                                </div>
                            </div>
                        </Conditional>

                        <Conditional condition={item.campaignPartners && item.campaignPartners.length > 0}>
                            <div className="PlannerItemDetail-infoBlock-property">
                                <div className="PlannerItemDetail-infoBlock-property-label">
                                    <Visage2Icon icon="calendar-1" color="spintrGrey" size="medium" />
                                    <CaptionBolder color="contentDark">
                                        {localize("PLANNER_CAMPAIGN_PARTNERS")}
                                    </CaptionBolder>
                                </div>
                                <div className="PlannerItemDetail-infoBlock-property-value">
                                    <SmallBody color="contentNormal">
                                        {item.campaignPartners}
                                    </SmallBody>
                                </div>
                            </div>
                        </Conditional>
                    </div>
                </section>
            </Conditional>

            <PlannerFileList
                fieldType={SpintrTypes.PlannerCampaignFileType.LegalComplianceInfo}
                files={item.files}
                itemType={SpintrTypes.PlannerItemType.Campaign} 
                text={item.legalComplianceInfo}
                title={localize("PLANNER_CAMPAIGN_LEGAL")} />
        </Fragment>
    );
}

export default PlannerCampaignDetails;
