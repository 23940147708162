import { AxiosResponse } from "axios";
import api from "src/spintr/SpintrApi";
import { IGetConversationsParams } from "../conversation-types";

const baseUrl = "/api/v1/conversations";

export type GetConversationsApiHandler =
    (params: IGetConversationsParams) => Promise<[number, Spintr.IConversation[]]>;
export const getConversations: GetConversationsApiHandler = async (params) => {
    try {
        const response = await api.get<Spintr.IConversation[]>(baseUrl, {
            params,
        });

        const headers = response.headers as { [ key: string]: string };

        if (!headers["X-Total-Count"]) {
            return [response.data.length, response.data];
        }

        const totalCount = parseInt(headers["X-Total-Count"], 10);
        return [
            isNaN(totalCount) ? response.data.length : totalCount,
            response.data
        ];
    } catch (err) {
        return [0, null];
    }
};

export type GetConversationApiHandler = (id: number) => Promise<Spintr.IConversation>;
export const getConversation: GetConversationApiHandler =
    async (id) => {
        try {
            const response = await api.get<Spintr.IConversation>(
                baseUrl + "/" + id,
            );

            return response.data;
        } catch {
            return null;
        }
    };

export type CreateConversationApiHandler = (
    participants: Spintr.IUser[],
    initialMessage?: string,
) => Promise<AxiosResponse<Spintr.IConversation>>;
export const createConversation: CreateConversationApiHandler = 
    (participants, message = null) => api.post(
        baseUrl, { message, participants },
    );