import { PrimaryButton } from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { IAcademyLesson, IAcademyQuestion } from "src/academy/types";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label, PageHeader } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import UberContentView from "src/ui/components/UberContent/UberContentView";
import "./AcademyLessonView.scss";
import AcademyImage from "../AcademyImage/AcademyImage";
import { SpintrTypes } from "src/typings";

interface IProps {
    onDone: () => void;
    id: number;
    isLastStep: boolean;
}

interface IState {
    isLoading: boolean;
    item?: IAcademyLesson;
    questions: IAcademyQuestion[];
    tasks: IAcademyQuestion[];
}

const AcademyLessonView = (props: IProps) => {
    const [state, setState] = useState<IState>({
        isLoading: true,
        questions: [],
        tasks: [],
    });

    useEffect(() => {
        fetch();
    }, []);

    const fetch = useCallback(() => {
        api.get("/api/v1/academy/lessons/" + props.id).then((response: AxiosResponse) => {
            const questions = response.data.uberContent.uberContentRows.
                flatMap((ucr) => ucr.uberContentColumns).
                filter((ucc) =>
                    ucc.type === SpintrTypes.UberContentSectionType.AcademyQuestion &&
                    ucc.state === SpintrTypes.UberContentSectionState.IsDisplayed
                ).
                map((ucc) => ({id: ucc.id, ...JSON.parse(ucc.content)}));

            const tasks = response.data.uberContent.uberContentRows.
                flatMap((ucr) => ucr.uberContentColumns).
                filter((ucc) =>
                    ucc.type === SpintrTypes.UberContentSectionType.AcademyTask &&
                    ucc.state === SpintrTypes.UberContentSectionState.IsDisplayed
                ).
                map((ucc) => ({id: ucc.id, ...JSON.parse(ucc.content)}));

            setState((s: IState) => {
                return {
                    ...s,
                    isLoading: false,
                    item: response.data,
                    questions,
                    tasks,
                }
            });
        }).catch(() => { });
    }, []);

    const updateAnswerInUberContent = (uberContent, question, correct) => {
        uberContent.uberContentRows = uberContent.uberContentRows.map((ucr) => ({
            ...ucr,
            uberContentColumns: ucr.uberContentColumns.map((ucc) => {
                if (ucc.id !== question.id) {
                    return ucc;
                }

                const obj = JSON.parse(ucc.content);
                obj.answer = {
                    ...question.answer,
                    isValidated: true,
                    validationResult: correct,
                }

                return {
                    ...ucc,
                    content: JSON.stringify(obj),
                };
            })
        }));
    }

    const onDone = useCallback(() => {
        const uberContent = {...state.item.uberContent};
        let incorrectAnswer = false;
        if (state.questions) {
            for (var question of state.questions) {
                const correctAnswer = question.alternatives.find((a) => a.isCorrect);

                if (question.answer.alternatives.some((a) => a.id === correctAnswer.id)) {
                    updateAnswerInUberContent(uberContent, question, true);
                } else {
                    incorrectAnswer = true;
                    updateAnswerInUberContent(uberContent, question, false);
                }
            }

            setState((prevState) => ({
                ...prevState,
                item: {
                    ...prevState.item,
                    uberContent,
                },
            }));
        }

        if (incorrectAnswer) {
            return;
        }

        if (state.item.userProgress.isCompleted) {
            return props.onDone();
        }

        setState((s: IState) => {
            return {
                ...s,
                isLoading: true
            }
        });

        api.post("/api/v1/academy/lessons/" + state.item.id + "/read").then(props.onDone).catch(() => { });
    }, [state.item, state.questions]);

    return (
        <div className="AcademyLessonView">
            {
                state.item && (
                    <div>
                        {state.item.imageUrl && (
                            <AcademyImage imageUrl={state.item.imageUrl} />
                        )}
                        <Label color="grey">{state.item.chapterName}</Label>
                        <PageHeader title={state.item.name} />
                        <div className="lesson-content">
                            <UberContentView
                                uberContent={state.item.uberContent}
                                onUpdateQuestion={(newQuestion) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        questions: prevState.questions.map((oldQuestion) => oldQuestion.id === newQuestion.id ? newQuestion : oldQuestion)
                                    }));
                                }}
                                onUpdateTask={(newTask) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        tasks: prevState.tasks.map((oldTask) => oldTask.id === newTask.id ? newTask : oldTask)
                                    }));
                                }}
                            />
                        </div>
                    </div>
                )
            }
            <div className="lesson-footer">
                {state.isLoading && (
                    <SpintrLoader />
                )}
                {!state.isLoading && !(props.isLastStep && state.item.userProgress.isCompleted) && (
                    <PrimaryButton
                        disabled={
                            state.questions.some((q) => !q.answer) ||
                            state.tasks.some((q) => q.alternatives.length !== q.answer?.alternatives.length)
                        }
                        text={props.isLastStep ? localize("FINISH_COURSE") : localize("GaVidare")}
                        onClick={onDone} />
                )}
            </div>
        </div>
    )
}

export default AcademyLessonView;
