import { isSidebarItemActive } from "src/utils";
import { SidebarActionTypes as ActionTypes } from "./action-types";

export enum VisageSidebarMenuItemActiveMode {
    exact = 1,
    relative = 2
}

export enum VisageSidebarDrawerId {
    none = 1,
    groups = 2,
    messages = 3,
    systemStatus = 4,
}

export interface IVisageSidebarMenuItem {
    key: string,
    name: string,
    icon?: string,
    iconStyle?: string;
    activeIcon?: string;
    route?: string,
    routes?: string[],
    search?: string,
    smallerIcon?: boolean,
    iconColor?: string,
    small?: boolean,
    children?: IVisageSidebarMenuItem[],
    isExpanded?: boolean;
    isActive?: boolean;
    unreadCount?: number;
    parentKey?: string;
    activeMode?: VisageSidebarMenuItemActiveMode;
    drawerId?: VisageSidebarDrawerId;
    moduleKey?: string;
    isLoading?: boolean;
    enableChildrenSearch?: boolean;
    dummyClassName?: string;
    imageUrl?: string;
    color?: string;
    menuClassName?: string;
    onClick?: (item: IVisageSidebarMenuItem) => boolean | undefined;
    lazyLoadChildren?: boolean;
}

export enum VisageSidebarMode {
    menu = 1,
    submenu = 2,
    drawer = 3,
    scoped = 4,
    scopedContracted = 5,
    scopedDrawer = 6,
    noMenu = 7,
}

export interface ISidebarState {
    items: IVisageSidebarMenuItem[];
    mode: VisageSidebarMode;
    drawerId: VisageSidebarDrawerId;
    menuItems: IVisageSidebarMenuItem[];
    menuFetchHasBeenInitialized: boolean;
    displaySearch: boolean;
    displayBackButton: boolean;
}

const initialState: ISidebarState = {
    items: [],
    mode: VisageSidebarMode.menu,
    drawerId: VisageSidebarDrawerId.none,
    menuItems: [],
    menuFetchHasBeenInitialized: false,
    displaySearch: false,
    displayBackButton: true
};

const setItemChildren = (items: IVisageSidebarMenuItem[], children: IVisageSidebarMenuItem[], key: string) => {
    return items.map((item: IVisageSidebarMenuItem) => {
        if (item.key === key || item.moduleKey === key) {
            return {
                ...item,
                children,
                isExpanded: true
            }
        } else if (!!item.children && item.children.length > 0) {
            return {
                ...item,
                children: setItemChildren(item.children, children, key),
            }
        } else {
            return item;
        }
    })
}

const doesItemsContainMenuItem = (items: IVisageSidebarMenuItem[], key: string) => {
    let existsInMenu = false;

    for (const item of items) {
        if (existsInMenu) {
            continue;
        }

        const isMatch = (!!item.key && item.key === key) ||
            (!!item.moduleKey && item.moduleKey === key);

        if (isMatch) {
            existsInMenu = true;
        }

        if (!!item.children) {
            const existsInChildren = doesItemsContainMenuItem(item.children, key);

            if (existsInChildren) {
                existsInMenu = true;
            }
        }
    }

    return existsInMenu;
}

const setItemExpanded = (items: IVisageSidebarMenuItem[], key: string, value: boolean, isLoading?: boolean) => {
    return items.map((item: IVisageSidebarMenuItem, index: number) => {
        if (item.key === key) {
            return {
                ...item,
                isExpanded: value,
                isLoading: isLoading
            }
        } else if (!!item.children && item.children.length > 0) {
            return {
                ...item,
                children: setItemExpanded(item.children, key, value)
            }
        } else {
            return item;
        }
    })
}

const sidebarReducer = (state: ISidebarState = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.SetMenu:
            return {
                ...state,
                menuItems: action.items,
                menuFetchHasBeenInitialized: true,
                items: state.mode === VisageSidebarMode.menu || state.mode === VisageSidebarMode.drawer ? action.items : state.items
            }
        case ActionTypes.SetSidebarItems:
            let existsInMenu = false;

            if (action.key) {
                existsInMenu = doesItemsContainMenuItem(state.menuItems, action.key);
            }

            if (existsInMenu) {
                const children = action.items.length === 1 ? action.items[0].children : action.items;
                const newMenuItems = setItemChildren(state.menuItems, children, action.key);

                return {
                    ...state,
                    items: newMenuItems,
                    menuItems: newMenuItems,
                    mode: VisageSidebarMode.menu
                }
            } else {
                return {
                    ...state,
                    items: [...action.items],
                    mode: VisageSidebarMode.submenu
                };
            }
        case ActionTypes.SetItemChildren:
            return {
                ...state,
                items: setItemChildren(state.items, action.items, action.key)
            }
        case ActionTypes.SetItemExpanded:
            const existsInMenu2 = doesItemsContainMenuItem(state.menuItems, action.key);

            return {
                ...state,
                items: setItemExpanded(state.items, action.key, action.value, action.isLoading),
                menuItems: existsInMenu2 ?
                    setItemExpanded(state.menuItems, action.key, action.value, false) :
                    state.menuItems
            }
        case ActionTypes.ExpandToActiveItem:
            let activeKeys: string[] = [];
            let flatList: IVisageSidebarMenuItem[] = [];

            const addItemsToFlatList = (items: IVisageSidebarMenuItem[], parentKey: string) => {
                for (let item of items) {
                    flatList.push({
                        ...item,
                        parentKey
                    });

                    if (item.children && item.children.length > 0) {
                        addItemsToFlatList(item.children, item.key);
                    }
                }
            }

            addItemsToFlatList(state.items, "");

            const addItemToActiveKeys = (item: IVisageSidebarMenuItem) => {
                activeKeys.push(item.key);

                if (!!item.parentKey) {
                    const parent = flatList.find((i: any) => i.key === item.parentKey);

                    if (!!parent) {
                        addItemToActiveKeys(parent)
                    }
                }
            }

            for (let item of flatList) {
                if (isSidebarItemActive(item)) {
                    addItemToActiveKeys(item);
                }
            }

            const getCollection = (items: IVisageSidebarMenuItem[]) => {
                return items.map((item: IVisageSidebarMenuItem, index: number) => {
                    return {
                        ...item,
                        isExpanded: item.isExpanded ? true : activeKeys.indexOf(item.key) > -1,
                        children: !!item.children ? getCollection(item.children) : item.children
                    }
                })
            }

            return {
                ...state,
                items: getCollection(state.items)
            }
        case ActionTypes.SetSidebarMode:
            if (action.mode === VisageSidebarMode.submenu) {
                return state;
            }

            return {
                ...state,
                mode: action.mode,
                items: action.mode === VisageSidebarMode.menu ? state.menuItems : state.items
            }
        case ActionTypes.SetSidebarDrawerId:
            return {
                ...state,
                drawerId: action.value
            }
        case ActionTypes.SetDisplaySearch:
            return {
                ...state,
                displaySearch: action.value
            }
        case ActionTypes.SetDisplayBackButton:
            return {
                ...state,
                displayBackButton: action.value
            }
        default:
            return state;
    }
}

export default sidebarReducer;
