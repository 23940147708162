import { SpintrTypes } from "src/typings";

export function fileTypeToExtension(type: Spintr.FileType, prefixWithFullStop: boolean = false): string {
    let fileType: string;

    switch (type) {
        case SpintrTypes.FileType.Pdf:
            fileType = "pdf";
            break;

        case SpintrTypes.FileType.Mp3:
            fileType = "mp3";
            break;

        case SpintrTypes.FileType.Jpg:
            fileType = "jpg";
            break;

        case SpintrTypes.FileType.Xls:
            fileType = "xls";
            break;

        case SpintrTypes.FileType.Xlsx:
            fileType = "xlsx";
            break;

        case SpintrTypes.FileType.Doc:
            fileType = "doc";
            break;

        case SpintrTypes.FileType.Docx:
            fileType = "docx";
            break;

        case SpintrTypes.FileType.Png:
            fileType = "png";
            break;

        case SpintrTypes.FileType.Ppt:
            fileType = "ppt";
            break; 

        case SpintrTypes.FileType.Pptx:
            fileType = "pptx";
            break;

        case SpintrTypes.FileType.Zip:
            fileType = "zip";
            break;

        case SpintrTypes.FileType.Gif:
            fileType = "gif";
            break;

        case SpintrTypes.FileType.Wav:
            fileType = "wav";
            break;

        case SpintrTypes.FileType.Rtf:
            fileType = "rtf";
            break;

        case SpintrTypes.FileType.Html:
            fileType = "html";
            break;

        case SpintrTypes.FileType.Txt:
            fileType = "txt";
            break;

        case SpintrTypes.FileType.Csv:
            fileType = "csv";
            break;

        case SpintrTypes.FileType.Pps:
            fileType = "pps";
            break;

        case SpintrTypes.FileType.Ods:
            fileType = "ods";
            break;

        case SpintrTypes.FileType.Odt:
            fileType = "odt";
            break;

        case SpintrTypes.FileType.Odp:
            fileType = "odp";
            break;

        case SpintrTypes.FileType.Sxw:
            fileType = "sxw";
            break;

        case SpintrTypes.FileType.Sxc:
            fileType = "sxc";
            break;

        case SpintrTypes.FileType.Sxi:
            fileType = "sxi";
            break;

        case SpintrTypes.FileType.Aac:
            fileType = "aac";
            break;

        default:
            fileType = "";
            break;
    }

    return prefixWithFullStop && fileType.length > 0 ? `.${fileType}` : fileType;
}