import {
    HoverCard,
    HoverCardType, Icon, IPlainCardProps, KeyCodes
} from "@fluentui/react/lib";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { addRemoteConversation, AddRemoteConversationHandler, setTabState, SetTabStateHandler } from 'src/chat';
import { localize } from 'src/l10n';
import { IApplicationState } from 'src/spintr/reducer';
import { Label, Loader, SpintrUser, UnstyledButton } from 'src/ui';
import "./UserHovercard.scss";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    userId: number;
    conversations: Spintr.IConversation[];
    currentUserId: number;
    disablePersonChat?: boolean;
}

interface IDispatchProps {
    setTabState: SetTabStateHandler;
    addRemoteConversation: AddRemoteConversationHandler;
}

interface IState {
    user: any;
    isFetching: boolean;
}

class UserHovercard extends Component<IDispatchProps & IProps, IState> {
    constructor (props) {
        super(props);

        this.state = {
            user: null,
            isFetching: false
        }
    }

    fetchIfNeeded() {
        if (!!this.state.user ||
            this.state.isFetching) {
            return;
        }

        this.fetch(this.props.userId);
    }

    fetch(userId) {
        if (this.state.isFetching) {
            return;
        }

        this.setState({
            isFetching: true
        }, () => {
            api.get(`/api/hovercard/${userId}`).then((response) => {
                //response.data.coverArt = "https://i.pinimg.com/originals/bb/61/8d/bb618db7d825e2e03c8d86f781a65e06.jpg";

                this.setState({
                    isFetching: false,
                    user: response.data
                });
            }).catch((error) => {
                // TODO: Handle error
            });;
        });
    }

    onRenderPlainCard = (user: any): JSX.Element => {
        if (!user) {
            return (
                <div className="UserHovercard">
                    <Loader />
                </div>
            )
        }

        return (
            <div className="UserHovercard">
                {
                    !!user.coverArt ?
                        <div className="UserHovercard-coverArt" style={{
                            backgroundImage: `url(${user.coverArt})`
                        }}></div> :
                        null
                }
                <div className="UserHovercard-personaWrapper">
                    <SpintrUser
                        name={user.name}
                        subText={user.department}
                        imageUrl={user.profileImage}
                        nameLink={`/profile/${user.id}`}
                        state={user.state}
                        personalName={true} />
                </div>
                <div className="UserHovercard-contact">
                    <div>
                        {
                            !!user.phone ?
                                <a className="" href={`tel:${user.phone}`}>
                                    <div className="UserHovercard-contact-row">
                                        <Visage2Icon className="UserHovercard-contact-row-icon" icon={"call"} />
                                        <Label
                                            className=""
                                            as="span"
                                            color="black"
                                        >
                                            {user.phone}
                                        </Label>
                                    </div>
                                </a> :
                                null
                        }
                        {
                            !!user.privatePhone ?
                                <a href={`tel:${user.privatePhone}`}>
                                    <div className="UserHovercard-contact-row">
                                        <Visage2Icon className="UserHovercard-contact-row-icon" icon={"call"} />
                                        <Label
                                            as="span"
                                            color="black"
                                        >
                                            {user.privatePhone}
                                        </Label>
                                    </div>
                                </a> :
                                null
                        }
                        {
                            !!user.email ?
                                <a href={`mailto:${user.email}`}>
                                    <div className="UserHovercard-contact-row">
                                        <Visage2Icon className="UserHovercard-contact-row-icon" icon={"sms"} />
                                        <Label
                                            as="span"
                                            className=""
                                        >
                                            {user.email}
                                        </Label>
                                    </div>
                                </a> :
                                null
                        }
                    </div>
                </div>
                <div className="UserHovercard-footer">
                    <Link to={`/profile/${user.id}`}>
                        <Label
                            as="span"
                            className=""
                            size="small-1"
                        >
                            {
                                localize("Profil")
                            }
                        </Label>
                    </Link>
                    {this.props.currentUserId !== user.id && !this.props.disablePersonChat && !user.isBlocked && !user.isBlockedBy && (
                        <>
                            <Label className="UserHovercard-footer-divider" as="span" size="small-1">
                                ·
                            </Label>

                            <UnstyledButton
                                onClick={async () => {
                                    if (!this.props.conversations.some((c) => c.id === user.conversationId)) {
                                        // 99% sure conversationId will always be set here?
                                        // const response = await api.get(
                                        //     `/api/v1/conversations/${user.conversationId}`
                                        // );
                                        // const conversation = new Conversation(response.data);
                                        // await this.props.addRemoteConversation(conversation);
                                    }
                                    this.props.setTabState(user.conversationId, true, false);
                                }}
                            >
                                <Label as="span" className="" size="small-1" color="light-blue">
                                    {localize("Kontakta")}
                                </Label>
                            </UnstyledButton>
                        </>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const plainCardProps: IPlainCardProps = {
            onRenderPlainCard: this.onRenderPlainCard,
            renderData: this.state.user
        };

        return (
            <HoverCard
                trapFocus
                openHotKey={KeyCodes.enter}
                className="UserHovercard-wrapper"
                plainCardProps={plainCardProps}
                onCardVisible={this.fetchIfNeeded.bind(this)}
                instantOpenOnClick
                type={HoverCardType.plain}>
                {
                    this.props.children
                }
            </HoverCard>
        );
    }
};


const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        currentUserId: state.profile.active.id,
        conversations: state.chat.conversations.items,
        disablePersonChat: state.instance.get('disablePersonChat'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...dispatch,
        ...bindActionCreators(
            {
                setTabState,
                addRemoteConversation
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserHovercard);
