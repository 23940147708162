import axios from "axios";
import { produce } from "immer";

import SearchActionTypes from "./action-types";
import { SearchAction } from "./actions";
import { ISearchParams } from "./api";
import { ISearchHeaders, parseSearchHeaders } from "./utils";

export interface ISearchState {
    currentResults?: Spintr.ISearchResult[];
    currentHeaders?: ISearchHeaders;
    errorMessageTag?: string;
    isLoadingResults: boolean;
    lastSuccessfulParams?: ISearchParams;
    onedriveResults?: any[];
    sharepointResults?: any[];
    isActive?: boolean;
}

const initialState: ISearchState = {
    isLoadingResults: false,
};

export const searchReducer =
    (state = initialState, action: SearchAction): ISearchState => produce(
        state, 
        (draft) => {
            switch (action.type) {
                case SearchActionTypes.ClearCurrentResults:
                    draft.currentResults = undefined;
                    draft.currentHeaders = undefined;
                    draft.lastSuccessfulParams = undefined;
                    draft.errorMessageTag = undefined;
                    draft.isLoadingResults = false;
                    draft.isActive = false;
                    break;

                case SearchActionTypes.ExecuteSearchFulfilled:
                    draft.currentHeaders = parseSearchHeaders(
                        action.payload.headers as { [name: string]: string },
                    );

                    const appendResults = action.meta.skip > 0;

                    if (appendResults) {
                        draft.currentResults = state.currentResults
                            .concat(action.payload.data);
                    } else {
                        draft.currentResults = action.payload.data;
                    }

                    draft.errorMessageTag = undefined;
                    draft.isLoadingResults = false;
                    draft.lastSuccessfulParams = action.meta;
                    break;

                case SearchActionTypes.ExecuteSearchPending:
                    draft.errorMessageTag = undefined;
                    draft.isLoadingResults = true;
                    draft.isActive = true;
                    break;

                case SearchActionTypes.ExecuteSearchRejected:
                    // TODO: switch over action.payload for exact reason
                    console.log(action.payload);
                    if (axios.isCancel(action.payload)) {
                        // don't show error message if the request was cancelled
                        // due to a new search request before the previous request finished
                        break;
                    }

                    draft.currentResults = undefined;
                    draft.currentHeaders = undefined;
                    draft.errorMessageTag = "TeknisktFel";
                    draft.isLoadingResults = false;
                    break;
                
                case SearchActionTypes.SetOnedriveResults:
                    draft.onedriveResults = action.results;
                    break;
                case SearchActionTypes.SetSharepointResults:
                    draft.sharepointResults = action.results;
                    break;
            }
        },
    );