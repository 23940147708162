import React, { ReactElement } from "react";
import { Redirect, Route, Switch } from "react-router";
import { PlannerItemsView } from "../PlannerItemsView";
import { useSelector } from "react-redux";
import { SpintrTypes } from "src/typings";

function PlannerRootView(): ReactElement {
    const isEnabled = useSelector<Spintr.AppState, boolean>(
        (state) => state.app.items.some(
            (app) => app.enabled && app.id === SpintrTypes.SpintrApp.Planner,
        ),
    );

    if (!isEnabled) {
        return <Redirect to="/404" />;
    }

    return (
        <section id="PlannerRootView" >
            <Switch>
                <Route path="/planner" component={PlannerItemsView} />
                <Redirect to="/planner" />
            </Switch>
        </section>
    )
}

export default PlannerRootView;