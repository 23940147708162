import React, { Component, CSSProperties } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { MarketplaceWidget } from 'src/marketplace';
import { SpintrTypes } from 'src/typings';
import { Style } from "src/ui/helpers";
import TinyFormattedContent from '../Tiny/displayment/TinyFormattedContent';
import { TeaserBox } from 'src/teaser-box/components';
import PageCalendar from '../PageCalendar';
import ExpandableField from '../ExpandableField';
import {
    Icon,
    PrimaryButton,
    ProgressIndicator
} from "@fluentui/react";
import "src/ui/components/UberContent/EditableContentSections/links.scss"
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Direkten from '../Direkten';
import PagePreamble from '../PagePreamble';
import UserList from '../UserList';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import "./UberContentView.scss";
import { ProductListColumn } from 'src/components';
import { UberContent } from './UberContent.types';
import TaxiFiles from '../TaxiFiles';
import { localize } from 'src/l10n';
import AcademyTestQuestion from 'src/academy/course/AcademyTestQuestion/AcademyTestQuestion';
import { IAcademyQuestion } from 'src/academy/types';

type OwnProps = {
    uberContent:                        UberContent;
    attachedFiles?:                     any | undefined;
    attachedFolders?:                   any | undefined;
    attachedSharePointListSearches?:    any | undefined;
    language?:                          string | undefined;
    onUpdateQuestion?:                  (updatedQuestion: IAcademyQuestion) => void;
    onUpdateTask?:                      (updatedTask: IAcademyQuestion) => void;
};

type StateProps = {
    isSmallViewMode?:   boolean | undefined;
}

type UberContentViewProps
    = OwnProps
    & StateProps
    & RouteComponentProps;

const linkIsInternal = (url: string, location) => {
    try {
        if (url.startsWith("/")) {
            return { isInternal: true, url };
        } else if (new URL(url).origin === window.location.origin) {
            return { isInternal: true, url: new URL(url).pathname };
        } else {
            return { isInternal: false, url: url };
        }
    } catch {
        return { isInternal: false, url: url };
    }
};

class UberContentView extends Component<UberContentViewProps> {
    render() {
        const displayedRows = this.props.uberContent.uberContentRows.map(x => {
            return {
                ...x,
                uberContentColumns: x.uberContentColumns.filter(x =>
                    x.state === SpintrTypes.UberContentSectionState.IsDisplayed)
            }
        }).filter(x => x.uberContentColumns.length > 0);

        return (
            <div className="UberContentView">
                {displayedRows.map((ucr) => {
                    return (
                        <div
                            key={ucr.index}
                            className="contentSection"
                            style={{
                                marginBottom: Style.getSpacingStr(5),
                            }}
                        >
                            {ucr.uberContentColumns.map((ucc) => {
                                const columnCount = ucr.uberContentColumns.length;
                                let isMultipleColumns = columnCount === 2 || columnCount > 2 && !this.props.isSmallViewMode;

                                let style: CSSProperties = {
                                    width: isMultipleColumns
                                        ? `calc((100% - ${Style.getSpacingStr(5 * (columnCount - 1))}) / ${columnCount})`
                                        : "100%",
                                    marginRight: isMultipleColumns
                                        ? ucc.index < columnCount - 1
                                            ? Style.getSpacingStr(5)
                                            : 0
                                        : 0,
                                    display: isMultipleColumns ? "inline-block" : "block",
                                    verticalAlign: "top",
                                };

                                let content = ucc.content;

                                if (ucc.type === SpintrTypes.UberContentSectionType.Image &&
                                    content &&
                                    content.length > 0) {
                                        let contentObj;

                                        if (content[0] === "{") {
                                            contentObj = JSON.parse(content);

                                            content = contentObj.content;
                                        }

                                        content = `<img alt="" style="max-width: 100%" src="${content}"
                                            ${contentObj?.externalAuthorName ? `data-external-author-name="${contentObj.externalAuthorName}"` : ``}
                                            ${contentObj?.externalAuthorUrl ? `data-external-author-url="${contentObj.externalAuthorUrl}"`: ``}
                                            ${contentObj?.externalPhotoUrl ? `data-external-photo-url="${contentObj.externalPhotoUrl}"` : ``}
                                            ${contentObj?.externalMediaflowId ? `data-external-mediaflow-id="${contentObj.externalMediaflowId}"` : ``}
                                        />`;
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.Video && content?.length > 0) {
                                    const videoObject = JSON.parse(content);

                                    if (videoObject.embedString) {
                                        content = JSON.parse(content).embedString;
                                    } else if (videoObject.ticket) {
                                        return (
                                            <ProgressIndicator
                                                label={localize("BearbetningPagar")}
                                                description={localize("BearbetningForbereds")}
                                            />
                                        );
                                    } else {
                                        content = `<video
                                            onplay="fetch('/api/v1/blogposts/play/${videoObject.id}', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({url: '${videoObject.videoUrl}'})});"
                                            style="width: 500px; height: 281px;"
                                            class="player zxc azuremediaplayer amp-default-skin amp-big-play-centered"
                                            controls="">
                                            <source src="${videoObject.videoUrl}" />
                                            </video>`;
                                    }
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.DataWidget) {
                                    return (
                                        <div
                                            key={ucc.index}
                                            style={{
                                                ...style,
                                                ...(columnCount > 2 && this.props.isSmallViewMode
                                                    ? { marginBottom: Style.getSpacingStr(5) }
                                                    : {}),
                                            }}
                                            className="data-widget"
                                        >
                                            <div style={{
                                                margin: "0 auto",
                                            }}>
                                                <MarketplaceWidget
                                                    id={content}
                                                    autoWidth
                                                    displayBorder
                                                />
                                            </div>
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.TeaserBox) {
                                    return (
                                        <div
                                            key={ucc.index}
                                            style={{
                                                ...style,
                                                ...(columnCount > 2 && this.props.isSmallViewMode
                                                    ? { marginBottom: Style.getSpacingStr(5) }
                                                    : {}),
                                            }}
                                            className="page-teaser-box"
                                        >
                                            <div style={{
                                                margin: "0 auto",
                                            }}>
                                                <TeaserBox
                                                    id={content}
                                                    showBorder
                                                />
                                            </div>
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.Calendar) {
                                    const inlineCalendarPattern = /(?:class="redactorInlineFolder)\s([a-z]*-[0-9]*)">([A-Za-zÀ-ÖØ-öø-ÿ\d\s]*)/igm;

                                    let match, calendarId, calendarName;

                                    while ((match = inlineCalendarPattern.exec(ucc.content))) {
                                        calendarId = parseInt(match[1].match(/(\d+)/)[0]);

                                        if (isNaN(calendarId)) {
                                            continue;
                                        }

                                        calendarName = match[2];
                                    };

                                    return (
                                        <div
                                            key={ucc.index}
                                            style={{
                                                ...style,
                                                ...(columnCount > 2 && this.props.isSmallViewMode
                                                    ? { marginBottom: Style.getSpacingStr(5) }
                                                    : {}),
                                            }}
                                            className="page-calendar-event-list"
                                        >
                                            <div style={{
                                                margin: "0 auto",
                                            }}>
                                                <PageCalendar
                                                    id={calendarId}
                                                    columnCount={columnCount}
                                                />
                                            </div>
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.ExpandableField) {
                                    let contentObject = { text: "", title: "" };

                                    if (ucc.content) {
                                        try {
                                            contentObject = JSON.parse(ucc.content)
                                        } catch {
                                            contentObject = { text: "", title: "" };
                                        }
                                    }

                                    return (
                                        <div
                                            key={ucc.index}
                                            style={{
                                                ...style,
                                                ...(columnCount > 2 && this.props.isSmallViewMode
                                                    ? { marginBottom: Style.getSpacingStr(5) }
                                                    : {}),
                                            }}
                                            className="page-expandable-field"
                                        >
                                            <div style={{
                                                margin: "0 auto",
                                            }}>
                                                <ExpandableField title={contentObject.title} text={contentObject.text} />
                                            </div>
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.Link) {
                                    if (!content || content.length === 0) return null;
                                    let { url, title } = JSON.parse(content)
                                    const internal = linkIsInternal(url, this.props.location);

                                    const isInternal = internal.isInternal
                                    if (isInternal) {
                                        url = internal.url
                                    }

                                    const useInternalLink = isInternal && !url.startsWith("/api/");

                                    return (
                                        <div
                                            key={ucc.index}
                                            style={{
                                                ...style,
                                                ...(columnCount > 2 && this.props.isSmallViewMode
                                                    ? { marginBottom: Style.getSpacingStr(5) }
                                                    : {}),
                                            }}
                                            className="page-link-block"
                                        >
                                            <div style={{
                                                margin: "0 auto",
                                            }}>
                                                {
                                                    useInternalLink ?
                                                        <Link to={url}>
                                                            <div className="fs-body-2 InTextPageLinkButton" style={{ color: "#22234A" }}> 
                                                                {title} 
                                                                <Visage2Icon style={{ marginLeft: 8 }} icon="arrow-right-1" hexColor="#22234A" />
                                                            </div>
                                                        </Link> :
                                                        <a rel="noopener noreferrer" target="_blank" href={url} style={{ color: "#22234A" }}>
                                                            <div className="fs-body-2 InTextPageLinkButton">
                                                                {title}
                                                                <Visage2Icon style={{ marginLeft: 8 }} icon="arrow-right-1" hexColor="#22234A" />
                                                            </div>
                                                        </a>
                                                }
                                            </div>
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.UserList) {
                                        return (
                                        <div key={ucc.index} style={style}>
                                            <UserList data={content}/>
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.Products) {
                                    return (
                                        <div key={ucc.index} style={style}>
                                            <ProductListColumn
                                                columnContent={content} />
                                        </div>
                                    );
                                }

                                if (ucc.type == SpintrTypes.UberContentSectionType.Direkten) {
                                    return (
                                        <div
                                            key={ucc.index}
                                            style={style}
                                        >
                                            <Direkten category={content} />
                                        </div>
                                    );
                                }

                                if (ucc.type == SpintrTypes.UberContentSectionType.Preamble) {
                                    return (
                                        <div key={ucc.index} style={style}>
                                            <PagePreamble 
                                                content={content}
                                                language={this.props.language} />
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.TaxiFiles) {
                                    return (
                                        <div key={ucc.index} style={style}>
                                            <TaxiFiles />
                                        </div>
                                    );
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.AcademyQuestion) {
                                    const contentObj = JSON.parse(ucc.content);
                                    contentObj.id = ucc.id;

                                    return (
                                        <div key={ucc.index} style={style}>
                                            <AcademyTestQuestion
                                                index={0}
                                                question={contentObj}
                                                onUpdate={(updatedQuestion: IAcademyQuestion) => {
                                                    this.props.onUpdateQuestion?.(updatedQuestion);
                                                }}
                                                disabled={false}
                                                isSubmitting={false}
                                                isOnLesson
                                            />
                                        </div>
                                    )
                                }

                                if (ucc.type === SpintrTypes.UberContentSectionType.AcademyTask) {
                                    const contentObj = JSON.parse(ucc.content);
                                    contentObj.id = ucc.id;

                                    return (
                                        <div key={ucc.index} style={style}>
                                            <AcademyTestQuestion
                                                index={0}
                                                question={contentObj}
                                                onUpdate={(updatedTask: IAcademyQuestion) => {
                                                    this.props.onUpdateTask?.(updatedTask);
                                                }}
                                                disabled={false}
                                                isSubmitting={false}
                                                isTask
                                                isOnLesson
                                            />
                                        </div>
                                    )
                                }

                                return (
                                    <div key={ucc.index} style={style}>
                                        <TinyFormattedContent
                                            content={content}
                                            attachedFiles={this.props.attachedFiles}
                                            attachedFolders={this.props.attachedFolders}
                                            attachedSharePointListSearches={this.props.attachedSharePointListSearches}
                                            language={this.props.language} />
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        )
    }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, Spintr.AppState>
    = (appState) => ({
        isSmallViewMode: appState.ui.isSmallViewMode,
    });

const connector = connect<StateProps, {}, OwnProps, Spintr.AppState>(
    mapStateToProps,
    null,
    null,
    { forwardRef: true },
);

const ConnectedUberContentView = connector(UberContentView);
const ConnectedUberContentViewWithRouter = withRouter(ConnectedUberContentView)

export { UberContentView as UberContentViewClass };

export default ConnectedUberContentViewWithRouter;
