import React from "react";
import AcademyCourseSidebarSection from "src/academy/course/sidebar/AcademyCourseSidebarSection/AcademyCourseSidebarSection";
import { IAcademyCourse, IAcademyLesson, IAcademyTest } from "src/academy/types";
import { localize } from "src/l10n";
import "./AcademyCourseSidebar.scss";
import AcademyCourseSidebarMeta from "../AcademyCourseSidebarMeta/AcademyCourseSidebarMeta";
import AcademyCourseSidebarStatus from "../AcademyCourseSidebarStatus/AcademyCourseSidebarStatus";
import AcademyCourseSidebarParticipants from "../AcademyCourseSidebarParticipants/AcademyCourseSidebarParticipants";
import AcademyCourseSidebarContent from "../AcademyCourseSidebarContent/AcademyCourseSidebarContent";

interface IProps {
    course: IAcademyCourse;
    activeStepId?: number;
    onOpenCourse: () => void;
    onJoinCourse: () => void;
    onCancelCourse: () => void;
    displayPreview: boolean;
    goToLesson: (lesson: IAcademyLesson) => void;
    goToTest: (test: IAcademyTest) => void;
    goToFinalExam: () => void;
    onReviewCourse: () => void;
}

const AcademyCourseSidebar = (props: IProps) => {
    return (
        <div className="AcademyCourseSidebar">
            {props.displayPreview && (
                <AcademyCourseSidebarSection
                    title={localize("COURSE_DETAILS")}
                    onRender={() => {
                        return <AcademyCourseSidebarMeta course={props.course} />
                    }} />
            )}
            {(props.displayPreview || props.course.userProgress.isCompleted) && (
                <AcademyCourseSidebarSection
                    onRender={() => {
                        return <AcademyCourseSidebarStatus
                            displayPreview={props.displayPreview}
                            onReviewCourse={props.onReviewCourse}
                            onOpenCourse={props.onOpenCourse}
                            onJoinCourse={props.onJoinCourse}
                            onCancelCourse={props.onCancelCourse}
                            course={props.course} />
                    }} />
            )}
            {props.displayPreview && props.course.users.totalCount > 0 && (
                <AcademyCourseSidebarSection
                    title={localize("COWORKERS_WHO_HAVE_COMPLETED_THIS_COURSE")}
                    onRender={() => {
                        return <AcademyCourseSidebarParticipants course={props.course} />
                    }} />
            )}
            {!props.displayPreview && (
                <AcademyCourseSidebarSection
                    onRender={() => {
                        return (
                            <AcademyCourseSidebarContent
                                course={props.course}
                                activeStepId={props.activeStepId}
                                goToLesson={props.goToLesson}
                                goToTest={props.goToTest}
                                goToFinalExam={props.goToFinalExam} />
                        )
                    }} />
            )}
        </div>
    )
}

export default AcademyCourseSidebar;
