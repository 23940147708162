import React, { ReactElement } from "react";
import classNames from "classnames";
import { ContentMetadataBoxProps } from "./ContentMetadataBox.types";
import { MetadataField } from "../MetadataField";
import { Reach, SpintrUser } from "src/ui";
import moment from "moment";
import { localize } from "src/l10n";
import { Link } from "react-router-dom";

function ContentMetadataBox(props: ContentMetadataBoxProps): ReactElement {
    const {
        className,
        contentManager,
        createdAt,
        links
    } = props;

    const contentManagerBlock = !contentManager ? null : (
        <MetadataField label={localize("Innehallsansvarig")} labelBlock>
            <SpintrUser
                id={contentManager.id}
                imageUrl={contentManager.imageUrl} 
                name={contentManager.name}
                personalName={true}
                subText={contentManager.departmentName} />
        </MetadataField>
    );

    const createdAtBlock = !props.createdAt ? null : (
        <MetadataField label={localize("Skapad")}>
            {moment(createdAt).format("YYYY-MM-DD")}
        </MetadataField>
    );

    const lastModifiedBlock = !props.lastModified ? null : (
        <MetadataField label={localize("SenastAndrad")}>
            {moment(createdAt).format("YYYY-MM-DD")}
        </MetadataField>
    );

    const reach = !props.showReach || !props.contentId ? null : (
        <MetadataField label={localize("Rackvidd")}>
            <Reach uberId={props.contentId} />
        </MetadataField>
    )

    const articleLinks = links.length <= 0 ? null : (
        <MetadataField label={localize("Lankar")}>
            {links.map((link) => (
                <a className="link" href={link.url} target="_blank">
                    {link.title || link.url}
                </a>
            ))}
        </MetadataField>
    )

    return (
        <div className={classNames("ContentMetadataBox", className)}>
            {contentManagerBlock}

            {createdAtBlock}

            {lastModifiedBlock}

            {reach}

            {articleLinks}
        </div>
    );
}

export default ContentMetadataBox;
