import React, { CSSProperties, Fragment, ReactElement, useCallback, useMemo } from "react";
import { TimelineBarGroupProps } from "./TimelineBarGroup.types";
import { TimelineBar } from "../TimelineBar";
import { calculateBarPosition, formatDates } from "../utils";
import { CaptionBody } from "src/components/Text";
import { Conditional } from "src/components/Conditional";

function TimelineBarGroup({
    axisWidth,
    group,
    onBarClick,
    onBarGroupClick,
    onItemDurationChange,
    timelineDuration,
    timelineMode,
    timelineWidth,
    todayTime,
}: TimelineBarGroupProps): ReactElement {
    const minDate = useMemo(() => group.items.reduce<number>(
        (acc, item) => Math.min(acc, item.start.getTime()),
        Number.MAX_SAFE_INTEGER,), [group]);

    const maxDate = useMemo(() => group.items.reduce<number>(
        (acc, item) => Math.max(acc, item.end.getTime()),
        Number.MIN_SAFE_INTEGER,), [group]);

    const barStyle = useMemo<CSSProperties>(() => {
        if (group.layers < 3) {
            return {};
        }

        const { left, right } = calculateBarPosition(
            timelineWidth,
            timelineDuration,
            minDate,
            maxDate,
        );

        return {
            height: "42px",
            left: `${left}px`,
            right: `${right}px`,
            top: "4px",
        };
    }, [minDate, maxDate, timelineDuration, timelineWidth]);

    const onBarGroupClicked = useCallback(
        () => onBarGroupClick?.(group),
        [group, onBarGroupClick],
    );

    if (group.layers < 3) {
        return (
            <Fragment>
                {group.items.map((item) => (
                    <TimelineBar
                        axisWidth={axisWidth}
                        item={item}
                        key={item.key}
                        onBarClick={onBarClick}
                        onItemDurationChange={onItemDurationChange}
                        timelineDuration={timelineDuration}
                        timelineMode={timelineMode}
                        timelineWidth={timelineWidth}
                        todayTime={todayTime}
                    />
                ))}
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className="TimelineBarGroup" style={barStyle}>
                <div
                    className="TimelineBarGroup-inner"
                    onClick={onBarGroupClicked}
                    style={{ backgroundColor: group.items[0].color + "33", }}
                >
                    <CaptionBody
                        className="TimelineBarGroup-label-top"
                        color="contentDark"
                        weight="medium"
                    >
                        + {group.items.length}
                    </CaptionBody>
                    <div className="TimelineBarGroup-label-bottom">
                        <CaptionBody
                            color="contentNormal"
                            weight="regular"
                        >
                            {formatDates(minDate, maxDate)}
                        </CaptionBody>
                    </div>
                </div>
            </div>
            <Conditional condition={group.expanded}>
                {group.items.map((item) => (
                    <TimelineBar
                        axisWidth={axisWidth}
                        fullSize={true}
                        item={item}
                        key={item.key}
                        onBarClick={onBarClick}
                        onItemDurationChange={onItemDurationChange}
                        timelineDuration={timelineDuration}
                        timelineMode={timelineMode}
                        timelineWidth={timelineWidth}
                        todayTime={todayTime}
                    />
                ))}
            </Conditional>
        </Fragment>
    );
}

export default TimelineBarGroup;
