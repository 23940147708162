import { SpintrTypes } from "src/typings";

export enum SocialFeedType {
    Combined,
    General,
    Group,
    Profile,
    SystemStatus,
}

export enum StreamFiltering {
    None = 0,
    Popular = 1,
    Unit = 2,
}

export interface ICombinedFeedParams {
    /**
     * Extra can be used to filter by unit id
     */
    extra?: string;
    hashId?: number;
    lastDate?: string;
    skip?: number;
    take?: number;
    uberTypes: Spintr.UberType[];
    filteringMode?: number;
    isSilentFetch?: boolean;
}

export interface IGeneralFeedParams {
    /**
     * Extra can be used to filter by unit id
     */
    extra?: string;
    filteringMode?: StreamFiltering;
    hashId?: number;
    skip?: number;
    take?: number;
    uberTypes: Spintr.UberType[];
}

export interface IGroupFeedParams {
    hashId?: number;
    feedId: number;
    skip?: number;
    uberTypes: Spintr.UberType[];
}

export interface ISystemStatusFeedParams {
    feedId: number;
    skip?: number;
    take?: number;
    systemStatusType?: SpintrTypes.SystemStatusType;
    allSystemStatusResources?: boolean;
    searchText: string;
    uberTypes: Spintr.UberType[];
}

export interface IUserFeedParams {
    hashId?: number;
    skip?: number;
    uberTypes: Spintr.UberType[];
    feedId: number;
}

export interface ICombinedFeedRequest {
    feed: SocialFeedType.Combined;
    params: ICombinedFeedParams;
}

export interface IGeneralFeedRequest {
    feed: SocialFeedType.General;
    params: IGeneralFeedParams;
}

export interface IGroupFeedRequest {
    feed: SocialFeedType.Group;
    params: IGroupFeedParams;
}

export interface IUserFeedRequest {
    feed: SocialFeedType.Profile;
    params: IUserFeedParams;
}

export interface ISystemStatusFeedRequest {
    feed: SocialFeedType.SystemStatus;
    params: ISystemStatusFeedParams;
}

export type FeedRequest =
      ICombinedFeedRequest
    | IGeneralFeedRequest
    | IGroupFeedRequest
    | IUserFeedRequest
    | ISystemStatusFeedRequest;

export interface IVisage2SocialPostType {
    type: SpintrTypes.StatusType,
    icon: string,
    bgColor: string,
    iconColor: string,
    titleTag: string,
    uberType: number,
    postHeaderTextTag: string,
    placeholderTag: string,
    hidden?: boolean;
}

export const visage2SocialPostTypes : IVisage2SocialPostType[] = [{
    type: SpintrTypes.StatusType.Question,
    icon: "status-type-6",
    bgColor: "#F1F2FC",
    iconColor: "#787CDD",
    titleTag: "Fraga",
    uberType: SpintrTypes.UberType.Status,
    postHeaderTextTag: "QUESTION_POST_HEADER_TEXT",
    placeholderTag: "COMPOSER_PLACEHOLDER",
    hidden: true,
}, {
    type: SpintrTypes.StatusType.SomethingFun,
    icon: "status-type-7",
    bgColor: "#FEF8EC",
    iconColor: "#F1AE15",
    titleTag: "SOMETHING_FUN",
    uberType: SpintrTypes.UberType.SomethingFun,
    postHeaderTextTag: "SOMETHING_FUN_POST_HEADER_TEXT",
    placeholderTag: "START_COMPOSER_PLACEHOLDER_TEXT_SOMETHING_FUN"
}, {
    type: SpintrTypes.StatusType.Kudos,
    icon: "status-type-9",
    bgColor: "#FCEEF5",
    iconColor: "#FC5371",
    titleTag: "KUDOS",
    uberType: SpintrTypes.UberType.Kudos,
    postHeaderTextTag: "KUDOS_POST_HEADER_TEXT",
    placeholderTag: "START_COMPOSER_PLACEHOLDER_TEXT_KUDOS"
}, {
    type: SpintrTypes.StatusType.BusinessDeal,
    icon: "status-type-8",
    bgColor: "#F1F2FC",
    iconColor: "#787CDD",
    titleTag: "CLOSED_NEW_DEAL",
    uberType: SpintrTypes.UberType.BusinessDeal,
    postHeaderTextTag: "BUSINESS_DEAL_POST_HEADER_TEXT",
    placeholderTag: "START_COMPOSER_PLACEHOLDER_TEXT_BUSINESS_DEAL"
}, {
    type: SpintrTypes.StatusType.SystemStatus,
    icon: "",
    bgColor: "",
    iconColor: "",
    titleTag: "",
    uberType: SpintrTypes.UberType.SystemStatus,
    postHeaderTextTag: "",
    placeholderTag: "START_COMPOSER_PLACEHOLDER_TEXT_SYSTEM_STATUS",
    hidden: true,
}];

export const visage2ComposerAttachButtons = [{
    type: "poll",
    titleTag: "Omrostning",
    icon: "chart-2",
    color: "#F1F2FC",
    iconColor: "#787CDD",
    uberTypeId: 33
}, {
    type: "image",
    titleTag: "Bild",
    icon: "gallery",
    color: "#FEF8EC",
    iconColor: "#F1AE15",
    uberTypeId: 49
}, {
    type: "file",
    titleTag: "Fil",
    icon: "document-1",
    color: "#FCEEF5",
    iconColor: "#FC5371",
    uberTypeId: 7
}, {
    type: "video",
    titleTag: "Video",
    icon: "video",
    color: "#F4F4F5",
    iconColor: "#727E94",
    uberTypeId: 13
// }, {
//     type: "search",
//     titleTag: "NEW_HEADER_SEARCH_PLACEHOLDER",
//     icon: "search-normal-1",
//     color: "#F4F4F5",
//     iconColor: "#727E94",
//     uberTypeId: 13
}];
