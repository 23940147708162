import React, { Component } from 'react';
import { connect } from "react-redux";
import { PageView } from "src/pages/views";
import { IApplicationState } from "src/spintr/reducer";
import { setStandaloneGroupHeader } from 'src/ui';
import { FillHeight, Scrollable } from "src/ui/components";

interface IProps {
    dispatch?: any,
    group: any,
    currentUser?: any;
}

class GroupPageView extends Component<IProps> {
    public async componentDidMount() {
        this.props.dispatch(setStandaloneGroupHeader(true));
    }

    componentWillUnmount(): void {
        this.props.dispatch(setStandaloneGroupHeader(false));
    }

    render() {
        const isGroupAdmin = !!this.props.group.members.find(
            (m) => m.id === this.props.currentUser.id && m.isAdministrator
        );

        return (
            <div className="GroupPageView">
                <div>
                    <PageView isGroupAdmin={isGroupAdmin} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps): IProps => ({
    ...props,
    group: state.groups.group,
    currentUser: state.profile.active,
});

export default connect(mapStateToProps)(GroupPageView);
