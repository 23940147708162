import { AxiosResponse, CancelToken, CancelTokenSource } from "axios";
import qs from "qs";
import api from "src/spintr/SpintrApi";

import { ISearchFeedback, SearchSorting } from "./types";

/**
 * Search endpoint base url
 */
const baseUrl = "/api/v1/search";

/**
 * Parameters used when executing a search query
 */
export interface ISearchParams {
    /**
     * Text to search for
     */
    query: string;

    /**
     * Skip x results. Defaults to 0
     */
    skip?: number;

    /**
     * Takes x results. Defaults to 21
     */
    take?: number;

    /**
     * Sorting mode. Defaults to Relevance
     */
    sort?: SearchSorting;

    /**
     * Only return from particular group. Defaults to null.
     */
    groupId?: number;

    /**
     * Only return group results. Defaults to false
     */
    onlyGroupResults?: boolean;

    /**
     * Widens the spread of types. Defaults to false
     */
    widenSpread?: boolean;

    /**
     * Image size in "{width}x{height}" format. Defaults to null
     */
    imageSize?: string;

    /**
     * Only return results of a given type. Defaults to 0.
     */
    objectType?: number;

    /**
     * Exclude given types. Defaults to emppty.
     */
    excludedTypes?: number[];

    /**
     * Only return results created before given date. Defaults to null
     */
    before?: Date;

    /**
     * Only return results created after given date. Defaults to null
     */
    after?: Date;

    /**
     * Only return results from a department. Defaults to null.
     */
    departmentId?: number;

    /**
     * Only return provided file types. Defaults to empty.
     */
    fileTypes?: Spintr.FileType[];
}

/**
 * Represents a search executer.
 */
export type SearchExecuter = (params: ISearchParams, cancelToken?: CancelToken) =>
    Promise<AxiosResponse<Spintr.ISearchResult[]>>;

/**
 * Executes a search based on provided params.
 * 
 * @param params Params to use for the request
 * @returns Spintr.ISearchResult array containing matches
 */
export const executeSearch: SearchExecuter = (params: ISearchParams, cancelToken?: CancelToken) =>
    api.get<Spintr.ISearchResult[]>(
        baseUrl, 
        { 
            params,
            paramsSerializer: (p) => qs.stringify(p, {
                arrayFormat: "indices",
            }),
            cancelToken
        },
    );

export const searchUsers: SearchExecuter = (params: ISearchParams, cancelToken?: CancelToken) =>
    api.get<Spintr.ISearchResult[]>(baseUrl, { params: {
        ...params,
        
        objectType: 1,
        },

        cancelToken
    });


export type MainSearchExecuter = (params: ISearchParams, cancelToken?: CancelToken) => Promise<AxiosResponse<Spintr.ISearchResult[]>>;
export const executeMainSearch: MainSearchExecuter = 
    (params: ISearchParams, cancelToken?: CancelToken) =>
        executeSearch({
            imageSize: "100x100",

            ...params,

            excludedTypes: (params.excludedTypes
                ? params.excludedTypes
                : [70]),
        }, cancelToken)
    ;

/**
 * A function that forwards feedback on a search
 */
export type SearchFeebackSubmitter = (model: ISearchFeedback) => Promise<void>;

/**
 * Sends feedback to the backend about a search
 * 
 * @param model The feedback model to send
 */
export const submitFeedback: SearchFeebackSubmitter =
    async (model: ISearchFeedback): Promise<void> => {
        try {
            await api.post(baseUrl + "/feedback", model);
        } catch (err) {
            /* ignored */
        }
    };