import { Icon, Label, TooltipHost } from "@fluentui/react";
import React, { FunctionComponent, useCallback } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IActiveUserProfile } from "src/profile/reducer";
import {
    setResponsiveMenuVisibleAction,
    setResponsiveProfileVisible,
    setResponsiveSearchVisible,
    setResponsiveShortcutsVisible,
    SpintrUser,
    UnstyledButton
} from "src/ui";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import "./ResponsiveHeader.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Style } from "src/ui/helpers";
import { startDeliverTrack } from "src/utils/spintrStartDeliverfunctions";
import { SpintrTypes } from "src/typings";
import { localize } from "src/l10n";
import cn from "classnames";

interface IDispatchProps {
    setResponsiveMenuVisibleAction: (visible: boolean) => void;
    setResponsiveProfileVisible: (visible: boolean) => void;
    setResponsiveSearchVisible: (visible: boolean) => void;
    setResponsiveShortcutsVisible: (visible: boolean)=>void
}

interface IOwnProps {
    logoTitle: string;
    logoUrl: string;
}

interface IStateProps {
    currentUser: IActiveUserProfile;
    instance: any;
    unreadItems: number;
}

type Props = IStateProps & IOwnProps & IDispatchProps;

const ResponsiveHeader: FunctionComponent<Props> = (props) => {
    const location = useLocation();

    const onMenuClick = useCallback(
        () => props.setResponsiveMenuVisibleAction(true),
        [props.setResponsiveMenuVisibleAction],
    );

    const onProfileClick = useCallback(
        () => props.setResponsiveProfileVisible(true),
        [props.setResponsiveProfileVisible],
    );

    const onShortcutsClick = useCallback(
        ()=> props.setResponsiveShortcutsVisible(true),
        [props.setResponsiveShortcutsVisible]
    )
    let classNames = ["ResponsiveHeader"];

    if (props.instance.get("useColorHeader") || !!props.instance.get("menuBackgroundColor")) {
        classNames.push("headerBGColor");
    }

    let logoUrl;

    let backgroundColor = "#FFFFFF";

    if (props.instance.get("useColorHeader")) {
        backgroundColor = props.instance.get("headerColor") as string;
    }

    let hasDarkBackground = getLightOrDarkColorBasedOnColor(backgroundColor,
        "a",
        "b") === "a";

    if (hasDarkBackground) {
        classNames.push("hasDarkBackground");

        logoUrl = !!props.instance.get("logoUrl") ?
            props.instance.get("logoUrl") :
            props.instance.get("colorLogoUrl");
    } else {
        logoUrl = !!props.instance.get("colorLogoUrl") ?
            props.instance.get("colorLogoUrl") :
            props.instance.get("logoUrl");
    }

    const isSupplierContact = props.currentUser.roles.indexOf("supplier") > -1;
    const useWhiteNotches = location.pathname.indexOf("/planner") === 0;

    return (
        <div id="ResponsiveHeader" className={classNames.join(" ")}>
            <div className="wrapper">
                {!isSupplierContact && (
                    <div className="menu-wrap">
                        <UnstyledButton
                            className="menu-button"
                            onClick={onMenuClick}
                        >
                            <Visage2Icon icon="menu-1" />
                            {props.unreadItems > 0 && (
                                <div
                                    className="unread-indicator"
                                    role="presentation"
                                />
                            )}
                        </UnstyledButton>
                    </div>
                )}
                <div className="logo-wrap">
                    <Link to="/">
                        <div className="logo">
                            <img
                                alt={props.logoTitle}
                                src={logoUrl}
                                title={props.logoTitle}
                            />
                        </div>
                    </Link>
                </div>
                <div className="right-buttons-wrap">
                    {!isSupplierContact && (       
                        <div className="shortcuts-wrap">
                            <UnstyledButton
                                className="shortcutsButton"
                                onClick={()=>{
                                    startDeliverTrack(SpintrTypes.InsightsType.OpenedShortcuts);
                                    onShortcutsClick()
                                }}
                            >
                                <div className={`shortcuts-link no-user-select`} id="shortcuts-link">
                                    <TooltipHost content={localize("Genvagar")}>
                                        <Visage2Icon icon="category-2" />
                                    </TooltipHost>
                                </div>
                            </UnstyledButton>
                        </div>
                    )}
                    <div className="profile-wrap" onClick={onProfileClick}>
                        <SpintrUser
                            hideText={true}
                            personalName={true}
                            imageUrl={props.currentUser.images.topBar}
                            name={props.currentUser.name}
                            size={32}
                            state={1}
                        />
                    </div>
                </div>
            </div>
            <div className="notches">
                <div className="notch-wrapper">
                    <div className={cn("notch-inner", { "white": useWhiteNotches })}></div>
                </div>
                <div className="notch-wrapper">
                    <div className={cn("notch-inner", { "white": useWhiteNotches })}></div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state) => ({
        currentUser: state.profile.active,
        instance: state.instance,

        unreadItems: (
            state.chat.conversations.items.filter(c => c.unread > 0).length
            + state.notification.unread),
    });

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = {
    setResponsiveMenuVisibleAction,
    setResponsiveProfileVisible,
    setResponsiveSearchVisible,
    setResponsiveShortcutsVisible
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveHeader);