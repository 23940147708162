import { SpintrTypes } from "src/typings";
import * as ConversationActions from './conversation-actions';
import { IUpdateGroupActivityActionPending } from "./conversation-actions";
import {
    IMessageReceivedAction,
    ISaveMessageFulfilledAction
} from './message-actions';
import { ChatReducerHandler } from "./types";

export const queryConversationsPending: ChatReducerHandler<ConversationActions.IQueryConversationsPendingAction> =
    (draft, action) => {
        draft.conversations.isLoading = true;

        if (action.meta.searchText && !action.meta.isLoadMore) {
            draft.conversations.items = draft.conversations.items.map(x => {
                return {
                    ...x,
                    isHidden: true
                }
            });
        }

        if (!action.meta.searchText && !action.meta.isLoadMore && !action.meta.isComplementary) {
            draft.conversations.items = [];
        }
    };

export const queryConversationsRejected: ChatReducerHandler<ConversationActions.IQueryConversationsRejectedAction> =
    (draft) => {
        draft.conversations.isLoading = false;
    };

export const queryConversationsFulfilled: ChatReducerHandler<ConversationActions.IQueryConversationsFulfilledAction> =
    (draft, action) => {
        draft.conversations.isLoading = false;
        draft.conversations.hasFetched = true;
        draft.conversations.items = draft.conversations.items.slice();

        draft.conversations.conversationsSkip = action.payload.data.conversationsSkip;
        draft.conversations.groupsSkip = action.payload.data.groupsSkip;
        draft.conversations.usersSkip = action.payload.data.usersSkip;

        action.payload.data.items.forEach(
            (conversation) => {
                const alreadyFetched = draft.conversations.items.some(
                    (c) =>  c.id !== 0 && c.id === conversation.id
                );

                if (alreadyFetched) {
                    draft.conversations.items = draft.conversations.items.map((c) => {
                        if (c.id === conversation.id) {
                            return {
                                ...c,
                                ...conversation,
                                lastRead: { ...c.lastRead, ...conversation.lastRead },
                                participants: [...c.participants, ...conversation.participants],
                                isHidden: false
                            };
                        } else {
                            return c;
                        }
                    });

                    return;
                }

                draft.conversations.items.push(conversation);
            }
        );

        draft.conversations.hasMore = action.payload.data.hasMore;
    };

export const getConversationFulfilled: ChatReducerHandler<ConversationActions.IGetConversationFulfilledAction> =
    (draft, action) => {
        const alreadyFetched = draft.conversations.items.some(
            (c) => c.id === action.payload.data.id
        );

        if (alreadyFetched) {
            draft.conversations.items = draft.conversations.items.map(
                (c) => c.id === action.payload.data.id
                    ? {
                        ...c,
                        ...action.payload.data,
                        isPinned: c.isPinned
                    }
                    : c,
            )
            return;
        }

        draft.conversations.items = draft.conversations.items.concat([
            action.payload.data
        ]);
    };

export const setTabState: ChatReducerHandler<ConversationActions.ISetTabStateAction> =
    (draft, action) => {
        const tabIndex = draft.conversations.tabIds.findIndex(
            (tab) => action.id === tab.id
        );

        if (tabIndex === -1 && action.open === false) {
            return;
        }

        if (tabIndex > -1 && action.open !== false) {
            draft.conversations.tabIds = draft.conversations.tabIds.slice();

            if (action.open) {
                draft.conversations.tabIds.push({
                    ...draft.conversations.tabIds.splice(tabIndex, 1)[0],

                    minimized: action.minimized,
                });
            } else {
                draft.conversations.tabIds[tabIndex] = {
                    ...draft.conversations.tabIds[tabIndex],
                    minimized: action.minimized,
                }
            }

            return;
        }

        if (action.open !== false) {
            draft.conversations.tabIds = draft.conversations.tabIds.concat([{
                id: action.id,
                minimized: action.minimized,
            }]);

            return;
        }

        draft.conversations.tabIds = draft.conversations.tabIds.slice();
        draft.conversations.tabIds.splice(tabIndex, 1);
    };

export const saveMessageFulfilled: ChatReducerHandler<ISaveMessageFulfilledAction> =
    (draft, action) => {
        const smfItemIndex = draft.conversations.items.findIndex(
            (c) => c.id === action.meta.message.conversationId
        );

        if (smfItemIndex === -1) {
            return;
        }

        draft.conversations.items = draft.conversations.items.slice();

        draft.conversations.items[smfItemIndex] = {
            ...draft.conversations.items[smfItemIndex],
            lastMessage: action.payload.data,
            unread: action.meta.ownId === action.meta.message.user.id
                ? draft.conversations.items[smfItemIndex].unread
                : (draft.conversations.items[smfItemIndex].unread || 0) + 1,
        }
    };

export const updateGroupActivity: ChatReducerHandler<IUpdateGroupActivityActionPending> =
    (draft, action) => {
        const conversation = draft.conversations.items.find((c) => c.group?.id === action.meta);

        if (!conversation) {
            return;
        }

        conversation.group.unread = 0;
    }

export const addMessageFulfilled: ChatReducerHandler<IMessageReceivedAction> =
    (draft, action) => {
        const amfItemIndex = draft.conversations.items.findIndex(
            (c) => c.id === action.meta.message.conversationId
        );

        if (amfItemIndex === -1) {
            return;
        }

        draft.conversations.items = draft.conversations.items.slice();

        draft.conversations.items[amfItemIndex] = {
            ...draft.conversations.items[amfItemIndex],
            lastMessage: action.meta.message,
            unread: action.meta.ownId === action.meta.message.user.id
                ? draft.conversations.items[amfItemIndex].unread
                : (draft.conversations.items[amfItemIndex].unread || 0) + 1,
        }
    };

export const createConversationPeding: ChatReducerHandler<ConversationActions.ICreateConversationPendingAction> =
    (draft, action) => {
        draft.conversations.items = draft.conversations.items.concat([
            action.meta
        ]);
    };

export const createConversationRejected: ChatReducerHandler<ConversationActions.ICreateConversationRejectedAction> =
    (draft, action) => {
        draft.conversations.items = draft.conversations.items.filter(
            (c) => c.id !== action.meta.id,
        );
    };

export const createConversationFulfilled: ChatReducerHandler<ConversationActions.ICreateConversationFulfilledAction> =
    (draft, action) => {
        const createdConvAlreadyAdded = draft.conversations.items.some(
            (c) => c.id === action.payload.data.id,
        );

        const tempConvIndex = draft.conversations.items.findIndex(
            (c) => c.id === action.meta.id,
        );

        if (tempConvIndex === -1) {
            if (!createdConvAlreadyAdded) {
                draft.conversations.items = draft.conversations.items.concat([action.payload.data]);
            }
            return;
        }

        draft.conversations.items = draft.conversations.items.slice();
        draft.conversations.items.splice(tempConvIndex, 1, action.payload.data);

        const tempTabIdIndex = draft.conversations.tabIds.findIndex(
            (t) => t.id === action.meta.id,
        );

        if (tempTabIdIndex === -1) {
            return;
        }

        draft.conversations.tabIds = draft.conversations.tabIds.map(
            (tab) => tab.id !== action.meta.id
                ? tab
                : ({
                    ...tab,

                    id: action.payload.data.id,
                }),
        );
    };

export const addRemoteConversation: ChatReducerHandler<ConversationActions.IAddRemoteConversationAction> =
    (draft, action) => {
        const remoteAlreadyAdded = draft.conversations.items.some(
            (c) => c.id === action.conversation.id,
        );

        if (remoteAlreadyAdded) {
            return;
        }

        draft.conversations.items = draft.conversations.items.concat([action.conversation]);
    };

export const markAsReadPending: ChatReducerHandler<ConversationActions.IMarkAsReadPendingAction> =
    (draft, action) => {
        draft.conversations.items = draft.conversations.items.map((c) => {
            if (c.id !== action.meta.conversationId) {
                return c;
            }

            return {
                ...c,
                unread: 0
            }
        });
    };

export const markAsReadRejected: ChatReducerHandler<ConversationActions.IMarkAsReadRejectedAction> =
    (draft, action) => {
        if (!action.meta.prevUnread) {
            return;
        }

        draft.conversations.items = draft.conversations.items.map((c) => {
            if (c.id !== action.meta.conversationId) {
                return c;
            }

            return {
                ...c,
                unread: c.unread + action.meta.prevUnread,
            }
        });
    };

export const lastReadReceived: ChatReducerHandler<ConversationActions.ILastReadReceivedAction> =
    (draft, action) => {
        draft.conversations.items = draft.conversations.items.map(
            (c) => {
                if (c.id !== action.conversationId) {
                    return c;
                }

                const lastRead: { [userId: number]: number } = {
                    ...c.lastRead,

                    [action.userId]: action.messageId,
                };

                const c2 = {
                    ...c,
                    lastRead
                }

                return c2;
            }
        )
    };

export const addIsTyping: ChatReducerHandler<ConversationActions.IAddIsTypingAction> =
    (draft, action) => {
        draft.conversations.isTyping[action.conversationId] = {
            ...(draft.conversations.isTyping[action.conversationId] || {}),
            [action.userId]: new Date()
        }
    };

export const removeIsTyping: ChatReducerHandler<ConversationActions.IRemoveIsTypingAction> =
    (draft, action) => {
        if (draft.conversations.isTyping[action.conversationId]) {
            draft.conversations.isTyping[action.conversationId][action.userId] = null;
        }
    };

export const setUnreadCountPersonConversations: ChatReducerHandler<ConversationActions.ISetUnreadCountPersonConversations> =
    (draft, action) => {
        draft.conversations.unreadCountPersonConversations = action.meta;
    };

export const setUnreadCountGroupConversations: ChatReducerHandler<ConversationActions.ISetUnreadCountGroupConversations> =
    (draft, action) => {
        draft.conversations.unreadCountGroupConversations = action.meta;
    };

export const updateConversation: ChatReducerHandler<ConversationActions.IUpdateConversation> =
    (draft, action) => {
        draft.conversations.items = draft.conversations.items.map((c) => {
            if (c.id === action.conversation.id) {
                return action.conversation;
            }

            return c;
        });
    }

export const setComposerReplyMessage: ChatReducerHandler<ConversationActions.ISetComposerReplyMessage> =
    (draft, action) => {
        draft.conversations.messageToEdit[action.conversationId] = undefined;
        draft.conversations.messageToReplyTo[action.conversationId] = action.message;
    }

export const setComposerEditMessage: ChatReducerHandler<ConversationActions.ISetComposerEditMessage> =
    (draft, action) => {
        draft.conversations.messageToEdit[action.conversationId] = action.message;
        draft.conversations.messageToReplyTo[action.conversationId] = undefined;
    }
