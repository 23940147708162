import React, { Fragment, ReactElement } from "react";
import { ConditionalProps } from "./Conditional.types";

function Conditional({ children, condition }: ConditionalProps): ReactElement {
    if (!condition) {
        return null;
    }

    return <Fragment>{children}</Fragment>;
}

export default Conditional;
